import React, { Component } from "react";
import { connect } from "react-redux";
import Sortable from "react-sortablejs";
import { FormattedMessage } from "react-intl";
import Testcopyrights from "../../Testcopyrights"; // used to show test copyright text under test

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer
} from "recharts";
import { PieChart, Pie, Cell } from "recharts";

import {
  fetchWorkCulture,
  saveWorkCulture,
  WorkCultureResult,
  getWorkCultureResult
} from "../../../actions/Target";
import Hash from "../../../helper/Hashing";

class WorkCultureReslutlink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      error: false,
      errorMsg: "",
      loading: true,
      user_id: null,
      language: null,
      testcode: this.props.testcodes !=null ? this.props.testcodes : localStorage.getItem('sharedTestId'),
      result: [],
      ques0: "",
      ques1: "",
      ques2: "",
      ques3: "",
      ques4: "",
      ques5: "",
      question0: [],
      question0Answers: [],
      question1: [],
      question1Answers: [],
      question2: [],
      question2Answers: [],
      question3: [],
      question3Answers: [],
      question4: [],
      question4Answers: [],
      question5: [],
      question5Answers: [],
      set1: [
        { label: "Expertise", score: "79.2" },
        { label: "Leadership", score: "47.9" },
        { label: "Autonomy", score: "54.2" },
        { label: "Security", score: "58.3" },
        { label: "Entrepreneurship", score: "56.3" },
        { label: "Service to others", score: "37.5" },
        { label: "Challenges", score: "52.1" },
        { label: "Harmonious lifestyle", score: "64.6" }
      ],

      set2: [
        { label: "Expertise", score: "59.2" },
        { label: "Leadership", score: "57.9" },
        { label: "Autonomy", score: "55.2" },
        { label: "Security", score: "42.3" },
        { label: "Entrepreneurship", score: "64.3" },
        { label: "Service to others", score: "45.5" },
        { label: "Challenges", score: "55.1" },
        { label: "Harmonious lifestyle", score: "55.6" }
      ],
      corrData: [],
      corrValue: [{ name: "Group A", value: 40 }]
    };
  }

  componentDidMount() {
    // let language;
    // if (this.props.lang === "en") language = "english";
    // if (this.props.lang === "fr") language = "french";
    const { hr } = this.props;
    let hrId = null;
    let code = null;
    if (hr) {
      code = hr;
      hrId = Hash.decrypt(hr);
    }

    const token = localStorage.getItem("token");
    
      this.setState({ error: false, loading: true });
      let language;
      if (this.props.lang === "en") language = "english";
      if (this.props.lang === "fr") language = "french";
      var data = {
        question1: this.state.question0Answers,
        question2: this.state.question1Answers,
        question3: this.state.question2Answers,
        question4: this.state.question3Answers,
        question5: this.state.question4Answers,
        question6: this.state.question5Answers,
        user_id: localStorage.getItem("userId"),
        language: language,
        testcode:this.state.testcode
      };
      this.props.getWorkCultureResult(token, data, response => {
        if (response === "success") {
          this.setState({ loading: false });
          this.props.stepUp();
        }
      });
  
      // // get result
      // const token = localStorage.getItem("token");
      // this.props.WorkCultureResult(token, response => {
      //   console.log(response);
      //   this.setState({ loading: false });
      // });

      // const { set1, set2 } = this.state;
      // var userValues = [];
      // var hrValues = [];
      // var corrData = [];
      // for (var i = 0; i < set1.length; i++) {
      //   userValues.push(set1[i].score);
      //   hrValues.push(set2[i].score);
      //   corrData.push({
      //     name: set1[i].label,
      //     user: set1[i].score,
      //     hr: set2[i].score
      //   });
      // }

      // this.setState({ corrData: corrData });

      // var getcoef = this.correlationCoefficient(userValues, hrValues);
      // //console.log('Inside correlationCoefficient '+getcoef);
    
  }

  correlationCoefficient(x, y) {
    var sum_x = 0;
    var sum_y = 0;
    var sum_xy = 0;
    var squareSum_x = 0;
    var squareSum_y = 0;

    for (let i = 0; i < x.length; i++) {
      sum_x = sum_x + x[i];
      sum_y = sum_y + y[i];
      sum_xy = sum_xy + x[i] * y[i];
      squareSum_x = squareSum_x + x[i] * x[i];
      squareSum_y = squareSum_y + y[i] * y[i];
    }
    var n = x.length;

    // formula
    var coeff =
      parseFloat(n * sum_xy - sum_x * sum_y) /
      Math.sqrt(
        (n * squareSum_x - sum_x * sum_x) * (n * squareSum_y - sum_y * sum_y)
      );
    return coeff.toFixed(2);
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.WorkCulture !== prevProps.WorkCulture) {
      console.log(this.props.Motivation);
        this.props.WorkCulture.data.map((elem, index) =>
          this.setState({
            ["question" + index]: elem.options
          })
        );
    }
  }

  componentWillUnmount() {
    this.setState({
      loading: false
    });
  }

  renderQuestions() {
    let { page } = this.state;
    //console.log(this.state);
    var questions = [0, 1, 2, 3, 4, 5];
    return questions.map((value, i) => {
      let ques = "question" + i;
      let quesAns = "question" + i + "Answers";

      const question = this.state[ques].map((val, key) => (
        //const question = Object.entries(this.state[ques]).map(([key, val]) => (
        <li className="list-group-item" key={key} data-id={val}>
          {val}
        </li>
      ));
      const questionAnswers = this.state[quesAns].map((val, key) => (
        <li className="list-group-item item-clr" key={key} data-id={val}>
          {val}
        </li>
      ));
      return (
        <React.Fragment key={value}>
          {page === i && (
            <div className="row p-3 mb-3">
              <div className="col-md-12 mx-auto mb-0">
                <div className="jumbotron p-2">
                  <p className="lead">{this.state["ques" + i]}</p>
                </div>
              </div>

              <div className="col-md-5 m-auto">
                <Sortable
                  options={{
                    animation: 150,
                    sort: false,
                    group: {
                      name: "clone2",
                      pull: true,
                      put: false
                    }
                  }}
                  className="list-group shadow minHeight-wc"
                  onChange={items => {
                    this.setState({ [ques]: items });
                  }}
                  tag="ul"
                >
                  {question}
                </Sortable>
              </div>
              <div className="col-md-2 m-auto pr-0 dragdropno">
                <ul className=" list-group minHeight-wc text-right bg-trans">
                  <li className="list-group-item border-0">n°1</li>
                  <li className="list-group-item border-0">n°2</li>
                  <li className="list-group-item border-0">n°3</li>
                  <li className="list-group-item border-0">n°4</li>
                  <li className="list-group-item border-0">n°5</li>
                  <li className="list-group-item border-0">n°6</li>
                </ul>
              </div>
              <div className="col-md-5 m-auto dragdropno1">
                <Sortable
                  options={{
                    animation: 150,
                    group: {
                      name: "clone2",
                      pull: true,
                      put: true
                    }
                  }}
                  className="list-group minHeight-wc shadow"
                  onChange={items => {
                    this.setState({ [quesAns]: items });
                  }}
                  tag="ul"
                >
                  {questionAnswers}
                </Sortable>
                {/* <button onClick={e => this.getNewSorted(e)}>Get result</button> */}
              </div>
              <div className="col-md-12 mx-auto mb-0">
                  <div className="jumbotron  mb-0 p-1 mt-2">
                      <p className="mb-0">
                      <FormattedMessage id="ind.target.workculture.expl" defaultMessage="Please select each icon in the left hand column by a mouse click, and drag it towards the appropriate box in the right hand column (N°1 is the most important). You can move icons around in the right hand column until they are in the right order"/>
                      </p>
                  </div>
              </div>              
            </div>
          )}
        </React.Fragment>
      );
    });
  }

  nextQuestion = () => {
    let { page } = this.state;
    let quesAns = "question" + page + "Answers";

    if (this.state[quesAns].length < 6) {
      this.setState({ error: true, errorMsg: "Drag all items" });
      setTimeout(
        function() {
          this.setState({ error: false });
        }.bind(this),
        5000
      );
    } else {
      this.setState({ page: page + 1, error: false });
    }
  };

  submitWorkCulture = () => {
    const token = localStorage.getItem("token");

    let { page } = this.state;
    let quesAns = "question" + page + "Answers";

    if (this.state[quesAns].length < 6) {
      this.setState({ error: true, errorMsg: "Drag all items" });
      setTimeout(
        function() {
          this.setState({ error: false });
        }.bind(this),
        5000
      );
    } else {
      this.setState({ error: false, loading: true });
      let language;
      if (this.props.lang === "en") language = "english";
      if (this.props.lang === "fr") language = "french";
      var data = {
        question1: this.state.question0Answers,
        question2: this.state.question1Answers,
        question3: this.state.question2Answers,
        question4: this.state.question3Answers,
        question5: this.state.question4Answers,
        question6: this.state.question5Answers,
        user_id: localStorage.getItem("userId"),
        language: language,
        testcode:this.state.testcode
      };
      this.props.saveWorkCulture(token, data, response => {
        if (response === "success") {
          this.setState({ loading: false });
          this.props.stepUp();
        }
      });
    }
  };

  renderButton() {
    let { page } = this.state;
    if (page < 5) {
      return (
        <div className="d-table m-auto p-90">
          <button className="btn btn-info mt-3" onClick={this.nextQuestion}>
          <FormattedMessage id="next" defaultMessage="NEXT"/>
          </button>
        </div>
      );
    } else {
      return (
        <div className="d-table m-auto p-90">
          <button className="btn btn-info" onClick={this.submitWorkCulture}>
          <FormattedMessage id="submit" defaultMessage="SUBMIT"/>
          </button>
        </div>        
      );
    }
  }

  render() {
    let { error, page, loading, errorMsg, corrData } = this.state;
    let angle = (80 / 100) * 360;

    const data = [{ name: "name", value: 51 }, { name: "other", value: 49 }];
    const COLORS = ["#61bf93", "#ededed"];

    if (loading)
      return (
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              <div className="text-center">
                <div
                  className="spinner-border m-5"
                  role="status"
                  style={{ width: "3rem", height: "3rem" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    return (
      <React.Fragment>
        <h2 className="text-center mb-1">
        <FormattedMessage id="ind.target.workculture.title" defaultMessage="Work culture"/>
        </h2>

        <div className="col-lg-8 col-md-10 col-sm-12 m-auto">
           {/*{error && <div className="alert alert-danger">{errorMsg}</div>}
          <PieChart width={140} height={155}>
            <Pie
              data={data}
              dataKey="value"
              cx={65}
              cy={65}
              labelLine={false}
              innerRadius={57}
              outerRadius={63}
              fill="#ededed"
              textAnchor="end"
            >
              {data.map((entry, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]} />
              ))}
              <Label width={30} position="center">
                22 %
              </Label>
            </Pie>
          </PieChart>
          <ResponsiveContainer width="70%" aspect={4.0 / 3.0}>
            <LineChart
              data={corrData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" interval={0} angle={-45} textAnchor="end" />
              <YAxis type="number" domain={[0, 100]} />
              <Tooltip />
              <Legend wrapperStyle={{ paddingTop: "80px" }} />
              <Line
                type="monotone"
                dataKey="user"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
              <Line type="monotone" dataKey="hr" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>

          <div style={{ width: "100%", height: 200 }}>
            <ResponsiveContainer width="40%">
              <PieChart>
                <Pie
                  data={this.state.corrValue}
                  nameKey="name"
                  dataKey="value"
                  innerRadius="60%"
                  outerRadius="70%"
                  startAngle={0}
                  endAngle={angle}
                  fill="#8884d8"
                >
                  <Label width={30} position="center">
                    Group AA
                  </Label>
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div> */}

          {this.renderQuestions()}
          {this.renderButton()}
        </div>
        <Testcopyrights />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  //console.log(state);

  return {
    isSignedIn: state.auth.isSignedIn,    
    WorkCulture: state.target.WorkCultureData,
    lang: state.locale.lang
  };
};

export default connect(
  mapStateToProps,
  { fetchWorkCulture, saveWorkCulture, WorkCultureResult,getWorkCultureResult }
)(WorkCultureReslutlink);
