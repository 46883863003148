import React, { Component } from "react";
import { connect } from "react-redux";
// import { useParams } from "react-router-dom";
// import $ from "jquery";
//import { history } from "../../../helper/History";
//import html2canvas from "html2canvas";
//import * as jsPDF from "jspdf";
import { FormattedMessage } from "react-intl";


import Motivation from "./Motivation";
import Personality from "./Personality";
import Communication from "./Communication";
import MotivationResult from "./result/MotivationResult";
import PersonalityResult from "./result/PersonalityResult";
import CommunicationResult from "./result/CommunicationResult";
import CombinedResult from "./result/CombinedResult";
import Hash from "../../../helper/Hashing";
import { updateTestConsume } from "../../../actions";
import Testcopyrights from "../../Testcopyrights"; // used to show test copyright text under test 
import DoAlert from "../../DoAlert"; // used to render the do another test button
import PrintPdf from "../../PrintPdf"; // used to print resutl PDF

class Talent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test: 1,
      loading: false,
      loader2: false,
      showResult: false,
      hr: null
    };

    
  }
  componentDidMount() {
    
    const { hr } = this.props.match.params;
    if (hr) {
      this.setState({ hr: hr });
    }
    //console.log('Response => '+JSON.stringify(this.props));
    let testType = localStorage.getItem('testType');
    //console.log('Response => '+JSON.stringify(this.props.location)+' type '+testType);    
    // set the test type to state 
    this.setState({ testType: testType });     
  }

  // printResult(){
  //   this.setState({ loader2: true });
  //   var divHeight = document.querySelector('#talentTest').offsetWidth;
  //   var divWidth = document.querySelector('#talentTest').offsetHeight;
  //   var ratio = divHeight / divWidth;    
  //   html2canvas(document.querySelector('#talentTest'),{height: divHeight, width: divWidth,}).then(canvas => {
  //       var image = canvas.toDataURL("image/jpeg");
  //       var doc = new jsPDF(); // using defaults: orientation=portrait, unit=mm, size=A4
  //       var width = doc.internal.pageSize.getWidth();    
  //       var height = doc.internal.pageSize.getHeight();
  //       height = ratio * width;
  //       doc.addImage(image, 'JPEG', 0, 0, width-10, height-10);
  //       doc.save('talentTestResult.pdf');
  //       this.setState({ loader2: false });              
  //   });
  // }

  // final working funcation 
  // printResult(){
  //   this.setState({ loader2: true });
  //   let CurrentDate   =  new Date();
  //   let copyRightDate = CurrentDate.getFullYear();

  //   var pilotRhLogo = new Image();
  //   pilotRhLogo.src = 'assets/images/logo.png';
    
  //   var PDF_Heighti = document.querySelector('#talentTest').offsetWidth;
  //   //var HTML_Width = $("#talentTest").width();
  //   var HTML_Width = 800;
  //   console.log('HTML_Width =>'+HTML_Width);
  //   var HTML_Height = $("#talentTest").height();
  //   console.log('HTML_Height =>'+HTML_Height);    
  //   //console.log(HTML_Height);
  //   var top_left_margin = 15;
  //   var PDF_Width = HTML_Width+(top_left_margin*2);
  //   //var PDF_Height = 2000;
  //   var PDF_Height = HTML_Height+(top_left_margin*2);    
  //   console.log('PDF_HxW'+PDF_Height+' x '+PDF_Width);
  //   var canvas_image_width = HTML_Width;
  //   var canvas_image_height = HTML_Height;
    
  //   var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;
  //   console.log('totalPDFPages =>'+totalPDFPages);
    
  //   //html2canvas($("#franchiseTest")[0],{allowTaint:true}).then(function(canvas) {
  //   html2canvas($("#talentTest")[0],{allowTaint:true}).then((canvas) => {
  //     canvas.getContext('2d');
  //     //console.log(canvas.height+" "+canvas.width);
  //     var imgData = canvas.toDataURL("image/jpeg", 1.0);
  //     var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
  //     pdf.addImage(pilotRhLogo, 'PNG', 330, 20);
  //     pdf.addImage(imgData, 'JPG', 10, 80);
  //     for (var i = 1; i <= totalPDFPages; i++) {
  //       pdf.addPage(PDF_Width, PDF_Height);
  //       pdf.addImage(imgData, 'JPG', top_left_margin, -PDF_Heighti+(top_left_margin));
  //     }
  //     // pdf.addImage(imgData, 'JPG', 15, 20,canvas_image_width,canvas_image_height);
  //     // for (var i = 1; i <= totalPDFPages; i++) {
  //     //   pdf.addPage(PDF_Width, PDF_Height);
  //     //   pdf.addImage(imgData, 'JPG', top_left_margin, -PDF_Heighti+(top_left_margin),canvas_image_width,canvas_image_height);
  //     // }
  //     pdf.setFontSize(12);
  //     pdf.setTextColor(108, 117, 125);
  //     pdf.text(160, (PDF_Height-30),'© Copyright 5W PILOT RH SOLUTIONS '+ CurrentDate.getFullYear() +' – All right reserved – www.pilotrhsolutions.com.');
  //     pdf.setTextColor(33, 37, 41);
  //     pdf.text(310, (PDF_Height-10), 'DATE OF TEST '+("0" + (CurrentDate.getDate() + 1)).slice(-2) +'/'+ ("0" + (CurrentDate.getMonth() + 1)).slice(-2)  +'/'+ CurrentDate.getFullYear());
  //     pdf.save("MyTalentTestTestResult.pdf");
  //     this.setState({ loader2: false });                      
  //   });
  // }

  // printResult(){
  //   this.setState({ loader2: true });    

  //   let CurrentDate   =  new Date();
  //   let copyRightDate = CurrentDate.getFullYear();

  //   var pilotRhLogo = new Image();
  //   pilotRhLogo.src = 'assets/images/logo.png';

  //   var PDF_Heighti   = document.querySelector('#talentTest').offsetWidth;
  //   //var HTML_Width    = $("#talentTest").width();
  //   var HTML_Width    = 900;    
  //   //console.log('Html width => '+HTML_Width);
  //   var HTML_Height   = $("#talentTest").height();
  //   //console.log('Html height => '+HTML_Height);
  //   var top_left_margin = 10;
  //   var PDF_Width = HTML_Width+(top_left_margin*2);
  //   //var PDF_Height = 2000;
  //   var PDF_Height = HTML_Height+(top_left_margin*2);
  //   //console.log('PDF Height x Width => '+PDF_Height+'x'+PDF_Width);
  //   var canvas_image_width  = HTML_Width;
  //   var canvas_image_height = HTML_Height;
    
  //   var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;
  //   //console.log('NoOfPage => '+totalPDFPages);
    
  //   //html2canvas($("#talentTest")[0],{allowTaint:true}).then(function(canvas) {
  //   html2canvas($("#talentTest")[0],{allowTaint:true}).then((canvas) => {
  //     canvas.getContext('2d');
  //     //console.log(canvas.height+" "+canvas.width);
  //     var imgData = canvas.toDataURL("image/jpeg", 1.0);
  //     var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
  //     //var pdf = new jsPDF();
  //     pdf.addImage(pilotRhLogo, 'PNG', 330, 10);
  //     pdf.addImage(imgData, 'JPG', 10, 80);
  //     for (var i = 1; i <= totalPDFPages; i++) {
  //       pdf.addPage(PDF_Width, PDF_Height);
  //       pdf.addImage(imgData, 'JPG', top_left_margin, -PDF_Heighti+(top_left_margin));
  //     }
  //     pdf.setFontSize(15);
  //     pdf.text(260, (PDF_Height-30),'Copyright '+ CurrentDate.getFullYear() +' All rights Reserved 5w Pilot Rh.');
  //     pdf.text(360, (PDF_Height-10), CurrentDate.getDate() +'/'+ CurrentDate.getMonth() +'/'+ CurrentDate.getFullYear());
  //     //pdf.text(107,68,currentDate);
  //     pdf.save("MyTalentTestTestResult.pdf");
  //     this.setState({ loader2: false });
  //   });
  // }

  renderAnotherTestButtons() {
    //console.log('Inisde renderAnotherTestButtons '+this.state.testType);
    const buttonName =  <FormattedMessage id="btn.do.another.test" defaultMessage="DO ANOTHER TEST"/>                    
    return (
      <DoAlert 
        type={this.state.testType} 
        buttonName={buttonName}
        className="btn btn-warning mt-3 mx-3 w-50"
        canvasSelector="talentTest"
        pdfName="MyTalentTestTestResult"
       />
    );
  }
  
  renderContactCoachButtons() {
    //console.log('Inisde renderContactCoachButtons '+this.state.testType);
    const buttonName =  <FormattedMessage id="btn.contact.a.coach" defaultMessage="CONTACT A COACH"/>                                
    return (
      <DoAlert 
        type={this.state.testType} 
        buttonName={buttonName}
        className="btn btn-info mt-3 w-50"
        target="_blank"
        href="/contact-to-coach"
        canvasSelector="talentTest"
        pdfName="MyTalentTestTestResult"        
      />
    );
  }  

  nextTest() {
    let prevTest = this.state.test;
    prevTest = prevTest + 1;

    this.setState({ test: prevTest });
  }

  getResult = () => {
    this.setState({ loading: true });
    const { hr } = this.state;
    let user_id = 0;
    let client_id = 0;
    let code = 0;
    if (hr) {
      user_id = Hash.decrypt(hr);
      client_id = localStorage.getItem("userId");
      code = hr;
    } else {
      user_id = localStorage.getItem("userId");
    }

    const token = localStorage.getItem("token");
    const submitData = {
      user_id: user_id,
      client_id: client_id,
      test: "Talent",
      code: code
    };
    this.props.updateTestConsume(token, submitData, resp => {
      if (resp === "success") {
        this.setState({
          showResult: true,
          loading: false
        });
      }
    });
  };
  

  render() {
    let { test, showResult, hr, loading, loader2 } = this.state;
    let {
      motivationResult,
      personalityResult,
      communicationResult,
      communicationTest,
      combinedResults
    } = this.props;
    var showTest;
    //console.log('Inside render '+test);
    //console.log('Talent test '+ test);
    if (test === 1) {
      showTest = <Motivation stepUp={this.nextTest.bind(this)} hr={hr} history={this.props.history} />;
    } else if (test === 2) {
      showTest = <Personality stepUp={this.nextTest.bind(this)} hr={hr} />;
    } else if (test === 3) {
      showTest = <Communication stepUp={this.nextTest.bind(this)} hr={hr} />;
    } else {
      showTest = (
        <React.Fragment>
          <section className="position-relative top-100">
            <div className="row d-flex p-0 m-0" id="mb-space">
              <div className="col-lg-12 col-md-12 p-0 m-0">
                <h2 className="text-center mb-1">Talent Test</h2>
                <div className="col-lg-8 col-md-10 col-sm-12 m-auto text-center">
                  <button className="btn btn-info" onClick={this.getResult}>
                    <FormattedMessage id="get_result" defaultMessage="Get Result"/>
                  </button>
                </div>
                <Testcopyrights />                
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }
    if (loading)
      return (
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              <div className="text-center">
                <div
                  className="spinner-border m-5"
                  role="status"
                  style={{ width: "3rem", height: "3rem" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      );

    if (showResult)
      return (
        <React.Fragment>
          <section className="position-relative top-100">
            <div className="row d-flex p-0 m-0" id="mb-space">
              <div className="col-lg-12 col-md-12 p-0 m-0" >
                <div className="col-lg-8 col-md-10 col-sm-12 m-auto m-90">
                  <div id="talentTest">
                    <h2 className="text-center mb-1">
                      <FormattedMessage id="talent.result.title" defaultMessage="Talent Test Result"/>
                    </h2>
                      <MotivationResult result={motivationResult}  testType={this.state.testType}/>
                      <PersonalityResult result={personalityResult} testType={this.state.testType}/>
                      <CombinedResult result={combinedResults}  testType={this.state.testType}/>
                      <CommunicationResult
                        result={communicationResult}
                        label={communicationTest.quesLabelArr}
                        testType={this.state.testType}
                      />
                    </div>
                    <div className="col-lg-8 col-md-10 col-sm-12 mx-auto text-center mb-4 pb-45 d-flex justify-content-center">
                      <PrintPdf  type={this.state.testType}  canvasSelector="talentTest"  pdfName="MyTalentTestTestResult"/>
                      {/* <button onClick={e => this.printResult(e)}  className="btn btn-info mt-3">
                        SAVE YOUR RESULTS
                      </button> */}
                      {this.renderAnotherTestButtons()}
                      {this.renderContactCoachButtons()}
                      {loader2 && (
                        <div className="position-absolute result-loader">
                          <div
                            className="spinner-border"
                            role="status"
                            style={{ width: "2.5rem", height: "2.5rem" }}
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      )}
                    </div>
                </div>
                <Testcopyrights />                
              </div>
            </div>
          </section>
        </React.Fragment>
      );

    return (
      <React.Fragment>
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0">{showTest}</div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  //console.log(state);

  return {
    motivationResult: state.talent.MotivationResult,
    personalityResult: state.talent.PersonalityResult,
    combinedResults: state.talent.CombineResult.data,
    communicationResult: state.talent.CommunicationResult,
    communicationTest: state.talent.CommunicationData.data,
  };
};

export default connect(
  mapStateToProps,
  { updateTestConsume }
)(Talent);
