import React, { Component } from "react";
import { Link } from "react-router-dom";
//import $ from "jquery";
import {
  FormattedMessage,
  FormattedHTMLMessage,
  IntlProvider,
} from "react-intl";
import Carousel from "react-bootstrap/Carousel";
import Footer from "./includes/Footer";
import Register from "./includes/Register";
import { history } from "../helper/History";
import $ from "jquery";
import ScrollIntoView from "react-scroll-into-view";
//import { FormattedHTMLMessage } from "react-intl";

class PreSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dis: "",
    };
    window.scrollTo(0, 0); // scroll page to top
  }


  showRegister(e) {
    var userType = localStorage.getItem("role");
    if (!userType) {
      history.push("/register");
     
    }
  }

  sendparam() {
    localStorage.setItem("classic", "classic");
  }
  showRegister2(e) {
    console.log("in " + e);
    $("#loginModal").modal("show");
    $("#register-form").delay(100).fadeIn(100);
    $("#login-form").fadeOut(100);
    $("#login-form-link").removeClass("active");
    console.log("Inside Header " + e);
    if (e === "#register-form-link") {
      $(".modal-title").text("SIGNUP");
      $(e).addClass("active");
    } else {
      $(e.target).addClass("active");
    }
  }

  handleClick() {
    const price = "unique";
    const element = document.getElementById(price);
    console.log(element);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section

      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  render() {
    return (
      <React.Fragment>
        <section
          id="wrapper"
          className="icon-boxed1 teaser-box  graph-box sourcing-page"
        >
          <div className=" ">
            <div className="">
              <div class="col-lg-12" style={{ marginTop:"9%" }}>
                <div className="col-lg-4 col-md-4 float-left ml-3">
                  <div
                    className="icon-box icon-blue-box text-left hero-bg box-shadow sourcing-page"
                    style={{
                      backgroundColor: "#18B6CE",
                      color: "white",
                      width:"100%",
                      height: "400px",
                      padding: "12px 23px",
                    }}
                  >
                    <div>
                      <h5>
                        <FormattedHTMLMessage
                          id="Preselection.Sourcing"
                          defaultMessage="starter"
                        />
                      </h5>

                      <p style={{ color: "white" }}>
                        <FormattedHTMLMessage
                          id="Preselection.Sourcing.list"
                          defaultMessage="12 months"
                        />
                      </p>
                    </div>
                    <div style={{ marginTop: "0px" }}>
                      <Link
                        to="/contact-us"
                        className="nav-link text-center"
                        data-target=".navbar-collapse.show"
                      >
                        <button
                          class="btn btn-primary"
                          style={{
                            background: "white",
                            border: "none",
                            color: "black",
                          }}
                        >
                          <FormattedHTMLMessage
                            id="subscribe_contactus"
                            defaultMessage="Subscribe"
                          />
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 float-left">
                   
                      <img
                        className=""
                        src="assets/images/outsourcing.png"
                        alt=""
                        style={{ width:"800px" }}
                      />
                   
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <Link to="/youareacompany">
              <div
                className="white-btns recruitment-btn"
                style={{
                  backgroundColor: "#003366",
                  color: "white",
                  marginTop: "10px",
                  borderRadius: "5px",
                }}
              >
                <div>
                  <FormattedHTMLMessage
                    id="home.titleButton1"
                    defaultMessage="<p className='pb-1'> €350 VAT included <span className='small'>instead of <del>450 €</del></span></p>"
                  />
                </div>
              </div>
            </Link>
            <Link to="/contact-professional">
              <div
                className="white-btns"
                style={{
                  backgroundColor: "#003366",
                  color: "white",

                  marginTop: "10px",
                  borderRadius: "5px",
                }}
              >
                <div>
                  <FormattedHTMLMessage
                    id="hrsupport.test.btn.content"
                    defaultMessage="<p className='pb-1'> €350 VAT included <span className='small'>instead of <del>450 €</del></span></p>"
                  />
                </div>
              </div>
            </Link>

            <ScrollIntoView selector="#unique">
              {/* <Link style={{ color: "white" }} to="#"> */}
                <button
                  className="white-btn sourcing-btn"
                  style={{
                    backgroundColor: "#003366",
                    color: "white",
                    border: "none",

                    borderRadius: "5px",
                  }}
                >
                  <FormattedHTMLMessage
                    id="tarifs"
                    defaultMessage="<p className='pb-1'> €350 VAT included <span className='small'>instead of <del>450 €</del></span></p>"
                  />
                </button>
              {/* </Link> */}
            </ScrollIntoView>
          </div>
        </section>

        <section className=" teaser-box lower-soucing-box mt-40" id="unique">
          <div className="container">
            <div className="row" style ={{marginTop:'6%'}}>
              <p className="text-center w-100 ">
                <FormattedHTMLMessage
                  id="pre-selection-heading"
                  defaultMessage="All subscriptions provide access to all tools and can be recharged with credits at any time."
                />
              </p>

              <div className="col-lg-4 col-md-4">
                <div className="icon-box text-center hero-bg box-shadow">
                  <div>
                    <p>
                      <h5>
                      {/* <Link to="/" className=""> */}
                      <FormattedHTMLMessage
                        id="pre-selection-starter-title0"
                        defaultMessage="starter"
                      />
                      {/* </Link> */}
                      </h5>
                      <FormattedHTMLMessage
                        id="pre-selection-starter-title"
                        defaultMessage="starter"
                      />
                    </p>
                    
                    <div className="HalfBordr mt-5">
                      <FormattedHTMLMessage
                        id="coach.offer.subs.desc2"
                        defaultMessage="12 months"
                      />

                      <p className="mt-5">
                        <FormattedHTMLMessage
                          id="coach.offer.sub.desc3"
                          defaultMessage="12 months"
                        />
                      </p>
                    </div>
                  </div>
                  <div style={{}}>
                    <Link
                      to="/contact-us"
                      className="nav-link"
                      data-target=".navbar-collapse.show"
                    >
                      <button
                        class="btn btn-primary"
                        style={{
                          background: "#18B6CE",
                          border: "none",
                          marginTop: "50px",
                        }}
                      >
                        <FormattedHTMLMessage
                          id="coach.offer.subs.btn1"
                          defaultMessage="Subscribe"
                        />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="icon-box text-center hero-bg box-shadow">
                  <div>
                    <p>
                      <h5>
                      <FormattedHTMLMessage
                        id="pre-selection-cv"
                        defaultMessage="Classic"
                      />
                      </h5>
                    </p>

                    <p>
                      <FormattedHTMLMessage
                        id="pre-selection-subhead"
                        defaultMessage="€49.90"
                      />
                    </p>

                    <div className="HalfBordr mt-5">
                      <FormattedHTMLMessage
                        id="pre-selection-title2"
                        defaultMessage="per month - annual payment"
                      />

                      <p class="mt-5">
                        <FormattedHTMLMessage
                          id="pre-selection-price"
                          defaultMessage="per month - annual payment"
                        />
                      </p>
                    </div>

                    {/* <p><FormattedHTMLMessage id="Starter.user" defaultMessage="1 user" /></p>    */}
                  </div>
                  <div style={{}}>
                    <Link
                      to="/contact-us"
                      className="nav-link"
                      data-target=".navbar-collapse.show"
                    >
                      <button
                        class="btn btn-primary"
                        style={{ background: "#18B6CE", border: "none" }}
                      >
                        <FormattedHTMLMessage
                          id="coach.offer.subs.btn2"
                          defaultMessage="Subscribe"
                        />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-4">
                <div className="icon-box text-center hero-bg box-shadow ">
                  <div>
                    <h5>
                      <FormattedHTMLMessage
                        id="pre-selection-candidate"
                        defaultMessage="Classic"
                      />
                    </h5>

                    <p>
                      <FormattedHTMLMessage
                        id="pre-selection-subhead2"
                        defaultMessage="€49.90"
                      />
                    </p>
                    <div className="HalfBordr mt-5" style={{ fontSize:"13px" }}>
                      <FormattedHTMLMessage
                        id="pre-selection-title3"
                        defaultMessage="per month - annual payment"
                      />

                      <p className="mt-5">
                        <FormattedHTMLMessage
                          id="pre-selection-price2"
                          defaultMessage="per month - annual payment"
                        />
                      </p>
                    </div>
                    {/* <p><FormattedHTMLMessage id="Starter.user" defaultMessage="1 user" /></p>    */}
                  </div>
                  <div style={{}}>
                    <Link
                      to="/contact-us"
                      className="nav-link"
                      data-target=".navbar-collapse.show"
                    >
                      <button
                        class="btn btn-primary"
                        style={{ background: "#18B6CE", border: "none" }}
                      >
                        <FormattedHTMLMessage
                          id="coach.offer.subs.btn2"
                          defaultMessage="Subscribe"
                        />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default PreSelection;
