import React, { Component } from "react";
import { Link } from "react-router-dom";
//import $ from "jquery";
import { FormattedMessage, FormattedHTMLMessage,IntlProvider  } from "react-intl";
import Carousel from "react-bootstrap/Carousel";
import Footer from "./includes/Footer";
import Register from "./includes/Register";
import { history } from "../helper/History";
import $ from "jquery";
//import { FormattedHTMLMessage } from "react-intl";

class enterprisePrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dis:"",
    };
    window.scrollTo(0, 0); // scroll page to top
   
  }  
  // componentDidMount(){
  //   if (this.props.isSignedIn) {

  //     this.setState({dis:"none"});
  //   }
  //   console.log(this.state.dis);
  // }

  showalert2(){

    localStorage.setItem('alert2','1')
  }
 
  showRegister(e) {
    var userType = localStorage.getItem("role");
    if(!(userType)){
      history.push("/register");
    // console.log('in '+e);
    // $("#loginModal").modal("show");
    // $("#register-form").delay(100).fadeIn(100);
    // $("#login-form").fadeOut(100);
    // $("#login-form-link").removeClass("active");
    // console.log('Inside Header '+e);
    // if(e === '#register-form-link'){
    //   $('.modal-title').text('SIGNUP');
    //   $(e).addClass("active");
    // }else{
    //   $(e.target).addClass("active");
    // }
  }
 

  }

  sendparam(){

    localStorage.setItem('classic','classic');
  }
  showRegister2(e) {
    console.log('in '+e);
    $("#loginModal").modal("show");
    $("#register-form").delay(100).fadeIn(100);
    $("#login-form").fadeOut(100);
    $("#login-form-link").removeClass("active");
    console.log('Inside Header '+e);
    if(e === '#register-form-link'){
      $('.modal-title').text('SIGNUP');
      $(e).addClass("active");
    }else{
      $(e.target).addClass("active");
    }
 
  }

  render() {
   

    return (
      <React.Fragment>
        
        {/* <section className="icon-boxed1 consultant-boxes1 teaser-box mt-10">
          <div className="container">
            <div className="row">
          
           

               <p className="text-center w-100 ">
                <FormattedHTMLMessage
                  id="talent.kit.heading"
                  defaultMessage="All subscriptions  provide access to all tools and can be recharged with credits at any time."
                />
              </p>
            
             
              <div className="col-lg-4 col-md-4">
                <div className="icon-box Consultant-box text-center hero-bg box-shadow " >
                
                  <div>
                  <p >
                  
                    <FormattedHTMLMessage
                      id="talent.kit.sub1"
                      defaultMessage="starter"
                    />
                  
                  </p>
               
                   <p>
                    <FormattedHTMLMessage
                      id="talentprice"
                      defaultMessage="12 months"
                    />
                  </p> 
                  <div className="HalfBordr mt-4">
                  <FormattedHTMLMessage
                      id="talent.price.sub"
                      defaultMessage="12 months"
                    />
                
                  <p className="mt-5">
                  <FormattedHTMLMessage
                      id="FREE.talent.kit"
                      defaultMessage="12 months"
                    />
                  </p>
                  </div>
                  <p className="mt-4">
                  <FormattedHTMLMessage
                      id="talent.kit.desc"
                      defaultMessage="12 months"
                    />
                  </p>
                  <p>
                  <FormattedHTMLMessage
                      id="talent.kit.desc2"
                      defaultMessage="12 months"
                    />
                  </p>

                  

             </div> 
                <div style={{marginTop:'50px'}}><Link to="/register" className="nav-link"  data-target=".navbar-collapse.show"><button class="btn btn-primary br_remove" oncClick={this.showalert2()} style={{background:"#18B6CE",border:"none"}}><FormattedHTMLMessage id="talent.kit.btn1" defaultMessage="Subscribe"/></button></Link></div>
                  
                </div>
        
              </div>
              <div className="col-lg-4 col-md-4"  >
                <div className="icon-box Consultant-box text-center hero-bg box-shadow " >
                 
                  <div>
                  <h3 >
               
                    <FormattedHTMLMessage
                      id="Flex.head"
                      defaultMessage="Classic"
                    />
                 
                  </h3>
                
             
                  <p>
                     <FormattedHTMLMessage
                      id="flex.sub.head"
                      defaultMessage="€49.90"
                    />
                    </p>
                    <div className="HalfBordr mt-4">
                     <FormattedHTMLMessage
                      id="flex.price"
                      defaultMessage="per month - annual payment"
                    />
                  

                    <p className="mt-5">
                     <FormattedHTMLMessage
                      id="flex.sub.price"
                      defaultMessage="per month - annual payment"
                    />
                    </p>

                  </div>

                </div>     
                <div style={{marginTop:'50px'}}><Link to="/buy-test" className="nav-link"  data-target=".navbar-collapse.show"><button class="btn btn-primary br_remove" style={{background:"#18B6CE",border:"none",marginTop:"100px"}}><FormattedHTMLMessage id="flex.btn" defaultMessage="Subscribe"/></button></Link></div>

           
                </div>
              
              </div>

              <div className="col-lg-4 col-md-4">
                <div className="icon-box Consultant-box text-center hero-bg box-shadow ">
                 
                  <div>
                  <h3 >
               
                    <FormattedHTMLMessage
                      id="classic.flex.heading"
                      defaultMessage="Classic"
                    />
                 
                  </h3>
            
                  <p>
                     <FormattedHTMLMessage
                      id="classic.flex.sub"
                      defaultMessage="€49.90"
                    />
                    </p>
                    <div className="HalfBordr mt-4">
                     <FormattedHTMLMessage
                      id="classic.flex"
                      defaultMessage="per month - annual payment"
                    />
                 

                    <p className="mt-5">
                     <FormattedHTMLMessage
                      id="classic.subprice"
                      defaultMessage="per month - annual payment"
                    />
                    </p> 
                    </div>
                    <div className="HalfBordr mt-4">
                     <FormattedHTMLMessage
                      id="classic.sub.price.flex"
                      defaultMessage="per month - annual payment"
                    />
                    
                    <p className="mt-5">
                     <FormattedHTMLMessage
                      id="classic.flex.rate"
                      defaultMessage="per month - annual payment"
                    />
                    </p> 
                    </div>


                </div>     
                <div style={{marginTop:'50px'}}><Link to="/buy-subscription" className="nav-link"  data-target=".navbar-collapse.show"><button class="btn btn-primary br_remove" style={{background:"#18B6CE",border:"none"}}><FormattedHTMLMessage id="classic.flex.btn" defaultMessage="Subscribe"/></button></Link></div>

           
                </div>
              
              </div>
            
             
            </div>
          
          

          </div>
        </section> */}
        <section className="icon-boxed1 teaser-box mt-10" id="unique1"> 
          <div className="container">
            <div className="row">
          
           

               <p className="text-center w-100 ">
                <FormattedHTMLMessage
                  id="talent.compy.subs.head1"
                  defaultMessage="All subscriptions provide access to all tools and can be recharged with credits at any time."
                />
              </p>
            
             
              <div className="col-lg-4 col-sm-4">
                <div className="icon-box text-center hero-bg box-shadow" >
                
                  <div  >
                  <p >
                    {/* <Link to="/" className=""> */}
                    <FormattedHTMLMessage
                      id="talent.compy.subs.desc1"
                      defaultMessage="starter"
                    />
                    {/* </Link> */}
                  </p>
               
                   <p>
                    <FormattedHTMLMessage
                      id="talent.compy.subs.desc2"
                      defaultMessage="12 months"
                    />
                  </p> 
               

                  
                 
                  <div className="HalfBordr mt-4">
                    <FormattedHTMLMessage
                      id="talent.compy.sub.desc3"
                      defaultMessage="12 months"
                    />
                  
                  <p className="mt-5">
                    <FormattedHTMLMessage
                      id="talent.compy.subs.desc4"
                      defaultMessage="12 months"
                    />
                  </p> 
                  </div>
                  <p className="mt-4">
                    <FormattedHTMLMessage
                      id="talent.compy.sub.desc5"
                      defaultMessage="12 months"
                    />
                  </p> 
                  <p>
                    <FormattedHTMLMessage
                      id="talent.compy.sub.desc8"
                      defaultMessage="12 months"
                    />
                  </p> 
               <p><Link to="/buy-test" className="nav-link"  data-target=".navbar-collapse.show"><button class="btn btn-primary" style={{background:"#18B6CE",border:"none",marginTop:"16%"}}><FormattedHTMLMessage id="taleny.compy.buy.1" defaultMessage="Subscribe"/></button></Link></p>

             </div> 
                {/* <div style={{marginTop:'50px'}}><Link to="/buy-test" className="nav-link"  data-target=".navbar-collapse.show"><button class="btn btn-primary" style={{background:"#18B6CE",border:"none",marginTop:"50px"}}><FormattedHTMLMessage id="coach.offer.subs.btn1" defaultMessage="Subscribe"/></button></Link></div> */}
                  
                </div>
        
              </div>
              <div className="col-lg-4 col-sm-4">
                <div className="icon-box text-center hero-bg box-shadow" style={{height:'700px'}}>
                 
                  <div>
                  <p >
               
                    <FormattedHTMLMessage
                      id="talent.compy.subs.head2"
                      defaultMessage="Classic"
                    />
                 
                  </p>
                
             
                  <p>
                     <FormattedHTMLMessage
                      id="talent.compy.subs.desc3"
                      defaultMessage="€49.90"
                    />
                    </p>
                    <div className="HalfBordr mt-4">
                     <FormattedHTMLMessage
                      id="talent.compy.sub.desc7"
                      defaultMessage="per month - annual payment"
                    />
           

                    <p className="mt-5">
                     <FormattedHTMLMessage
                      id="talent.compy.sub.price"
                      defaultMessage="per month - annual payment"
                    />
                    </p>
                  </div>
                  <div className="HalfBordr mt-4">
                     <FormattedHTMLMessage
                      id="talent.compy.sub.desc9"
                      defaultMessage="per month - annual payment"
                    />
                 
                    <p className="mt-5"> 
                     <FormattedHTMLMessage
                      id="talent.compy.sub.desc6"
                      defaultMessage="per month - annual payment"
                    />
                    </p>
                    </div>
                

                <p>
                  <Link to="/buy-test" className="nav-link"  data-target=".navbar-collapse.show"><button class="btn btn-primary" style={{background:"#18B6CE",border:"none",marginTop:"6%"}}><FormattedHTMLMessage id="taleny.compy.buy.2" defaultMessage="Subscribe"/></button></Link>
                  
                  </p>   
                </div>     
                {/* <div style={{marginTop:'50px'}}><Link to="/buy-test" className="nav-link"  data-target=".navbar-collapse.show"><button class="btn btn-primary" style={{background:"#18B6CE",border:"none"}}><FormattedHTMLMessage id="coach.offer.subs.btn2" defaultMessage="Subscribe"/></button></Link></div> */}

           
                </div>
              
              </div>

              <div className="col-lg-4 col-sm-4">
                <div className="icon-box text-center hero-bg box-shadow " style={{height:'700px'}}>
                 
                  <div>
                  <h3 >
               
                    <FormattedHTMLMessage
                      id="talent.compy.fullass"
                      defaultMessage="Classic"
                    />
                 
                  </h3>
            
                  <p>
                     <FormattedHTMLMessage
                      id="talent.compy.fullass.sub"
                      defaultMessage="€49.90"
                    />
                    </p>
                    <div className="HalfBordr mt-4">
                     <FormattedHTMLMessage
                      id="talent.compy.fullass.sub2"
                      defaultMessage="per month - annual payment"
                    />
                    

                    <p className="mt-5">
                     <FormattedHTMLMessage
                      id="talent.compy.fullass.sub3"
                      defaultMessage="per month - annual payment"
                    />
                    </p> 
                    </div>
                    <div className="HalfBordr mt-4">
                     <FormattedHTMLMessage
                      id="talent.compy.fullass.sub4"
                      defaultMessage="per month - annual payment"
                    />
                  
                    <p className="mt-5">
                     <FormattedHTMLMessage
                      id="talent.compy.fullass.sub5"
                      defaultMessage="per month - annual payment"
                    />
                    </p> 
                    </div>

                <p><Link to="/buy-test" className="nav-link"  data-target=".navbar-collapse.show"><button class="btn btn-primary" style={{background:"#18B6CE",border:"none",marginTop:"6%"}}><FormattedHTMLMessage id="taleny.compy.buy.3" defaultMessage="Subscribe"/></button></Link></p>   
                </div>     
                {/* <div style={{marginTop:'50px'}}><Link to="/buy-test" className="nav-link"  data-target=".navbar-collapse.show"><button class="btn btn-primary" style={{background:"#18B6CE",border:"none"}}><FormattedHTMLMessage id="coach.offer.subs.btn2" defaultMessage="Subscribe"/></button></Link></div> */}

           
                </div>
              
              </div>
            
             
            </div>
          
          

          </div>
        </section>
        
    
      </React.Fragment>
    );
  }
}
export default enterprisePrice;
