import React, { Component } from "react";
import { Link } from "react-router-dom";
//import $ from "jquery";
import {
  FormattedMessage,
  FormattedHTMLMessage,
  IntlProvider,
} from "react-intl";
import Carousel from "react-bootstrap/Carousel";
import Footer from "./includes/Footer";
import Register from "./includes/Register";
import { history } from "../helper/History";
import $ from "jquery";
import ScrollIntoView from "react-scroll-into-view";
//import { FormattedHTMLMessage } from "react-intl";

class Recruitmentsource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dis: "",
    };
    window.scrollTo(0, 0); // scroll page to top
  }
  // componentDidMount(){
  //   if (this.props.isSignedIn) {

  //     this.setState({dis:"none"});
  //   }
  //   console.log(this.state.dis);
  // }

  showRegister(e) {
    var userType = localStorage.getItem("role");
    if (!userType) {
      history.push("/register");
      // console.log('in '+e);
      // $("#loginModal").modal("show");
      // $("#register-form").delay(100).fadeIn(100);
      // $("#login-form").fadeOut(100);
      // $("#login-form-link").removeClass("active");
      // console.log('Inside Header '+e);
      // if(e === '#register-form-link'){
      //   $('.modal-title').text('SIGNUP');
      //   $(e).addClass("active");
      // }else{
      //   $(e.target).addClass("active");
      // }
    }
  }

  sendparam() {
    localStorage.setItem("classic", "classic");
  }
  showRegister2(e) {
    console.log("in " + e);
    $("#loginModal").modal("show");
    $("#register-form").delay(100).fadeIn(100);
    $("#login-form").fadeOut(100);
    $("#login-form-link").removeClass("active");
    console.log("Inside Header " + e);
    if (e === "#register-form-link") {
      $(".modal-title").text("SIGNUP");
      $(e).addClass("active");
    } else {
      $(e.target).addClass("active");
    }
  }

  handleClick() {
    const price = "unique";
    const element = document.getElementById(price);
    console.log(element);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section

      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  render() {
    return (
      <React.Fragment>
        <section
          id="wrapper"
          className="icon-boxed1 teaser-box  graph-box sourcing-page"
        >
          <div className="container ">
            <div className="row">
              <div class="source-page-graph">
                <div className="col-lg-8">
                  <div
                    className="icon-box icon-blue-box text-left hero-bg box-shadow sourcing-page"
                    style={{
                      backgroundColor: "#18B6CE",
                      color: "white",
                    }}
                  >
                    <div>
                      <h3 className=" text-center ">
                        <FormattedHTMLMessage
                          id="Recruitment.Sourcing"
                          defaultMessage="starter"
                        />
                      </h3>

                      <p style={{ color: "white" }}>
                        <FormattedHTMLMessage
                          id="Recruitment.Sourcing.list"
                          defaultMessage="12 months"
                        />
                      </p>
                    </div>
                    <div style={{ marginTop: "50px" }}>
                      <Link
                        to="/contact-us"
                        className="nav-link text-center"
                        data-target=".navbar-collapse.show"
                      >
                        <button
                          class="btn btn-primary"
                          style={{
                            background: "white",
                            border: "none",
                            color: "black",
                          }}
                        >
                          <FormattedHTMLMessage
                            id="discover1"
                            defaultMessage="Subscribe"
                          />
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 responsive-mb">
                  <div
                    className="icon-box icon-blue-box text-center hero-bg box-shadow graph-box-height sourcing-page"
                    style={{
                      backgroundColor: "#18B6CE",
                      color: "white",
                    }}
                  >
                    <div className="recruit-img">
                      <img
                        className="recruit-inner-image"
                        src="assets/images/recruitment.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <Link to="/youareacompany">
              <div
                className="white-btns recruitment-btn"
                style={{
                  backgroundColor: "#003366",
                  color: "white",
                  marginTop: "10px",
                  borderRadius: "5px",
                }}
              >
                <div>
                  <FormattedHTMLMessage
                    id="home.titleButton1"
                    defaultMessage="<p className='pb-1'> €350 VAT included <span className='small'>instead of <del>450 €</del></span></p>"
                  />
                </div>
              </div>
            </Link>
            <Link to="/contact-professional">
              <div
                className="white-btns"
                style={{
                  backgroundColor: "#003366",
                  color: "white",

                  marginTop: "10px",
                  borderRadius: "5px",
                }}
              >
                <div>
                  <FormattedHTMLMessage
                    id="hrsupport.test.btn.content"
                    defaultMessage="<p className='pb-1'> €350 VAT included <span className='small'>instead of <del>450 €</del></span></p>"
                  />
                </div>
              </div>
            </Link>

            <ScrollIntoView selector="#unique">
              {/* <Link style={{ color: "white" }} to="#"> */}
                <button
                  className="white-btn sourcing-btn"
                  style={{
                    backgroundColor: "#003366",
                    color: "white",
                    border: "none",

                    borderRadius: "5px",
                  }}
                >
                  <FormattedHTMLMessage
                    id="tarifs"
                    defaultMessage="<p className='pb-1'> €350 VAT included <span className='small'>instead of <del>450 €</del></span></p>"
                  />
                </button>
              {/* </Link> */}
            </ScrollIntoView>
          </div>
        </section>

        <section className=" teaser-box lower-soucing-box mt-40" id="unique">
          <div className="container">
            <div className="row" style ={{marginTop:'6%'}}>
              <p className="text-center w-100 ">
                <FormattedHTMLMessage
                  id="recruitement.sourcing"
                  defaultMessage="All subscriptions provide access to all tools and can be recharged with credits at any time."
                />
              </p>

              <div className="col-lg-4 col-md-4 responsive-mb">
                <div className="icon-box subscription-boxes text-center hero-bg box-shadow">
                  <div
                    class="top-content width-add"
                    style={{ height: "480px" }}
                  >
                    <div class="mb-5">
                      {/* <Link to="/" className=""> */}
                      <FormattedHTMLMessage
                        id="creation"
                        defaultMessage="starter"
                      />
                      {/* </Link> */}
                    </div>

                    <div className="HalfBordr">
                      <FormattedHTMLMessage
                        id="flat.rate"
                        defaultMessage="12 months"
                      />

                      <p className="mt-5">
                        <FormattedHTMLMessage
                          id="flat.price"
                          defaultMessage="12 months"
                        />
                      </p>
                    </div>
                    <p className="mt-4">
                      <FormattedHTMLMessage
                        id="creation.dec"
                        defaultMessage="12 months"
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 responsive-mb">
                <div className="icon-box  subscription-boxes text-center hero-bg box-shadow ">
                  <div class="top-content width-add">
                    <div class="mb-5">
                      <FormattedHTMLMessage
                        id="uploading"
                        defaultMessage="Classic"
                      />
                    </div>

                    <div className="HalfBordr">
                      <FormattedHTMLMessage
                        id="uploading.price"
                        defaultMessage="€49.90"
                      />

                      <p className="mt-5">
                        <FormattedHTMLMessage
                          id="uploading.prices"
                          defaultMessage="per month - annual payment"
                        />
                      </p>
                    </div>

                    <div className="HalfBordr2 mt-5">
                      <FormattedHTMLMessage
                        id="uplaoding.sub"
                        defaultMessage="per month - annual payment"
                      />

                      <p className="mt-5">
                        <FormattedHTMLMessage
                          id="uploading.sub.2"
                          defaultMessage="per month - annual payment"
                        />
                      </p>
                    </div>
                    <p className="mt-4">
                      <FormattedHTMLMessage
                        id="uploading.sub3"
                        defaultMessage="per month - annual payment"
                      />
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 responsive-mb">
                <div className="icon-box  subscription-boxes text-center hero-bg box-shadow ">
                  <div style={{ height: "480px" }}>
                    <div>
                      <FormattedHTMLMessage
                        id="canidate.follow"
                        defaultMessage="Classic"
                      />
                    </div>

                    <div className="HalfBordr mt-5">
                      <FormattedHTMLMessage
                        id="flat.rate"
                        defaultMessage="€49.90"
                      />

                      <p className="mt-2">
                        <FormattedHTMLMessage
                          id="candidate.follow.sub"
                          defaultMessage="per month - annual payment"
                        />
                      </p>
                    </div>

                    <p className="mt-4">
                      <FormattedHTMLMessage
                        id="candidate.follow.sub2"
                        defaultMessage="per month - annual payment"
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default Recruitmentsource;
