import React from "react";
import { FormattedMessage } from "react-intl";

import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  LabelList,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";

function MotivationResult(props) {
  let { result } = props;
  //console.log('Recruitment motiveResult --=> '+JSON.stringify(props));      
  console.log('Recruitment motiveResult +++=> '+JSON.stringify(result.username));      
  //console.log('Recruitment motiveResult => '+JSON.stringify(props));          
  const getDescription = label => {
    let des = result.filter(elem => {
      return elem.label === label;
    });
    //console.log('Motivations=> '+JSON.stringify(result));
    if (des.length > 0) {
      return des[0].desc;
    } else {
      return null;
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    //console.log(label+' -- '+JSON.stringify(payload));
    if (active) {
      return (
        <div className="custom-tooltip">
          <p className="label">
            <b>{`${label} : ${payload[0].value}`}</b>
          </p>
          <p className="desc">{getDescription(label)}.</p>
        </div>
      );
    }

    return null;
  };

  return (
    <React.Fragment>
      <div className="row" >
        <div className="col-md-12">
          <div className="m-auto">
          <div>
            {localStorage.getItem("userRole") === 'User' ? (
              <h4 className="text-center mb-4"><FormattedMessage id="hr.recruitment.candidate.title" defaultMessage="CANDIDATE PROFILE"/></h4>
            ) :localStorage.getItem("u")!=null ? <h4 className="text-center mb-4"><FormattedMessage id="hr.recruitment.candidate.title" defaultMessage="CANDIDATE PROFILE"/></h4>:<h4 className="text-center mb-4"><FormattedMessage id="hr.recruitment.hr.title" defaultMessage="JOB PROFILE"/></h4> 
              
            }
            <h4 className="text-center mb-1"><FormattedMessage id="hr.recruitment.motivation.title" defaultMessage="Motivation Result"/></h4>        
          </div>
                {/* {(localStorage.getItem("u")?<h4 className="mb-2 float-right" style={{fontSize:"15px",marginTop:"-80px"}}>Poste : Conseiller Emploi </h4>
             :"")}  */}
             {/* <h4 className="mb-2 float-right" style={{fontSize:"15px",marginTop:"-80px"}}>Poste : Conseiller Emploi </h4> */}
             {/* Candidat(e):  {localStorage.getItem("u")} {localStorage.getItem("u2")} */}
          <div style={{ width: "100%", height: 600 }} className="m-auto">
            <ResponsiveContainer>
              <ComposedChart
                layout="vertical"
                width={500}
                height={600}
                data={result}
                margin={{
                  top: 5,
                  right: 30,
                  bottom: 20,
                  left: 5
                }}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis type="number" domain={[0, 100]} />
                <YAxis
                  dataKey="label"
                  type="category"
                  angle={-45}
                  textAnchor="end"
                  interval={0}
                  width={100}                
                  // tick={false}
                />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="score" barSize={20} fill="#17a2b8">
                  <LabelList dataKey="score" position="right" />
                </Bar>
                <Line dataKey="mid" stroke="#ff7300" />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default MotivationResult;
