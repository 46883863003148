import CryptoJS from "crypto-js";
import { HASH_KEY } from "./Config";

const Hash = {
  encrypt: function(hrId) {
    var ciphertext = CryptoJS.AES.encrypt(hrId, HASH_KEY);
    return btoa(ciphertext.toString());
  },
  decrypt: function(encKeyword) {
    var bytes = CryptoJS.AES.decrypt(atob(encKeyword), HASH_KEY);
    var plaintext = bytes.toString(CryptoJS.enc.Utf8);
    return plaintext;
  }
};

export default Hash;
