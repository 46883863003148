import { HAPPINESS_DATA } from "../actions/Happiness";
import { SALE_DATA } from "../actions/Sale";
import { ENTREPRENEUR_DATA } from "../actions/Entrepreneur";
import { FRANCHISE_DATA } from "../actions/Franchise";
import { BUSINESSCREATION_DATA } from "../actions/BusinessCreation";
//import { AUTH_ERROR } from "../actions/Happiness";

const initialState = {
  HappinessData: [],
  SaleData: [],
  EntrepreneurData: [],
  FranchiseData: [],
  BusinessCreationData: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case HAPPINESS_DATA:
      return { ...state, HappinessData: action.payload };

    case SALE_DATA:
      return { ...state, SaleData: action.payload };

    case FRANCHISE_DATA:
        return { ...state, FranchiseData: action.payload };

    case BUSINESSCREATION_DATA:
        return { ...state, BusinessCreationData: action.payload };    
  
    case ENTREPRENEUR_DATA:
          return { ...state, EntrepreneurData: action.payload };
          

    default:
      return state;
  }
}
