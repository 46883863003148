import React from "react";
import { FormattedMessage } from "react-intl";

import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  LabelList,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";

function WorkCultureResult(props) {
  let { result, top, word, testType  } = props;

  //console.log('testType '+testType+' -- '+result);
  //console.log('word '+word+' top '+top);
  // check condition based on test type and show only three graph for individual test 
  if(testType === 'individual'){
    //console.log(' Before  '+JSON.stringify(result));
    var i;
    var resultData =[]; //define empty array 
    for (i = 0; i < 3; i++) { 
      //console.log(' Loop  '+i+' result '+JSON.stringify(Object.values(result)[i]));
      resultData.push(Object.values(result)[i]); // assign only first three to result data 
    }
    result = resultData; // set result data to result 
  }

  const getDescription = label => {
    let des = result.filter(elem => {
      return elem.label === label;
    });
    if (des.length > 0) {
      return des[0].desc;
    } else {
      return null;
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          <p className="label">
            <b>{`${label} : ${payload[0].value}`}</b>
          </p>
          <p className="desc">{getDescription(label)}.</p>
        </div>
      );
    }

    return null;
  };

  const renderFooter = data => {
    //console.log(' ProfessionalResult  '+JSON.stringify(data));
    return data.map((item, index) => {
      return (
        <React.Fragment key={index}>
          <span>
            {/* {item.label} */}
            {item.option}
            {data.length === index + 1 ? "." : ", "}
          </span>
        </React.Fragment>
      );
    });
  };

  // function used to render the explanation of motivational hover effect 
  function RenderResultExpliation(resultData, testType) {
    //console.log('Inside RenderResultExpliation'+resultData);
    if (resultData) {
      return resultData.map((value, index) => {
        //console.log('testType' +testType+' i '+index);
          return (
            <React.Fragment key={index}>
              <li className="greenclass flex-1" key={index} >
                <span className="font-weight-bold">{value.option} : {value.score}</span>
                <p className="mb-0">{value.desc}</p>
              </li>
            </React.Fragment>
          );        
      });
    }
  }

  const renderSelected = (data)  => {
      console.log(' renderSelected  '+JSON.stringify(data));
      return data.map((value, index) => {
        if(index === 0){
          return (
            <React.Fragment key={index}>
              <div className="row mb-2">
                <div className="col-md-3 bg-info text-white d-flex align-items-center font-weight-bold p-3">
                  {value.option}
                </div>
                <div className="col-md-6 p-0 border-top border-bottom px-2 text-justify">
                  {value.desc}
                </div>
                <div className="col-md-3 bg-info text-white d-flex align-items-center">
                  {value.label}                  
                </div>
              </div>
            </React.Fragment>
          );
        }
      });
  };

  return (
    <React.Fragment>
      {/* <div className="col-lg-10 col-md-10 col-sm-12 m-auto"> // change for print PDF  */} 
      <div className="m-auto">
        {testType === 'individual' && ( /// check type of test individual
          <h4 className="text-center mb-1 mt-5">
            <FormattedMessage id="ind.target.your.work.culture.result.title" defaultMessage="Your work culture"/>
          </h4>
        )}
        {props.testType != 'individual' && ( /// check type of test professional                
          <h4 className="text-center mb-1 mt-5">
            <FormattedMessage id="ind.target.your.work.culture.result.title" defaultMessage="Work culture"/>
          </h4>
        )}
        <div className="card">
          <div className="card-body">
            {props.testType === 'individual' && ( /// check type of test individual or professional                            
              <div>
                {renderSelected(result)}
              </div>
            )}
            
            <div className="row">
              <div className="col-md-6">
                <div
                  style={{ width: "100%", height: (testType === 'individual' ? 500 : 685) }}
                  className="m-auto"
                  id="target-result"
                >
                  <ResponsiveContainer>
                    <ComposedChart
                      layout="vertical"
                      width={500}
                      height={(testType === 'individual' ? 500 : 685)}
                      data={result}
                      margin={{
                        top: 10,
                        right: 35,
                        bottom: 10,
                        left: 0
                      }}
                    >
                      <CartesianGrid stroke="#f5f5f5" />
                      <XAxis type="number" domain={[0, 100]} />
                      <YAxis
                        dataKey="option"
                        type="category"
                        width={200}
                        textAnchor="end"
                        interval={0}
                        // tick={false}
                      />
                      <Tooltip content={<CustomTooltip />} />
                      <Bar dataKey="score" barSize={30} fill="#17a2b8">
                        <LabelList dataKey="score" position="right" />
                      </Bar>
                      <Line dataKey="mid" stroke="#ff7300" />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div className="col-md-6">
                <ul className="custom-list h-100 d-flex flex-column">
                    {RenderResultExpliation(result, testType)}
                    {/* one additional li to makr labels equlant to graphs  */}
                    {testType !== 'individual' && ( /// check type of test individual
                      <li className="greenclass flex-1" ><span className="font-weight-bold"></span><p className="mb-0"></p></li>
                    )}
                </ul>            
              </div> 
            </div>
          </div>
          {testType !== 'individual' && ( /// check type of test individual or professional
          <div className="card-footer text-muted">
            <div className="alert alert-secondary text-justify" role="alert">
              <FormattedMessage id="ind.target.result.preffered.company.culture1" defaultMessage="Company culture is "/>  
              <FormattedMessage id="ind.target.result.preffered.company.culture2" defaultMessage="important to me in any case, what matter most to me are"/> <b>{renderFooter(top)}</b>
              {/* <FormattedMessage
                id="ind.target.result.preffered.company.culture"
                defaultMessage={`Company culture is {word} important to me in any case, what matter most to me are {top}`}
                values={{word: word}, {top: renderFooter(top)}}
              /> */}
              {/* Company culture is {word} important to me in any case, what matter most to me are {renderFooter(top)} */}
            </div>
          </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default WorkCultureResult;
