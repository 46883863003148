import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "./includes/Footer";
import { history } from "../helper/History";
import { Field, reduxForm } from "redux-form";
import { contactacoach, clearContactCoachMessage } from "../actions";
import $ from "jquery";
import { FormattedMessage } from "react-intl";

class ContactToCoach extends Component {
    constructor(props) {
        super(props);
        this.state = {
        roles: [],
        isSubmit: null,
        language: null
        };
        window.scrollTo(0, 0); // scroll page to top
        this.contactToCoachForm = this.contactToCoachForm.bind(this);
    }

    componentDidMount() {
      //console.log('componentDidMount');      
      if (this.props.isSignedIn) 
      {
        //console.log('Response => '+JSON.stringify(this.props));
        let testType = localStorage.getItem('testType');
        //console.log('Response => '+JSON.stringify(this.props.location)+' type '+testType);    
        // set the test type to state 
        this.setState({ testType: testType }); 
        let language;
        if (this.props.lang === "en") language = "english";
        if (this.props.lang === "fr") language = "french";                
      }
    } 

    contactToCoachForm(values) {
      values['language'] = this.props.lang;      
      //console.log('Init submit form');
      $("#contactus-loader").show();          
      this.refs.sbmt.setAttribute("disabled", "disabled");          

        //console.log('Inside contactToCoachForm '+values.email);
        this.props.contactacoach(values, this.refs.sbmt, response => {
          //console.log('Response '+response);

          if (response === "success") {
            this.props.reset("contactUsValidation"); 
            // on successfull signup show login form 
            $("#contactus-loader").hide();
            // after successfully submit redirect to respective page
            if (this.state.testType != 'individual') {
              setTimeout(function(){ window.location = "/hr"; }, 10000);
            }else{
              setTimeout(function(){ window.location = "/individual"; }, 10000);
            }
            //   setTimeout(() => {
            //     if (this.state.testType != 'individual') {
            //      history.push("/hr");
            //     }else{
            //      history.push("/individual");
            //     }
            //  }, 15000);            
          }
        });
    }

    renderInput(field) {
      return (
        <div className="form-group">
          <label>{field.label}</label>
          <input
            type={field.type}
            className="form-control"
            placeholder={field.placeholder}
            autoComplete="off"
            {...field.input}
          />
          {field.meta.touched && field.meta.error && (
            <small className="text-danger">{field.meta.error}</small>
          )}
        </div>
      );
    }

    renderTextarea(field) {
      return (
        <div className="form-group">
          <label>{field.label}</label>
          <textarea
            type={field.type}
            className="form-control md-textarea"
            placeholder={field.placeholder}
            {...field.input}
          ></textarea>
          {field.meta.touched && field.meta.error && (<small className="text-danger">{field.meta.error}</small>)}
        </div>
      );
    }

    errorMessage() {
      const { testType } = this.state;      
      //console.log(testType+' --  '+this.props.contactCoachMessage+' props '+JSON.stringify(this.props));
      if (this.props.contactCoachError !== null && this.props.contactCoachError !== "") {
        return (
          <div className="col-md-12 alert alert-danger" role="alert">
            {this.props.contactCoachError}
          </div>
        );
      }
      if (
        this.props.contactCoachMessage !== null &&
        this.props.contactCoachMessage !== ""
      ) {
        return (
          <div className="col-md-12 alert alert-success" role="alert">
            {this.props.contactCoachMessage}
          </div>
        );
      }
      // used to reset the error or sucess message
      // setTimeout(() => {
      //    this.props.clearContactCoachMessage();
      //    if (this.state.testType != 'individual') {
      //     //history.push("/hr");
      //    }else{
      //     //history.push("/individual");
      //    } 
      // }, 15000);
    }
    
  render() {
    const { testType } = this.state;
    var redirectUrl = (testType === 'individual' ? 'individual':'hr');
    //console.log('Message => '+this.props.contactCoachMessage);
    const firstname  = <FormattedMessage id="contact.us.name" defaultMessage="First name, Name *"/>
    const emailaddress  = <FormattedMessage id="contact.us.emailaddress" defaultMessage="Email address *"/>
    const phoneno  = <FormattedMessage id="contact.us.phoneno" defaultMessage="Phone Number"/>    
    const yourmessage  = <FormattedMessage id="contact.us.message" defaultMessage="Your message *"/>

    return (
    <React.Fragment>
      <section className="mb-4 pages">
        <div className="container">
          <h2 className="h1-responsive font-weight-bold text-center my-4">
            <FormattedMessage id="contact_coach_title" defaultMessage="Contact a Coach"/>            
          </h2>
          {this.props.contactCoachMessage == null ? (
            <div className="row">
              <p className="text-center w-responsive mx-auto mb-5">
                <FormattedMessage id="contact_coach_sub_title" defaultMessage="Please leave your contact details and message below and you will be contacted soon."/>
              </p>
              <div className="row w-100">
                {this.errorMessage()}                
              </div>
              <form id="contact-form" onSubmit={this.props.handleSubmit(e => this.contactToCoachForm(e))} className="row w-100" >
                <div className="col-lg-10 col-md-12 p-0 m-0">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="md-form mb-0">
                                    <Field
                                      name="name"
                                      type="text"
                                      label={firstname}
                                      placeholder=""
                                      className="form-control"
                                      component={this.renderInput}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="md-form mb-0">
                                  <Field
                                      name="email"
                                      type="email"
                                      label={emailaddress}
                                      placeholder=""
                                      className="form-control"
                                      component={this.renderInput}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="md-form mb-0">
                                    <Field
                                      name="phone"
                                      type="tel"
                                      label={phoneno}
                                      placeholder="(+ 33 (0)6 XX XX XX)"
                                      className="form-control"
                                      component={this.renderInput}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="md-form">
                                    <Field
                                      name="message"
                                      type="textarea"
                                      label={yourmessage}
                                      placeholder=""
                                      className="form-control md-textarea"
                                      rows="2"
                                      component={this.renderTextarea}
                                    />
                                </div>
                            </div>
                        </div>
                </div>
                <div className="col-lg-2 col-md-12 d-flex flex-column p-0 m-0 text-center">
                    <ul className="list-unstyled mb-0">
                        <li><i className="fa fa-map-marker"></i>
                            <p>4 rue des Fosses Saint-Jacques 75005 PARIS</p>
                        </li>

                        <li><i className="fa fa-phone"></i>
                            <p>+ 33 (0) 9 83 20 00 15</p>
                        </li>

                        <li><i className="fa fa-envelope"></i>
                            <p>contact@pilotrhsolutions.com</p>
                        </li>
                    </ul>
                </div>
                <div className="col-md-12 text-center">
                    <div className="spinner-border text-primary m-auto" role="status" id="contactus-loader">
                      <span className="sr-only"><FormattedMessage id="loading" defaultMessage="Loading..."/>   </span>
                    </div>
                </div>                
                <div className="col-md-12 text-center">
                    <input type="hidden" name="language" value={this.props.lang} />                  
                    <button type="submit" name="send-query" id="send-query" tabIndex="4"  className="btn btn-primary" ref="sbmt">
                      <FormattedMessage id="submit" defaultMessage="SUBMIT"/>                       
                    </button>
                    {/* <input type="submit" name="send-query" id="send-query" tabIndex="4"  className="btn btn-primary" value="SUBMIT"  ref="sbmt"/> */}
                </div>
                <div className="status"></div>
              </form>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-12 p-4 m-0 text-center">
                {this.errorMessage()}
                <div className="col-sm-12 mx-auto text-center mb-4 pb-45">
                  <Link to={redirectUrl} className="btn btn-warning mt-3 mx-3">
                    <FormattedMessage id="do_another_test" defaultMessage="DO ANOTHER TEST"/>
                  </Link>
                  <Link to="/" className="btn btn-primary mt-3 mx-3">
                    <FormattedMessage id="back_to_home" defaultMessage="BACK TO HOME PAGE"/>
                  </Link>
                </div>
              </div>
            </div>    
          )}
        </div> 
      </section>
      <Footer />      
    </React.Fragment>
    );
  }
}

const validate = values => {
  //console.log('Inside validate');
    const errors = {};
    if (!values.name) {
      errors.name = "Name is required";
    }
    if (!values.email) {
      errors.email = "Email address is required";
    }
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)){
      errors.username = "Invalid email";
    }
    // if (!values.subject) {
    //   errors.subject = "Subject is required";
    // }
    if (!values.message) {
      errors.message = "Message is required";
    }    
    return errors;
  };

const mapStateToProps = state => {
  return {
    isSignedIn: state.auth.isSignedIn,
    contactUsValidation: state.form.contactUsValidation,
    contactCoachError: state.auth.contactCoachError,
    contactCoachMessage: state.auth.contactCoachMessage,    
    lang: state.locale.lang
  };
};

ContactToCoach = connect(
  mapStateToProps,
  { contactacoach, clearContactCoachMessage }
)(ContactToCoach);


export default reduxForm({
    form: "contactUsValidation",
    enableReinitialize: true,    
    validate
  })(ContactToCoach);

//export default ContactToCoach;

