import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";

const Enterprise = () => {
  return (
    <React.Fragment>
      <section>
        <div className="col-md-12 d-flex p-0">
          <Carousel id="slider-main" className="individual-slider">
            <Carousel.Item>
              <img
                className="d-block w-100 bg"
                src="assets/images/enterprise-slide-demo.jpg"
                alt="First slide"
              />
              <Carousel.Caption className="text-left d-flex h-100 align-items-center justify-content-center">
                <div className="intro_layers_wrapper d-flex align-items-center text-center text-dark">
                  <div className="intro_layers text-center">
                  {/* <div
                      className="intro_layer mt-3 animated bounce"
                      data-animation="fadeIn"
                    >
                      <div className="d-inline-block">
                        <p>
                          <button
                            type="button"
                            className="btn btn-danger btn-outline-maincolor center-block"
                            data-animation="fadeIn"
                          >
                            PRIVATE DEMONSTRATION <br />
                            <div className="small">(Enterprise only)</div>
                          </button>
                        </p>
                      </div>
                    </div> */}
                    <div
                      className="intro_layer animated fadeInRight "
                      data-animation="fadeInLeft"
                    >
                      <h1 className="text-uppercase intro_featured_word mb-2">
                      <FormattedMessage
                        id="enterprise.title"
                        defaultMessage="ENTERPRISE SOLUTIONS"
                      />
                      </h1>
                      {/* <h4>(for Enterprise) </h4> */}
                    </div>
                    <div
                      className="intro_layer animated fadeInLeft mb-3"
                      data-animation="fadeInLeft"
                    >
                      <h4 className="intro_before_featured_word">
                          <span className="">
                          <p>
                          <FormattedMessage
                            id="enterprise.sub_title_1"
                            defaultMessage="Private demonstration"
                          />
                          </p>
                          <p>
                          <FormattedMessage
                            id="enterprise.sub_title_2"
                            defaultMessage="Saas platforms"
                          />
                          </p>
                          <p>
                          <FormattedMessage
                            id="enterprise.sub_title_3"
                            defaultMessage="Matching & Scoring"
                          />
                          </p>
                          </span>
                      </h4>
                    </div>


                  </div>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
        <div className="">
              <div className="white-btn">
                  <div>
                      <FormattedHTMLMessage id="enterprise.test.btn.content" defaultMessage="<p className='pb-1'> €350 VAT included <span className='small'>instead of <del>450 €</del></span></p>"/>
                  </div>
              </div>
          </div>
      </section>

      <section className="enterprise-test">
        <div className="container-fluid p-0">
          <div className="intro_layer" data-animation="fadeIn">
            <div className="d-block">
              <div className="enterprise-list indi-tests">
                <ul className="d-flex txtwhite">
                  <li className="yellow">
                    <button
                      data-toggle="modal"
                      data-target="#happiness"
                      className="btn"
                    >
                      <img src="assets/images/smile-1.png" alt="" />
                      <span>HAPPINESS</span><br/>
                       <FormattedMessage
                          id="platform"
                          defaultMessage="PLATFORM"
                        />
                    </button>
                  </li>                  
                  <li className="blue">
                    <button
                      data-toggle="modal"
                      data-target="#sale"
                      className="btn"
                    >
                      <img src="assets/images/sale-1.png" alt="" />
                      <span>SALES FORCE</span><br/>
                       <FormattedMessage
                          id="platform"
                          defaultMessage="PLATFORM"
                        />                      
                    </button>
                  </li>                  
                  <li className="green">
                    <button
                      data-animation="fadeIn"
                      data-toggle="modal"
                      data-target="#recruitment"
                      className="btn"
                    >
                      <img src="assets/images/recruitment-1.png" alt="" />
                      <span>RECRUITMENT</span><br/>
                        <FormattedMessage
                          id="platform"
                          defaultMessage="PLATFORM"
                        />                      
                    </button>
                  </li>
                  <li className="purple">
                    <button
                      data-toggle="modal"
                      data-target="#enterprise"
                      className="btn"
                    >
                      <img src="assets/images/enterprise-1.png" alt="" />
                      <span>ENTREPRENEURIAL</span><br/>
                        <FormattedMessage
                          id="platform"
                          defaultMessage="PLATFORM"
                        />                      
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="col-md-12 bottombox">
          <div className="col-md-12">
            <Link className="" to="/sourceresources">
              <div className="black-bottom-btn">
                <FormattedHTMLMessage id="enterprise.bottombottn1" defaultMessage="Totally confidential"/>
              </div>
              </Link>
          </div>
        </div>
      <div
        className="modal fade pilotModal"
        id="myModal"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header bg-secondary">
              <h4 className="modal-title text-center text-uppercase w-100 medium text-white font-20">
                Example of Results{" "}
              </h4>
              <button type="button" className="close" data-dismiss="modal">
                ×
              </button>
            </div>

            <div className="modal-body">
              <h4 className="text-center text-uppercase">Happiness At Work</h4>
              <img
                src="assets/images/1.png"
                className="img-fluid w-100"
                alt=""
              />
              <div className="about pt-2" id="services">
                <div className="container text-justify">
                  <div
                    className="col-sm-12 col-md-12 animate animated slideInLeft"
                    data-animation="slideInLeft"
                  >
                    <h2 className="about-title text-left">
                      MEASURE OF HAPPINESS AT WORK :
                    </h2>
                    <div className="about-text">
                      <p>
                        Business life brings people together to ensure the best
                        possible performance. Certain conditions must be met for
                        everyone to flourish. Factors of balance and development
                        at work : freedom of exchange, possibility of expressing
                        one's wishes, personal action, career follow-up,
                        possibility of working at one's own race, accomplishment
                        of a work of freedom, identity, meaningless (Identité au
                        travail – R. Sainsaulieu – 1988 – Presses de la
                        Fondation Nationale des Sciences Politiques). PILOT RH®
                        offer to the candidate assess their overall satisfaction
                        level and identifies areas for improvement (very useful
                        for the annual appraisal interview, job mobility and
                        career transition).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade pilotModal"
        id="happiness"  >
          {/* //data-backdrop="static" */}
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header bg-secondary">
              <h4 className="modal-title text-center text-uppercase w-100 medium text-white font-20">
              HAPPINESS Platform®

              </h4>
              <button type="button" className="close" data-dismiss="modal">
                ×
              </button>
            </div>

            <div className="modal-body">
              <div className="">
                <div className="about" id="services">
                  <div className=" text-justify">
                    <div
                      className=" animate animated slideInLeft"
                      data-animation="slideInRight"
                    >
                      <h2 className="about-title text-left">
                        <FormattedHTMLMessage id="ent.happiness.popup.title" defaultMessage="HAPPINESS Assessment Platform"/>
                      </h2>
                      <div className="about-text">
                        <FormattedHTMLMessage id="ent.happiness.popup.about" defaultMessage="<p>You are an enterprise and want to know how happy employees are at work and what you could be still improved.</p><p><b>The HAPPINESS Assessment Platform</b> allows in three minutes to each employees know his/her level of happiness at work and on height key factors of balance and development (Discuss and exchange ideas, Act and achieve in his or her own way, Work at his or her own rate, A good career development, Express his or her wishes, A job that brings freedom, A job that builds identity, A job that is meaningful). </p><p>Moreover enterprise get global statistical on results and can see what could be improve per population (age, location, function, …). The platform could be customized per company.</p>"/>
                        {/* <p>You are an enterprise and want to know how happy employees are at work and what you could be still improved.</p>
                        <p><b>The HAPPINESS Assessment Platform</b> allows in three minutes to each employees know his/her level of happiness at work and on height key factors of balance and development (Discuss and exchange ideas, Act and achieve in his or her own way, Work at his or her own rate, A good career development, Express his or her wishes, A job that brings freedom, A job that builds identity, A job that is meaningful). </p>
                        <p>Moreover enterprise get global statistical on results and can see what could be improve per population (age, location, function, …). The platform could be customized per company.</p> */}
                      </div>
                      <div className="indi-list">
                        <FormattedHTMLMessage id="ent.happiness.duration" defaultMessage="<h3>Duration</h3><ul className='indi-ul'> <li>40 questions – 3 minutes.</li></ul>"/>
                      </div>
                      <div className="indi-list">
                        <FormattedHTMLMessage id="ent.happiness.resultlist" defaultMessage="<h3 class=''>Results </h3> <ul className='indi-ul'> <li> Global satisfaction of employees compare to the average of the company. </li><li> Detail of satisfaction on eight key factors of balance and development. </li><li>How company still improved happiness of employees per population.</li></ul>"/>
                      </div>
                      <div className="indi-list">
                        <FormattedHTMLMessage id="ent.happiness.help" defaultMessage="<h3 class=''>Help </h3> <ul className='indi-ul'> <li>Practical online test to prepare professional interview.</li><li> Identify malaise of employee or for all company. </li><li> Degrease turn-over of the company </li><li> Have a sample observatory of the well-being in the company </li></ul>"/>
                      </div>
                      {/* <div className="indi-list">
                        <FormattedHTMLMessage id="ent.happiness.price" defaultMessage="<h3 class=''>Price</h3> <ul className='indi-ul'> <li>Ask for a quote</li></ul>"/> */}
                        {/* <h3 className="">Price</h3>
                        <ul className="indi-ul">
                          <li>Ask for a quote</li>
                        </ul> */}
                      {/* </div> */}
                      <div
                        className="intro_layer mt-5 d-flex"
                        data-animation="fadeIn"
                      >
                        <div className="d-table m-auto">
                          <div className="d-inline-block flex-fill">
                            <p>
                              <button
                                type="button"
                                className="btn center-block"
                                data-animation="fadeIn"
                                style={{backgroundColor:"#191970",color:"white"}}
                              >
                                <Link target="_blank" className="text-white" to="/contact-enterprise">
                                  <FormattedHTMLMessage id="hrsupport.test.btn.content" defaultMessage="<span>CONTACT US</span>  <div class=''> FOR A PRIVATE DEMONSTRATION </div><br/> (Enterprise ONLY)"/>
                                </Link>
                                {/* <h5>CONTACT US</h5> <br />
                                <div className="">
                                  FOR A PRIVATE DEMONSTRATION 
                                </div> <br /> (Enterprise ONLY) */}
                              </button>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade pilotModal" id="sale">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header bg-secondary">
              <h4 className="modal-title text-center text-uppercase w-100 medium text-white font-20">
              SALES FORCE Assessment Platform
              </h4>
              <button type="button" className="close" data-dismiss="modal">
                ×
              </button>
            </div>

            <div className="modal-body">
              <div className="">
                <div className="about" id="services">
                  <div className=" text-justify">
                    <div
                      className=" animate animated slideInLeft"
                      data-animation="slideInRight"
                    >
                      <h2 className="about-title text-left">
                        <FormattedHTMLMessage id="ent.sale.popup.title" defaultMessage="SALES FORCE Assessment Platform "/>
                      </h2>
                      <div className="about-text">
                        <FormattedHTMLMessage id="ent.sale.popup.about" defaultMessage="<p>You are an enterprise and want to assess the work force of your company and improve the sale and its performance.</p><p><b>The SAFE FORCE Assessment Platform</b> allows in three minutes to each sale person see his/her sale force potential on height key factors of success (Openness, Listening Skills, Adaptability, Know How, Negotiation Skills, Decision Making, Self-Control).</p><p>Moreover enterprise get global statistical results and see per population what could be still improve to increase sale performance. The platform could be customized per company.</p>"/>
                        {/* <p>You are an enterprise and want to assess the work force of your company and improve the sale and its performance.</p>
                        <p><b>The SAFE FORCE Assessment Platform</b> allows in three minutes to each sale person see his/her sale force potential on height key factors of success (Openness, Listening Skills, Adaptability, Know How, Negotiation Skills, Decision Making, Self-Control).</p>
                        <p>Moreover enterprise get global statistical results and see per population what could be still improve to increase sale performance. The platform could be customized per company.</p> */}
                      </div>
                      <div className="indi-list">
                        <FormattedHTMLMessage id="ent.sale.duration" defaultMessage="<h3>Duration</h3><ul className='indi-ul'> <li>40 questions – 3 minutes.</li></ul>"/>
                      </div>
                      <div className="indi-list">
                        <FormattedHTMLMessage id="ent.sale.resultlist" defaultMessage="<h3 class=''>Results </h3> <ul className='indi-ul'> <li> Global satisfaction of employees compare to the average of the company. </li><li> Detail of satisfaction on eight key factors of balance and development. </li><li>How company still improved happiness of employees per population.</li></ul>"/>
                      </div>
                      <div className="indi-list">
                        <FormattedHTMLMessage id="ent.sale.help" defaultMessage="<h3 class=''>Help </h3> <ul className='indi-ul'> <li>Practical online test to prepare professional interview.</li><li> Identify malaise of employee or for all company. </li><li> Degrease turn-over of the company </li><li> Have a sample observatory of the well-being in the company </li></ul>"/>
                      </div>
                      {/* <div className="indi-list">
                        <FormattedHTMLMessage id="ent.sale.price" defaultMessage="<h3 class=''>Price</h3> <ul className='indi-ul'> <li>Ask for a quote</li></ul>"/>
                      </div> */}
                      <div
                        className="intro_layer mt-5 d-flex"
                        data-animation="fadeIn"
                      >
                        <div className="d-table m-auto">
                          <div className="d-inline-block flex-fill">
                            <p>
                              <button
                                type="button"
                                className="btn  center-block"
                                data-animation="fadeIn"
                                style={{backgroundColor:"#191970",color:"white"}}
                              >
                              <Link target="_blank" className="text-white" to="/contact-enterprise">
                                <FormattedHTMLMessage id="hrsupport.test.btn.content" defaultMessage="<span>CONTACT US</span>  <div class=''> FOR A PRIVATE DEMONSTRATION </div><br/> (Enterprise ONLY)"/>
                              </Link>
                              </button>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade pilotModal" id="recruitment">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header bg-secondary">
              <h4 className="modal-title text-center text-uppercase w-100 medium text-white font-20">
              RECRUITMENT Assessment Platform
              </h4>
              <button type="button" className="close" data-dismiss="modal">
                ×
              </button>
            </div>

            <div className="modal-body">
              <div className="">
                <div className="about" id="services">
                  <div className=" text-justify">
                    <div
                      className=" animate animated slideInLeft"
                      data-animation="slideInRight"
                    >
                      <h2 className="about-title text-left">
                        <FormattedHTMLMessage id="ent.recruitment.popup.title" defaultMessage="RECRUITMENT Assessment Platform"/>
                      </h2>
                      <div className="about-text">
                        <FormattedHTMLMessage id="ent.recruitment.popup.about" defaultMessage="<p>You are a business and want to source the right candidate to your company especially on motivations and values waiting for.</p><p><b>The RECRUITMENT Assessment Platform</b> allows in three minutes to each candidate assess his/her potential to fit with the culture and value of the company and fit with skills asked (Organizer, Administrator, Manager, Commercial, Expertise, Listening, Adaptability, Personal situation).</p><p>Moreover enterprise get global statistical results and see per population what the candidate expected more to adapt the recruitment. The platform is customized per company.</p>"/>
                        {/* <p>You are an enterprise and want to source the right candidate to your company especially on motivations and values waiting for.</p>
                        <p><b>The RECRUITMENT Assessment Platform</b> allows in three minutes to each candidate  assess his/her potential to fit with the culture and value of the company and fit with skills asked (Organizer, Administrator, Manager, Commercial, Expertise, Listening, Adaptability, Personal situation).</p>
                        <p>Moreover enterprise get global statistical results and see per population what the candidate expected more to adapt the recruitment. The platform is customized per company.</p> */}
                      </div>
                      <div className="indi-list">
                        <FormattedHTMLMessage id="ent.recruitment.duration" defaultMessage="<h3>Duration</h3><ul className='indi-ul'> <li>40 questions – 3 minutes.</li></ul>"/>
                      </div>
                      <div className="indi-list">
                        <FormattedHTMLMessage id="ent.recruitment.resultlist" defaultMessage="<h3 class=''>Results </h3> <ul className='indi-ul'> <li> Global satisfaction of employees compare to the average of the company. </li><li> Detail of satisfaction on eight key factors of balance and development. </li><li>How company still improved happiness of employees per population.</li></ul>"/>
                      </div>
                      <div className="indi-list">
                        <FormattedHTMLMessage id="ent.recruitment.help" defaultMessage="<h3 class=''>Help </h3> <ul className='indi-ul'> <li>Practical online test to prepare professional interview.</li><li> Identify malaise of employee or for all company. </li><li> Degrease turn-over of the company </li><li> Have a sample observatory of the well-being in the company </li></ul>"/>
                      </div>
                      {/* <div className="indi-list">
                        <FormattedHTMLMessage id="ent.recruitment.price" defaultMessage="<h3 class=''>Price</h3> <ul className='indi-ul'> <li>Ask for a quote</li></ul>"/>
                      </div>                       */}
                      <div
                        className="intro_layer mt-5 d-flex"
                        data-animation="fadeIn"
                      >
                        <div className="d-table m-auto">
                          <div className="d-inline-block flex-fill">
                            <p>
                              <button
                                type="button"
                                className="btn  center-block"
                                data-animation="fadeIn"
                                style={{backgroundColor:"#191970",color:"white"}}
                              >
                              <Link target="_blank" className="text-white" to="/contact-enterprise">
                                <FormattedHTMLMessage id="hrsupport.test.btn.content" defaultMessage="<span>CONTACT US</span>  <div class=''> FOR A PRIVATE DEMONSTRATION </div><br/> (Enterprise ONLY)"/>
                              </Link>
                              </button>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>      

      <div className="modal fade pilotModal" id="enterprise">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header bg-secondary">
              <h4 className="modal-title text-center text-uppercase w-100 medium text-white font-20">
              ENTREPRENEURIAL Assessment Platform
              </h4>
              <button type="button" className="close" data-dismiss="modal">
                ×
              </button>
            </div>
            <div className="modal-body">
              <div className="">
                <div className="about" id="services">
                  <div className="container text-justify">
                    <div
                      className=" animate animated slideInLeft"
                      data-animation="slideInRight"
                    >
                      <h2 className="about-title text-left">
                        <FormattedHTMLMessage id="ent.creation.popup.title" defaultMessage="BUSINESS CREATION Assessment Platform"/>
                      </h2>
                      <div className="about-text">
                        <FormattedHTMLMessage id="ent.creation.popup.about" defaultMessage="<p>You are an enterprise and want to source the right candidate to your company especially on motivations and values waiting for.</p><p><b>The RECRUITMENT Assessment Platform</b> allows in three minutes to each candidate assess his/her potential to fit with the culture and value of the company and fit with skills asked (Organizer, Administrator, Manager, Commercial, Expertise, Listening, Adaptability, Personal situation).</p><p>Moreover enterprise get global statistical results and see per population what the candidate expected more to adapt the recruitment. The platform is customized per company.</p>"/>
                      </div>
                      <div className="indi-list">
                        <FormattedHTMLMessage id="ent.creation.duration" defaultMessage="<h3>Duration</h3><ul className='indi-ul'> <li>40 questions – 3 minutes.</li></ul>"/>
                      </div>
                      <div className="indi-list">
                        <FormattedHTMLMessage id="ent.creation.resultlist" defaultMessage="<h3 class=''>Results </h3> <ul className='indi-ul'> <li> Global satisfaction of employees compare to the average of the company. </li><li> Detail of satisfaction on eight key factors of balance and development. </li><li>How company still improved happiness of employees per population.</li></ul>"/>
                      </div>
                      <div className="indi-list">
                        <FormattedHTMLMessage id="ent.creation.help" defaultMessage="<h3 class=''>Help </h3> <ul className='indi-ul'> <li>Practical online test to prepare professional interview.</li><li> Identify malaise of employee or for all company. </li><li> Degrease turn-over of the company </li><li> Have a sample observatory of the well-being in the company </li></ul>"/>
                      </div>
                      {/* <div className="indi-list">
                        <FormattedHTMLMessage id="ent.creation.price" defaultMessage="<h3 class=''>Price</h3> <ul className='indi-ul'> <li>Ask for a quote</li></ul>"/>
                      </div>                                  */}
                      <div
                        className="intro_layer mt-5 d-flex"
                        data-animation="fadeIn"
                      >
                        <div className="d-table m-auto">
                          <div className="d-inline-block flex-fill">
                            <p>
                              <button
                                type="button"
                                className="btn  center-block"
                                style={{backgroundColor:"#191970",color:"white"}}
                                data-animation="fadeIn"
                              >
                              <Link target="_blank" className="text-white" to="/contact-enterprise">
                                <FormattedHTMLMessage id="hrsupport.test.btn.content" defaultMessage="<span>CONTACT US</span>  <div class=''> FOR A PRIVATE DEMONSTRATION </div><br/> (Enterprise ONLY)"/>
                              </Link>
                              </button>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
    

    
    </React.Fragment>
  );
};

export default Enterprise;
