import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { FormattedMessage } from "react-intl";
import $ from "jquery";
import Hash from "../../../helper/Hashing";
import Testcopyrights from "../../Testcopyrights"; // used to show test copyright text under test 

import {
  fetchCommunicationTest,
  saveCommunicationTest,getCommunicationResult
} from "../../../actions/Talent";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
class CommunicationResultlink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      now: 0,
      loading: true,
      error: false,
      errorMsg: "",
      test_id: null,
      user_id: null,
      test_version_id: null,
      language: null,
      invalid: true,
      success_msg: false,
      showViewBtn: false,
      hideForm: true,
      showResultDiv: false,
      ansData: [],
      labels: [],
      answer1: null,
      answer2: null,
      answer3: null,
      answer4: null,
      answer5: null,
      answer6: null,
      answer7: null,
      showChart: false,
      testcode: localStorage.getItem('sharedTestId')
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    let language;
    if (this.props.lang === "en") language = "english";
    if (this.props.lang === "fr") language = "french";
    const { hr } = this.props;
    let hrId = null;
    let code = null;
    if (hr) {
      code = hr;
      hrId = Hash.decrypt(hr);
    }else{
      code = localStorage.getItem('sharedTestId');
     
    }
    // console.log('Response fetchCommunicationTest => '+JSON.stringify(this.props)+' hrId '+hrId+' code '+code);    

    const token = localStorage.getItem("token");

    this.props.fetchCommunicationTest(token, hrId, code, language, resp => {
      // if (resp === "success") {
      //   this.setState({ loading: false });
      // }
      // if (resp === "no test") {
      //   this.setState({ loading: false });
      //   this.props.history.push("/buy-test");
      // }
      // if (resp === "used") {
      //   this.setState({
      //     loading: false,
      //     error: true,
      //     errorMsg: "Test already taken"
      //   });
      // }
      // if (resp === "error") {
      //   this.setState({
      //     loading: false,
      //     error: true,
      //     errorMsg: "Error occur, please try again!!"
      //   });
      // }
    });
      this.setState({ loading: true });
      this.props.getCommunicationResult(token, this.state, response => {
        if (response.data.message === "success") {
          this.setState({ loading: false });
          this.props.stepUp();
        }

        // this.setState({
        //   ansData: response.data.detailData,
        //   success_msg: true,
        //   invalid: false,
        //   hideForm: false,
        //   showViewBtn: true
        // });
      });
    
   
  }

  renderField(field) {
    return (
      <div className="custom-control custom-radio custom-control-inline d-block w-100 text-center pl-0">
        <input
          type="radio"
          className={field.class}
          id={field.id}
          value={field.rbvalue}
          onClick={() => {
            field.handleChange(field.rbvalue);
          }}
          {...field.input}
        />
        <label className="custom-control-label" htmlFor={field.id}>
          {field.label}
        </label>
      </div>
    );
  }

  selectAnswer(val) {
    var { page } = this.state;
    let questionNo = "answer" + page;
    this.setState({
      [questionNo]: val
    });

    $("#error-message").hide();
  }

  renderQuestions() {
    const { page } = this.state;
    let inc = 1;

    if (this.props.test) {
      return this.props.test.commQuestions.map((question, index) => {
        return (
          <React.Fragment key={index}>
            {page === inc && (
              <div className="card">
                <div className="card-header">{question.question}</div>
                <ul className="list-group list-group-flush  w-100 float-left">
                  <span>
                    <li
                      className="list-group-item w-50 float-left"
                      key={inc + 1}
                    >
                      <Field
                        name={"option" + question.id}
                        id={"id" + question.option1}
                        class={"custom-control-input option" + index}
                        component={this.renderField}
                        label={question.option1}
                        rbvalue={1}
                        handleChange={e => this.selectAnswer(e)}
                      />
                    </li>
                    <li
                      className="list-group-item  w-50 float-left"
                      key={index}
                    >
                      <Field
                        name={"option" + question.id}
                        id={"id" + question.option2}
                        class={"custom-control-input option" + index}
                        component={this.renderField}
                        label={question.option2}
                        rbvalue={0}
                        handleChange={e => this.selectAnswer(e)}
                      />
                    </li>
                  </span>
                </ul>
              </div>
            )}
            <div className="d-none">{(inc = inc + 1)}</div>
          </React.Fragment>
        );
      });
    }
  }

  nextQuestion() {
    const { page } = this.state;
    let quesNo = page - 1;

    if ($(`.option${quesNo}`).is(":checked")) {
      this.setState({
        page: page + 1
      });
      $("#error-message").hide();
      if (page === 6) {
        let language;
        if (this.props.lang === "en") language = "english";
        if (this.props.lang === "fr") language = "french";

        this.setState({
          invalid: false,
          test_id: this.props.test.id,
          user_id: localStorage.getItem("userId"),
          language: language
        });
      }
    } else {
      $("#error-message").show();
    }
  }

  onSubmit() {
    const { page } = this.state;
    let quesNo = page - 1;
    const token = localStorage.getItem("token");

    if ($(`.option${quesNo}`).is(":checked")) {
      this.setState({ loading: true });
      this.props.saveCommunicationTest(token, this.state, response => {
        if (response.data.message === "success") {
          this.setState({ loading: false });
          this.props.stepUp();
        }

        // this.setState({
        //   ansData: response.data.detailData,
        //   success_msg: true,
        //   invalid: false,
        //   hideForm: false,
        //   showViewBtn: true
        // });
      });
    } else {
      $("#error-message").show();
    }
  }

  //***** view saved test result*** */
  showTestResult(val) {
    //const token = localStorage.getItem("token");
    this.setState({
      showResultDiv: true,
      showChart: true,
      showViewBtn: false,
      success_msg: false
    });
  }

  renderLabels(ansData, labels) {
    let inc = 1;
    if (labels) {
      return labels.map((label, index) => {
        return (
          <React.Fragment key={index}>
            {ansData.detail.map((value, key) => {
              // var answer  =  'answer'+[inc];
              //var ques    = 'question'+inc
              return (
                <span key={key}>
                  {value["question_" + inc] === 1 && (
                    <li className="li-style" key={key}>
                      <span>+</span>
                      {label}{" "}
                    </li>
                  )}
                  {value["question_" + inc] === 0 && (
                    <li className="li-style" key={key}>
                      <span>-</span>
                      {label}
                    </li>
                  )}
                </span>
              );
            })}
            <div className="d-none">{(inc = inc + 1)}</div>
          </React.Fragment>
        );
      });
    }
  }

  renderCharts(score) {
    var resultData = [{ score: score }];
    if (this.state.showChart) {
      return (
        <div style={{ width: "100%", height: 300 }} className="m-auto">
          <ResponsiveContainer>
            <BarChart
              data={resultData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              <XAxis dataKey="name" />
              <YAxis domain={[0, 7]} />
              <Tooltip />
              <Legend />
              <Bar dataKey="score" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      );
    } else {
      return null;
    }
  }

  /*renderResult(data) {
    const { ansData, labels } = this.state;

    if (this.state.ansData) {
      let answer1 = this.state.ansData.sum_count < 3 ? "With difficulty" : "";
      let answer2 =
        this.state.ansData.sum_count === "3" ||
        this.state.ansData.sum_count === "4" ||
        this.state.ansData.sum_count === "5"
          ? "With easily"
          : "";
      let answer3 =
        this.state.ansData.sum_count === "6" ||
        this.state.ansData.sum_count === "7"
          ? "With great ease"
          : "";

      if (this.state.showResultDiv) {
        return (
          <React.Fragment>
            <div className="card">
              <div className="row">
                <div className="col-md-4">
                  {this.renderCharts(this.state.ansData.sum_count)}
                </div>
                <div className="col-md-8">
                  <ul>{this.renderLabels(ansData, labels)}</ul>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4" />
                <div className="col-md-8">
                  {answer1 && (
                    <p>
                      I communicate my feelings "<b>{answer1}</b>"
                    </p>
                  )}
                  {answer2 && (
                    <p>
                      I communicate my feelings "<b>{answer2}</b>"
                    </p>
                  )}
                  {answer3 && (
                    <p>
                      I communicate my feelings "<b>{answer3}</b>"
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="d-none" />
          </React.Fragment>
        );
      }
    }
  }*/

  render() {
    const {
      page,
      success_msg,
      hideForm,
      showViewBtn,
      loading,
      error,
      errorMsg
    } = this.state;
    var displayBtn = { display: page === 7 ? "none" : "block" };
    var displayForm = { display: hideForm ? "block" : "none" };

    if (loading)
      return (
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              <div className="text-center">
                <div
                  className="spinner-border m-5"
                  role="status"
                  style={{ width: "3rem", height: "3rem" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      );

    return (
      <React.Fragment>
        <h2 className="text-center mb-1">Communication</h2>
        <div className="col-lg-8 col-md-10 col-sm-12 m-auto communication-test">
          {error && <div className="alert alert-danger">{errorMsg}</div>}
          {success_msg && (
            <div id="success-message" className="text-success">
              <FormattedMessage id="test_saved" defaultMessage="Test saved successfully !"/>
            </div>
          )}
          <div id="error-message" className="text-danger">
            Select option
          </div>

          <div style={displayForm}>
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
              {this.renderQuestions()}
              <button
                type="submit"
                className="btn btn-info mt-2 mx-auto"
                style={{ display: !this.state.invalid ? "block" : "none" }}
              >
              <FormattedMessage id="submit" defaultMessage="SUBMIT"/>
              </button>
            </form>
          </div>
          <button
            style={displayBtn}
            className="btn btn-info mt-2 mx-auto text-center"
            onClick={() => this.nextQuestion()}
          >
          <FormattedMessage id="next" defaultMessage="NEXT"/>
          </button>
          {showViewBtn ? (
            <button
              className="btn btn-info mt-2"
              onClick={() => this.showTestResult(this.state)}
            >
            <FormattedMessage id="view_result" defaultMessage="View Result"/>
            </button>
          ) : (
            ""
          )}

          {/* <div className="resultGraph">{this.renderResult(this.state)}</div> */}
        </div>
        {page > 0 && ( // check page and hide the title from first two steps i.e user explanations
          <Testcopyrights />
        )}        
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    test: state.talent.CommunicationData.data,
    result: state.talent.CommunicationResult,
    lang: state.locale.lang
  };
};

CommunicationResultlink = connect(
  mapStateToProps,
  { fetchCommunicationTest,saveCommunicationTest,getCommunicationResult }
)(CommunicationResultlink);

export default reduxForm({
  form: "CommunicationForm"
})(CommunicationResultlink);
