import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import $ from "jquery";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FormattedMessage } from "react-intl";
import { ROOT_URL, APP_URL } from "../../../helper/Config";
import Testcopyrights from "../../Testcopyrights"; // used to show test copyright text under test 
// import { useParams } from "react-router-dom"
import {
  fetchWorkEnvironment,
  saveWorkEnvironment
} from "../../../actions/Target";
import Hash from "../../../helper/Hashing";
//import { log } from "util";

class WorkEnvironment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      now: 0,
      loading: true,
      pages:localStorage.getItem('page'),
      nows:localStorage.getItem('now'),
      error: false,
      errorMsg: "",
      test_id: null,
      user_id: null,
      test_version_id: null,
      language: null,
      invalid: true,
      success_msg: false,
      showViewBtn: false,
      hideForm: true,
      showResultDiv: false,
      ansData: [],
      labels: [],
      answer1: null,
      answer2: null,
      answer3: null,
      answer4_1: [],
      answer4_2: [],
      answer4_3: [],
      answer4_4: [],
      answer4_5: [],
      answer4_6: [],
      answer4_7: [],
      answer4_8: [],
      answer4_9: [],
      checkedArray1: [],
      checkedArray2: [],
      checkedArray3: [],
      checkedArray4: [],
      checkedArray5: [],
      checkedArray6: [],
      checkedArray7: [],
      checkedArray8: [],
      checkedArray9: [],
      checked: false,
      HrId:null,
      resultlink:null,
      testcode:localStorage.getItem('sharedTestId'),
      candidateid:null
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    // console.log(this.props.match.params.op);
    // let par = useParams()
    let language;
    if (this.props.lang === "en") language = "english";
    if (this.props.lang === "fr") language = "french";
    const { hr } = this.props;
    let hrId = null;
    let code = null;
    if (hr) {
      code = hr;
      hrId = Hash.decrypt(hr);
      this.setState({ HrId: hrId }); 
      const link=`${APP_URL}CoachingCareerResult/${code}`;
      this.setState({resultlink: link});
    }else{
      code = localStorage.getItem('sharedTestId');
     let hrcode = Hash.decrypt(code);
      this.setState({ HrId: hrcode }); 
      const link=`${APP_URL}CoachingCareerResult/${code}`;
      this.setState({resultlink: link});
    }

    // const queryParams = new URLSearchParams(window.location.search);
    // const term = queryParams.get("op");
    console.log(this.props.pars);
    if(code != "null"){
      code = localStorage.getItem('sharedTestId');
      const link=`${APP_URL}CoachingCareerResult/${code}`;
      this.setState({resultlink: link});
      if(this.props.pars == 3){
        this.setState({ candidateid: 3 }); 
      }
     if(code!=null){
     hrId =Hash.decrypt(code);
     this.setState({ HrId: hrId });
     }
   }
    
    console.log('Response fetchWorkEnvironment => '+JSON.stringify(this.props)+' hrId '+hrId+' code '+code);    

    const token = localStorage.getItem("token");
    this.props.fetchWorkEnvironment(token, hrId, code, language, resp => {
      if (resp === "success") {
        this.setState({ loading: false });
      }
      if (resp === "no test") {
        this.setState({ loading: false });
        this.props.history.push("/buy-test");
      }
      if (resp === "used") {
        this.setState({
          loading: false,
          error: true,
          errorMsg: "Test already taken"
        });
      }
      if (resp === "error") {
        this.setState({
          loading: false,
          error: true,
          errorMsg: "Error occur, please try again!!"
        });
      }
    });
  }

  renderField(field) {
    return (
      <div className="custom-control custom-radio m-3 ">
        <input
          type="radio"
          className={field.class}
          id={field.id}
          value={field.rbvalue}
          onClick={() => {
            field.handleChange(field.rbvalue);
          }}
          {...field.input}
        />
        <label className="custom-control-label" htmlFor={field.id}>
          {field.label}
        </label>
      </div>
    );
  }

  renderCheckBoxField(field) {
    //console.log(field.id+' -- '+field.rbvalue);
    return (
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className={field.class}
          id={field.id}
          value={field.rbvalue}
          onClick={() => {
            field.handleChange(field.rbvalue);
          }}
          {...field.input}
        />
        <label className="custom-control-label" htmlFor={field.id}>
          {field.label}
        </label>
      </div>
    );
  }

  selectAnswer(val) {
    var { page } = this.state;
    let questionNo = "answer" + page;

    this.setState({
      [questionNo]: val
    });
    $("#error-message").hide();
  }

  /* check/uncheck values*/
  changeEvent(event, key) {
    var {
      page,
      checkedArray1,
      checkedArray2,
      checkedArray3,
      checkedArray4,
      checkedArray5,
      checkedArray6,
      checkedArray7,
      checkedArray8,
      checkedArray9
    } = this.state;
    let questionNo = "answer" + page + "_" + key;

    let selectedValue = event;
    var uniqueArr = [];
    if (key === 1) {
      if ($.inArray(event, checkedArray1) !== -1) {
        uniqueArr = Array.from(new Set(checkedArray1));
        //var index = $.inArray(event, checkedArray1);
        //var popElement = uniqueArr.splice(index, 1);
        var leftElement = uniqueArr.toString();
        uniqueArr = [];
        if (leftElement !== "") {
          uniqueArr.push(leftElement);
        }
      } else {
        uniqueArr = Array.from(new Set(checkedArray1));
        uniqueArr.push(selectedValue);
      }
      this.setState({
        [questionNo]: uniqueArr,
        checkedArray1: uniqueArr
      });
    }

    if (key === 2) {
      if ($.inArray(event, checkedArray2) !== -1) {
        uniqueArr = Array.from(new Set(checkedArray2));
        //index = $.inArray(event, checkedArray2);
        // popElement = uniqueArr.splice(index, 1);
        leftElement = uniqueArr.toString();
        uniqueArr = [];
        if (leftElement !== "") {
          uniqueArr.push(leftElement);
        }
      } else {
        uniqueArr = Array.from(new Set(checkedArray2));
        uniqueArr.push(selectedValue);
      }
      this.setState({
        [questionNo]: uniqueArr,
        checkedArray2: uniqueArr
      });
    }
    if (key === 3) {
      if ($.inArray(event, checkedArray3) !== -1) {
        uniqueArr = Array.from(new Set(checkedArray3));
        //index = $.inArray(event, checkedArray3);
        //popElement = uniqueArr.splice(index, 1);
        leftElement = uniqueArr.toString();
        uniqueArr = [];
        if (leftElement !== "") {
          uniqueArr.push(leftElement);
        }
      } else {
        uniqueArr = Array.from(new Set(checkedArray3));
        uniqueArr.push(selectedValue);
      }
      this.setState({
        [questionNo]: uniqueArr,
        checkedArray3: uniqueArr
      });
    }
    if (key === 4) {
      if ($.inArray(event, checkedArray4) !== -1) {
        uniqueArr = Array.from(new Set(checkedArray4));
        //index = $.inArray(event, checkedArray4);
        //popElement = uniqueArr.splice(index, 1);
        leftElement = uniqueArr.toString();
        uniqueArr = [];
        if (leftElement !== "") {
          uniqueArr.push(leftElement);
        }
      } else {
        uniqueArr = Array.from(new Set(checkedArray4));
        uniqueArr.push(selectedValue);
      }
      this.setState({
        [questionNo]: uniqueArr,
        checkedArray4: uniqueArr
      });
    }
    if (key === 5) {
      if ($.inArray(event, checkedArray5) !== -1) {
        uniqueArr = Array.from(new Set(checkedArray5));
        //index = $.inArray(event, checkedArray5);
        //popElement = uniqueArr.splice(index, 1);
        leftElement = uniqueArr.toString();
        uniqueArr = [];
        if (leftElement !== "") {
          uniqueArr.push(leftElement);
        }
      } else {
        uniqueArr = Array.from(new Set(checkedArray5));
        uniqueArr.push(selectedValue);
      }
      this.setState({
        [questionNo]: uniqueArr,
        checkedArray5: uniqueArr
      });
    }
    if (key === 6) {
      if ($.inArray(event, checkedArray6) !== -1) {
        uniqueArr = Array.from(new Set(checkedArray6));
        //index = $.inArray(event, checkedArray6);
        //popElement = uniqueArr.splice(index, 1);
        leftElement = uniqueArr.toString();
        uniqueArr = [];
        if (leftElement !== "") {
          uniqueArr.push(leftElement);
        }
      } else {
        uniqueArr = Array.from(new Set(checkedArray6));
        uniqueArr.push(selectedValue);
      }
      this.setState({
        [questionNo]: uniqueArr,
        checkedArray6: uniqueArr
      });
    }
    if (key === 7) {
      if ($.inArray(event, checkedArray7) !== -1) {
        uniqueArr = Array.from(new Set(checkedArray7));
        //index = $.inArray(event, checkedArray7);
        // popElement = uniqueArr.splice(index, 1);
        leftElement = uniqueArr.toString();
        uniqueArr = [];
        if (leftElement !== "") {
          uniqueArr.push(leftElement);
        }
      } else {
        uniqueArr = Array.from(new Set(checkedArray7));
        uniqueArr.push(selectedValue);
      }
      this.setState({
        [questionNo]: uniqueArr,
        checkedArray7: uniqueArr
      });
    }
    if (key === 8) {
      if ($.inArray(event, checkedArray8) !== -1) {
        uniqueArr = Array.from(new Set(checkedArray8));
        //index = $.inArray(event, checkedArray8);
        //popElement = uniqueArr.splice(index, 1);
        leftElement = uniqueArr.toString();
        uniqueArr = [];
        if (leftElement !== "") {
          uniqueArr.push(leftElement);
        }
      } else {
        uniqueArr = Array.from(new Set(checkedArray8));
        uniqueArr.push(selectedValue);
      }
      this.setState({
        [questionNo]: uniqueArr,
        checkedArray8: uniqueArr
      });
    }
    if (key === 9) {
      if ($.inArray(event, checkedArray9) !== -1) {
        uniqueArr = Array.from(new Set(checkedArray9));
        //index = $.inArray(event, checkedArray9);
        //popElement = uniqueArr.splice(index, 1);
        leftElement = uniqueArr.toString();
        uniqueArr = [];
        if (leftElement !== "") {
          uniqueArr.push(leftElement);
        }
      } else {
        uniqueArr = Array.from(new Set(checkedArray9));
        uniqueArr.push(selectedValue);
      }
      this.setState({
        [questionNo]: uniqueArr,
        checkedArray9: uniqueArr
      });
    }
  }

  renderQuestions() {
    const { page } = this.state;
    let inc = 1;

    if (this.props.test) {
      return this.props.test.map((question, index) => {
        return (
          <React.Fragment key={index}>
            {page === inc && (
              <div className="card" attr={page}>
                <div className="card-header">{question.question} </div>
                <div className="row m-0">
                  {this.renderAnswers(question, index)}
                </div>
              </div>
            )}
            <div className="d-none">{(inc = inc + 1)}</div>
          </React.Fragment>
        );
      });
    }
  }

  renderAnswers(question, index) {
    let inc = 1;
    var { page } = this.state;

    return (
      <React.Fragment>
        <div className="row m-0 flex-nowrap w-100 justify-content-between">
          {question.option1 && (
            <div className="flex-fill">
              <Field
                name={"answer" + inc}
                id={"option1" + question.id}
                class={"custom-control-input option" + page}
                component={this.renderField}
                label={question.option1}
                rbvalue={question.option1}
                handleChange={e => this.selectAnswer(e)}
              />
            </div>
          )}
          {question.option2 && (
            <div className="flex-fill">
              <Field
                name={"answer" + question.id}
                id={"option2" + question.id}
                class={"custom-control-input option" + page}
                component={this.renderField}
                label={question.option2}
                rbvalue={question.option2}
                handleChange={e => this.selectAnswer(e)}
              />
            </div>
          )}
          {question.option3 && (
            <div className="flex-fill">
              <Field
                name={"answer" + question.id}
                id={"option3" + question.id}
                class={"custom-control-input option" + page}
                component={this.renderField}
                label={question.option3}
                rbvalue={question.option3}
                handleChange={e => this.selectAnswer(e)}
              />
            </div>
          )}
          {question.option4 && (
            <div className="flex-fill">
              <Field
                name={"answer" + question.id}
                id={"option4" + question.id}
                class={"custom-control-input option" + page}
                component={this.renderField}
                label={question.option4}
                rbvalue={question.option4}
                handleChange={e => this.selectAnswer(e)}
              />
            </div>
          )}
        </div>
        {this.renderQuestionChilderns(question.children)}

        <div className="d-none">{(inc = inc + 1)}</div>
      </React.Fragment>
    );
  }

  renderQuestionChilderns(childern) {
    if (childern) {
      return childern.map((value, key) => {
        return (
          <React.Fragment key={key + 1}>
            <div className="col-md-4">
              <div className="py-3 border-bottom">
                <h6 className="text-primary font-weight-bold">
                  {value.category}
                </h6>

                <Field
                  name={"answer" + value.parent_id + "_" + (key + 1) + "[]"}
                  id={"answer1_" + value.parent_id + "_" + (key + 1)}
                  class={"custom-control-input option" + key}
                  component={this.renderCheckBoxField}
                  label={value.option1}
                  rbvalue={value.option1}
                  handleChange={e => this.changeEvent(e, key + 1)}
                />

                <Field
                  name={"answer" + value.parent_id + "_" + (key + 1) + "[]"}
                  id={"answer2_" + value.parent_id + "_" + (key + 1)}
                  class={"custom-control-input option option" + key}
                  component={this.renderCheckBoxField}
                  label={value.option2}
                  rbvalue={value.option2}
                  handleChange={e => this.changeEvent(e, key + 1)}
                />
              </div>
            </div>
          </React.Fragment>
        );
      });
    }
  }

  renderProgress() {
    const { nows, pages } = this.state;

    console.log(nows);
    console.log(pages)

    if (pages > 0 ) {
      return <ProgressBar now={nows} label={`${nows}%`} className="mb-3" />;
    } else {
      return null;
    }
  }

  nextQuestion() {
    const { page,pages } = this.state;
    let quesNo = page;
    if(localStorage.getItem('testType')=='individual'){
      let progres = (100 /21) * pages;
      let progress= progres.toFixed(2);
      // let quesAns = "question" + page + "Answers";
     
      let addedpages=parseInt(pages) + 1;
      this.setState({ pages: addedpages, nows: progress });
      let addpage=parseInt(this.state.pages) + 1;
      localStorage.setItem('page',addpage);
      localStorage.setItem('now',this.state.nows)
    }else{
    let progres = (100 /35) * pages;
    let progress= progres.toFixed(2);
    // let quesAns = "question" + page + "Answers";
 
    let addedpages=parseInt(pages) + 1;
    this.setState({ pages: addedpages, nows: progress });
    let addpage=parseInt(this.state.pages) + 1;
    localStorage.setItem('page',addpage);
    localStorage.setItem('now',this.state.nows)
    }
    if ($(`.option${quesNo}`).is(":checked")) {
      this.setState({
        page: page + 1
      });
      $("#error-message").hide();
      if (page === 3) {
        let language;
        if (this.props.lang === "en") language = "english";
        if (this.props.lang === "fr") language = "french";
        this.setState({
          invalid: false,
          test_id: this.props.test.id,
          user_id: localStorage.getItem("userId"),
          language: language
        });
      }
    } else {
      $("#error-message").show();
    }
  }

  onSubmit() {
    //const { page} = this.state;
    // let   quesNo  = page+1;
    const token = localStorage.getItem("token");

    //if ($("input:checkbox:checked").length >= 1) { // validate last stesp 
      this.setState({ loading: true });
      const submitData = {
        user_id: this.state.user_id,
        answer1: this.state.answer1,
        answer2: this.state.answer2,
        answer3: this.state.answer3,
        answer4_1: this.state.answer4_1,
        answer4_2: this.state.answer4_2,
        answer4_3: this.state.answer4_3,
        answer4_4: this.state.answer4_4,
        answer4_5: this.state.answer4_5,
        answer4_6: this.state.answer4_6,
        answer4_7: this.state.answer4_7,
        answer4_8: this.state.answer4_8,
        answer4_9: this.state.answer4_9,
        language: this.state.language,
        testcode:this.state.testcode,
        resultlink:this.state.resultlink,
        candidateid:this.state.candidateid,
        hrID:this.state.HrId
      };
      this.props.saveWorkEnvironment(token, submitData, response => {
        this.setState({ loading: false });
        this.props.stepUp();
        // this.setState({
        //   ansData: response.detailData,
        //   success_msg: true,
        //   invalid: false,
        //   hideForm: false,
        //   showViewBtn: true
        // });
      });
      $("#error-message").hide();
    // } else {
    //   $("#error-message").show();
    // }
  }

  //***** view saved test result*** */
  showTestResult(val) {
    this.setState({
      showResultDiv: true,
      showChart: true,
      showViewBtn: false,
      success_msg: false
    });
  }

  /*renderResult(data) {
    const { ansData } = this.state;
    if (this.state.ansData) {
      if (this.state.showResultDiv) {
        return (
          <React.Fragment>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  {ansData.category.map((value, key) => {
                    return (
                      <div key={key} className="col-md-4">
                        <div
                          className={
                            key < 9
                              ? "py-3 border-bottom we-minhgt"
                              : "py-3 we-minhgt"
                          }
                        >
                          <h6 className="text-primary font-weight-bold">
                            {value.question === null || ""
                              ? value.category
                              : value.question}
                          </h6>
                          <b />
                          {this.renderSavedAnswer(ansData, key + 1)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      }
    }
  }

  renderSavedAnswer(data, key) {
    if (data) {
      return (
        <React.Fragment>
          <span>
            {key === 1 && <p>{data.question1}</p>}
            {key === 2 && <p>{data.question2}</p>}
            {key === 3 && <p>{data.question3}</p>}
            {key === 4 && (
              <p>
                {data.question4_1.includes(",")
                  ? data.question4_1.replace(",", " and ")
                  : data.question4_1}
              </p>
            )}
            {key === 5 && (
              <p>
                {data.question4_2.includes(",")
                  ? data.question4_2.replace(",", " and ")
                  : data.question4_2}
              </p>
            )}
            {key === 6 && (
              <p>
                {data.question4_3.includes(",")
                  ? data.question4_3.replace(",", " and ")
                  : data.question4_3}
              </p>
            )}
            {key === 7 && (
              <p>
                {data.question4_4.includes(",")
                  ? data.question4_4.replace(",", " and ")
                  : data.question4_4}
              </p>
            )}
            {key === 8 && (
              <p>
                {data.question4_5.includes(",")
                  ? data.question4_5.replace(",", " and ")
                  : data.question4_5}
              </p>
            )}
            {key === 9 && (
              <p>
                {data.question4_6.includes(",")
                  ? data.question4_6.replace(",", " and ")
                  : data.question4_6}
              </p>
            )}
            {key === 10 && (
              <p>
                {data.question4_7.includes(",")
                  ? data.question4_7.replace(",", " and ")
                  : data.question4_7}
              </p>
            )}
            {key === 11 && (
              <p>
                {data.question4_8.includes(",")
                  ? data.question4_8.replace(",", " and ")
                  : data.question4_8}
              </p>
            )}
            {key === 12 && (
              <p>
                {data.question4_9.includes(",")
                  ? data.question4_9.replace(",", " and ")
                  : data.question4_9}
              </p>
            )}
          </span>
        </React.Fragment>
      );
    }
  }*/

  render() {
    const {
      page,
      success_msg,
      hideForm,
      loading,
      error,
      errorMsg
    } = this.state;
    var displayBtn = { display: page === 4 ? "none" : "block" };
    var displayForm = { display: hideForm ? "block" : "none" };

    if (loading)
      return (
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              <div className="text-center">
                <div
                  className="spinner-border m-5"
                  role="status"
                  style={{ width: "3rem", height: "3rem" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      );

    return (
      <React.Fragment>
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              <h2 className="text-center mb-1"> 
              <FormattedMessage id="ind.target.workplacedesire.title" defaultMessage="Workplace desired"/>
             </h2>
           
              <div className="col-lg-8 col-md-10 col-sm-12 m-auto">
                {error && <div className="alert alert-danger">{errorMsg}</div>}
                {success_msg && (
                  <div id="success-message" className="text-success">
                    <FormattedMessage id="test_saved" defaultMessage="Test saved successfully !"/>
                  </div>
                )}
                <div id="error-message" className="text-danger">
                  Select option
                </div>

                <div className="d-table mx-auto p-90 w-100" style={displayForm}>
                  <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                  {this.renderProgress()}
                    {this.renderQuestions()}
                    <button
                      type="submit"
                      className="btn btn-info mt-3 mx-auto"
                      style={{ display: this.state.invalid ? "none" : "block" }}
                    >
                    <FormattedMessage id="submit" defaultMessage="SUBMIT"/>
                    </button>
                  </form>
                </div>
                <div className="d-table mx-auto p-90">
                  <button
                    style={displayBtn}
                    className="btn btn-info mt-2 mx-auto"
                    onClick={() => this.nextQuestion()}
                  >
                  <FormattedMessage id="next" defaultMessage="NEXT"/>
                  </button>
                </div>                
              </div>
              <Testcopyrights />
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    test: state.target.WorkEnvData,
    lang: state.locale.lang
  };
};

WorkEnvironment = connect(
  mapStateToProps,
  { fetchWorkEnvironment, saveWorkEnvironment }
)(WorkEnvironment);

export default reduxForm({
  form: "WorkEnvironmentTestForm"
})(WorkEnvironment);

// BEGIN
// 	UPDATE user_test SET no_of_test = IF(no_of_test > 0, no_of_test - 1, 0), is_free = '0' WHERE user_id = NEW.user_id;
//  END