import axios from "axios";

import { ROOT_URL } from "../helper/Config";

export const MAKE_CHARGE = "make_charge";
export const AUTH_ERROR = "auth_error";

export function updaetPaymentData(token, data, callback) {

  //console.log(data);
  return dispatch => {
    return axios.post(`${ROOT_URL}/updaetPaymentData`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }).then(response => {
        //console.log('Here we go 0'+response);
        //console.log('updaetPaymentData then '+JSON.stringify(response));
        callback(response);
      }).catch(error => {
        console.log('updaetPaymentData catch '+JSON.stringify(error));         
        //console.log(error.response.status);
        //callback(error);
      });
  };
}
