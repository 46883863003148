import React, { Component } from "react";
//import { history } from "../helper/History";
//import { confirmAlert } from 'react-confirm-alert'; // Import
import $ from "jquery";
import { FormattedMessage } from "react-intl";
import html2canvas from "html2canvas";
import * as jsPDF from "jspdf";
import { APP_URL } from "../helper/Config";
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

class PrintPdf extends Component {
    constructor(props) {
        //console.log("Inside PrintPdf => "+JSON.stringify(props));
        super(props);
        this.state = {
            loader2: false,
            showDialog: false,
            testType: null,
            pdfName: null,
            canvasSelector: null
        };
    }

    async printResult(){
      const { canvasSelector, pdfName } = this.props;
      // console.log(canvasSelector+' -+- '+pdfName);
      if(canvasSelector !== undefined && pdfName !== undefined){
        this.setState({ loader2: true });

        let CurrentDate   =  new Date();
        //let copyRightDate = CurrentDate.getFullYear();

        var pilotRhLogo = new Image();
        pilotRhLogo.src = APP_URL+'assets/images/LOGO33.jpg';
        console.log('Logo src '+pilotRhLogo.src);
        // var PDF_Heighti   = document.querySelector('#'+canvasSelector).offsetWidth;
        //var HTML_Width    = $("#happinessTest").width();
        var HTML_Width    = $('#'+canvasSelector).width();
        //console.log('Html width => '+HTML_Width);
        var HTML_Height   = $('#'+canvasSelector).height();
        //console.log('Html height => '+HTML_Height);
        var top_left_margin = 15;
        var PDF_Width = HTML_Width+(top_left_margin*2);
        //var PDF_Height = 2000;
        var PDF_Height = HTML_Height+(top_left_margin*2);
        //console.log('PDF Height x Width => '+PDF_Height+'x'+PDF_Width);
        // var canvas_image_width  = HTML_Width;
        // var canvas_image_height = HTML_Height;

        var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;
        //console.log('NoOfPage => '+totalPDFPages);
	      var pdf = new jsPDF('p', 'mm');
	      var pdfWidth = pdf.internal.pageSize.getWidth();
		  var pdfHeight = pdf.internal.pageSize.getHeight();
		  var pdfheight_left =  pdfHeight;
		  pdfheight_left -= 40;
		  pdf.addImage(pilotRhLogo, 'png', ((pdfWidth / 2) - 20), 10);
          var lastInsert =  40;
          var len = $('#'+canvasSelector).children().length;
          console.log($('#'+canvasSelector).children());
          var i = 0;
          var waiting = -1;
		 while (i <= len) {
		 	console.log(i);
		 	if(i == waiting ){
		 		continue;
		 	}
      		if (i != len){
          	await html2canvas($('#'+canvasSelector).children()[i],{allowTaint:true}).then((canvas) => {
          		waiting = i;
          		var imgData = canvas.toDataURL("image/jpeg", 1.0);
          		var imgWidth = pdfWidth - 25;
          		var imgHeight = canvas.height * imgWidth / canvas.width;
          		if (i === (len - 1)){
          			lastInsert = lastInsert + 30;
          		}
          		if((pdfHeight - lastInsert) > imgHeight ){
          			pdf.addImage(imgData, 'PNG', 10, lastInsert, imgWidth, imgHeight );
          			lastInsert = imgHeight + lastInsert+10;
          			i++
          		}
          		else{
          			pdf.addPage();
          			pdf.addImage(imgData, 'PNG', 10, 10 , imgWidth, imgHeight );
          			lastInsert = imgHeight+10;
          			i++
          		}
          	})
          }
          else{
          	   i++
      		   pdf.setFontSize(12);
	           pdf.setTextColor(108, 117, 20);
	           pdf.text(10, (pdfHeight-20),'© Copyright 5W PILOT RH SOLUTIONS '+ CurrentDate.getFullYear() +' – All right reserved – www.pilotrhsolutions.com.');
	           pdf.setTextColor(23, 162, 184);
	           pdf.setFontType("bold");
             {(this.props.username !=null ?
	           pdf.text(((pdfWidth / 2) - 30), (pdfHeight-10),this.props.username.toUpperCase()+' - '+("0" + (CurrentDate.getDate())).slice(-2) +'/'+ ("0" + (CurrentDate.getMonth() + 1)).slice(-2)  +'/'+ CurrentDate.getFullYear()+' - '+ CurrentDate.getHours()+':'+ CurrentDate.getMinutes())
             :pdf.text(((pdfWidth / 2) - 30), (pdfHeight-10), localStorage.getItem("userName").toUpperCase()+' - '+("0" + (CurrentDate.getDate())).slice(-2) +'/'+ ("0" + (CurrentDate.getMonth() + 1)).slice(-2)  +'/'+ CurrentDate.getFullYear()+' - '+ CurrentDate.getHours()+':'+ CurrentDate.getMinutes())
             )}
             pdf.save(pdfName+".pdf");
      		}
		  }

          this.setState({ loader2: false });
        /*html2canvas($('#'+canvasSelector)[0],{allowTaint:true}).then((canvas) => {
          canvas.getContext('2d');
          //console.log(canvas.height+" "+canvas.width);
          var imgData = canvas.toDataURL("image/jpeg", 1.0);
          var imgWidth = pdfWidth - 20;
		  var pageHeight = pdfHeight;
		  var imgHeight = canvas.height * imgWidth / canvas.width;
		  var heightLeft = (imgHeight - 60 );
		  var lastInsert = 0;
          var position = 40;
          //var pdf = new jsPDF();
          //console.log('Inside  '+pilotRhLogo);
          pdf.addImage(pilotRhLogo, 'png', ((pdfWidth / 2) - 20), 10);
          pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight );
          heightLeft -= pageHeight - 100;
          while (heightLeft >= 0) {
			  position = heightLeft - imgHeight;
			  pdf.addPage();
			  pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
			  heightLeft -= pageHeight;
		  }
          pdf.setFontSize(12);
          pdf.setTextColor(108, 117, 20);
          pdf.text(10, (pageHeight-20),'© Copyright 5W PILOT RH SOLUTIONS '+ CurrentDate.getFullYear() +' – All right reserved – www.pilotrhsolutions.com.');
          pdf.setTextColor(23, 162, 184);
          pdf.setFontType("bold");
          pdf.text(((pdfWidth / 2) - 30), (pageHeight-10), localStorage.getItem("userName").toUpperCase()+' - '+("0" + (CurrentDate.getDate() + 1)).slice(-2) +'/'+ ("0" + (CurrentDate.getMonth() + 1)).slice(-2)  +'/'+ CurrentDate.getFullYear()+' - '+ CurrentDate.getHours()+':'+ CurrentDate.getMinutes());
          pdf.save(pdfName+".pdf");
          this.setState({ loader2: false });
        });*/

        //html2canvas($("#happinessTest")[0],{allowTaint:true}).then(function(canvas) {
        // await html2canvas($('#'+canvasSelector)[0],{allowTaint: false, useCORS: true}).then((canvas) => {
        //   canvas.getContext('2d');
        //   //console.log(canvas.height+" "+canvas.width);
        //   var imgData = canvas.toDataURL("image/jpeg", 1.0);
        //   console.log(imgData);
        //   imgData.onload = function(){
        //     var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
        //     //var pdf = new jsPDF();
        //     pdf.addImage(pilotRhLogo, 'png', 330, 10);
        //     pdf.addImage(imgData, 'JPG', 10, 80);
        //     for (var i = 1; i <= totalPDFPages; i++) {
        //       pdf.addPage(PDF_Width, PDF_Height);
        //       pdf.addImage(imgData, 'JPG', top_left_margin, -PDF_Heighti+(top_left_margin));
        //     }
        //     pdf.setFontSize(12);
        //     pdf.setTextColor(108, 117, 125);
        //     pdf.text(160, (PDF_Height-40),'© Copyright 5W PILOT RH SOLUTIONS '+ CurrentDate.getFullYear() +' – All right reserved – www.pilotrhsolutions.com.');
        //     pdf.setTextColor(23, 162, 184);
        //     pdf.setFontType("bold");
        //     pdf.text(310, (PDF_Height-20), localStorage.getItem("userName").toUpperCase()+' - '+("0" + (CurrentDate.getDate())).slice(-2) +'/'+ ("0" + (CurrentDate.getMonth() + 1)).slice(-2)  +'/'+ CurrentDate.getFullYear()+' - '+ CurrentDate.getHours()+':'+ CurrentDate.getMinutes());
        //     pdf.save(pdfName+".pdf");
        //     this.setState({ loader2: false });
        //   };
        // });
      }
    }

    render() {
      //const { testType } = this.props;
      return (
        <React.Fragment>
            <button onClick={e => this.printResult(e)} className="btn btn-info mt-3 w-50">
              <FormattedMessage id="btn.save.your.result" defaultMessage="SAVE YOUR RESULTS"/>
            </button>
        </React.Fragment>
      );
    }
  }

  export default PrintPdf;
