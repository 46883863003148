import axios from "axios";

import { ROOT_URL } from "../helper/Config";
import { logout } from "./index";

export const AUTH_ERROR = "auth_error";
export const GROUP_DATA = "group_data";
export const WORK_GROUP_RESULT = "work_group_result";
export const WORK_ENV_DATA = "work_env_data";
export const WORK_ENV_RESULT = "work_env_result";

export const PROFESSIONAL_DATA = "professional_data";
export const PROFESSIONAL_RESULT = "professional_result";
export const PUBLIC_DATA = "public_data";
export const PUBLIC_SECTOR_RESULT = "public_sector_result";
export const WORK_CULTURE_DATA = "work_culture_data";
export const WORK_CULTURE_RESULT = "work_culture_result";
export const WORK_ATMOSPHERE_DATA = "work_atmosphere_data";
export const WORK_ATMOSPHERE_RESULT = "work_atmosphere_result";
export const MANAGEMENT_DATA = "management_data";
export const MANAGEMENT_RESULT = "management_result";

export function fetchProfessionalTest(
  token,
  hr = null,
  code = null,
  language,
  callback
) {
  let test_url;
  // if (code != "") {
  //   test_url = `${ROOT_URL}/professional/${language}/${hr}/${code}`;
  // } else if (hr !== null) {
  //   test_url = `${ROOT_URL}/professional/${language}/${hr}`;
  // }else{
  //   test_url = `${ROOT_URL}/professional/${language}`;    
  // }
  // console.log('Target professional url '+test_url+' language '+language+' hr '+hr+' code '+code);
  if (hr === null) {
    test_url = `${ROOT_URL}/professional/${language}`;
  } else {
    test_url = `${ROOT_URL}/professional/${language}/${hr}/${code}`;
  }

  return dispatch => {
    return axios
      .get(test_url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.data.message === "success") {
          dispatch({
            type: PROFESSIONAL_DATA,
            payload: response.data
          });
          callback("success");
        } else {
          callback(response.data.message);
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logout());
          }
        }
        callback("error");
      });
  };
}
export function fetchGroupWork(
  token,
  hr = null,
  code = null,
  language,
  callback
) {
  let test_url;
  // if (code != "") {
  //   test_url = `${ROOT_URL}/group-work/${language}/${hr}/${code}`;
  // } else if (hr !== null) {
  //   test_url = `${ROOT_URL}/group-work/${language}/${hr}`;
  // }else{
  //   test_url = `${ROOT_URL}/group-work/${language}`;    
  // }
  console.log('Target group-work url '+test_url+' language '+language+' hr '+hr+' code '+code);

  if (hr === null) {
    test_url = `${ROOT_URL}/group-work/${language}`;
  } else {
    test_url = `${ROOT_URL}/group-work/${language}/${hr}/${code}`;
  }

  return dispatch => {
    return axios
      .get(test_url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.data.message === "success") {
          dispatch({
            type: GROUP_DATA,
            payload: response.data
          });
          callback(response.data.message);
        } else {
          callback(response.data.message);
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logout());
          }
        }
        callback("error");
      });
  };
}

export function fetchPublicSector(
  token,
  hr = null,
  code = null,
  language,
  callback
) {
  let test_url;
  // if (code != "") {
  //   test_url = `${ROOT_URL}/public-sector/${language}/${hr}/${code}`;
  // } else if (hr !== null) {
  //   test_url = `${ROOT_URL}/public-sector/${language}/${hr}`;
  // }else{
  //   test_url = `${ROOT_URL}/public-sector/${language}`;    
  // }
  console.log('Target public-sector url '+test_url+' language '+language+' hr '+hr+' code '+code);

  if (hr === null) {
    test_url = `${ROOT_URL}/public-sector/${language}`;
  } else {
    test_url = `${ROOT_URL}/public-sector/${language}/${hr}/${code}`;
  }

  return dispatch => {
    return axios
      .get(test_url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.data.message === "success") {
          dispatch({
            type: PUBLIC_DATA,
            payload: response.data
          });
          callback("success");
        } else {
          callback(response.data.message);
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logout());
          }
        }
        callback("error");
      });
  };
}

export function WorkCultureResult(token, callback) {
  return dispatch => {
    return axios
      .get(`${ROOT_URL}/work-culture-result`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        callback(response.data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logout());
          }
        }
        callback("error");
      });
  };
}

export function fetchWorkCulture(
  token,
  hr = null,
  code = null,
  language,
  callback
) {
  let test_url;
  // if (code != "") {
  //   test_url = `${ROOT_URL}/work-culture/${language}/${hr}/${code}`;
  // } else if (hr !== null) {
  //   test_url = `${ROOT_URL}/work-culture/${language}/${hr}`;
  // }else{
  //   test_url = `${ROOT_URL}/work-culture/${language}`;    
  // }
  console.log('Target work-culture url '+test_url+' language '+language+' hr '+hr+' code '+code);

  if (hr === null) {
    test_url = `${ROOT_URL}/work-culture/${language}`;
  } else {
    test_url = `${ROOT_URL}/work-culture/${language}/${hr}/${code}`;
  }

  return dispatch => {
    return axios
      .get(test_url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.data.message) {
          callback(response.data.message);
        } else {
          dispatch({
            type: WORK_CULTURE_DATA,
            payload: response.data
          });
          callback("success");
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logout());
          }
        }
        callback("error");
      });
  };
}

export function saveWorkCulture(token, data, callback) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/work-culture`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch({
          type: WORK_CULTURE_RESULT,
          payload: response.data
        });
        callback(response.data.message);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logout());
          }
        }
        callback("error");
      });
  };
}

export function getWorkCultureResult(token, data, callback) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/getWorkCultureResult`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch({
          type: WORK_CULTURE_RESULT,
          payload: response.data
        });
        callback(response.data.message);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logout());
          }
        }
        callback("error");
      });
  };
}


export function saveProfessionalTest(token, data, callback) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/professional`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch({
          type: PROFESSIONAL_RESULT,
          payload: response.data
        });
        callback(response.data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logout());
          }
        }
        callback("error");
      });
  };
}

export function getProfessionalResult(token, data, callback) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/getProfessionalResult`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch({
          type: PROFESSIONAL_RESULT,
          payload: response.data
        });
        callback(response.data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logout());
          }
        }
        callback("error");
      });
  };
}



export function saveGroupWork(token, data, callback) {
  //console.log(data);

  return dispatch => {
    return axios
      .post(`${ROOT_URL}/group-work`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch({
          type: WORK_GROUP_RESULT,
          payload: response.data
        });
        callback(response.data.message);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logout());
          }
        }
        callback("error");
      });
  };
}

export function getGroupWorkResult(token, data, callback) {
  //console.log(data);

  return dispatch => {
    return axios
      .post(`${ROOT_URL}/getWorkGroupReslut`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch({
          type: WORK_GROUP_RESULT,
          payload: response.data
        });
        callback(response.data.message);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logout());
          }
        }
        callback("error");
      });
  };
}

export function savePublicSector(token, data, callback) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/public-sector`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch({
          type: PUBLIC_SECTOR_RESULT,
          payload: response.data
        });
        callback(response.data.message);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logout());
          }
        }
        callback("error");
      });
  };
}


export function getPublicSectorResult(token, data, callback) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/getPublicSectorResult`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch({
          type: PUBLIC_SECTOR_RESULT,
          payload: response.data
        });
        callback(response.data.message);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logout());
          }
        }
        callback("error");
      });
  };
}

// export function fetchWorkEnvironmentTests(token, callback) {
//   return dispatch => {
//     return axios
//       .get(`${ROOT_URL}/work-environment-test`, {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`
//         }
//       })
//       .then(response => {
//         //console.log(response);

//         dispatch({
//           type: WORK_ENV_DATA,
//           payload: response.data
//         });
//         callback(response.data);
//       })
//       .catch(error => {
//         if (error.response.status === 401) {
//           localStorage.clear();
//           dispatch({
//             type: AUTH_ERROR,
//             payload: "error"
//           });
//           callback("Unauthenticate");
//         }
//       });
//   };
// }

// export function saveWorkEnvironmentTargetTest(token, data, callback) {
//   return dispatch => {
//     return axios
//       .post(`${ROOT_URL}/save-env-test`, data, {
//         headers: {
//           "content-type": "application/json",
//           Authorization: `Bearer ${token}`
//         }
//       })
//       .then(response => {
//         callback(response);
//       })
//       .catch(error => {
//         console.log(error);
//       });
//   };
// }

export function fetchWorkAtmosphere(
  token,
  hr = null,
  code = null,
  language,
  callback
) {
  let test_url;
  // if (code != "") {
  //   test_url = `${ROOT_URL}/work-atmosphere/${language}/${hr}/${code}`;
  // } else if (hr !== null) {
  //   test_url = `${ROOT_URL}/work-atmosphere/${language}/${hr}`;
  // }else{
  //   test_url = `${ROOT_URL}/work-atmosphere/${language}`;    
  // }
  console.log('Target work-atmosphere url '+test_url+' language '+language+' hr '+hr+' code '+code);

  if (hr === null) {
    test_url = `${ROOT_URL}/work-atmosphere/${language}`;
  } else {
    test_url = `${ROOT_URL}/work-atmosphere/${language}/${hr}/${code}`;
  }
  return dispatch => {
    return axios
      .get(test_url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.data.message === "success") {
          dispatch({
            type: WORK_ATMOSPHERE_DATA,
            payload: response.data
          });
          callback("success");
        } else {
          callback(response.data.message);
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logout());
          }
        }
        callback("error");
      });
  };
}

export function saveWorkAtmosphere(token, data, callback) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/work-atmosphere`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch({
          type: WORK_ATMOSPHERE_RESULT,
          payload: response.data
        });
        callback(response.data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logout());
          }
        }
        callback("error");
      });
  };
}

export function getWorkAtmosphereResult(token, data, callback) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/getWorkAtmosphereResult`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch({
          type: WORK_ATMOSPHERE_RESULT,
          payload: response.data
        });
        callback(response.data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logout());
          }
        }
        callback("error");
      });
  };
}


export function fetchManagement(
  token,
  hr = null,
  code = null,
  language,
  callback
) {
  let test_url;
  // if (code != "") {
  //   test_url = `${ROOT_URL}/management/${language}/${hr}/${code}`;
  // } else if (hr !== null) {
  //   test_url = `${ROOT_URL}/management/${language}/${hr}`;
  // }else{
  //   test_url = `${ROOT_URL}/management/${language}`;    
  // }
  console.log('Target management url '+test_url+' language '+language+' hr '+hr+' code '+code);

  if (hr === null) {
    test_url = `${ROOT_URL}/management/${language}`;
  } else {
    test_url = `${ROOT_URL}/management/${language}/${hr}/${code}`;
  }
  return dispatch => {
    return axios
      .get(test_url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.data.message === "success") {
          dispatch({
            type: MANAGEMENT_DATA,
            payload: response.data
          });
          callback("success");
        } else {
          callback(response.data.message);
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logout());
          }
        }
        callback("error");
      });
  };
}

export function saveManagement(token, data, callback) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/management`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch({
          type: MANAGEMENT_RESULT,
          payload: response.data
        });
        callback(response.data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logout());
          }
        }
        callback("error");
      });
  };
}

export function getManagementResult(token, data, callback) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/getManagementResult`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch({
          type: MANAGEMENT_RESULT,
          payload: response.data
        });
        callback(response.data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logout());
          }
        }
        callback("error");
      });
  };
}


export function fetchWorkEnvironment(
  token,
  hr = null,
  code = null,
  language,
  callback
) {
  let test_url;
  // if (code != "") {
  //   test_url = `${ROOT_URL}/work-environment/${language}/${hr}/${code}`;
  // } else if (hr !== null) {
  //   test_url = `${ROOT_URL}/work-environment/${language}/${hr}`;
  // }else{
  //   test_url = `${ROOT_URL}/work-environment/${language}`;    
  // }
  console.log('Target work-environment url '+test_url+' language '+language+' hr '+hr+' code '+code);

  if (hr === null) {
    test_url = `${ROOT_URL}/work-environment/${language}`;
  } else {
    test_url = `${ROOT_URL}/work-environment/${language}/${hr}/${code}`;
  }

  return dispatch => {
    return axios
      .get(test_url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.data.message) {
          callback(response.data.message);
        } else {
          dispatch({
            type: WORK_ENV_DATA,
            payload: response.data
          });
          callback("success");
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logout());
          }
        }
        callback("error");
      });
  };
}

export function saveWorkEnvironment(token, data, callback) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/work-environment`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch({
          type: WORK_ENV_RESULT,
          payload: response.data
        });
        callback(response.data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logout());
          }
        }
        callback("error");
      });
  };
}

export function getWorkEnvironmentResult(token, data, callback) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/getWorkEnvironmentResult`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch({
          type: WORK_ENV_RESULT,
          payload: response.data
        });
        callback(response.data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logout());
          }
        }
        callback("error");
      });
  };
}

