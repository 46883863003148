import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import $ from "jquery";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FormattedMessage } from "react-intl";
import { relative } from "path";
import { fetchPublicSector, savePublicSector } from "../../../actions/Target";
import Hash from "../../../helper/Hashing";
import Testcopyrights from "../../Testcopyrights"; // used to show test copyright text under test 

class PublicSector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      now: 0,
      pages:localStorage.getItem('page'),
      nows:localStorage.getItem('now'),
      loading: true,
      error: false,
      errorMsg: "",
      test_id: null,
      user_id: null,
      test_version_id: null,
      language: null,
      invalid: true,
      success_msg: false,
      hideForm: true,
      ansData: [],
      labels: [],
      answer1: null,
      answer2: null,
      answer3: null,
      showChart: false,
      displayNext: true,
      testcode: localStorage.getItem('sharedTestId')
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    let language;
    if (this.props.lang === "en") language = "english";
    if (this.props.lang === "fr") language = "french";
    const { hr } = this.props;
    let hrId = null;
    let code = null;
    if (hr) {
      code = hr;
      hrId = Hash.decrypt(hr);
    }else{
      code = localStorage.getItem('sharedTestId');
    }

    
    if(code != "null"){
      code = localStorage.getItem('sharedTestId');
    
     if(code!=null){
     hrId =Hash.decrypt(code);
     }
   }
    console.log('Response fetchPublicSector => '+JSON.stringify(this.props)+' hrId '+hrId+' code '+code);    
    const token = localStorage.getItem("token");
    this.props.fetchPublicSector(token, hrId, code, language, resp => {
      if (resp === "success") {
        this.setState({ loading: false });
      }
      if (resp === "no test") {
        this.setState({ loading: false });
        this.props.history.push("/buy-test");
      }
      if (resp === "used") {
        this.setState({
          loading: false,
          error: true,
          errorMsg: "Test already taken"
        });
      }
      if (resp === "error") {
        this.setState({
          loading: false,
          error: true,
          errorMsg: "Error occur, please try again!!"
        });
      }
    });
  }

  renderField(field) {
    //console.log('RenderField'+field.rbvalue)
    return (
      <div className="custom-control custom-radio custom-control-inline d-block w-100 text-center pl-0">
        <input
          type="radio"
          className={field.class}
          id={field.id}
          value={field.rbvalue}
          onClick={() => {
            field.handleChange(field.rbvalue);
          }}
          {...field.input}
        />
        <label className="custom-control-label" htmlFor={field.id}>
          {field.label}
        </label>
     </div>      
      // <div className="form-check">
      //   <label className="per-form-check-label positionRel">
      //     <input
      //       type="radio"
      //       className={field.class}
      //       value={field.rbvalue}
      //       onClick={() => {
      //         field.handleChange(field.rbvalue);
      //       }}
      //       {...field.input}
      //     />
      //     {field.label}
      //   </label>
      // </div>
    );
  }

  selectAnswer(val) {
    var { page } = this.state;
    let questionNo = "answer" + page;
    this.setState({
      [questionNo]: val
    });

    $("#error-message").hide();
  }

  renderQuestions() {
    const { page } = this.state;
    let inc = 1;
    //var displayPosition = { position: relative };
    if (this.props.test) {
      return this.props.test.map((question, index) => {
        return (
          <React.Fragment key={index}>
            {page === inc && (
              <div className="card">
                <div className="card-header">{question.question}</div>
                <ul className="list-group list-group-flush  w-100 float-left">
                  {/* <span>
                    <li className="list-group-item w-50 float-left" key={inc + 1}>
                      <Field
                        name={"answer" + inc}
                        id={"option1" + question.id}                        
                        class={
                          "form-check-input positionRel w-100 text-center option" +
                          index
                        }
                        component={this.renderField}
                        label={question.option1}
                        rbvalue={1}
                        handleChange={e => this.selectAnswer(e)}
                      />
                    </li>
                    <li className="list-group-item  w-50 float-left" key={index}>
                      <Field
                        name={"answer" + question.id}
                        class={
                          "form-check-input positionRel w-100 text-center option" +
                          index
                        }
                        component={this.renderField}
                        label={question.option2}
                        rbvalue={0}
                        handleChange={e => this.selectAnswer(e)}
                      />
                    </li>
                  </span> */}
                <span>
                    <li className="list-group-item w-50 float-left" key={inc + 1}>
                      <Field
                        name={"option" + question.id}
                        id={"id" + question.option1}
                        class={"custom-control-input option" + index}
                        component={this.renderField}
                        label={question.option1}
                        rbvalue={1}
                        handleChange={e => this.selectAnswer(e)}
                      />
                    </li>
                    <li className="list-group-item  w-50 float-left"  key={index}>
                      <Field
                        name={"option" + question.id}
                        id={"id" + question.option2}
                        class={"custom-control-input option" + index}
                        component={this.renderField}
                        label={question.option2}
                        rbvalue={0}
                        handleChange={e => this.selectAnswer(e)}
                      />
                    </li>
                  </span>                  
                </ul>
              </div>
            )}
            <div className="d-none">{(inc = inc + 1)}</div>
          </React.Fragment>
        );
      });
    }
  }
  renderProgress() {
    const { nows, pages } = this.state;

    if (pages > 0 ) {
      return <ProgressBar now={nows} label={`${nows}%`} className="mb-3" />;
    } else {
      return null;
    }
  }

  nextQuestion() {
    const { page,pages } = this.state;

    let quesNo = page - 1;
    if(localStorage.getItem('testType')=='indiviudal'){
      let progres = (100 /21) * pages;
      let progress= progres.toFixed(2);
      // let quesAns = "question" + page + "Answers";
  
      let addedpages=parseInt(pages) + 1;
      this.setState({ pages: addedpages, nows: progress });
      let addpage=parseInt(this.state.pages) + 1;
      localStorage.setItem('page',addpage);
      localStorage.setItem('now',this.state.nows)
    }else{
    let progres = (100 /35) * pages;
    let progress= progres.toFixed(2);
    // let quesAns = "question" + page + "Answers";
    
    let addedpages=parseInt(pages) + 1;
    this.setState({ pages: addedpages, nows: progress });
    let addpage=parseInt(this.state.pages) + 1;
    localStorage.setItem('page',addpage);
    localStorage.setItem('now',this.state.nows)
    }
    if ($(`.option${quesNo}`).is(":checked")) {
      this.setState({
        page: page + 1
      });
      $("#error-message").hide();
      if (page === 2) {
        let language;
        if (this.props.lang === "en") language = "english";
        if (this.props.lang === "fr") language = "french";

        this.setState({
          invalid: false,
          user_id: localStorage.getItem("userId"),
          language: language,
          displayNext: false
        });
      }
    } else {
      $("#error-message").show();
    }
  }

  onSubmit() {
    const { page } = this.state;
    let quesNo = page - 1;
    const token = localStorage.getItem("token");

    if ($(`.option${quesNo}`).is(":checked")) {
      this.setState({ loading: true });
      const submitData = {
        answer1: this.state.answer1,
        answer2: this.state.answer2,
        answer3: this.state.answer3,
        language: this.state.language,
        user_id: this.state.user_id,
        testcode: this.state.testcode
      };
      this.props.savePublicSector(token, submitData, response => {
        if (response === "success") {
          this.setState({ loading: false });
          this.props.stepUp();
        }
      });
    } else {
      $("#error-message").show();
    }
  }

  render() {
    const { success_msg, page, displayNext, loading, error, errorMsg } = this.state;

    if (loading)
      return (
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              <div className="text-center">
                <div
                  className="spinner-border m-5"
                  role="status"
                  style={{ width: "3rem", height: "3rem" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      );

    return (
      <React.Fragment>
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              <h2 className="text-center mb-1">
                <FormattedMessage id="ind.target.workinpublicsector.title" defaultMessage="Work in public sector"/>
              </h2>
              <div className="col-lg-8 col-md-10 col-sm-12 m-auto">
                {error && <div className="alert alert-danger">{errorMsg}</div>}
                {success_msg && (
                  <div id="success-message" className="text-success">
                    <FormattedMessage id="test_saved" defaultMessage="Test saved successfully !"/>
                  </div>
                )}
                <div id="error-message" className="text-danger">
                  Select option
                </div>

                <div>
                  <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    {this.renderProgress()}
                    {this.renderQuestions()}
                    <div className="d-table m-auto">
                      <button
                        type="submit"
                        className="btn btn-info mt-2 mx-auto"
                        style={{
                          display: this.state.invalid ? "none" : "block"
                        }}
                      >
                      <FormattedMessage id="submit" defaultMessage="SUBMIT"/>
                      </button>
                    </div>
                  </form>
                </div>

                {displayNext ? (
                    <div className="d-table mx-auto text-center">
                      <button
                        className="btn btn-info mt-2 mx-auto"
                        onClick={() => this.nextQuestion()}
                      >
                      <FormattedMessage id="next" defaultMessage="NEXT"/>
                      </button>
                    </div>                  
                ) : (
                  ""
                )}
              </div>
              {page > 0 && ( // check page and hide the title from first two steps i.e user explanations
                <Testcopyrights />
              )}              
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    test: state.target.PublicData.data,
    lang: state.locale.lang
  };
};

PublicSector = connect(
  mapStateToProps,
  { fetchPublicSector, savePublicSector }
)(PublicSector);

export default reduxForm({
  form: "PublicSectorTestForm"
})(PublicSector);
