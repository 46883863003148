import React, { Component } from "react";
import MyStoreCheckout from "./striped/MyStoreCheckout";
import $ from "jquery";

class Buysubscription extends Component {
  
  componentDidMount() {
    //window.scrollTo(0, 0); // scroll page to top   modal-backdrop
    $( ".modal-backdrop" ).removeClass('show').addClass( "hide" );
  }

  render() {
    return (
      <React.Fragment>
        <section className="position-relative top-100">
          <div className="row d-flex">
            <div className="col-lg-6 col-md-8 m-auto">
              <MyStoreCheckout />
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default Buysubscription;
