import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { FormattedMessage } from "react-intl";
import { newsletter } from "../actions";
import $ from "jquery";


class Newsletter extends Component {
    constructor(props) {
        super(props);
        this.state = {
        roles: []
        };
        this.NewsletterForm = this.NewsletterForm.bind(this);
    }

    NewsletterForm(values) {
      //console.log('Init submit form');
   
    

      $("#newsletter-loader").show();          
      this.refs.sbmt.setAttribute("disabled", "disabled");          

        //console.log('Inside NewsletterForm '+values.email);
        this.props.newsletter(values,this.refs.sbmt, response => {
          console.log('Response '+response);

          if (response === "success") {
            this.props.reset("NewsletterValidation"); 
            // on successfull signup show login form 
            $("#newsletter-loader").hide();
          }
        });
    }

    renderInput(field) {
      //console.log('renderInput +++=> '+JSON.stringify(field));
      return (
        <div className="form-group">
          <label>{field.label}</label>
          <input
            type={field.type}
            className="form-control"
            placeholder=""
            autoComplete="off"
            {...field.input}
          />
          {field.meta.touched && field.meta.error && (
            <small className="text-danger">{field.meta.error}</small>
          )}
        </div>
      );
    }

    errorMessage() {
      //console.log(this.props.newsletterError+' --  '+this.props.newsletterMessage+' props '+JSON.stringify(this.props));
      if (this.props.newsletterError !== null && this.props.newsletterError !== "") {
        return (
          <div className="alert-danger" role="alert">
            {this.props.newsletterError}
          </div>
        );
      }
      if (
        this.props.newsletterMessage !== null &&
        this.props.newsletterMessage !== ""
      ) {
        return (
          <div className="alert-success" role="alert">
            {this.props.newsletterMessage}
          </div>
        );
      }
  
      // setTimeout(() => {
      //   this.props.clearMessage();
      // }, 8000);
    }    
    
  render() {
    const subscribe  = <FormattedMessage id="subscribe" defaultMessage="Subscribe"/>
    return (
    <React.Fragment>
      <section className="widget_product_search">
        <h3 className="widget-title text-white">
          <FormattedMessage
            id="newsletter"
            defaultMessage="Newsletter"
          />
        </h3>
        <p>
          <FormattedMessage
            id="newsletter_desc"
            defaultMessage="Enter your email address here always to be updated. We promise not to spam!"
          />
        </p>
          <div className="">
              <div className="">
              {this.errorMessage()}
                <form id="newsletter-form" className="signup" onSubmit={this.props.handleSubmit(e => this.NewsletterForm(e))} >
                    <div className="md-form col-md-12 mb-0 ">
                        <Field
                        name="email_address"
                        type="email"
                        className="form-control mailchimp_email"
                        component={this.renderInput}
                        />
                    </div>
                    <div className="text-center">
                     {/* <input type="submit" name="newsletter" id="newsletter" tabIndex="4"  className="search-submit" value="Subscribe"  ref="sbmt"/> */}
                     <button type="submit" name="newsletter" id="newsletter" tabIndex="4"  className="search-submit" ref="sbmt">
                       <FormattedMessage id="subscribe" defaultMessage="Subscribe"/>                       
                     </button>
                    </div>
                    {/* <div className="">
                        <div className="spinner-border text-primary ml-2" role="status" id="newsletter-loader">
                          <span className="sr-only">Loading...</span>
                        </div>
                    </div>                   */}
                </form>
                <div className="status"></div>
              </div>
          </div>
      </section>    
    </React.Fragment>
    );
  }
}

const validate = values => {
    //console.log('Inside validate'+JSON.stringify(values));
    const errors = {};
    if (!values.email_address) {
      errors.email_address = "Email address is required";
    }
    return errors;
  };

const mapStateToProps = state => {
  //console.log('mapStateToProps => '+JSON.stringify(state.auth));  
  return {
    NewsletterValidation: state.form.NewsletterValidation,
    newsletterError: state.auth.newsletterError,
    newsletterMessage: state.auth.newsletterMessage,    
    lang: state.locale.lang
  };
};

Newsletter = connect(
  mapStateToProps,
  { newsletter }
)(Newsletter);


export default reduxForm({
    form: "NewsletterValidation",
    enableReinitialize: true,    
    validate
  })(Newsletter);

//export default Newsletter;

