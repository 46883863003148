import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";

const SupportResources = () => {
  return (
    <React.Fragment>
      <div className="container">
        <div className="container about pages text-justify">
          <div className="row">
            <div className="col-md-12">
            <h4 className="pb-2 text-center"><span><FormattedMessage id="supportresources.test.title" defaultMessage="FULL entrepreneurskillS ASSESSMENT"/> : </span></h4>
            <h5 className="pb-2 text-center"><span><FormattedMessage id="supportresources.test.desc" defaultMessage="5 tests + 1 hour of online debriefing"/> </span></h5>
            </div>
          </div>
          <div className="row">
          <div className="col-md-6 m-auto p-0">
                  <img src="assets/images/supportresources.png" alt="Olivier Colson" className="img-fluid rounded"/>
              </div>
            <div className="col-md-12">
            <span>
              <FormattedHTMLMessage id="supportresources.test.content" defaultMessage=""/>
            </span>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-12 img-content">
              <div className="col-md-12 text-center mb-5">
                <Link className="" to="/buy-test">
                  <FormattedHTMLMessage id="supportresources.test.buy.button" defaultMessage="<button class='buy-full-skill px-5 py-3'><h3 class='mb-4'>Start now</h3><h4>One free assessment </h4></button>"/>
                </Link>
              </div>
            </div>
        </div>
        </div>
      </div>      
    </React.Fragment>
  );
};

export default SupportResources;
