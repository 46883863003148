import React, { Component } from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { history } from "../helper/History";
import Carousel from "react-bootstrap/Carousel";
import ScrollIntoView from "react-scroll-into-view";

class Enterprisesub extends Component {
  buyNowTest() {
    $(".pilotModal").modal("hide");
    localStorage.setItem("testType", "professional"); // set testtyle to localstorage
    history.push("/buy-test");
  }
  openHappiness() {
    var userType = localStorage.getItem("role");
    if (userType === "Hr") {
      $(".pilotModal").modal("hide");
      localStorage.setItem("testType", "professional"); // set testtype to localstorage
      history.push("/happiness");
    } else {
      const title =
        this.props.lang === "en"
          ? "ACCESS ONLY FOR PROFESSIONAL"
          : "ACCES POUR PROFESSIONNEL UNIQUEMENT";
      const errorMsg =
        this.props.lang === "en"
          ? "Sorry this part of the presentation is accessible only by professionnals"
          : "Désolé cette partie de la plateforme est réservée aux professionnels. Si vous êtes un professionnel, enregistrez-vous.";
      $("#happiness").modal("hide");
      $("#errorModal .modal-title").text(title);
      $(".modal-body #errorTxt").text(errorMsg);
      $("#errorModal").modal("show");
    }
  }

  openSale() {
    var userType = localStorage.getItem("role");
    if (userType === "Hr") {
      $(".pilotModal").modal("hide");
      localStorage.setItem("testType", "professional"); // set testtype to localstorage
      history.push("/sale");
    } else {
      const title =
        this.props.lang === "en"
          ? "ACCESS ONLY FOR PROFESSIONAL"
          : "ACCES POUR PROFESSIONNEL UNIQUEMENT";
      const errorMsg =
        this.props.lang === "en"
          ? "Sorry this part of the presentation is accessible only by professionnals"
          : "Désolé cette partie de la plateforme est réservée aux professionnels. Si vous êtes un professionnel, enregistrez-vous.";
      $("#sale").modal("hide");
      $("#errorModal .modal-title").text(title);
      $(".modal-body #errorTxt").text(errorMsg);
      $("#errorModal").modal("show");
    }
  }

  openBusinessCreation() {
    var userType = localStorage.getItem("role");
    if (userType === "Hr") {
      $(".pilotModal").modal("hide");
      localStorage.setItem("testType", "professional"); // set testtype to localstorage
      history.push("/business-creation");
    } else {
      const title =
        this.props.lang === "en"
          ? "ACCESS ONLY FOR PROFESSIONAL"
          : "ACCES POUR PROFESSIONNEL UNIQUEMENT";
      const errorMsg =
        this.props.lang === "en"
          ? "Sorry this part of the presentation is accessible only by professionnals"
          : "Désolé cette partie de la plateforme est réservée aux professionnels. Si vous êtes un professionnel, enregistrez-vous.";
      $("#target").modal("hide");
      $("#errorModal .modal-title").text(title);
      $(".modal-body #errorTxt").text(errorMsg);
      $("#errorModal").modal("show");
    }
  }

  openRecruitment() {
    var userType = localStorage.getItem("role");
    if (userType === "Hr") {
      $(".pilotModal").modal("hide");
      localStorage.setItem("testType", "professional"); // set testtype to localstorage
      history.push("/recruitment");
    } else {
      const title =
        this.props.lang == "en"
          ? "ACCESS ONLY FOR PROFESSIONAL"
          : "ACCES POUR PROFESSIONNEL UNIQUEMENT";
      const errorMsg =
        this.props.lang == "en"
          ? "Sorry this part of the presentation is accessible only by professionnals"
          : "Désolé cette partie de la plateforme est réservée aux professionnels. Si vous êtes un professionnel, enregistrez-vous.";
      $("#RECRUITMENT").modal("hide");
      $("#errorModal .modal-title").text(title);
      $(".modal-body #errorTxt").text(errorMsg);
      $("#errorModal").modal("show");
    }
  }

  setEnterprise(){
    localStorage.setItem("redirectpage","Enterprise")
  }

  openCoachingCareer() {
    var userType = localStorage.getItem("role");
    if (userType === "Hr") {
      $(".pilotModal").modal("hide");
      localStorage.setItem("testType", "professional"); // set testtype to localstorage
      history.push("/coaching-career");
    } else {
      const title =
        this.props.lang === "en"
          ? "ACCESS ONLY FOR PROFESSIONAL"
          : "ACCES POUR PROFESSIONNEL UNIQUEMENT";
      const errorMsg =
        this.props.lang === "en"
          ? "Sorry this part of the presentation is accessible only by professionnals"
          : "Désolé cette partie de la plateforme est réservée aux professionnels. Si vous êtes un professionnel, enregistrez-vous.";
      $("#talent").modal("hide");
      $("#errorModal .modal-title").text(title);
      $(".modal-body #errorTxt").text(errorMsg);
      $("#errorModal").modal("show");
    }
  }

  // open exmplease function
  openHappinessExamples() {
    let lang = this.props.lang;
    var userType = localStorage.getItem("role");
    $(".pilotModal").modal("hide");
    //console.log('loggedIn '+this.props.isSignedIn);
    if (this.props.isSignedIn === null || !this.props.isSignedIn) {
      // $("#loginModal").modal("show");
      history.push("/login");
    } else if (this.props.isSignedIn) {
      if (userType === "Hr") {
        const startButtonText =
          this.props.lang === "en"
            ? "Start Happiness Tool"
            : "Démarrer Happiness Tool";
        $("#testResultData").attr(
          "src",
          "assets/images/professionalHappinessResult_" + lang + ".png"
        );
        //$("#exampleResultTitle").text("Happiness Tools Results"); // added title
        // Add start button below examples
        const happinessButton = (
          <div>
            <div>
              <button
                onClick={this.openHappiness.bind(this)}
                className="btn btn-warning center-block"
              >
                {startButtonText}
                <br />
              </button>
            </div>
            <div>
              <button
                type="button"
                className="close btn  center-block"
                style={{}}
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        );

        ReactDOM.render(
          happinessButton,
          document.getElementById("startButton")
        );
        $("#myModal").modal("show");
      } else {
        const title =
          this.props.lang === "en"
            ? "ACCESS ONLY FOR PROFESSIONAL"
            : "ACCES POUR PROFESSIONNEL UNIQUEMENT";
        const errorMsg =
          this.props.lang === "en"
            ? "Sorry this part of the presentation is accessible only by professionnals"
            : "Désolé cette partie de la plateforme est réservée aux professionnels. Si vous êtes un professionnel, enregistrez-vous.";
        $("#happiness").modal("hide");
        $("#errorModal .modal-title").text(title);
        $(".modal-body #errorTxt").text(errorMsg);
        $("#errorModal").modal("show");
      }
    }
  }

  openSaleExamples() {
    let lang = this.props.lang;
    var userType = localStorage.getItem("role");
    $(".pilotModal").modal("hide");
    if (this.props.isSignedIn === null || !this.props.isSignedIn) {
      // $("#loginModal").modal("show");
      history.push("/login");
    } else if (this.props.isSignedIn) {
      if (userType === "Hr") {
        const startButtonText =
          this.props.lang === "en" ? "Start Sales Tool" : "Démarrer Sales Tool";
        $("#testResultData").attr(
          "src",
          "assets/images/professionalSaleResult_" + lang + ".png"
        );
        //$("#exampleResultTitle").text("Sale Tools Results"); // added title
        // Add start button below examples
        const SaleButton = (
          <div>
            <div>
              <button
                onClick={this.openSale.bind(this)}
                className="btn btn-warning center-block"
              >
                {startButtonText}
                <br />
              </button>
            </div>
            <div>
              <button
                type="button"
                className="close btn  center-block"
                style={{}}
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        );
        ReactDOM.render(SaleButton, document.getElementById("startButton"));
        $("#myModal").modal("show");
      } else {
        const title =
          this.props.lang === "en"
            ? "ACCESS ONLY FOR PROFESSIONAL"
            : "ACCES POUR PROFESSIONNEL UNIQUEMENT";
        const errorMsg =
          this.props.lang === "en"
            ? "Sorry this part of the presentation is accessible only by professionnals"
            : "Désolé cette partie de la plateforme est réservée aux professionnels. Si vous êtes un professionnel, enregistrez-vous.";
        $("#sale").modal("hide");
        $("#errorModal .modal-title").text(title);
        $(".modal-body #errorTxt").text(errorMsg);
        $("#errorModal").modal("show");
      }
    }
  }

  openBusinessCreationExamples() {
    let lang = this.props.lang;
    var userType = localStorage.getItem("role");
    $(".pilotModal").modal("hide");
    if (this.props.isSignedIn === null || !this.props.isSignedIn) {
      // $("#loginModal").modal("show");
      history.push("/login");
    } else if (this.props.isSignedIn) {
      if (userType === "Hr") {
        const startButtonText =
          this.props.lang === "en"
            ? "Start Business creation tool"
            : "Démarrer Business creation tool";
        $("#testResultData").attr(
          "src",
          "assets/images/professionailBussinessCreation_" + lang + ".png"
        );
        //$("#exampleResultTitle").text("Business Creation Tools Results"); // added title
        // Add start button below examples
        const businessCreationButton = (
          <div>
            <div>
              <button
                onClick={this.openBusinessCreation.bind(this)}
                className="btn btn-warning center-block"
              >
                {startButtonText}
                <br />
              </button>
            </div>
            <div>
              <button
                type="button"
                className="close btn  center-block"
                style={{}}
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        );
        ReactDOM.render(
          businessCreationButton,
          document.getElementById("startButton")
        );
        $("#myModal").modal("show");
      } else {
        const title =
          this.props.lang === "en"
            ? "ACCESS ONLY FOR PROFESSIONAL"
            : "ACCES POUR PROFESSIONNEL UNIQUEMENT";
        const errorMsg =
          this.props.lang === "en"
            ? "Sorry this part of the presentation is accessible only by professionnals"
            : "Désolé cette partie de la plateforme est réservée aux professionnels. Si vous êtes un professionnel, enregistrez-vous.";
        $("#target").modal("hide");
        $("#errorModal .modal-title").text(title);
        $(".modal-body #errorTxt").text(errorMsg);
        $("#errorModal").modal("show");
      }
    }
  }

  openRecruitmentExamples() {
    let lang = this.props.lang;
    var userType = localStorage.getItem("role");
    $(".pilotModal").modal("hide");
    if (this.props.isSignedIn === null || !this.props.isSignedIn) {
      // $("#loginModal").modal("show");
      history.push("/login");
    } else if (this.props.isSignedIn) {
      if (userType === "Hr") {
        const startButtonText =
          this.props.lang === "en"
            ? "Start Recruitment tool"
            : "Démarrer Recruitment tool";
        $("#testResultData").attr(
          "src",
          "assets/images/recruitment_" + lang + ".png"
        );
        //$("#exampleResultTitle").text("Recruitment Tool Results"); // added title
        // Add start button below examples
        const recruitmentButton = (
          <div>
            <div>
              <button
                onClick={this.openRecruitment.bind(this)}
                className="btn btn-warning center-block"
              >
                {startButtonText}
                <br />
              </button>
            </div>
            <div>
              <button
                type="button"
                className="close btn  center-block"
                style={{}}
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        );
        ReactDOM.render(
          recruitmentButton,
          document.getElementById("startButton")
        );
        $("#myModal").modal("show");
      } else {
        const title =
          this.props.lang === "en"
            ? "ACCESS ONLY FOR PROFESSIONAL"
            : "ACCES POUR PROFESSIONNEL UNIQUEMENT";
        const errorMsg =
          this.props.lang === "en"
            ? "Sorry this part of the presentation is accessible only by professionnals"
            : "Désolé cette partie de la plateforme est réservée aux professionnels. Si vous êtes un professionnel, enregistrez-vous.";
        $("#RECRUITMENT").modal("hide");
        $("#errorModal .modal-title").text(title);
        $(".modal-body #errorTxt").text(errorMsg);
        $("#errorModal").modal("show");
      }
    }
  }

  openCoachingCareerExamples() {
    let lang = this.props.lang;
    var userType = localStorage.getItem("role");
    $(".pilotModal").modal("hide");
    if (this.props.isSignedIn === null || !this.props.isSignedIn) {
      // $("#loginModal").modal("show");
      history.push("/login");
    } else if (this.props.isSignedIn) {
      if (userType === "Hr") {
        const startButtonText =
          this.props.lang === "en"
            ? "Start Coaching tool"
            : "Démarrer Coaching tool";
        $("#testResultData").attr(
          "src",
          "assets/images/professionalCoachingCareerResult_" + lang + ".png"
        );
        //$("#exampleResultTitle").text("Coaching Tool Results"); // added title
        // Add start button below examples
        const coachingCareerButton = (
          <div>
            <div>
              <button
                onClick={this.openCoachingCareer.bind(this)}
                className="btn btn-warning center-block"
              >
                {startButtonText}
                <br />
              </button>
            </div>
            <div>
              <button
                type="button"
                className="close btn  center-block"
                style={{}}
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        );
        ReactDOM.render(
          coachingCareerButton,
          document.getElementById("startButton")
        );
        $("#myModal").modal("show");
      } else {
        const title =
          this.props.lang === "en"
            ? "ACCESS ONLY FOR PROFESSIONAL"
            : "ACCES POUR PROFESSIONNEL UNIQUEMENT";
        const errorMsg =
          this.props.lang === "en"
            ? "Sorry this part of the presentation is accessible only by professionnals"
            : "Désolé cette partie de la plateforme est réservée aux professionnels. Si vous êtes un professionnel, enregistrez-vous.";
        $("#talent").modal("hide");
        $("#errorModal .modal-title").text(title);
        $(".modal-body #errorTxt").text(errorMsg);
        $("#errorModal").modal("show");
      }
    }
  }

  showalert2() {
  
    localStorage.setItem("alert2", "3");
  }
  handleClick() {
    const price = "unique";
    const element = document.getElementById(price);
    console.log(element);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section

      element.scrollIntoView({ behavior: "smooth" });
    }
  }
  handleButton() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  render() {
    let CopyrightDate = new Date();
    return (
      <React.Fragment>
 

        <div className="col-md-12 bottombox" id="unique">
          <section className="icon-boxed1 teaser-box mt-10" id="unique1">
            <div className="container">
              <div className="row">
                <p className="text-center w-100 ">
                  <FormattedHTMLMessage
                    id="talent.compy.subs.head1"
                    defaultMessage="All subscriptions provide access to all tools and can be recharged with credits at any time."
                  />
                </p>

                <div className="col-lg-4 col-md-4">
                  <div className="icon-box text-center hero-bg box-shadow">
                    <div class="top-content">
                      <p>
                        {/* <Link to="/" className=""> */}
                        <FormattedHTMLMessage
                          id="talent.compy.subs.desc1"
                          defaultMessage="starter"
                        />
                        {/* </Link> */}
                      </p>

                      <p>
                        <FormattedHTMLMessage
                          id="talent.compy.subs.desc2"
                          defaultMessage="12 months"
                        />
                      </p>

                      <div className="HalfBordr mt-4">
                        <FormattedHTMLMessage
                          id="talent.compy.sub.desc3"
                          defaultMessage="12 months"
                        />

                        <p className="mt-2">
                          <FormattedHTMLMessage
                            id="talent.compy.subs.desc4"
                            defaultMessage="12 months"
                          />
                        </p>
                      </div>
                      <p className="mt-2">
                        <FormattedHTMLMessage
                          id="talent.compy.sub.desc5"
                          defaultMessage="12 months"
                        />
                      </p>
                      {/* <p>
                    <FormattedHTMLMessage
                      id="talent.compy.sub.desc8"
                      defaultMessage="12 months"
                    />
                  </p>  */}
                    </div>
                    <div class="bottom-content width-add">
                      <p>
                        <Link
                          to="/register"
                          className="nav-link"
                          data-target=".navbar-collapse.show"
                        >
                          <button
                            class="btn btn-primary"
                            onClick={this.showalert2}
                            style={{
                              background: "#18B6CE",
                              border: "none",
                              marginTop: "16%",
                            }}
                          >
                            <FormattedHTMLMessage
                              id="taleny.compy.buy.1"
                              defaultMessage="Subscribe"
                            />
                          </button>
                        </Link>
                      </p>
                    </div>
                    {/* <div style={{marginTop:'50px'}}><Link to="/buy-test" className="nav-link"  data-target=".navbar-collapse.show"><button class="btn btn-primary" style={{background:"#18B6CE",border:"none",marginTop:"50px"}}><FormattedHTMLMessage id="coach.offer.subs.btn1" defaultMessage="Subscribe"/></button></Link></div> */}
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="icon-box text-center hero-bg box-shadow">
                    <div class="top-content width-add">
                      <p>
                        <FormattedHTMLMessage
                          id="talent.compy.subs.head2"
                          defaultMessage="Classic"
                        />
                      </p>

                      <p>
                        <FormattedHTMLMessage
                          id="talent.compy.subs.desc3"
                          defaultMessage="€49.90"
                        />
                      </p>
                      <div className="HalfBordr mt-4">
                        <FormattedHTMLMessage
                          id="talent.compy.sub.desc7"
                          defaultMessage="per month - annual payment"
                        />

                        <p className="mt-5">
                          <FormattedHTMLMessage
                            id="talent.compy.sub.price"
                            defaultMessage="per month - annual payment"
                          />
                        </p>
                      </div>
                      <div className="HalfBordr mt-4">
                        <FormattedHTMLMessage
                          id="talent.compy.sub.desc9"
                          defaultMessage="per month - annual payment"
                        />

                        <p className="mt-5">
                          <FormattedHTMLMessage
                            id="talent.compy.sub.desc6"
                            defaultMessage="per month - annual payment"
                          />
                        </p>
                      </div>
                    </div>
                    <div className="bottom-content width-add">
                      <p>
                         <Link
                          to="/buy-subscription"
                          className="nav-link"
                          data-target=".navbar-collapse.show"
                        >
                          <button
                            className="btn btn-primary"
                            style={{
                              background: "#18B6CE",
                              border: "none",
                              marginTop: "6%",
                            }}
                            onClick={this.setEnterprise}
                          >
                            <FormattedHTMLMessage
                              id="taleny.compy.buy.2"
                              defaultMessage="Subscribe"
                            />
                          </button>
                        </Link> 
                    
                      </p>
                    </div>
                    {/* 
                        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
  <input type="hidden" name="cmd" value="_s-xclick" />
  <input type="hidden" name="hosted_button_id" value="Y84JGLTR4TJRU" />
  <input type="hidden" name="currency_code" value="USD" />
  <input type="button" src="https://www.paypalobjects.com/en_US/i/btn/btn_subscribe_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Subscribe" />
</form>
                    
                    
                    <div style={{marginTop:'50px'}}><Link to="/buy-test" className="nav-link"  data-target=".navbar-collapse.show"><button class="btn btn-primary" style={{background:"#18B6CE",border:"none"}}><FormattedHTMLMessage id="coach.offer.subs.btn2" defaultMessage="Subscribe"/></button></Link></div> */}
                  </div>
                </div>

                <div className="col-lg-4 col-md-4">
                  <div className="icon-box text-center hero-bg box-shadow ">
                    <div class="top-content width-add">
                      <h3>
                        <FormattedHTMLMessage
                          id="talent.compy.fullass"
                          defaultMessage="Classic"
                        />
                      </h3>

                      <p>
                        <FormattedHTMLMessage
                          id="talent.compy.fullass.sub"
                          defaultMessage="€49.90"
                        />
                      </p>
                      <div className="HalfBordr mt-4">
                        <FormattedHTMLMessage
                          id="talent.compy.fullass.sub2"
                          defaultMessage="per month - annual payment"
                        />

                        <p className="mt-2">
                          <FormattedHTMLMessage
                            id="talent.compy.fullass.sub3"
                            defaultMessage="per month - annual payment"
                          />
                        </p>
                      </div>
                      <div className="HalfBordr mt-4">
                        <FormattedHTMLMessage
                          id="talent.compy.fullass.sub4"
                          defaultMessage="per month - annual payment"
                        />

                        <p className="mt-2">
                          <FormattedHTMLMessage
                            id="talent.compy.fullass.sub5"
                            defaultMessage="per month - annual payment"
                          />
                        </p>
                      </div>
                    </div>
                    <div class="bottom-content width-add">
                      <p>
                        <Link
                          to="/contact-us"
                          className="nav-link"
                          data-target=".navbar-collapse.show"
                        >
                          <button
                            class="btn btn-primary"
                            style={{
                              background: "#18B6CE",
                              border: "none",
                              marginTop: "6%",
                            }}
                          >
                            <FormattedHTMLMessage
                              id="taleny.compy.buy.3"
                              defaultMessage="Subscribe"
                            />
                          </button>
                        </Link>
                      </p>
                    </div>

                    {/* <div style={{marginTop:'50px'}}><Link to="/buy-test" className="nav-link"  data-target=".navbar-collapse.show"><button class="btn btn-primary" style={{background:"#18B6CE",border:"none"}}><FormattedHTMLMessage id="coach.offer.subs.btn2" defaultMessage="Subscribe"/></button></Link></div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="modal fade pilotModal" id="happiness">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header bg-secondary">
                <h4 className="modal-title text-center text-uppercase w-100 medium text-white font-20">
                  HAPPINESS Tool
                </h4>
                <button type="button" className="close" data-dismiss="modal">
                  ×
                </button>
              </div>
              <div className="modal-body">
                <div className=" mt-5">
                  <div className="about" id="services">
                    <div className=" text-justify">
                      <div
                        className=" animate animated slideInLeft"
                        data-animation="slideInLeft"
                      >
                        <h2 className="about-title text-left">
                          <FormattedHTMLMessage
                            id="hr.happiness.popup.title"
                            defaultMessage="HAPPINESS AT WORK TOOL"
                          />
                        </h2>
                      </div>
                      <div
                        className="add-width intro_layer mt-5 d-flex"
                        data-animation="fadeIn"
                      >
                        <div className="d-flex m-auto w-100">
                          <div className="d-inline-block w-50">
                            <button
                              onClick={this.openHappinessExamples.bind(this)}
                              className="btn btn-warning center-block example_button_radius"
                              style={{
                                backgroundColor: "#191970",
                                color: "white",
                              }}
                            >
                              <FormattedHTMLMessage
                                id="hr.happiness.btn.examples"
                                defaultMessage="SEE EXAMPLE OF RESULTS"
                              />
                            </button>
                          </div>
                          <div className="d-inline-block w-50">
                            <p>
                              <button
                                type="button"
                                className="btn  center-block example_button_radius"
                                onClick={this.openHappiness.bind(this)}
                                style={{
                                  backgroundColor: "#1ca9c9",
                                  color: "white",
                                }}
                              >
                                <FormattedHTMLMessage
                                  id="hr.happiness.btn.start"
                                  defaultMessage="START HAPPINESS TOOL"
                                />
                                <br />
                              </button>
                            </p>
                          </div>
                          <div className="d-inline-block w-50">
                            <p>
                              <button
                                class="btn btn-warning"
                                style={{
                                  backgroundColor: "#191970",
                                  color: "white",
                                }}
                                onClick={this.buyNowTest.bind(this)}
                              >
                                <FormattedMessage
                                  id="buynow"
                                  defaultMessage="Buy Now"
                                />
                              </button>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="indi-list">
                        <FormattedHTMLMessage
                          id="hr.happiness.p.duration"
                          defaultMessage="<h3>Duration</h3><ul class='indi-ul'> <li>40 questions – 7 minutes.</li></ul>"
                        />
                        {/* <h3>Duration </h3>
                      <ul className="indi-ul">
                        <li>40 questions – 7 minutes. </li>
                      </ul> */}
                      </div>
                      <div className="indi-list">
                        <FormattedHTMLMessage
                          id="hr.happiness.p.resultlist"
                          defaultMessage="<h3 class=''>Results </h3> <ul class='indi-ul'> <li> Global satisfaction of candidate compare to the average. </li><li> Detail of satisfaction on eight key factors of balance and development. </li><li>How candidate still improved happiness at work.</li></ul>"
                        />
                        {/* <h3 className="">Results </h3>
                      <ul className="indi-ul">
                        <li>
                          Global satisfaction of candidate compare to the
                          average.
                        </li>
                        <li>
                          Detail of satisfaction on eight key factors of balance
                          and development.
                        </li>
                        <li>How candidate still improved happiness at work.</li>
                      </ul> */}
                      </div>
                      <div className="indi-list">
                        <FormattedHTMLMessage
                          id="hr.happiness.p.help"
                          defaultMessage="<h3 class=''>Help </h3> <ul class='indi-ul'> <li> Identify reasons of a possible malaise or weariness{' '}</li><li>Identify a specific coaching program.</li><li>Decide an internal or external mobility</li></ul>"
                        />
                        {/* <h3 className="">Help </h3>
                      <ul className="indi-ul">
                        <li>
                          Identify reasons of a possible malaise or weariness{" "}
                        </li>
                        <li>Identify a specific coaching program.</li>
                        <li>Decide an internal or external mobility</li>
                      </ul> */}
                      </div>
                      <div className="indi-list">
                        <FormattedHTMLMessage
                          id="hr.happiness.p.research"
                          defaultMessage="<h3 class=''>Scientific-Researches </h3> <p class='modal-text'> MEASURE OF HAPPINESS AT WORK:</p><p class='pl-3'> Business life brings people together to ensure the best possible performance. Some of conditions must be met for everyone to flourish. Factors of balance and development at work : freedom of exchange, possibility of expressing one's wishes, personal action, career follow-up, possibility of working at one's own race, accomplishment of a work of freedom, identity, meaningless (L‘Identité au travail’ – R. Sainsaulieu – 1988 – Presses de la Fondation Nationale des Sciences Politiques).</p>"
                        />
                        {/* <h3 className="">Scientific-Researches </h3>
                      <p className="modal-text">
                        MEASURE OF HAPPINESS AT WORK:{" "}
                      </p>
                      <p className="pl-3">
                        Business life brings people together to ensure the best
                        possible performance. Some of conditions must be met for
                        everyone to flourish. Factors of balance and development
                        at work : freedom of exchange, possibility of expressing
                        one's wishes, personal action, career follow-up,
                        possibility of working at one's own race, accomplishment
                        of a work of freedom, identity, meaningless (L‘Identité
                        au travail’ – R. Sainsaulieu – 1988 – Presses de la
                        Fondation Nationale des Sciences Politiques).{" "}
                      </p> */}
                      </div>
                      <div className="indi-list">
                        <FormattedHTMLMessage
                          id="hr.happiness.p.start.test"
                          defaultMessage="<h3 class=''>Start Your Test </h3> <div class='about-text'> <p> Before using ‘HAPPINESS Tool’ you can see an example of results exactly as you will send to your candidate. Results of test still totally confidential and we don’t use any named data coming from your candidate for other destination of the test (French Data Authority n°661001) </p></div>"
                        />
                        {/* <h3 className="">Start Your Test </h3>
                        <div className="about-text">
                        <p>
                          Before using ‘HAPPINESS Tool’ you can see an example
                          of results exactly as you will send to your candidate.
                          Results of test still totally confidential and we
                          don’t use any named data coming from your candidate
                          for other destination of the test (French Data
                          Authority n°661001)
                        </p>
                      </div>*/}
                        <div
                          className="add-width intro_layer mt-5 d-flex"
                          data-animation="fadeIn"
                        >
                          <div className="d-flex m-auto w-100">
                            <div className="d-inline-block w-50">
                              <button
                                onClick={this.openHappinessExamples.bind(this)}
                                className="btn btn-warning center-block example_button_radius"
                                style={{
                                  backgroundColor: "#191970",
                                  color: "white",
                                }}
                              >
                                <FormattedHTMLMessage
                                  id="hr.happiness.btn.examples"
                                  defaultMessage="SEE EXAMPLE OF RESULTS"
                                />
                              </button>
                            </div>
                            <div className="d-inline-block w-50">
                              <p>
                                <button
                                  type="button"
                                  className="btn  center-block example_button_radius"
                                  onClick={this.openHappiness.bind(this)}
                                  style={{
                                    backgroundColor: "#1ca9c9",
                                    color: "white",
                                  }}
                                >
                                  <FormattedHTMLMessage
                                    id="hr.happiness.btn.start"
                                    defaultMessage="START HAPPINESS TOOL"
                                  />
                                  <br />
                                </button>
                              </p>
                            </div>
                            <div className="d-inline-block w-50">
                              <p>
                                <button
                                  class="btn btn-warning"
                                  style={{
                                    backgroundColor: "#191970",
                                    color: "white",
                                  }}
                                  onClick={this.buyNowTest.bind(this)}
                                >
                                  <FormattedMessage
                                    id="buynow"
                                    defaultMessage="Buy Now"
                                  />
                                </button>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="indi-list">
                        <FormattedHTMLMessage
                          id="ind.happiness.price"
                          defaultMessage="<h3 class=''>Price </h3>"
                        />
                        {/* <ul className="indi-ul">
                        <li>
                          <FormattedHTMLMessage id="hr.happiness.price" defaultMessage="€ 15 1 credit"/>
                        </li>
                      </ul> */}
                        <div className="row">
                          <div className="col-md-12 col-sm-12 m-auto w100_991">
                            <div
                              className="card text-center mt-4"
                              style={{ border: "none" }}
                            >
                              {/* <div className="card-body"> */}
                              {/* <h4 className="card-title">
                                <del><FormattedHTMLMessage id="hr.happiness.single.price" defaultMessage="€ 15"/></del>
                              </h4> */}
                              {/* <p className="card-text"><i>HAPPINESS TOOL©</i></p> */}
                              {/* <p className="card-text"><FormattedHTMLMessage id="hr.happiness.price.credits" defaultMessage="(or 2 credits)"/></p> */}
                              {/* <button className="btn btn-warning" style={{backgroundColor:"#808080 "}}>
                                  <Link target="_blank" style={{color:"white"}}to="/contact-professional">
                                    <FormattedMessage id="ask.free.trial" defaultMessage="ASK FOR FREE TRIAL CREDIT"/>
                                  </Link>
                              </button> */}
                              {/* <button className="btn btn-warning" style={{backgroundColor:"#808080"}}>
                                  <Link target="_blank" style={{color:"white"}} to="/contact-professional">
                                    <FormattedMessage id="ask.free.tria2" defaultMessage="ASK FOR FREE CREDIT"/>
                                  </Link>
                                </button> */}
                              {/* <span><b style={{color:"#000", fontSize:"20px"}}><FormattedMessage id="ask.free.tria2" defaultMessage="ASK FOR FREE CREDIT"/></b></span>  */}

                              {/* </div> */}
                            </div>
                          </div>
                        </div>

                        <center>
                          <FormattedHTMLMessage
                            id="hr.license"
                            defaultMessage=""
                          />
                        </center>
                      </div>
                      <div>
                        <FormattedHTMLMessage
                          id="hr.pice.detail1"
                          defaultMessage="<ul class='indi-ul'> <li>Buy 1 test credit = <b>€ 20</b>/test</li><li>Buy 2 test credits = <b>€ 13,5</b>/test</li><li>Buy 3 test credits = <b>€ 12</b>/test</li><li>Buy 4 test credits and more = <b>€ 10</b>/test</li></ul>"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

   

        

      

      
     
        {/* <div className="modal fade pilotModal"  id="hrContactUs"  data-backdrop="static">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header bg-secondary">
                <h4 className="modal-title text-center text-uppercase w-100 medium text-white font-20">
                  Leave Your Email
                </h4>
                <button type="button" className="close" data-dismiss="modal">
                  ×
                </button>
              </div>

              <div className="modal-body">
                <div className="col-md-12">
                  <div className="about" id="services">
                    <div className="container">
                      <div
                        className="col-sm-12 col-md-12 animate animated slideInLeft"
                        data-animation="slideInLeft"
                      >
                        <form>
                          <div className="form-group row">
                              <input type="text" readOnly="readonly" placeholder="yourEmail@gmail.com" className="form-control" id="staticEmail" required="required"/>
                          </div>
                          <div className="form-group row">
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Your Message" required="required"></textarea>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div className="modal-footer">
             <button type="button" className="btn btn-default btn-primary" data-dismiss="modal">Cancle</button>
             <button type="button" className="btn btn-default btn-primary" data-dismiss="modal">Submit</button>
            </div>
            </div>
          </div>
        </div>          */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    lang: state.locale.lang,
  };
};

Enterprisesub = connect(mapStateToProps, {})(Enterprisesub);

export default Enterprisesub;
