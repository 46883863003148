import React, { Component } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import Sortable from "react-sortablejs";
import { FormattedMessage } from "react-intl";
import ProgressBar from "react-bootstrap/ProgressBar";
import Hash from "../../../helper/Hashing";
import CustomerRights from "../../CustomerRights"; // used to show the two steps of customer right before start test 
import Testcopyrights from "../../Testcopyrights"; // used to show test copyright text under test 

import {
  fetchProfessionalTest,
  saveProfessionalTest
} from "../../../actions/Target";

class Professional extends Component {
  constructor(props) {
    super(props);
    let testId = localStorage.getItem('sharedTestId'); // get testid from    
    if(testId != null){
      this.state = {
        page:1,
        now: 0,
        pages:localStorage.getItem('page'),
        nows:localStorage.getItem('now'),
        loading: true,
        error: false,
        errorMsg: "",
        test_id: null,
        user_id: null,
        test_version_id: null,
        invalid: true,
        success_msg: false,
        hideForm: true,
        language: null,
        question: [],
        questionAnswers: [],
        maxElements: null,
        disable: false,
        testcode: localStorage.getItem('sharedTestId')
      };
    }else{
      this.state = {
        page: -1,
        now: 0,
        pages:localStorage.getItem('page'),
        nows:localStorage.getItem('now'),
        loading: true,
        error: false,
        errorMsg: "",
        test_id: null,
        user_id: null,
        test_version_id: null,
        invalid: true,
        success_msg: false,
        hideForm: true,
        language: null,
        question: [],
        questionAnswers: [],
        maxElements: null,
        disable: false,
        testcode: null
      };
    }
    //this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    let language;
    if (this.props.lang === "en") language = "english";
    if (this.props.lang === "fr") language = "french";
    const { hr } = this.props;
    let hrId = null;
    let code = null;
    if (hr) {
      code = hr;
      hrId = Hash.decrypt(hr);
    }else{
      code = localStorage.getItem('sharedTestId');
    }

    
    if(code != "null"){
      code = localStorage.getItem('sharedTestId');
    
     if(code!=null){
     hrId =Hash.decrypt(code);
     }
   }
    console.log('Response fetchProfessionalTest => '+JSON.stringify(this.props)+' hrId '+hrId+' code '+code);
    //console.log('Response => '+JSON.stringify(this.props));
    let testType = localStorage.getItem('testType');
    //console.log('Test Type from local storage => '+testType);    
    // set the test type to state  used to check the type of test individutal or hr
    this.setState({ testType: testType });
    // check condition if testType is professional set page to 0 
    //console.log('Test Type from state => '+testType);
    if(testType == 'professional'){
      this.setState({ page: 1 }); // update page counter to 1 if test type if professional to skip 2 step user guidlines
    }

    const token = localStorage.getItem("token");
    let maxElements = $(`.right`).length;
    this.props.fetchProfessionalTest(token, hrId, code, language, resp => {
      //console.log('Response '+resp);
      if (resp === "success") {
        this.setState({
          loading: false,
          question: this.props.test.options,
          max: maxElements
        });
      }else if (resp === "used") {
        this.setState({
          loading: false,
          error: true,
          errorMsg: "Test already taken"
        });
      }else if (resp === "no test") {
        this.setState({
          loading: false,
          error: true,
          errorMsg: "Test already taken"
        });
        this.props.history.push("/buy-test");
      }else if (resp === "error") {
        this.setState({
          loading: false,
          error: true,
          errorMsg: "Error occur, please try again!!"
        });
      }
    });

    // const token = localStorage.getItem("token");
    // let { maxElements } = $(`.right`).length;

    // this.props.fetchProfessionalTest(token, resp => {
    //   this.setState({
    //     question: resp.data.options,
    //     max: maxElements,
    //     loading: false
    //   });
    // });
    // if(localStorage.getItem('testType')=='individual'){
    //   localStorage.setItem('page',-1)
    //   localStorage.setItem('now',-1)
    //   }          
  }

  selectAnswer(val) {
    var { page } = this.state;
    let questionNo = "answer" + page;
    this.setState({
      [questionNo]: val
    });

    $("#error-message").hide();
  }

  renderQuestions() {
    const { page } = this.state;
    // show two stepe candidate rights option before start test     
    if(page >0){
      let inc = 1;
      //console.log('renderQuestions '+page+' inc '+inc);
      if (this.props.test) {
        return (
          <React.Fragment>
            {page === inc && (
              <div className="card">
                <div className="card-header">{this.props.test.question}</div>
                {this.renderSortedData()}
              </div>
            )}
            <div className="d-none">{(inc = inc + 1)}</div>
          </React.Fragment>
        );
      }
    }else {
      return (
        <CustomerRights page={page} />
      );            
      //console.log('Inside 2 steps '+page);
    }
  }

  renderSortedData() {
    // for left side question answer
    const question = this.state.question.map((val, key) => (
      <div className="col-md-4 card" key={key} data-id={val}>
        {val}
      </div>
    ));

    // for right side question answer after pull from left side
    const questionAnswers = this.state.questionAnswers.map((val, key) => (
      <div className="col-md-12 p-3 card right" key={key} data-id={val}>
        {val}
      </div>
    ));

    return (
      <React.Fragment>
        <div className="row p-3">
          <div className="col-md-8 m-auto">
            <Sortable
              options={{
                animation: 150,
                sort: false,
                group: {
                  name: "clone2",
                  pull: true,
                  put: true
                }
              }}
              className="row shadow minHeight"
              onChange={items => {
                if (items.length === 3) {
                  return false;
                }
                this.setState({ question: items });
              }}
              tag="div"
            >
              {question}
            </Sortable>
          </div>

          <div className="col-md-4 m-auto">
            <div className="row">
              <div className="col-md-3 m-auto pr-0 minHeight dragdropno">
                <ul className=" list-group minHeight text-right bg-trans d-flex">
                  <li className="list-group-item border-0 p-0 flex-1">n°1</li>
                  <li className="list-group-item border-0 p-0 flex-1">n°2</li>
                </ul>
              </div>            
              <Sortable
                className="col-md-9 ansMinHeight dragdropno1"
                onChange={(items, sortable, evt) => {
                  //let { length } = items.length;
                  if (items.length > 2) {
                    return false;
                  }
                  this.setState({
                    questionAnswers: items,
                    user_id: localStorage.getItem("userId")
                  });
                }}
                options={{
                  animation: 150,
                  group: {
                    name: "clone2",
                    pull: true,
                    put: true
                  }
                }}
                tag="div"
              >
                {questionAnswers}
              </Sortable>
            </div>
          </div>
        </div>
        <div className="col-md-12 mx-auto mb-0">
          {/* <div className="jumbotron  mb-0 p-1 mt-2">
            <p className="mb-0">
            <FormattedMessage id="ind.target.proff.expl" defaultMessage="Identify from these blocks, the two you preffer and place them in right hand column (n°1 being the most important)"/>
            </p>
          </div> */}
        </div>        
      </React.Fragment>
    );
    //});
  }

  // onSubmit(e) {
  //   e.preventDefault();
  //   this.setState({ loading: true });
  //   //const { page } = this.state;
  //   //let quesNo = page - 1;
  //   const token = localStorage.getItem("token");
  //   let maxElements = $(`.right`).length;
  //   //console.log(this.state);

  //   if (maxElements === 2) {
  //     let language;
  //     if (this.props.lang === "en") language = "english";
  //     if (this.props.lang === "fr") language = "french";

  //     const submitData = {
  //       user_id: this.state.user_id,
  //       questionAnswers: this.state.questionAnswers,
  //       language: language
  //     };
  //     this.props.saveProfessionalTest(token, submitData, response => {
  //       if (response.message === "success") {
  //         this.setState({ loading: false });
  //         this.props.stepUp();
  //       }
  //     });
  //   } else {
  //     $("#error-message").show();
  //   }
  // }
  
  submitProfessional = () => {
    this.setState({ loading: true });
    //const { page } = this.state;
    //let quesNo = page - 1;
    const token = localStorage.getItem("token");
    //let maxElements = $(`.right`).length;
    //console.log('submitProfessional '+JSON.stringify(this.state)+' '+this.state.questionAnswers.length);

    if (this.state.questionAnswers.length === 2) {
      let language;
      if (this.props.lang === "en") language = "english";
      if (this.props.lang === "fr") language = "french";

      const submitData = {
        user_id: this.state.user_id,
        questionAnswers: this.state.questionAnswers,
        language: language,
        testcode: this.state.testcode
      };
      this.props.saveProfessionalTest(token, submitData, response => {
        if (response.message === "success") {
          this.setState({ loading: false });
          this.props.stepUp();
        }
      });
    } else {
      this.setState({ error: true, loading: false, errorMsg: "Drag at least 2 items" });
      setTimeout(
        function() {
          this.setState({ error: false });
        }.bind(this),
        5000
      );
    } 
  };


  renderProgress() {
    const { nows, pages } = this.state;

    console.log(nows);
    console.log(pages)

    if (pages > 0 ) {
      return <ProgressBar now={nows} label={`${nows}%`} className="mb-3" />;
    } else {
      return null;
    }
  }

  nextQuestion = () => {
    let { page,pages } = this.state;
    if(localStorage.getItem('testType')){
      let progres = (100 /21) * pages;
      let progress= progres.toFixed(2);
      // let quesAns = "question" + page + "Answers";
  
    
      // let quesAns = "question" + page + "Answers";
   
      this.setState({ page: page +1, now: progress });
      localStorage.setItem('page',this.state.page+1);
      localStorage.setItem('now',this.state.now)
      // let addedpages=parseInt(pages) + 1;
      // this.setState({ pages: addedpages, nows: progress });
      // let addpage=parseInt(this.state.pages) + 1;
      // localStorage.setItem('page',addpage);
      // localStorage.setItem('now',this.state.nows)
    }else{
    let progres = (100 /35) * pages;
    let progress= progres.toFixed(2);
    // let quesAns = "question" + page + "Answers";
    console.log(progress);
    let addedpages=parseInt(pages) + 1;
    this.setState({ pages: addedpages, nows: progress });
    let addpage=parseInt(this.state.pages) + 1;
    localStorage.setItem('page',addpage);
    localStorage.setItem('now',this.state.nows)
    }
    // show two stepe candidate rights option before start test  questionAnswers
    //console.log('Inside nextQuestion '+page+'count '+this.state.questionAnswers);
    if(page >0){
        this.setState({ page: page + 1, error: false });
    } else{
      this.setState({ page: page + 1, error: false }); // increatement steps in case till then page set 1       
      //console.log('No validate');
    }
  };
  
  renderButton() {
    let { page } = this.state;
    if (page <= 0) {
      return (
        <div className="d-table m-auto">
          <button className="btn btn-info mt-3" onClick={this.nextQuestion}>
            <FormattedMessage id="start" defaultMessage="START"/>
          </button>
        </div>
      );      
    }else if (page < 1) {
      return (
        <div className="d-table m-auto">
          <button className="btn btn-info mt-3" onClick={this.nextQuestion}>
          <FormattedMessage id="next" defaultMessage="NEXT"/>
          </button>
        </div>
      );
    } else {
      return (
        <div className="d-table mx-auto p-90">
          <button className="btn btn-info" onClick={this.submitProfessional}>
          <FormattedMessage id="submit" defaultMessage="SUBMIT"/>
          </button>
        </div>
      );
    }
  }  

  render() {
    const { success_msg, hideForm, loading, error, page, errorMsg } = this.state;
    var displayForm = { display: hideForm ? "block" : "none" };
    let testId = localStorage.getItem('sharedTestId'); // get testid from    
    //console.log('page'+page+' testId' +testId)
    if (loading)
      return (
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              <div className="text-center">
                <div
                  className="spinner-border m-5"
                  role="status"
                  style={{ width: "3rem", height: "3rem" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      );

    return (
      <React.Fragment>
        <section className="position-relative ">
          <div className="row d-flex p-0 m-0">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              {page <= 0 && ( // show the main title of target test for individual 
                <h2 className="text-center mb-1">Target Test</h2>
              )}
              {page > 0 && ( // check page and hide the title from first two steps i.e user explanations
                <h2 className="text-center mb-1">
                  <FormattedMessage id="ind.target.workenviroment.title" defaultMessage="Work Environment"/>                                
                </h2>
              )}
              <div className="col-lg-8 col-md-10 col-sm-12 m-auto">
                {error && <div className="alert alert-danger">{errorMsg}</div>}
                {success_msg && (
                  <div id="success-message" className="text-success">
                    <FormattedMessage id="test_saved" defaultMessage="Test saved successfully !"/>
                  </div>
                )}
                <div id="error-message" className="alert alert-danger">
                  Drag two Items
                </div>

                <div style={displayForm}>
                {this.renderProgress()}
                  {this.renderQuestions()}
                  {this.renderButton()}
                </div>
              </div>
              {page > 0 && ( // check page and hide the title from first two steps i.e user explanations
                <Testcopyrights />
              )}              
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    test: state.target.ProfessionalData,
    lang: state.locale.lang
  };
};

export default connect(
  mapStateToProps,
  { fetchProfessionalTest, saveProfessionalTest }
)(Professional);
