import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import ProgressBar from "react-bootstrap/ProgressBar";
import $ from "jquery";
//import html2canvas from "html2canvas";
//import * as jsPDF from "jspdf";
import Hash from "../../helper/Hashing";
import { history } from "../../helper/History";
import CustomerRights from "../CustomerRights"; // used to show the two steps of customer right before start test 
import Testcopyrights from "../Testcopyrights"; // used to show test copyright text under test 
import DoAlert from "../DoAlert"; // used to render the do another test button
import PrintPdf from "../PrintPdf"; // used to print resutl PDF
import NeedToImprove from "../NeedToImprove"; // used to render the Need to improve in test result

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  PieChart, Pie, Label,
  ResponsiveContainer
} from "recharts";

import { fetchFranchise, saveFranchise } from "../../actions/Franchise";
import { updateTestConsume } from "../../actions";

// function is used to render the result title what need to improve to user
// function ResultActionNeedToPerform(props) {
//   if (props.sum > 480) {
//     return (
//       <div className="row">
//         <div className="col-md-12">      
//           <p>Generally Satisfied</p>
//         </div>
//       </div>
//     );
//   }else{
//     return false;
//   }
// }


class Franchise extends Component {
  constructor(props) {
    super(props);
    //this.nextPage = this.nextPage.bind(this);
    //this.previousPage = this.previousPage.bind(this)
    this.state = {
      page: -1,
      now: 0,
      loading: true,
      loader2: false,      
      error: false,
      errorMsg: "",
      sectionResult: null,      
      test_id: null,
      user_id: null,
      userScore: null,
      average: null,
      scoreGroup: null,
      averageGroup: null,
      chart2: null,
      add:"",
      global:"",
      sum:[],
      resultData: [],
      needToImprove: null,
      resultBtn: false,
      showChart: false,
      test_version_id: null,
      language: null,
      answer1: null,
      answer2: null,
      answer3: null,
      answer4: null,
      answer5: null,
      answer6: null,
      answer7: null,
      answer8: null,
      answer9: null,
      answer10: null,
      answer11: null,
      answer12: null,
      answer13: null,
      answer14: null,
      answer15: null,
      answer16: null,
      answer17: null,
      answer18: null,
      answer19: null,
      answer20: null,
      answer21: null,
      answer22: null,
      answer23: null,
      answer24: null,
      answer25: null,
      answer26: null,
      answer27: null,
      answer28: null,
      answer29: null,
      answer30: null,
      answer31: null,
      answer32: null,
      answer33: null,
      answer34: null,
      answer35: null,
      answer36: null,
      answer37: null,
      answer38: null,
      answer39: null,
      answer40: null,
      invalid: true,
      totaltest:"",
      newaverage:"",
      globalAverage:"",
      totalTestc:"",
      globalav:""
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.isSignedIn) 
    {        
      let language;
      if (this.props.lang === "en") language = "english";
      if (this.props.lang === "fr") language = "french";
      const { hr } = this.props.match.params;
      let hrId = null;
      let code = null;
      if (hr) {
        code = hr;
        hrId = Hash.decrypt(hr);
      }
      let testType = localStorage.getItem('testType');
      //console.log('Response => '+JSON.stringify(this.props.location)+' type '+testType);    
      // set the test type to state 
      this.setState({ testType: testType }); 
      
      const token = localStorage.getItem("token");
      this.props.fetchFranchise(token, hrId, code, language, resp => {
        if (resp === "success") {
          this.setState({ loading: false });
        }
        if (resp === "no test") {
          this.setState({ loading: false });
          this.props.history.push("/buy-test");
        }
        if (resp === "used") {
          this.setState({
            loading: false,
            error: true,
            errorMsg: "Test already taken"
          });
        }

        if (resp === "error") {
          this.setState({
            loading: false,
            error: true,
            errorMsg: "Error occur, please try again!!"
          });
        }
      });
    } 
  }

  //renderField({ input, label, type, meta: { touched, error } }) {
  renderField(field) {
    return (
      <div className="form-check">
        <label className="form-check-label">
          <input
            type="radio"
            className={field.class}
            value={field.rbvalue}
            onClick={() => {
              field.handleChange(field.rbvalue);
            }}
            {...field.input}
          />
          {field.label}
        </label>
      </div>
    );
  }

  selectAnswer(val) {
    //console.log(val);
    var { page } = this.state;
    const questionNo = "answer" + page;
    this.setState({
      [questionNo]: val
    });

    $("#error-message").hide();
  }

  renderAnswers(answers, questionId, quesNo) {
    if (answers.length > 0) {
      return answers.map(answer => {
        return (
          <li className="list-group-item" key={answer.answer_id}>
            <Field
              name={"answer" + questionId}
              class={"form-check-input answer" + quesNo}
              component={this.renderField}
              label={answer.answer}
              rbvalue={answer.score}
              handleChange={e => this.selectAnswer(e)}
            />
          </li>
        );
      });
    }
  }

  renderQuestions() {
    const { page } = this.state;
    let inc = 1;
    // show two stepe candidate rights option before start test     
    if(page >0){
      if (this.props.test) {
        return this.props.test.questions.map((question, index) => {
          return (
            <React.Fragment key={question.question_id}>
              {page === inc && (
                <div className="card">
                  <div className="card-header">{question.question}</div>
                  <ul className="list-group list-group-flush">
                    {this.renderAnswers(
                      question.answers,
                      question.question_id,
                      index
                    )}
                  </ul>
                </div>
              )}
              <div className="d-none">{(inc = inc + 1)}</div>
            </React.Fragment>
          );
        });
      }      
    } else {
      return (
        <CustomerRights page={page} />
      );
    //console.log('Inside 2 steps '+page);
    } 
  }

  nextQuestion() {
    const { page } = this.state;
    let quesNo = page - 1;
    // show two stepe candidate rights option before start test 
    if(page >0){
      if ($(`.answer${quesNo}`).is(":checked")) {
        let progress = (100 / 40) * page;
        this.setState({ page: page + 1, now: progress });
        $("#error-message").hide();
  
        if (page === 40) {
          let language;
          if (this.props.lang === "en") language = "english";
          if (this.props.lang === "fr") language = "french";
  
          this.setState({
            invalid: false,
            test_id: this.props.test.id,
            user_id: localStorage.getItem("userId"),
            language: language
          });
        }
      } else {
        $("#error-message").show();
      }
    } else {
      this.setState({ page: page + 1, now: 0 }); // increatement steps in case till then page set 1       
      //console.log('No validate');
    }
    //console.log(this.state);
  }

  showResult() {
    this.setState({ loading: true });
    const { hr } = this.props.match.params;
    let user_id = 0;
    let client_id = 0;
    let code = 0;
    if (hr) {
      user_id = Hash.decrypt(hr);
      client_id = localStorage.getItem("userId");
      code = hr;
    } else {
      user_id = localStorage.getItem("userId");
    }

    const token = localStorage.getItem("token");

    const submitData = {
      user_id: user_id,
      client_id: client_id,
      test: "Franchise",
      code: code
    };
    this.props.updateTestConsume(token, submitData, resp => {
      if (resp === "success") {
        var y = (this.props.lang === "en") ? "Your Score" : "Votre score";

        this.setState({
          resultData: [
            { name: y, score: this.state.userScore },
            { name: "Average Score", score: this.state.average }
          ],
          showChart: true,
          loading: false
        });
      }
    });
  }

  onSubmit() {
    this.setState({ loading: true });
    const token = localStorage.getItem("token");

    this.props.saveFranchise(
      token,
      this.state,
      (testScore, averageScore, groupResult, sectionResult,needToImprove,totaltest,newaverage,globalAverage,totalTestc,globalav) => {
        this.setState({ loading: false });
        if (testScore >= 0) {
          this.setState({
            userScore: testScore,
            average: averageScore,
            chart2: groupResult,
            sectionResult: sectionResult,
            needToImprove: needToImprove,            
            resultBtn: true,
            totaltest:totaltest,
            newaverage:newaverage,
            globalAverage:globalAverage,
            totalTestc:totalTestc ,
            globalav:globalav  
          });
        }
      }
    );
  }

  
  renderAnotherTestButtons() {
    //console.log('Inisde renderAnotherTestButtons '+this.state.testType);
    const buttonName =  <FormattedMessage id="btn.do.another.test" defaultMessage="DO ANOTHER TEST"/>    
    return (
      <DoAlert 
        type={this.state.testType} 
        buttonName={buttonName}
        className="btn btn-warning mt-3 mx-3 w-50"
        canvasSelector="franchiseTest"
        pdfName="franchiseTestResult"
       />
    );
  }
  
  renderContactCoachButtons() {
    //console.log('Inisde renderContactCoachButtons '+this.state.testType);
    if (this.state.testType == 'individual') {
      const buttonName =  <FormattedMessage id="btn.contact.a.coach" defaultMessage="CONTACT A COACH"/>            
      return (
        <DoAlert 
          type={this.state.testType} 
          buttonName={buttonName}
          className="btn btn-info mt-3 w-50"
          target="_blank"
          href="/contact-to-coach"
          canvasSelector="franchiseTest"
          pdfName="franchiseTestResult"          
        />
      );
    }else{
      // const buttonName =  <FormattedMessage id="btn.send.results" defaultMessage="SEND RESULTS"/>                  
      // return (
      //   <DoAlert 
      //     type={this.state.testType} 
      //     buttonName={buttonName}
      //     className="btn btn-info mt-3 w-50"
      //     target=""
      //     href="mailto:name@mail.com?subject=PilotRH: %20Happiness%2Tools%2Result&amp;body=This%20is%20a%20message%20body"      
      //     canvasSelector="franchiseTest"
      //     pdfName="franchiseTestResult"          
      //   />
      // );
    }
  }  

  // printResult(){
  //   this.setState({ loader2: true });    

  //   let CurrentDate   =  new Date();
  //   let copyRightDate = CurrentDate.getFullYear();

  //   var pilotRhLogo = new Image();
  //   pilotRhLogo.src = 'assets/images/logo.png';

  //   var PDF_Heighti   = document.querySelector('#franchiseTest').offsetWidth;
  //   //var HTML_Width    = $("#franchiseTest").width();
  //   var HTML_Width    = 800;    
  //   //console.log('Html width => '+HTML_Width);
  //   var HTML_Height   = $("#franchiseTest").height();
  //   //console.log('Html height => '+HTML_Height);
  //   var top_left_margin = 10;
  //   var PDF_Width = HTML_Width+(top_left_margin*2);
  //   //var PDF_Height = 2000;
  //   var PDF_Height = HTML_Height+(top_left_margin*2);
  //   //console.log('PDF Height x Width => '+PDF_Height+'x'+PDF_Width);
  //   var canvas_image_width  = HTML_Width;
  //   var canvas_image_height = HTML_Height;
    
  //   var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;
  //   //console.log('NoOfPage => '+totalPDFPages);
    
  //   //html2canvas($("#franchiseTest")[0],{allowTaint:true}).then(function(canvas) {
  //   html2canvas($("#franchiseTest")[0],{allowTaint:true}).then((canvas) => {
  //     canvas.getContext('2d');
  //     //console.log(canvas.height+" "+canvas.width);
  //     var imgData = canvas.toDataURL("image/jpeg", 1.0);
  //     var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
  //     //var pdf = new jsPDF();
  //     pdf.addImage(pilotRhLogo, 'PNG', 330, 10);
  //     pdf.addImage(imgData, 'JPG', 10, 80);
  //     for (var i = 1; i <= totalPDFPages; i++) {
  //       pdf.addPage(PDF_Width, PDF_Height);
  //       pdf.addImage(imgData, 'JPG', top_left_margin, -PDF_Heighti+(top_left_margin));
  //     }
  //     pdf.setFontSize(12);
  //     pdf.setTextColor(108, 117, 125);
  //     pdf.text(160, (PDF_Height-30),'© Copyright 5W PILOT RH SOLUTIONS '+ CurrentDate.getFullYear() +' – All right reserved – www.pilotrhsolutions.com.');
  //     pdf.setTextColor(33, 37, 41);
  //     pdf.text(310, (PDF_Height-10), 'DATE OF TEST '+("0" + (CurrentDate.getDate() + 1)).slice(-2) +'/'+ ("0" + (CurrentDate.getMonth() + 1)).slice(-2)  +'/'+ CurrentDate.getFullYear());
  //     //pdf.text(107,68,currentDate);
  //     pdf.save("franchiseTestResult.pdf");
  //     this.setState({ loader2: false });
  //   });
  // }

  renderCharts() {
    const { resultData, chart2, sectionResult, needToImprove, loader2 } = this.state;
    //var data = [{ name: "a", score: 50 }, { name: "b", score: 86 }];
    const COLORS = ["#17a2b8", "#e0fbff"];    
    //console.log('resultData => '+JSON.stringify(this.state, null, 4));
    const AVERAGEDATA = Object.values(resultData)[0];    
    var your = (this.props.lang == "en") ? "You" : (this.props.lang == "fr") ? "Vous" : "You";
    var average  = (this.props.lang == "en") ? "Average of tests" : (this.props.lang == "fr") ? "Moyenne des tests" : "Average of tests";    

    if (this.state.showChart) {
      return (
        <React.Fragment>
          <div id="franchiseTest">
            <h2 className="text-center mb-1">
              <FormattedMessage id="franchise.test.results" defaultMessage="Franchise Test Result"/>              
            </h2>
            <h4 className="text-center mb-4">
              <FormattedMessage id="franchise.result.subtitle" defaultMessage="My ability to be successful in Franchise"/>                            
            </h4>
            <div className="row">
              {/* <div className="col-md-5">
                <div style={{ width: "100%", height: 300 }} className="m-auto">
                  <ResponsiveContainer>
                    <BarChart
                      data={resultData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis domain={[0, 100]} />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="score">
                        {resultData.map((result, index) => (
                          <Cell
                            key={index}
                            fill={result.score > 50 ? "#17a2b8" : "#C0C0C0"}
                          />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div> */}
              <div className="col-md-12">
                <div className="m-auto">
                  <div style={{ width: '100%', height: 300 }}>
                      <ResponsiveContainer>
                        <PieChart>
                              <Pie
                                data={resultData}
                                dataKey="score"
                                labelLine={false}
                                innerRadius={60}
                                outerRadius={90}
                                fill="#17a2b8"
                                textAnchor="end"
                              >
                            {resultData.map((entry, index) => (
                              <Cell key={index} fill={COLORS[index % COLORS.length]} />
                            ))}

{
                    
                    this.state.globalav.map((chart,index)=>(
                     
                      this.state.sum.push(chart.Moyennedestests)
                     
                 
                    ))}
                    {
                      this.state.add=this.state.sum.reduce((result,number)=> result+number)
                      
                    }
                    { this.state.global=this.state.add/8}
                            <Label width={80} position="center">                  
                            {`${AVERAGEDATA.score}%`}
                            </Label>
                          </Pie>
                          <Tooltip />                
                        </PieChart>               
                      </ResponsiveContainer>
                    </div>  
                </div>
              </div>
            </div>           
            {/* <h5 className="text-center mb-4" style={{color:"grey",fontSize:"14px"}}><FormattedMessage id="test-average" defaultMessage="Tests average:62%"/> {this.state.totalTestc} tests: {Math.round(this.state.global*100)/100} % </h5>                                            */}
 
            <div className="row">
              <div style={{ width: "100%", height: 500 }} className="m-auto col-md-12 Mob-hide">
                <ResponsiveContainer>
                  <BarChart
                    width={500}
                    height={300}
                    data={chart2}
                    margin={{
                      top: 70,
                      right: 30,
                      left: 20,
                      bottom: 5
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="name"
                      interval={0}
                      angle={-12}
                      textAnchor="end"
                    />
                    <YAxis domain={[0, 100]} />
                    <Tooltip />
                    <Legend wrapperStyle={{ paddingTop: "60px" }} />
                      <Bar dataKey={your} fill="#17a2b8" />
                      <Bar dataKey={average} fill="#C0C0C0" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
            <hr/>
            <NeedToImprove average={this.state.userScore } sectionResult={sectionResult}  needToImprove={needToImprove} testType={this.state.testType}/>
            {/* <h4>NEEDS TO IMPROVE</h4>
            <div className="row">
              <ResultActionNeedToPerform sum={this.state.sectionResult.col1 + sectionResult.col2 + sectionResult.col3 + sectionResult.col4 + sectionResult.col5 + sectionResult.col6 + sectionResult.col7 + sectionResult.col8} />
              {sectionResult.col1 + sectionResult.col2 + sectionResult.col3 <
                180 &&
                this.graphDetail(
                  "Freedom of action",
                  {
                    line1: "Open communication with everybody",
                    line2: "Personal style of action",
                    line3: "Work rate suited to one's needs"
                  },
                  "If group interactions or teamwork limits this person's freedom of action or communication, he or she may sense a loss of identity or never successfully build it."
                )}
              {sectionResult.col4 < 60 &&
                this.graphDetail(
                  "RELIABLE PROFESSIONAL RELATIONSHIPS",
                  {
                    line1: "Follow up on career plans",
                    line2: "Recognition of achievements",
                    line3: "Trusting and reliable relationships"
                  },
                  "This person needs reliable and continuous professional relationships to build his or her identity. Regular exchanges are necessary so that he or she can become independent, acquire consistency and be recognized as dependable by others."
                )}
              {sectionResult.col5 < 60 &&
                this.graphDetail(
                  "ACKNOWLEDGEMENT OF UNIQUENESS",
                  {
                    line1: "Free and uninhibited speech",
                    line2: "Acceptance of individual differences",
                    line3: "Easy access to management"
                  },
                  "For this person to construct or confirm his or her identity, it is essential that personal opinions and desires be acknowledged by others. Obtaining this acknowledgement necessarily leads to conflicting relationships that must be solved by negotiation and without violence. The uniqueness of each person and his or her differences would otherwise not be recognised and accepted."
                )}
              {sectionResult.col6 < 60 &&
                this.graphDetail(
                  "A PATH TO FREEDOM",
                  {
                    line1: "Good salary",
                    line2: "Self organisation and autonomy",
                    line3: "Relationships built on trust"
                  },
                  "This person's job allows him or her to provide for all essentials (food & lodging, heating, clothing and travel). It also represents an opportunity for the person to gradually improve his or her decision-making skills and become more independent."
                )}
              {sectionResult.col7 < 60 &&
                this.graphDetail(
                  "A PATH TO IDENTITY",
                  {
                    line1: "Personalised assignments",
                    line2: "Broad use of skills",
                    line3: "Expression of uniqueness"
                  },
                  "This person's work challenges his or her skills, and thus reveals his or her personality and qualities. In this way, it contributes to the building of a sense of identity and uniqueness."
                )}
              {sectionResult.col8 < 60 &&
                this.graphDetail(
                  "A PATH TO SELF REALISATION",
                  {
                    line1: "Measures of achievement",
                    line2: "Immediate feedback",
                    line3: "Meaningful contribution to society"
                  },
                  "From this person's point of view, work is a factor of progress that contributes to the collective well being and makes society more human. As such, it makes the individual's life more meaningful."
                )}
            </div> */}
          </div>

          <div className="col-lg-8 col-md-10 col-sm-12 mx-auto text-center mb-4 pb-45 d-flex justify-content-center">
            <PrintPdf  type={this.state.testType}  canvasSelector="franchiseTest"  pdfName="franchiseTestResult"/>            
            {/* <button onClick={e => this.printResult(e)} className="btn btn-info mt-3">
              SAVE YOUR RESULTS
            </button> */}
            {this.renderAnotherTestButtons()}
            {this.renderContactCoachButtons()}            
            {loader2 && (
              <div className="position-absolute result-loader">
                <div
                  className="spinner-border"
                  role="status"
                  style={{ width: "2.5rem", height: "2.5rem" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </div>                                   
        </React.Fragment>
      );
    } else {
      return null;
    }
  }

  // graphDetail(title, header, description) {
  //   return (
  //     <div className="col-md-4 p-1">
  //       <div className="card text-center mb-3 shadow gd-card">
  //         <div
  //           className="card-header bg-info text-white shadow text-uppercase"
  //           style={{ borderRadius: ".25rem", fontSize: "20px" }}
  //         >
  //           {title}
  //         </div>
  //         <div className="card-body">
  //           <div className="border-bottom">
  //             <h6 className="card-title">{header.line1}</h6>
  //             <h6 className="card-title">{header.line2}</h6>
  //             <h6 className="card-title">{header.line3}</h6>
  //           </div>
  //           <div className="pt-2">
  //             <p className="mb-0 text-muted" style={{ fontSize: "14px" }}>
  //               {description}
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }  

  renderProgress() {
    const { now, page, showChart } = this.state;
    if (page > 1 && !showChart) {
      return <ProgressBar now={now} label={`${now}%`} className="mb-3" />;
    } else {
      return null;
    }
  }

  render() {
    const {
      invalid,
      resultBtn,
      showChart,
      loading,
      error,
      page,
      errorMsg
    } = this.state;

    if (loading)
      return (
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              <div className="text-center">
                <div
                  className="spinner-border m-5"
                  role="status"
                  style={{ width: "3rem", height: "3rem" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      );

    return (
      <React.Fragment>
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0 m-90">
              {this.state.showChart === false && ( // show if there is not short chart or result
                <h2 className="text-center mb-2">Franchise Test</h2>
              )}
              {error ? (
                <div className="alert alert-danger col-md-6 text-center mx-auto">
                  <h4 className="alert-heading">
                    Hello {localStorage.getItem("userName")}!
                  </h4>
                  {errorMsg}
                </div>
              ) : (
                <div className="col-lg-8 col-md-10 col-sm-12 m-auto m-90">
                  <div id="result-chart">{this.renderCharts()}</div>

                  <div id="error-message" className="text-danger">
                    Select option
                  </div>
                  {this.renderProgress()}
                  <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    {this.renderQuestions()}
                    <button
                      type="submit"
                      className="btn btn-info mt-2 mx-auto"
                      disabled={invalid}
                      style={{
                        display: invalid || resultBtn ? "none" : "block"
                      }}
                    >
                      <FormattedMessage id="submit" defaultMessage="SUBMIT"/>
                    </button>
                  </form>
                  {page > 0 && ( // check page and hide the title from first two steps i.e user explanations
                    <button
                      className="btn btn-info mt-2  text-left"
                      onClick={() => this.nextQuestion()}
                      disabled={!invalid}
                      style={{
                        display: invalid && !resultBtn ? "block" : "none"
                      }}
                    >
                    <span>
                      <FormattedMessage id="next" defaultMessage="NEXT"/>
                    </span>
                    </button>                    
                  )}
                  {page <= 0 && (
                    <button
                      className="btn btn-info mt-2  mx-auto"
                      onClick={() => this.nextQuestion()}
                      disabled={!invalid}
                      style={{
                        display: invalid && !resultBtn ? "block" : "none"
                      }}
                    >
                    <span>
                      <FormattedMessage id="start" defaultMessage="START"/>
                    </span>
                    </button>
                  )} 
                  <div
                    className="text-center"
                    style={{
                      display: resultBtn && !showChart ? "block" : "none"
                    }}
                  >
                    <h3 className="text-info mb-3">
                      <FormattedMessage id="test_saved" defaultMessage="Test save successfully"/>
                    </h3>
                    <button
                      className="btn btn-info"
                      onClick={() => this.showResult()}
                    >
                      <FormattedMessage id="get_result" defaultMessage="Get Result"/>
                    </button>
                  </div>
                </div>
              )}
            </div>
            {page > 0 && ( // check page and hide the title from first two steps i.e user explanations
              <Testcopyrights />
            )}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    isSignedIn: state.auth.isSignedIn,
    test: state.happiness.FranchiseData.data,
    lang: state.locale.lang
  };
};

Franchise = connect(
  mapStateToProps,
  { fetchFranchise, saveFranchise, updateTestConsume }
)(Franchise);

export default reduxForm({
  form: "FranchiseForm"
})(Franchise);
