import React, { Component } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
//import $ from "jquery";
import {
  FormattedMessage,
  FormattedHTMLMessage,
  IntlProvider,
} from "react-intl";
import Carousel from "react-bootstrap/Carousel";
import Footer from "./includes/Footer";
import Register from "./includes/Register";
import { history } from "../helper/History";
import $ from "jquery";
//import { FormattedHTMLMessage } from "react-intl";

class Youcompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dis: "",
    };
    window.scrollTo(0, 0); // scroll page to top
  }
  // componentDidMount(){
  //   if (this.props.isSignedIn) {

  //     this.setState({dis:"none"});
  //   }
  //   console.log(this.state.dis);
  // }

  showRegister(e) {
    var userType = localStorage.getItem("role");
    if (!userType) {
      history.push("/register");
      // console.log('in '+e);
      // $("#loginModal").modal("show");
      // $("#register-form").delay(100).fadeIn(100);
      // $("#login-form").fadeOut(100);
      // $("#login-form-link").removeClass("active");
      // console.log('Inside Header '+e);
      // if(e === '#register-form-link'){
      //   $('.modal-title').text('SIGNUP');
      //   $(e).addClass("active");
      // }else{
      //   $(e.target).addClass("active");
      // }
    }
  }

  sendparam() {
    localStorage.setItem("classic", "classic");
  }
  showRegister2(e) {
    console.log("in " + e);
    $("#loginModal").modal("show");
    $("#register-form").delay(100).fadeIn(100);
    $("#login-form").fadeOut(100);
    $("#login-form-link").removeClass("active");
    console.log("Inside Header " + e);
    if (e === "#register-form-link") {
      $(".modal-title").text("SIGNUP");
      $(e).addClass("active");
    } else {
      $(e.target).addClass("active");
    }
  }

  render() {
    return (
      <React.Fragment>
        <section id="wrapper" className="icon-boxed1 company-page teaser-box">
          <div
            className="demo-btn-block company-btn"
            style={{ display: "flex" }}
          >
            <Link
              className="btn btn-primary p-link"
              to="/contact-professional"
              style={{
                background: "#28326a",
                marginBottom: "3%",
                marginTop: "3%",
                width: "254px",
                height: "55px",
                border: "none",
              }}
            >
              <p className="demo-btn">
                {" "}
                <FormattedHTMLMessage
                  id="home.titleButton"
                  defaultMessage="FAQ"
                />
              </p>
            </Link>

            <Link
              className="btn btn-primary p-link"
              style={{
                background: "#28326a",
                marginBottom: "3%",
                marginTop: "3%",
                width: "254px",
                height: "55px",
                border: "none",
                marginLeft:"25%",
              }}
            >
              <p className="demo-btn">
                {" "}
                <FormattedHTMLMessage
                  id="company.title_button"
                  defaultMessage="FAQ"
                />
              </p>
            </Link>
          </div>

          <div className="container">
            <div className="row" style={{ paddingRight:"0px", paddingLeft:"0px" }}>
              <div className="col-lg-3 col-md-6 responsive-mb" style={{ marginLeft: "-88px" }}>
                    <Link to="/talentcompy" style={{ textDecoration: "none" }}>
                      <div className="icon-box text-left hero-bg box-shadow boxHeight">
                        {/* <div className="teaser-icon icon-styled bg-maincolor3 m-auto d-flex align-items-center justify-content-center">
                        <img src="assets/images/scientific.png" alt="Scientific" />
                      </div> */}
                        <div>
                          <p className="text-center">
                            {/* <Link to="/" className=""> */}
                            <b>
                              <FormattedHTMLMessage
                                id="you.company.title3"
                                defaultMessage="starter"
                              />
                              {/* </Link> */}
                            </b>
                          </p>

                          <p style={{ color: "white" }}>
                            <FormattedHTMLMessage
                              id="you.company.card3"
                              defaultMessage="12 months"
                            />
                          </p>
                        </div>
                        <div class="text-center BtnBotm">
                          <Link
                            to="/talentcompy"
                            className="nav-link text-center"
                            data-target=".navbar-collapse.show"
                          >
                            <button class="btn btn-primary btnDiscover">
                              <FormattedHTMLMessage
                                id="Discover"
                                defaultMessage="Subscribe"
                              />
                            </button>
                          </Link>
                        </div>
                      </div>
                    </Link>
              </div>
              <div className="col-lg-3 col-md-6 responsive-mb" style={{ marginLeft: "-57px" }}>
                <Link to="/hraudit" style={{ textDecoration: "none" }}>
                  <div className="icon-box text-left hero-bg box-shadow boxHeight">
                    <div>
                      <p className=" text-center ">
                        <b>
                          <FormattedHTMLMessage
                            id="you.company.title2"
                            defaultMessage="Classic"
                          />
                        </b>
                      </p>
                      {/* <p>
                    <FormattedHTMLMessage
                      id="Starter.subtitle.2"
                     . defaultMessage="12 months"
                    />
                  </p> */}

                      <p style={{ color: "white" }}>
                        <FormattedHTMLMessage
                          id="you.company.card2"
                          defaultMessage="€49.90"
                        />
                      </p>
                      {/* <p>
                     <FormattedHTMLMessage
                      id="Starter.subpricetitle1"
                      defaultMessage="per month - annual payment"
                    />
                    </p> */}

                      {/* <p><FormattedHTMLMessage id="Starter.user" defaultMessage="1 user" /></p>    */}
                    </div>
                    <div class="text-center BtnBotm">
                      <Link
                        to="/hraudit"
                        className="nav-link text-center"
                        data-target=".navbar-collapse.show"
                      >
                        <button
                          class="btn btn-primary  btnDiscover"
                          onClick={this.sendparam()}
                        >
                          <FormattedHTMLMessage
                            id="Discover"
                            defaultMessage="Subscribe"
                          />
                        </button>
                      </Link>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-md-6 responsive-mb" style={{ marginLeft: "-57px" }}>
                <Link
                  to="/recruitmentsourcing"
                  style={{ textDecoration: "none" }}
                >
                  <div className="icon-box text-left hero-bg box-shadow boxHeight">
                    <div>
                      <p className=" text-center ">
                        <b>
                          <FormattedHTMLMessage
                            id="you.company.title1"
                            defaultMessage="Classic"
                          />
                        </b>
                      </p>
                      <p style={{ color: "white" }}>
                        <FormattedHTMLMessage
                          id="you.company"
                          defaultMessage="12 months"
                        />
                      </p>

                      {/* <p className="font24">
                     <FormattedHTMLMessage
                      id="Classic.price"
                      defaultMessage="€49.90"
                    />
                    </p>
                    <p>
                     <FormattedHTMLMessage
                      id="Starter.subpricetitle1"
                      defaultMessage="per month - annual payment"
                    />
                    </p> */}
                      {/*                     
                <h6><FormattedHTMLMessage id="Classic.buy" defaultMessage="Buy per unit"/></h6>        
                <p><i><FormattedHTMLMessage id="Classic.buytitle" defaultMessage="buy" /></i></p>     */}
                    </div>
                    <div class="BtnBotm">
                      <Link
                        to="/recruitmentsourcing"
                        className="nav-link text-center"
                        data-target=".navbar-collapse.show"
                      >
                        <button
                          class="btn btn-primary px-4  "
                          style={{
                            background: "white",
                            border: "none",
                            color: "black",
                          }}
                          onClick={this.sendparam()}
                        >
                          <FormattedHTMLMessage
                            id="Discover"
                            defaultMessage="Subscribe"
                          />
                        </button>
                      </Link>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-md-6 responsive-mb" style={{ marginLeft: "-57px" }}>
                <Link to="/coachingoffer" style={{ textDecoration: "none" }}>
                  <div className="icon-box text-left hero-bg box-shadow boxHeight">
                    <div>
                      <p class="text-center">
                        {/* <Link to="/" className=""> */}
                        <b>
                          <FormattedHTMLMessage
                            id="you.company.title4"
                            defaultMessage="Premium"
                          />
                        </b>
                        {/* </Link> */}
                      </p>
                      <p style={{ color: "white" }}>
                        <FormattedHTMLMessage
                          id="you.company.card4"
                          defaultMessage="12 months"
                        />
                      </p>
                    </div>
                    <div class="BtnBotm">
                      <Link
                        to="/coachingoffer"
                        className="nav-link text-center"
                        data-target=".navbar-collapse.show"
                      >
                        <button class="btn btn-primary btnDiscover ">
                          <FormattedHTMLMessage
                            id="Discover"
                            defaultMessage="Subscribe"
                          />
                        </button>
                      </Link>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-md-6 responsive-mb" style={{ marginLeft: "-57px" }}>
                <Link to="/pre-selection" style={{ textDecoration: "none" }}>
                  <div className="icon-box text-left hero-bg box-shadow boxHeight" style={{ padding:"19px" }}>
                    <div>
                      <p class="text-center">
                        {/* <Link to="/" className=""> */}
                        <b>
                          <FormattedHTMLMessage
                            id="you.company.title5"
                            defaultMessage="Premium"
                          />
                        </b>
                        {/* </Link> */}
                      </p>
                      <p style={{ color: "white" }}>
                        <FormattedHTMLMessage
                          id="you.company.card5"
                          defaultMessage="12 months"
                        />
                      </p>
                    </div>
                    <div class="BtnBotm">
                      <Link
                        to="/pre-selection"
                        className="nav-link text-center"
                        data-target=".navbar-collapse.show"
                      >
                        <button class="btn btn-primary btnDiscover ">
                          <FormattedHTMLMessage
                            id="Discover"
                            defaultMessage="Subscribe"
                          />
                        </button>
                      </Link>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-2 col-md-3 responsive-mb company-pdf-links" style={{ width:"100%" }}>
                    <a href="/assets/pdf/PILOTRH_Grand_Groupe.pdf" style={{ textDecoration: "none" }} target="_blank">
                      <div className="icon-box text-left hero-bg box-shadow" style={{ width:"100%", height:"40%",marginTop:"17%", background:"#fff" }}>
                        <div >
                          <p className=" text-center " >
                            <b style={{ color:"#000",fontSize:"15px"  }}>
                          <FormattedHTMLMessage
                            id="big_group_pdf1"
                            defaultMessage="pdf"
                            
                          /></b><br/>
                          <img
                              className="" style={{width:"100%"}}
                              src="assets/images/pdf.jpg"
                              alt=""
                            />
                          </p>
                        </div>
                      </div>
                    </a>
                    <a href="/assets/pdf/PILOTRH_Consulting.pdf" style={{ textDecoration: "none" }} target="_blank">
                      <div className="icon-box text-left hero-bg box-shadow" style={{ width:"100%", height:"40%",marginTop:"15%", background:"#fff" }}>
                        <div >
                        <p className=" text-center " >
                        <b style={{ color:"#000" }}>
                          <FormattedHTMLMessage
                            id="big_group_pdf2"
                            defaultMessage="pdf"
                          /></b><br/>
                          <img
                              className="" style={{width:"100%"}}
                              src="assets/images/pdf.jpg"
                              alt=""
                            />
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default Youcompany;
