import React, {useEffect } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";


const FullSkillAssessment = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  
  return (
    <React.Fragment>
      <div className="container">
        <div className="container about pages text-justify">
          <div className="row">
            <div className="col-md-12">
           
             
              <div><br></br></div>
            <h4 className="pb-2 text-center"><span><FormattedMessage id="skill.test.title" defaultMessage="FULL SKILLS ASSESSMENT"/> : </span></h4>
            <h5 className="pb-2 text-center"><span><FormattedMessage id="skill.test.desc" defaultMessage="5 tests + 1 hour of online debriefing"/> </span></h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
            <span>
              <FormattedHTMLMessage id="skill.test.content" defaultMessage="<p className='text-muted'><strong>You are an Individual and would like to know better yourself.</strong></p><p className='text-muted'>Identify your talents, your strengths, your business and entrepreneurial skills, your interpersonal skills, your current job satisfaction and the environments best suited to your personality?</p><p className='text-muted'>Our tests have been used for 20 years for career management and recruitment in large schools as well as in large groups. </p><p className='text-muted'>Today online, you realize and edit your results yourself. </p><p className='text-muted'>A professional will assist you in analyzing your reports during an one-hour debreifing in complete confidentiality (phone or other media). </p><p className='text-muted'>Before each test starts, a description and sample of test results are presented.</p>"/>
            </span>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-12 img-content">
              <h2 className="col-md-12 py-3 text-center"><FormattedMessage id="skill.test.credit" defaultMessage="5 TESTS (+ 1 free)"/></h2>
              <div className="col-md-12 m-auto p-0">
                  {localStorage.getItem('lang') == 'en' ? <img src="assets/images/full-skill-test-en.png" alt="Olivier Colson" className="img-fluid rounded"/> : <img src="assets/images/full-skill-test-fr.png" alt="Olivier Colson" className="img-fluid rounded"/>}
              </div>
              <div className="col-md-4 mx-auto mt-4 p-0 plus-icon text-center">
                  <i className="fa fa-plus"></i>  <i className="fas fa-equals"></i>
              </div>
              <h3 className="col-md-12 py-4 text-center"><FormattedMessage id="skill.test.debriefing" defaultMessage="1 hour of online debriefing"/></h3>
              <div className="col-md-4 m-auto p-0">
                  <img src="assets/images/online-debriefing.jpg" alt="Olivier Colson" className="img-fluid rounded"/>
              </div>
              <div className="col-md-4 mx-auto mt-4 p-0 plus-icon text-center">
                <span class="font-weight">&#x3d;</span>
              </div>              
              <h3 className="col-md-12 py-4 text-center">
                <FormattedHTMLMessage id="skill.test.price" defaultMessage="€350 vat included instead of <span className='text-danger'><del>€450</del></span>"/>
              </h3>
              <div className="col-md-12 text-center mb-5">
                <Link className="" to="/buy-test">
                  <FormattedHTMLMessage id="skill.test.buy.button" defaultMessage="<button class='buy-full-skill px-5 py-3'><h3 className='mb-4'>BUY NOW</h3><h4>Full skills assessment</h4><h6>(5 tests + 1 hour of online debriefing) </h6><h4> €350 </h4><p>(safe & secure payment) </p></button>"/>
                </Link>
              </div>
              
            </div>
        </div>
        </div>
      </div>      
    </React.Fragment>
  );
};

export default FullSkillAssessment;
