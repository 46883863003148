import React, { Component } from "react";
import { connect } from "react-redux";

import {history} from "../../../helper/History";
import Hash from "../../../helper/Hashing";
import { updateTestConsume } from "../../../actions";
//import { fetchMotivation } from "../../../actions/Recruitment";
import { Link } from "react-router-dom";
import Motivation from "./Motivation";
import WorkCulture from "./WorkCulture";
import MotivationResult from "./result/MotivationResult";
import WorkCultureResult from "./result/WorkCultureResult";
import PrintPdf from "../../PrintPdf"; // used to print resutl PDF
import { FormattedMessage } from "react-intl";

class Recruitment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test: 1,
      loading: false,
      loader2: false,
      showResult: false,
      hr: this.props.match.params.testId
    };
  }

  componentDidMount() {
    //const { hr } = this.props.match.params.testId;
    // if (hr) {
    //   this.setState({ hr: hr });
    // }
    //
    console.log(this.props.match.params,this.state);
    if (this.props.isSignedIn)
    {
      let language;
      if (this.props.lang === "en") language = "english";
      if (this.props.lang === "fr") language = "french";
      //const { testId } = this.props.match.params.testId;
      //console.log('Recruitment =>'+JSON.stringify(this.props.match.params)+' testId '+this.props.match.params.testId);
      // let hrId = null;
      // let code = null;
      // if (hr) {
      //   code = hr;
      //   hrId = Hash.decrypt(hr);
      // }else{
      //   code = this.props.match.params.testId;
      // }
      // //console.log('Inside Recruitment js ');
      // const token = localStorage.getItem("token");
      // // used to check if test is empty then redirec it to buy test page
      // this.props.fetchMotivation(token, hrId, code, language, resp => {
      //   if (resp === "success") {
      //     this.setState({ loading: false });
      //   }
      //   if (resp === "no test") {
      //     this.setState({ loading: false });
      //     this.props.history.push("/buy-test");
      //   }
      //   if (resp === "used") {
      //     this.setState({
      //       loading: false,
      //       error: true,
      //       errorMsg: "Test already taken"
      //     });
      //   }

      //   if (resp === "error") {
      //     this.setState({
      //       loading: false,
      //       error: true,
      //       errorMsg: "Error occur, please try again!!"
      //     });
      //   }
      // });
    }
    //console.log('UserType => '+localStorage.getItem("role"))
    else{

if(this.props.match.params.testId){
  localStorage.setItem("Linktest",this.props.match.params.testId)
}
      history.push("/login")
    
    }
  
  }

  // printResult() {
  //   this.setState({ loader2: true });
  //   var len = 2; // define No. of graphs to print
  //   var pdf = new jsPDF({
  //     orientation: "landscape"
  //   });
  //   var position = 0;
  //   for (let i = 1; i <= len; i++) {
  //     html2canvas(document.querySelector("#result-" + i), {
  //       dpi: 0, // Set to 300 DPI
  //       scale: 1 // Adjusts your resolution
  //     }).then(canvas => {
  //       pdf.addImage(canvas.toDataURL("images/png", 1), "PNG", 0, position);
  //       if (i === len) {
  //         pdf.save("recruitment.pdf");
  //         this.setState({ loader2: false });
  //       } else {
  //         pdf.addPage();
  //       }
  //     });
  //   }
  // }

  nextTest() {
    let prevTest = this.state.test;
    prevTest = prevTest + 1;

    this.setState({ test: prevTest });
  }

  renderGetResultButtons() {
    // check logged in usertype
    // if (localStorage.getItem("role") === 'User') {
    //   return (
    //     <p>Thank you your test is saved.</p>
    //   );
    // } else {
    //   return (
    //   <button className="btn btn-info" onClick={this.getResult}>
		// 	  <FormattedMessage id="get_result" defaultMessage="Get Result"/>
		//   </button>
    //   );
    // }
    return (
      <button className="btn btn-info" onClick={this.getResult}>
			  <FormattedMessage id="get_result" defaultMessage="Get Result"/>
		  </button>
    );
  }
  renderGetResultButtons2() {
    // check logged in usertype
    // if (localStorage.getItem("role") === 'User') {
    //   return (
    //     <p>Thank you your test is saved.</p>
    //   );
    // } else {
    //   return (
    //   <button className="btn btn-info" onClick={this.getResult}>
		// 	  <FormattedMessage id="get_result" defaultMessage="Get Result"/>
		//   </button>
    //   );
    // }
    return (
      // <Link className="" to="/">
      <div>
      <button className="btn btn-info" onClick={this.getResult2}>
			  <FormattedMessage id="btn.goto.home" defaultMessage="Get Result"/>
		  </button>
      </div>
      // </Link>
    );
  }

  getResult = () => {
    this.setState({ loading: true });
    const { hr } = this.state;
    let user_id = 0;
    let client_id = 0;
    let code = 0;
    if (hr) {
      user_id = Hash.decrypt(hr);
      client_id = localStorage.getItem("userId");
      code = hr;
    } else {
      user_id = localStorage.getItem("userId");
    }

    const token = localStorage.getItem("token");
    const submitData = {
      user_id: user_id,
      client_id: client_id,
      test: "Recruitment",
      code: code
    };
    this.props.updateTestConsume(token, submitData, resp => {
      if (resp === "success") {
        this.setState({
          showResult: true,
          loading: false
        });
      }
    });
  };

  getResult2 = () => {
    this.setState({ loading: true });
    const { hr } = this.state;
    let user_id = 0;
    let client_id = 0;
    let code = 0;
    if (hr) {
      user_id = Hash.decrypt(hr);
      client_id = localStorage.getItem("userId");
      code = hr;
    } else {
      user_id = localStorage.getItem("userId");
    }

    const token = localStorage.getItem("token");
    const submitData = {
      user_id: user_id,
      client_id: client_id,
      test: "Recruitment",
      code: code
    };
    this.props.updateTestConsume(token, submitData, resp => {
      // if (resp === "success") {
      //   this.setState({
      //     showResult: true,
      //     loading: false
      //   });
      // }
    });
    this.props.history.push("/");
  };

  render() {
    let { test, showResult, hr, loading, loader2 } = this.state;
    let { motivationResult, workCultureResult } = this.props;
    let ResultDate =  new Date();
    console.log(this.props.resu);
    //console.log('workCultureResult => '+JSON.stringify(workCultureResult)+' - - '+JSON.stringify(motivationResult)+' test => '+test);
    //console.log('Recruitment =>  - - '+JSON.stringify(motivationResult)+' object => '+JSON.stringify(motivationResult.result));
    var showTest;
    if (test === 1) {
      showTest = <Motivation stepUp={this.nextTest.bind(this)} hr={this.props.match.params.hr} testId={this.props.match.params.testId} />;
    } else if (test === 2) {
      showTest = <WorkCulture stepUp={this.nextTest.bind(this)} hr={this.props.match.params.hr} testId={this.props.match.params.testId} />;
    } else {
      showTest = (
        <React.Fragment>
          <section className="position-relative top-100">
            <div className="row d-flex p-0 m-0" id="mb-space">
              <div className="col-lg-12 col-md-12 p-0 m-0">
                <h2 className="text-center mb-1">Recruitment Tool</h2>
                {this.props.match.params.op !=2 &&this.props.match.params.op == 0  && window.location.href !="https://pilotrh.drishinfo.com/recruitment" && <h5 className="text-center mb-1">
                      <FormattedMessage id="test_result" defaultMessage="Your test result has been mailed to your corresponding HR"/>
                    </h5> }
                {this.props.match.params.testId && this.props.match.params.op !=2 && this.props.match.params.op !=3?
                <div className="col-lg-8 col-md-10 col-sm-12 m-auto text-center">
                  {this.renderGetResultButtons2()}
                </div>:<div className="col-lg-8 col-md-10 col-sm-12 m-auto text-center">
                  {this.renderGetResultButtons()}
                </div>
               }
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }

    if (loading)
      return (
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space"> 
            <div className="col-lg-12 col-md-12 p-0 m-0">
              <div className="text-center">
                <div
                  className="spinner-border m-5"
                  role="status"
                  style={{ width: "3rem", height: "3rem" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      );

    if (showResult)
      return (
        <React.Fragment>
          <section className="position-relative top-100" id="mb-space">
            <div className="row d-flex p-0 m-0">
              <div className="col-lg-12 col-md-12 p-0 m-0">
                <div className="col-lg-8 col-md-10 col-sm-12 m-auto m-90">
                  <div id="recruitmentTest" >
                    <h2 className="text-center mb-2"><FormattedMessage id="hr.recruitment.tool.results" defaultMessage="Recruitment Tool Result"/></h2>
                   
              
                    <MotivationResult result={motivationResult} />
                    <WorkCultureResult result={workCultureResult.result} />
                    <div className="row">
                      <div className="col-md-6 text-center mb-2">
                        <h5 class="text-center mb-1">{localStorage.getItem("jobprofilel")} ({localStorage.getItem("userName")})</h5>
                      </div>
                      <div className="col-md-6 text-center mb-2">
                        <h5 class="text-center mb-1">{("0" + (ResultDate.getDate())).slice(-2) +'/'+ ("0" + (ResultDate.getMonth() + 1)).slice(-2)  +'/'+ ResultDate.getFullYear()+' - '+ ResultDate.getHours()+':'+ ResultDate.getMinutes() }</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-10 col-md-10 col-sm-12 mx-auto text-center mb-4">
                  
                    <PrintPdf  type={this.state.testType}  canvasSelector="recruitmentTest"  pdfName="recruitmentTestResult"/>
                    {/* <button
                      onClick={e => this.printResult(e)}
                      className="btn btn-info"
                    >
                      Print result
                    </button> */}
                    {loader2 && (
                      <div className="position-absolute result-loader">
                        <div  className="spinner-border" role="status" style={{ width: "2.5rem", height: "2.5rem" }}>
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </React.Fragment>
      );

    return (
      <React.Fragment>
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0">{showTest}</div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  //console.log(state);
  return {
    motivationResult: state.talent.MotivationResult,
    workCultureResult: state.target.WorkCultureResult,
    resu:state.talent.MotivationsData,
    
  };
};

export default connect(
  mapStateToProps,
  // { fetchMotivation,updateTestConsume }
  { updateTestConsume }
)(Recruitment);
