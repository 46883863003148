import React from "react";
import { FormattedMessage } from "react-intl";

function  returnTableThead(data, testType){
  //console.log('Inside return Thead'+JSON.stringify(data));
  return data.map((thead, key) => {
      if(key == 0){  // return only single column in case of individual 
        return (
          <th key={key} className="text-uppercase text-center grey-bg">{thead.label}</th>
        );       
      }
      // check condition based on individual 
      // if(testType == 'individual'){
      //   if(key == 0){  // return only single column in case of individual 
      //     return (
      //       <th key={key} className="text-uppercase text-center grey-bg">{thead.label}</th>
      //     );       
      //   }
      // } else {
      //   return (
      //     <th key={key} className="text-center">{thead.label}</th>
      //   );       
      // }
  });  
};

function returnCombinedResultTableTr(data, testType){
  //console.log(data);
  //console.log('testType' +testType);  
  return data.map((val, key) => {
    //create thead dynamicaly and set condition 
    if (key === 0) {
      return (
        <tr key={key}>
          {key < 0 ? <td>0</td> : <td className="text-center width33">
            <FormattedMessage id="ind.talent.motivation.personality" defaultMessage="Motivations/Personality"/>
          </td>}
          {returnTableThead(val.thead, testType)}
        </tr>
      );       
    } else {
      // else create rows dynamically
      //console.log('Column lable => '+val.label+' value  '+val.column[0].value);
      if(key <= 3){  // return only single column in case of individual 
        return (
          <tr key={key}>
            <td className="text-center grey-bg">{val.label}</td>
            <td className="bg-info text-white  text-center">{val.column[0].value}</td>
          </tr>
        );     
      }
      // check condition based on individual       
      // if(testType == 'individual'){
      //   if(key <= 3){  // return only single column in case of individual 
      //     return (
      //       <tr key={key}>
      //         <td className="text-center grey-bg">{val.label}</td>
      //         <td className="bg-info text-white  text-center">{val.column[0].value}</td>
      //       </tr>
      //     );     
      //   }
      // } else {
      //   return (
      //     <tr key={key}>
      //     <td className="text-center">{val.label}</td>
      //       <td className="text-center">
      //       {val.column[0].value}
      //       </td>
      //       <td className="text-center">
      //       {val.column[1].value}
      //       </td>
      //       <td className="text-center">
      //       {val.column[2].value}
      //       </td>
      //       <td className="text-center">
      //       {val.column[3].value}
      //       </td>                                                
      //     </tr>
      //   );     
      // }   
    }
  });  
 
}

function CombinedResult(props) {
  let { result, testType } = props;  
  //console.log('Inside CombinedResult'+props.result+' testType '+props.testType);
  return (
    <div className="mt-4 mx-auto p-0">
      <h4 className="text-center mb-1 mt-5">
      <FormattedMessage id="ind.talent.your.best.talent.title" defaultMessage="Your best talents"/>
      </h4>
      <div className="card border-0">
          <table border="1px solid red">
          <tbody>
          {returnCombinedResultTableTr(result, testType)}
          </tbody>
          </table>
        </div>
    </div>
  );
}

export default CombinedResult;
