import React, {  } from "react";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";

const CustomerRights = props => {
    //console.log('CustomerRights => '+props.page);
    var userType = localStorage.getItem("role");
    //console.log('userType ...'+userType);
    // set test guidlines based on user type for step 2
    var guidelines  = <FormattedHTMLMessage  id="your_rights_desc_p2_ind" defaultMessage="<p>You can take as much time as you want to answer the questionnaire.</p><p>Just one piece of advice: remain true to yourself and answer as precisely as you can.</p>"/>     
    if(userType === 'Hr'){
        guidelines  = <FormattedHTMLMessage  id="your_rights_desc_p2_hr" defaultMessage="<p>Just one piece of advice:</p><p>Stay true to yourself, and check the answer that best relates to it.</p>"/>
    }
    return (
        <React.Fragment>
        {props.page === 0 && (
            <div className="card">
                <div className="card-header bg-info text-white font-weight-bold">
                    <FormattedMessage
                    id="your_rights_title"
                    defaultMessage="YOUR RIGHTS (French law n° 78-17, 6th January 1978 enforced by the French National Commission for Software & Freedom)"
                    />
                </div>
                <div className="p-3 small customer-rights">
                    <FormattedHTMLMessage id="your_rights_desc_p1"   defaultMessage='<p className="text-muted">PILOT RH Solutions is a French consulting firm founded in 1999 providing digital HR assessment tools for individuals, HR Professionals and enterprises. </p><p className="text-muted">Its solutions are used by large groups as Air France KLM, SAFRAN Snecma, Intermarché and many more of its franchises. </p><p className="text-muted">They provide various online quizzes/tests to assess the candidate’s ability, personality, business and entrepreneurial skills and used for high potentials recruitment. Its digital tools produce intuitive graphs to understand immediately the scope of candidates. </p><p className="text-muted">PILOT RH makes accessible the best and psychology and sociology scientific researches used around the world (C G Jung, E. Schein, R. Sainsaulieu, …). </p><p className="text-muted">It uses intelligent methodology of cross-checking weak signals to highlight the strengths of the candidate and correlation technologies to secure HR decisions. </p>'/>
                </div>
            </div>
        )}
        {props.page === -1 && (
            <div className="card">
            <div className="card-header bg-info text-white font-weight-bold">
                <FormattedMessage id="your_rights_title_2" defaultMessage="We are going to start..."/>                
            </div>
            <div className="p-3 small">
                {guidelines}
            </div>
            </div>
        )}                        
        </React.Fragment>
    );
} 

export default CustomerRights;
