import React from "react";
import { FormattedMessage } from "react-intl";

const renderSelected = (data, order, testType) => {
  let name1 = "name" + order[0];
  let name2 = "name" + order[1];
  let desc1 = "desc" + order[0];
  let desc2 = "desc" + order[1];
  if(testType === 'individual'){        /// check type of test individual or professional
    return (
      <React.Fragment>
        <div className="row mb-2">
          <div className="col-md-3 bg-info text-white d-flex align-items-center font-weight-bold p-3">
            {data[name1]}
          </div>
          <div className="col-md-9 p-0 border px-2">
            <b>{data[name1]} : </b> {data[desc1]}
          </div>
        </div>
      </React.Fragment>
    );
  }else{
    return (
      <React.Fragment>
        <div className="row mb-2">
          <div className="col-md-2 display-3 text-center res-display">N° 1</div>
          <div className="col-md-3 bg-info text-white d-flex align-items-center font-weight-bold p-3">
            {data[name1]}
          </div>
          <div className="col-md-6 p-0 border px-2">
            <b>{data[name1]} : </b> {data[desc1]}
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-md-2 display-4 text-center res-display">n° 2</div>
          <div className="col-md-3 bg-info text-white d-flex align-items-center font-weight-bold p-3">
            {data[name2]}
          </div>
          <div className="col-md-6 p-0 border px-2 text-justify">
            <b>{data[name2]} : </b> {data[desc2]}
          </div>
        </div>
      </React.Fragment>
    );
  }   
};

const renderUnSelected = (data, order2, testType) => {
  if(testType != 'individual'){ /// check type of test individual or professional
    let name1 = "name" + order2;
    let desc1 = "desc" + order2;
    return (
      <React.Fragment>
        <div className="row mb-2">
          <div className="col-md-2" />        
          <div className="col-md-3 grey-bg d-flex align-items-center font-weight-bold p-3">
            {data[name1]}
          </div>
          <div className="col-md-6 p-0 border px-2 text-justify">
            <b>{data[name1]} : </b> {data[desc1]}{" "}
          </div>
        </div>
      </React.Fragment>
    );
  }
};

const renderFooter = (data, order) => {
  //console.log(' ProfessionalResult  '+JSON.stringify(data)+' type '+order);
  let word1 = "name" + order[0];
  let word2 = "name" + order[1];
  return (
    <React.Fragment>
      <div className="alert alert-secondary" role="alert">
        <FormattedMessage id="ind.target.feel.happiest" defaultMessage="I feel happiest working with"/>"<b> {data[word1]} </b>"  <FormattedMessage id="and" defaultMessage="and"/> "<b> {data[word2]} </b>" 
      </div>
    </React.Fragment>
  );
};

function WorkAtmosphereResult(props) {
  return (
    // <div className="col-lg-10 col-md-10 col-sm-12 m-auto">
    <div className="m-auto">
        {props.testType === 'individual' && ( /// check type of test individual
          <h4 className="text-center mb-1 mt-5">
            <FormattedMessage id="ind.target.your.work.atmosphere.result.title" defaultMessage="Your work atmosphere"/>
          </h4>
        )}
        {props.testType !== 'individual' && ( /// check type of test professional                
          <h4 className="text-center mb-1 mt-5">
            <FormattedMessage id="ind.target.work.atmosphere.result.title" defaultMessage="Work atmosphere"/>            
          </h4>
        )}
      <div className="card">
        {props.testType !== 'individual' && ( /// check type of test individual or professional
          <div className="card-header">
            <FormattedMessage id="ind.target.two.preffered.atmosphere" defaultMessage="MY TWO PREFERED WORK ATMOSPHERES"/>            
            
          </div>
        )}
        <div className="card-body">
          {renderSelected(props.result.top, props.result.order, props.testType)}
          {renderUnSelected(props.result.bottom, props.result.order2, props.testType)}
        </div>
        {props.testType !== 'individual' && ( /// check type of test individual or professional
          <div className="card-footer text-muted text-center">
            {renderFooter(props.result.top, props.result.order)}
          </div>
        )}        
      </div>
    </div>
  );
}

export default WorkAtmosphereResult;
