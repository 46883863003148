import React from "react";
import { FormattedMessage } from "react-intl";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  PieChart, Pie, Sector, 
  Label,
  ResponsiveContainer
} from "recharts";



function renderCharts(score) {
  const COLORS = ["#17a2b8","#cfcfcf"];    
  //console.log('CalcualateScore  => '+CalcualateScore.score);  
  let answer;
  if (score < 3) answer = <FormattedMessage id="ind.talent.communication.difficulty" defaultMessage="With difficulty"/>
  if (score>= 3 && score < 6) answer = <FormattedMessage id="ind.talent.communication.easly" defaultMessage="With easily"/>
  if (score >= 6) answer = <FormattedMessage id="ind.talent.communication.greatease" defaultMessage="With great ease"/>

  const AVERAGEDATA = Math.round((score/7) * 100); //calcualte the % 
  //var resultData = [{name: "Your Score", score: score},{name: "Out Of", score: 7}];  // generate array count set total count out of 7   
  var resultData = [{"name": "Your Score","score": AVERAGEDATA},{ "name": "Out Of", "score": (100-AVERAGEDATA)}];
  //console.log('Score => '+score);  
  //console.log(AVERAGEDATA);
  // const data01 = [
  //   { name: 'Your Score', score: AVERAGEDATA }
  // ];
  // const data02 = [
  //   { name: 'Out Of', score: AVERAGEDATA },
  // ];

  return (
    <div id="result-chart" className="m-auto" style={{ width: '100%', height: 300 }}>
    <h5 className="text-center pt-3"> <FormattedMessage id="your.score" defaultMessage="You score:"/> {score}/7</h5>
    {answer && (
      <p className="text-center mb-0">
          <FormattedMessage id="ind.talent.communicate.my.feelings" defaultMessage="I communicate my feelings"/> <br/>"<b>{answer}</b>"
      </p>
    )}           
    <ResponsiveContainer>
      <PieChart>
          <Pie
              data={resultData}
              dataKey="score"
              labelLine={false}
              innerRadius={60}
              outerRadius={90}
              fill="#17a2b8"
              textAnchor="end"
            >
            {resultData.map((entry, index) => (
              <Cell key={index} fill={COLORS[index % COLORS.length]} />
            ))}
        <Label width={80} position="center">                  
          {AVERAGEDATA.toFixed(2)} %
        </Label>
        </Pie>
        <Tooltip />                
      </PieChart>
      </ResponsiveContainer>                    
    </div>
  );
}

function renderStrongPointsLabels(ansData, labels) {
  //console.log(ansData.detail);
  //console.log(Object.values(ansData.detail)[0]);
  let postiveData = [];  
  let inc = 1;
  if (labels) {
    //create array =ve and -ve order wise to show it seprate -2 
    labels.map((label, index) => {
      ansData.detail.map((value, key) => {
          //console.log('Int '+inc+'  index '+index+' '+label+' val '+value["question_" + (index+1)]);
          if(value["question_" + (index+1)] >0){
            //console.log('+ve'+inc);
            postiveData.push({'label': label, 'value' : 1});
            //fintalArray[index] = [{'label': label, 'valuie' : 1}];
          }
      });
    });
    // console.log(' + '+postiveData);
    // console.log(Object.values(postiveData)[0]);    
    // console.log('- '+negativeData);
    // console.log(Object.values(negativeData)[0]);        

    return postiveData.map((data, index) => {
        return (
          <React.Fragment key={index}>
              <span key={index}>
                  <li className="li-style" key={index}>
                     {data.label}
                  </li>
              </span>
          </React.Fragment>
        );
      }); 

    //let inc = 1; // old code done my manpreet
    // if (labels) {
    //   return labels.map((label, index) => {
    //     return (
    //       <React.Fragment key={index}>
    //         {ansData.detail.map((value, key) => {
    //           // var answer  =  'answer'+[inc];
    //           //var ques    = 'question'+inc
    //           //console.log('Int '+inc+'  key '+key+' '+label+' val '+value["question_" + inc]);
    //           return (
    //             <span key={key}>
    //               {value["question_" + inc] === 1 && (
    //                 <li className="li-style" key={key}>
    //                   <span>+</span>
    //                   {label}{" "}
    //                 </li>
    //               )}
    //               {value["question_" + inc] === 0 && (
    //                 <li className="li-style" key={key}>
    //                   <span>-</span>
    //                   {label}
    //                 </li>
    //               )}
    //             </span>
    //           );
    //         })}
    //         <div className="d-none">{(inc = inc + 1)}</div>
    //       </React.Fragment>
    //     );
    //   });
    // }
  }  
}


function renderAreaToDevelopLabels(ansData, labels) {
  //console.log(ansData.detail);
  //console.log(Object.values(ansData.detail)[0]);
  let negativeData = [];  
  let inc = 1;
  if (labels) {
    //create array =ve and -ve order wise to show it seprate -2 
    labels.map((label, index) => {
      ansData.detail.map((value, key) => {
          //console.log('Int '+inc+'  index '+index+' '+label+' val '+value["question_" + (index+1)]);
          if(value["question_" + (index+1)] >0){
            
          }else{
            //console.log('-ve'+inc);
            negativeData.push({'label': label, 'value' : 0});
            //fintalArray[index] = [{'label': label, 'valuie' : 0}];
          }
      });
    });

    return negativeData.map((data, index) => {
        return (
          <React.Fragment key={index}>
              <span key={index}>
                  <li className="li-style" key={index}>
                    {data.label}
                  </li>
              </span>
          </React.Fragment>
        );
      });            
    }
}

function CommunicationResult(props) {
  let labels = props.label;
  let ansData = props.result;
  //console.log(ansData);
  //console.log('Type => '+props.testType);
  // let answer;
  // if (ansData.sum_count < 3) answer = "With difficulty";
  // if (ansData.sum_count >= 3 && ansData.sum_count < 6) answer = "With easily";
  // if (ansData.sum_count >= 6) answer = "With great ease";
  // let answer1 = ansData.sum_count < 3 ? "With difficulty" : "";
  //   let answer2 =
  //     ansData.sum_count === "3" ||
  //     ansData.sum_count === "4" ||
  //     ansData.sum_count === "5"
  //       ? "With easily"
  //       : "";
  //   let answer3 =
  //     ansData.sum_count === "6" ||
  //     ansData.sum_count === "7"
  //       ? "With great ease"
  //       : "";

  return (
    <div className="mt-4 mx-auto p-0">
        {props.testType === 'individual' && ( /// check type of test individual
          <h4 className="text-center mb-1 mt-5">
            <FormattedMessage id="ind.talent.your.communication.title" defaultMessage="Your communication"/>
          </h4>
        )}
        {props.testType != 'individual' && ( /// check type of test professional                
          <h4 className="text-center mb-1 mt-5">
            <FormattedMessage id="ind.talent.communication.title" defaultMessage="Communication"/>
          </h4>
        )}
      <div className="card">
        <div className="row align-items-center">
          <div className="col-md-4">{renderCharts(ansData.sum_count)}</div>
          <div className="col-md-8">
            <h5>
            <FormattedMessage id="ind.talent.communicate.stribg.point" defaultMessage="Your strong points"/> (+)
            </h5>
            <ul className="list-unstyled pl-2">
              {renderStrongPointsLabels(ansData, labels)}
            </ul>
            <h5>
            <FormattedMessage id="ind.talent.communicate.ared.develop" defaultMessage="Your area to develop"/> (-)
            </h5>
            <ul className="list-unstyled pl-2">
              {renderAreaToDevelopLabels(ansData, labels)}
            </ul>            
          </div>
        </div>
        <div className="row">
          <div className="col-lg-10 col-md-10 col-sm-12 mt-4 pt-4 mx-auto text-justify">
            <p>
            <FormattedMessage id="ind.talent.your.communication.exp" defaultMessage="Emotions leave a strong mark on the personality : fear, anger, sadness, joy, ... Often a taboo topic, feelings are not always well understood. The ability to manage one's feelings and those of others increases communication and persuasion skills.  Seven skills are used to assess the Emotional Quotient (E.Q.)"/>
            </p>
          </div>  
        </div>
        {/*<div className="row">
          <div className="col-md-4" />
          <div className="col-md-8">
            {answer && (
              <p>
                I communicate my feelings "<b>{answer}</b>"
              </p>
            )}
             {answer2 && (
          <p>
            I communicate my feelings "<b>{answer2}</b>"
          </p>
        )}
        {answer3 && (
          <p>
            I communicate my feelings "<b>{answer3}</b>"
          </p>
        )} 
          </div>
        </div>*/}
      </div>

      <div className="d-none" />
    </div>
  );
}

export default CommunicationResult;
