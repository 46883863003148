import axios from 'axios';
import $ from "jquery";
import { ROOT_URL } from "../helper/Config";
const API_BASE_URL=""

class DeleteService {


    delete(token,id){
        return axios.get(`${ROOT_URL}/hr-candidate-delete/${id}`,{  headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`
                }
        });
    };

    deletejobprofile(token,hrid,text){
        return axios.get(`${ROOT_URL}/hr-job-profile-delete/${hrid}/${text}`,{  headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });
};
};

export default new DeleteService();
