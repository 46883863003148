import {
  SIGN_IN,
  SIGN_OUT,
  SIGN_ERROR,
  CLEAR_MESSAGE,
  REGISTER,
  REGISTER_ERROR,
  CONTACT_US,
  CLEAR_CONT_MESSAGE,
  NEWSLETTER,
  CONTACT_HR,
  CLEAR_CONT_HR_MSG,
  CONTACT_COACH,
  CLEAR_CONT_COACH_MSG,
  CLEAR_SHARELINK_MESSAGE,
  SHARE_LINK,
  PROFILE_MATCHING,
  CLEAR_PROFILE_MATCHING_MESSAGE,
} from "../actions";
import { AUTH_ERROR } from "../actions/Happiness";
import { AUTH_ERROR_TALENT } from "../actions/Talent";

const initialState = {
  //UserData: {},
  isSignedIn: null,
  loginError: null,
  signupError: null,
  AuthError: null,
  successMessage: null,
  contactUsMessage: null,
  contactUsError: null,
  newsletterMessage: null,
  newsletterError: null,  
  contactHrMessage: null,
  contactHrError: null,    
  contactCoachMessage: null,
  contactCoachError: null,
  shareLinkError: null,
  shareLinkMessage :null,
  profileMatchingError: null,
  profileMatchingMessage :null,  
};

export default function(state = initialState, action) {
  //const userData = {};
  // console.log('Inside authReducer '+JSON.stringify(action.payload));
  switch (action.type) {
    case SIGN_IN:
      return { ...state, isSignedIn: true };
    case REGISTER:
      return { ...state, successMessage: action.payload };
    case REGISTER_ERROR:
        return { ...state, signupError: action.payload };      
    case SIGN_OUT:
      return { ...state, isSignedIn: false };
    case SIGN_ERROR:
      return { ...state, loginError: action.payload };
    case AUTH_ERROR:
      return { ...state, AuthError: action.payload, isSignedIn: false };
    case AUTH_ERROR_TALENT:
      return { ...state, AuthError: action.payload, isSignedIn: false };
    case CLEAR_MESSAGE:
      return { ...state, loginError: null, successMessage: null };
    case CONTACT_US:
        return { ...state, contactUsMessage: action.payload };
    case CLEAR_CONT_MESSAGE:
          return { ...state, contactUsError: null, contactUsMessage: null };        
    case NEWSLETTER:
          return { ...state, newsletterMessage: action.payload };
    case CONTACT_HR:
          return { ...state, contactHrMessage: action.payload };
    case CLEAR_CONT_HR_MSG:
          return { ...state, contactHrError: null, contactHrMessage: null };                  
    case CONTACT_COACH:
          return { ...state, contactCoachMessage: action.payload };
    case CLEAR_CONT_COACH_MSG:
          return { ...state, contactCoachError: null, contactCoachMessage: null };
    case SHARE_LINK:
          return { ...state, shareLinkMessage: action.payload };  
    case CLEAR_SHARELINK_MESSAGE:
          return { ...state, shareLinkError: null, shareLinkError: null };
    case PROFILE_MATCHING:
          return { ...state, profileMatchingMessage: action.payload };  
    case CLEAR_PROFILE_MATCHING_MESSAGE:
          return { ...state, profileMatchingError: null, profileMatchingError: null };          
    default:
      return state;
  }
}
