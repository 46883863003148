import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import Sortable from "react-sortablejs";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer
} from "recharts";
import { PieChart, Pie, Cell } from "recharts";
import CustomerRights from "../../CustomerRights"; // used to show the two steps of customer right before start test
import Testcopyrights from "../../Testcopyrights"; // used to show test copyright text under test

import { fetchMotivation, saveMotivation,getMotivationresult,motivationResult, combineResult } from "../../../actions/Recruitment";
import Hash from "../../../helper/Hashing";


class Resultmotivation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: -2,    // talent motication question start from 0 thats why we set it to -2
      test: 1,
      error: false,
      errorMsg: "",
      loading: true,
      user_id: null,
      language: null,
      result: [],
      ques0: "",
      ques1: "",
      ques2: "",
      ques3: "",
      ques4: "",
      ques5: "",
      question0: [],
      question0Answers: [],
      question1: [],
      question1Answers: [],
      question2: [],
      question2Answers: [],
      question3: [],
      question3Answers: [],
      question4: [],
      question4Answers: [],
      question5: [],
      question5Answers: [],
      persResult: [],
      persOrder: [],
      combineResultData: [],
      data1:[]
    };
  }

  componentDidMount() {
    //const { hr } = this.props.match;
    // if (this.props.isSignedIn) {
      //let language;
      //if (this.props.lang === "en") language = "english";
      //if (this.props.lang === "fr") language = "french";
      // set default values to localstorage
      // localStorage.setItem("jobprofilel", '');
      // var language = (this.props.lang === "en" ? "english": "french");

      //const { hr } = this.props.testId;
      let hrId = null;
      let code = null;
      if (this.props.testId) {
        code = this.props.testId;
        hrId = Hash.decrypt(this.props.testId);
      }
      // if (this.props.hr) {
      //   code = this.props.hr;
      //   hrId = Hash.decrypt(this.props.hr);
      // }

      console.log(this.props.testId);
      //console.log('Response motivation => '+JSON.stringify(this.props)+' -- '+this.props.testId+' -code- '+code+' -hrId- '+hrId);
      let testType = localStorage.getItem('testType');
      // set the test type to state
      this.setState({ testType: testType });

      const token = localStorage.getItem("token");



      // let code=null;
      // if(this.props.testId){
      //   code=this.props.testId;
      // }
  
      // let { page } = this.state;
      // let quesAns = "question" + page + "Answers";
      // //localStorage.getItem("userRole") === "Hr"
      // if (this.state[quesAns].length < 8) {
      //   this.setState({ error: true, errorMsg: "Drag all items" });
      //   setTimeout(
      //     function() {
      //       this.setState({ error: false });
      //     }.bind(this),
      //     5000
      //   );
      // } else {
        this.setState({ error: false, loading: true });
        let language;
        if (this.props.lang === "en") language = "english";
        if (this.props.lang === "fr") language = "french";
        var data = {
          // question1: this.state.question0Answers,
          // question2: this.state.question1Answers,
          // question3: this.state.question2Answers,
          // question4: this.state.question3Answers,
          // question5: this.state.question4Answers,
          // question6: this.state.question5Answers,
          // user_id: localStorage.getItem("userId"),
          job_profile: localStorage.getItem("jobprofilel"),
          language: language,
          testcode:code
        };
        //console.log('submitMotivation data => '+JSON.stringify(this.state));
        console.log(code);
  
        this.props.getMotivationresult(token, data,response => {
          if (response === "success") {
            this.setState({ loading: false });
            this.props.stepUp();
          }
        });
      //   this.props.motivationResult(token,this.state,(data)=>{
      //     this.setState({ data1:data});
         
      //   });
      // console.log(this.state.data1);
      //console.log('Inside  rec Motivation => '+this.props.lang+' language '+language);
      // this.props.fetchMotivation(token, language, hrId, code, resp => {
      //   //console.log('Response ==> '+resp);
      //   switch(resp) {
      //     case 'success':
      //       this.setState({ loading: false });
      //     break;
      //     case 'used':
      //       this.setState({
      //         loading: false,
      //         error: true,
      //         errorMsg: "Test already taken"
      //       });
      //     break;
      //     case 'invalid':
      //       this.setState({
      //         loading: false,
      //         error: true,
      //         errorMsg: "Invalid test link please contact to admin or Job Profile."
      //       });
      //     break;
      //     case 'no test':
      //       this.setState({ loading: false });
      //       this.props.history.push("/buy-test");
      //     break;
      //     case 'error':
      //       this.setState({
      //         loading: false,
      //         error: true,
      //         errorMsg: "Error occur, please try again!!"
      //       });
      //     break;
      //     default:
      //       this.setState({ loading: false });
      //       //this.props.history.push("/buy-test");
      //     }
      // });

      // this.props.motivationResult(token, response => {
      //   //console.log('Inside motivation '+JSON.stringify(response));
      //   this.setState({ loading: false, result: response });
      // });

    
  }

  getCombineResult(){
    //console.log('in combine');

    const token = localStorage.getItem("token");
    var sendData = {'motivation': this.state.result, 'personality': this.state.persResult}
    this.props.combineResult(token, sendData, response => {
      //console.log(response);

      this.setState({  combineResultData: response.data.data });
    });
  }

  returnTableThead(data){
    //let inc = 1;
    //console.log('Inside return Thead'+JSON.stringify(data));
    return data.map((thead, key) => {
        return (
            <th key={key}>{thead.label}</th>
        );
    });
  };

  returnCombinedResultTableTr(motivResult, persResult){
    //let inc = 1;
    //console.log('motivResult => '+JSON.stringify(motivResult));
    //console.log('persResult => '+JSON.stringify(persResult.result));
  //   var data = [
  //     {
  //         id : "001",
  //         label : "Motivating Factors \ Personalities",
  //         thead : [
  //           {
  //             label : "Manager Organizer",
  //           },
  //           {
  //             label : "Innovator Inventor",
  //           },
  //           {
  //             label : "Doer",
  //           },
  //           {
  //             label : "Communicator",
  //           }
  //       ]
  //     },
  //     {
  //         id : "002",
  //         label : "Expertise",
  //         column : [
  //           {
  //             value : "1_1",
  //           },
  //           {
  //             value : "1_2",
  //           },
  //           {
  //             value : "1_3",
  //           },
  //           {
  //             value : "1_4",
  //           }
  //       ]
  //     },
  //     {
  //         id : "003",
  //         label : "Harmonious Lifestyle",
  //         column : [
  //           {
  //             value : "2_1",
  //           },
  //           {
  //             value : "2_2",
  //           },
  //           {
  //             value : "2_3",
  //           },
  //           {
  //             value : "2_4",
  //           }
  //         ]
  //     },
  //     {
  //       id : "004",
  //       label : "Secutiry",
  //       column : [
  //         {
  //           value : "3_1",
  //         },
  //         {
  //           value : "3_2",
  //         },
  //         {
  //           value : "3_3",
  //         },
  //         {
  //           value : "3_4",
  //         }
  //       ]
  //     },
  //     {
  //       id : "005",
  //       label : "Enterpnureship",
  //       column : [
  //         {
  //           value : "4_1",
  //         },
  //         {
  //           value : "4_2",
  //         },
  //         {
  //           value : "4_3",
  //         },
  //         {
  //           value : "4_4",
  //         }
  //       ]
  //     },
  //     {
  //       id : "006",
  //       label : "Autonomy",
  //       column : [
  //         {
  //           value : "5_1",
  //         },
  //         {
  //           value : "5_2",
  //         },
  //         {
  //           value : "5_3",
  //         },
  //         {
  //           value : "5_4",
  //         }
  //       ]
  //     },
  //     {
  //       id : "007",
  //       label : "Challenges",
  //       column : [
  //         {
  //           value : "6_1",
  //         },
  //         {
  //           value : "6_2",
  //         },
  //         {
  //           value : "6_3",
  //         },
  //         {
  //           value : "6_4",
  //         }
  //       ]
  //     },
  //     {
  //       id : "008",
  //       label : "Leadership",
  //       column : [
  //         {
  //           value : "7_1",
  //         },
  //         {
  //           value : "7_2",
  //         },
  //         {
  //           value : "7_3",
  //         },
  //         {
  //           value : "7_4",
  //         }
  //       ]
  //     },
  //     {
  //       id : "009",
  //       label : "Services to other",
  //       column : [
  //         {
  //           value : "8_1",
  //         },
  //         {
  //           value : "8_2",
  //         },
  //         {
  //           value : "8_3",
  //         },
  //         {
  //           value : "8_4",
  //         }
  //       ]
  //     }
  // ];

    return this.state.combineResultData.map((val, key) => {
      //create thead dynamicaly and set condition
      if (key == 0) {

        return (
          <tr key={key}>
            {key < 0 ? <td>0</td> : <td>Motavating Factors \ Personalities</td>}
            {this.returnTableThead(val.thead)}
          </tr>
        );
      } else {
        // else create rows dynamically
        //console.log('Combined Result '+val.column);
        return (
          <tr key={key}>
          <td>{val.label}</td>
            <td>
            {val.column[0].value}
            </td>
            <td>
            {val.column[1].value}
            </td>
            <td>
            {val.column[2].value}
            </td>
            <td>
            {val.column[3].value}
            </td>
          </tr>
        );
      }
    });
    // return motivResult.map((val, key) => {
    //   return (
    //           <tr>
    //           {key < 0 ? <td>0</td> : <td>{val.label}</td>}
    //             <td>
    //                {(inc = key)}
    //             </td>
    //             <td>
    //                {(inc = key)}
    //             </td>
    //             <td>
    //                {(inc = key)}
    //             </td>
    //             <td>
    //                {(inc = key)}
    //             </td>
    //           </tr>
    //   );
    // });
  }

  renderCombineResult(motivResult, persResult){
    //console.log('persResult => '+persResult);
    let inc = 1;
    let combinedResultData = '';
      return (
        <React.Fragment key={(inc = inc + 1)}>
          <div className="card border-0">
            <table border="1px solid red">
            <tbody>
            {this.returnCombinedResultTableTr(motivResult, persResult)}
            </tbody>
            </table>
            {this.renderNotSelectedData(motivResult)}
          </div>
        </React.Fragment>
      );
  }

   renderResult(data) {

    var background = { color: "white" };
    if (data.result) {
      let firstAns = data.firstQuesAns;
    let thirdAns = data.thirdQuesAns;
    let inc = 1;
      return data.result.selected.map((labels, index) => {
        return (
          <React.Fragment key={(inc = inc + 1)}>
            <div className="card border-0">
              {firstAns === thirdAns && (
                <div className="row border">
                  <div className="col-sm col-md-3 bg-primary text-white d-flex align-items-center font-weight-bold">
                    {labels["name" + firstAns]}
                  </div>
                  <div className="col-md-9 p-0 border-0">
                    <div className="px-2 pt-2 font-weight-bold">
                      {labels["label" + firstAns]}
                    </div>
                    <div className="px-2 pb-2">{labels["desc" + firstAns]}</div>
                  </div>
                </div>
              )}
              {firstAns !== thirdAns && (
                <div className="row border">
                  <div className="col-sm col-md-3 bg-primary text-white d-flex align-items-center font-weight-bold">
                    {labels["name" + firstAns]}
                  </div>
                  <div className="col-md-9 p-0 border-0">
                    <div className="px-2 pt-2 font-weight-bold">
                      {labels["label" + firstAns]}
                    </div>
                    <div className="px-2 pb-2">{labels["desc" + firstAns]}</div>
                  </div>
                </div>
              )}
              {firstAns !== thirdAns && (
                <div className="row border">
                  <div className="col-sm col-md-3 bg-primary text-white d-flex align-items-center font-weight-bold">
                    {labels["name" + thirdAns]}
                  </div>
                  <div className="col-md-9 p-0 border-0">
                    <div className="px-2 pt-2 font-weight-bold">
                      {labels["label" + thirdAns]}
                    </div>
                    <div className="px-2 pb-2">{labels["desc" + thirdAns]}</div>
                  </div>
                </div>
              )}

              {this.renderNotSelectedData(data)}
            </div>
            <div className="d-none">{(inc = inc + 1)}</div>
          </React.Fragment>
        );
      });
    }
  }

   renderNotSelectedData(data) {
    let inc = 1;

    if (data.result) {
      return data.result.notSelected.map((labels, index) => {
        return (
          <React.Fragment key={(inc = inc + 1)}>
            <div className="card border-0">
              {data.result.notSelectedValues.map((value, key) => {
                return (
                  <div className="row border" key={(inc = inc + 1)}>
                    <div className="col-sm col-md-3 bg-secondary text-white d-flex align-items-center font-weight-bold">
                      {labels["name" + value]}
                    </div>
                    <div className="col-md-9 p-0 border-0">
                      <div className="px-2 pt-2 font-weight-bold">
                        {labels["label" + value]}
                      </div>
                      <div className="px-2 pb-2">{labels["desc" + value]}</div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="d-none">{(inc = inc + 1)}</div>
          </React.Fragment>
        );
      });
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.Motivation !== prevProps.Motivation) {
      //console.log(this.props.Motivation);
      this.props.Motivation.data.map((elem, index) =>
        this.setState({
          ["question" + index]: elem.options,
          ["ques" + index]: elem.question
        })
      );
    }
  }

  componentWillUnmount() {
    this.setState({
      loading: false
    });
  }

  renderJobProfileOption(page){
    //console.log('renderJobProfileOption => '+page);
    var jobProfileLabel =  <FormattedMessage id="job.profile.lable" defaultMessage="Give name of your job Profile"/>
    if(page === 0){
      return (
        <div className="col-md-12 mx-auto mb-0">
           <div className="jumbotron d-flex p-2">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">{jobProfileLabel}</span>
                </div>
                <input type="text" aria-label="Job Profile" className="form-control" name="jobProfileName" onChange={items => { localStorage.setItem("jobprofilel", items.target.value)}}/>
              </div>
           </div>
        </div>
      );
    }else{
      return false;
    }
  }

  renderQuestions() {
    let { page } = this.state;
    //console.log(this.state);
    // show two stepe candidate rights option before start test
    if(page >= 0){
      var questions = [0, 1, 2, 3, 4, 5];
      return questions.map((value, i) => {
        let ques = "question" + i;
        let quesAns = "question" + i + "Answers";

        const question = this.state[ques].map((val, key) => (
          //const question = Object.entries(this.state[ques]).map(([key, val]) => (
          <li className="list-group-item" key={key} data-id={val}>
            {val}
          </li>
        ));
        const questionAnswers = this.state[quesAns].map((val, key) => (
          <li className="list-group-item item-clr" key={key} data-id={val}>
            {val}
          </li>
        ));
        return (
          <React.Fragment key={value}>
            {page === i && (
              <div className="row p-2 mb-2">
                {localStorage.getItem("userRole") === "Hr"  ? this.renderJobProfileOption(page): ''}
                <div className="col-md-12 mx-auto mb-0">
                  <div className="jumbotron p-2">
                    <p className="lead">{this.state["ques" + i]}</p>
                  </div>
                </div>
                <div className="col-md-5 m-auto">
                  <Sortable
                    options={{
                      animation: 150,
                      sort: false,
                      group: {
                        name: "clone2",
                        pull: true,
                        put: false
                      }
                    }}
                    className="list-group shadow minHeight"
                    onChange={items => {
                      this.setState({ [ques]: items });
                    }}
                    tag="ul"
                  >
                    {question}
                  </Sortable>
                </div>
                <div className="col-md-2 m-auto pr-0 dragdropno">
                  <ul className=" list-group minHeight text-right bg-trans">
                    <li className="list-group-item border-0">n°1</li>
                    <li className="list-group-item border-0">n°2</li>
                    <li className="list-group-item border-0">n°3</li>
                    <li className="list-group-item border-0">n°4</li>
                    <li className="list-group-item border-0">n°5</li>
                    <li className="list-group-item border-0">n°6</li>
                    <li className="list-group-item border-0">n°7</li>
                    <li className="list-group-item border-0">n°8</li>
                  </ul>
                </div>
                <div className="col-md-5 m-auto dragdropno1">
                  <Sortable
                    options={{
                      animation: 150,
                      group: {
                        name: "clone2",
                        pull: true,
                        put: true
                      }
                    }}
                    className="list-group minHeight shadow"
                    onChange={items => {
                      this.setState({ [quesAns]: items });
                    }}
                    tag="ul"
                  >
                    {questionAnswers}
                  </Sortable>
                  {/* <button onClick={e => this.getNewSorted(e)}>Get result</button> */}
                </div>
                <div className="col-md-12 mx-auto mb-0">
                  <div className="jumbotron  mb-0 p-1 mt-2">
                      <p className="mb-0">
                        <FormattedMessage id="ind.talent.motivation.expl" defaultMessage="Please select each icon in the left hand column by a mouse click, and drag it towards the appropriate box  in the right hand column (N°1 is the most important). You can move icons around in the right hand column until they are in the right order."/>
                      </p>
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        );
      });
    } else {
      return (
        <CustomerRights page={page+1} testType="1" />
      );
      //console.log('Inside 2 steps '+page);
    }
  }

  renderButton() {
    let { page } = this.state;
    if (page < 0) {
      return (
        <div className="d-table m-auto">
          <button className="btn btn-info mt-3" onClick={this.nextQuestion}>
            <FormattedMessage id="start" defaultMessage="START"/>
          </button>
        </div>
      );
    }else if (page < 5) {
      return (
        <div className="d-table m-auto">
          <button className="btn btn-info mt-3" onClick={this.nextQuestion}>
          <FormattedMessage id="next" defaultMessage="NEXT"/>
          </button>
        </div>
      );
    } else {
      return (
        <div className="d-table m-auto">
          <button className="btn btn-info" onClick={this.submitMotivation}>
            <FormattedMessage id="submit" defaultMessage="Submit"/>
          </button>
        </div>
      );
    }
  }

  nextQuestion = () => {
    let { page } = this.state;
    // show two stepe candidate rights option before start test
    if(page >= 0){
      //console.log('Check LS'+localStorage.getItem("jobprofilel")+' ==> '+localStorage.getItem("userRole"));
      var jobProfile =  <FormattedMessage id="job.profile.mandatory" defaultMessage="Job profile is mandatory."/>
      var dragAll =  <FormattedMessage id="drag.all.item" defaultMessage="Drag all items"/>
      let quesAns = "question" + page + "Answers";
      // set validation based upton user roles
      if(localStorage.getItem("userRole") === 'Hr'){
        if (localStorage.getItem("jobprofilel") === '') {
          this.setState({ error: true, errorMsg: jobProfile });
          setTimeout(
            function() {
              this.setState({ error: false });
            }.bind(this),
            5000
          );
        }else if (this.state[quesAns].length < 8) {
          this.setState({ error: true, errorMsg: dragAll });
          setTimeout(
            function() {
              this.setState({ error: false });
            }.bind(this),
            5000
          );
        } else {
          this.setState({ page: page + 1, error: false });
        }

      }else{
        if (this.state[quesAns].length < 8) {
          this.setState({ error: true, errorMsg: dragAll });
          setTimeout(
            function() {
              this.setState({ error: false });
            }.bind(this),
            5000
          );
        } else {
          this.setState({ page: page + 1, error: false });
        }
      }
    } else{
      this.setState({ page: page + 1, error: false }); // increatement steps in case till then page set 1
      //console.log('No validate');
    }
  };

  submitMotivation = () => {
    const token = localStorage.getItem("token");
    let code=null;
    if(this.props.testId){
      code=this.props.testId;
    }

    let { page } = this.state;
    let quesAns = "question" + page + "Answers";
    //localStorage.getItem("userRole") === "Hr"
    if (this.state[quesAns].length < 8) {
      this.setState({ error: true, errorMsg: "Drag all items" });
      setTimeout(
        function() {
          this.setState({ error: false });
        }.bind(this),
        5000
      );
    } else {
      this.setState({ error: false, loading: true });
      let language;
      if (this.props.lang === "en") language = "english";
      if (this.props.lang === "fr") language = "french";
      var data = {
        question1: this.state.question0Answers,
        question2: this.state.question1Answers,
        question3: this.state.question2Answers,
        question4: this.state.question3Answers,
        question5: this.state.question4Answers,
        question6: this.state.question5Answers,
        user_id: localStorage.getItem("userId"),
        job_profile: localStorage.getItem("jobprofilel"),
        language: language,
        testcode:code
      };
      //console.log('submitMotivation data => '+JSON.stringify(this.state));
      console.log(code);

      this.props.saveMotivation(token, data,response => {
        if (response === "success") {
          this.setState({ loading: false });
          this.props.stepUp();
        }
      });
    }
  };



  render() {

    if(this.props.resu !=null){
    localStorage.setItem("u",this.props.resu);
    }
    if(this.props.resul !=null){
      localStorage.setItem("u2",this.props.resul);
      }
    let { error, loading, errorMsg, page, result } = this.state;
    //console.log('Pagecount => '+page);
    const getDescription = label => {
      let des = result.filter(elem => {
        return elem.label === label;
      });
      if (des.length > 0) {
        return des[0].desc;
      } else {
        return null;
      }
    };
    const CustomTooltip = ({ active, payload, label }) => {
      if (active) {
        return (
          <div className="custom-tooltip">
            <p className="label">
              <b>{`${label} : ${payload[0].value}`}</b>
            </p>
            <p className="desc">{getDescription(label)}.</p>
          </div>
        );
      }

      return null;
    };

    if (loading)
      return (
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              <div className="text-center">
                <div
                  className="spinner-border m-5"
                  role="status"
                  style={{ width: "3rem", height: "3rem" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    return (
      <React.Fragment>
        {page < 0 && ( // check page and hide the title from first two steps i.e user explanations
          <h2 className="text-center mb-1">RECRUITMENT TOOL</h2>
        )}
        {page >= 0 && ( // check page and hide the title from first two steps i.e user explanations
          <h2 className="text-center mb-1">Motivation</h2>
        )}
        {/* {this.state.testType !='professional' && <h2 className="text-center mb-4">Motivation Test</h2>}
        {this.state.testType =='professional' && <h2 className="text-center mb-4">Coaching Career</h2>} */}
        <div className="col-lg-8 col-md-10 col-sm-12 m-auto p-90">
            {error ? (
                <div className="alert alert-danger col-md-6 text-center mx-auto">
                  <h4 className="alert-heading">
                  <FormattedMessage id="hello" defaultMessage="Hello"/> {localStorage.getItem("userName")}!
                  </h4>
                  {/* {errorMsg} */}
                </div>
            ) : (
              ""// this.renderQuestions()
            )}
            {error ? (
                <div> </div>
            ) : (
              ""// this.renderButton()
            )}
          {/* {this.renderQuestions()}
          {this.renderButton()} */}
          {/* <button onClick={e => this.getCombineResult()}>get combine result</button> */}
        </div>
        {page > 0 && ( // check page and hide the title from first two steps i.e user explanations
          <Testcopyrights />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  //console.log(state);

  return {
    Motivation: state.talent.MotivationData.data,
    Result: state.talent.MotivationResult,
    isSignedIn: state.auth.isSignedIn,
    lang: state.locale.lang,
    resu:state.talent.MotivationsData,
    resul:state.talent.MotivationsDatas,
  };
};

export default connect(
  mapStateToProps,
  { fetchMotivation, saveMotivation,getMotivationresult, motivationResult, combineResult }
)(Resultmotivation);
