// export const ROOT_URL = "http://localhost:81/pilotRH/api"; // local
// export const ROOT_URL = "https://pilotrh.dilonline.in/pilotRH/api"; // dev
export const ROOT_URL = "https://www.pilotrhsolutions.com/pilotRH/api"; // live
export const token = localStorage.getItem("token");

export const HASH_KEY = "Ocolson";
// export const APP_URL  = "http://localhost:3000/";
//  export const APP_URL = "https://pilotRH.drishinfo.com/";
//  export const APP_URL = "https://pilotrh.dilonline.in/";

 export const APP_URL = "https://www.pilotrhsolutions.com/";  // lives

// export const PAYPAL_KEY = "AXnMBudg9zjq15Zi_ZBG_KXPVhMzUKym1fUoQtphObIOnCIP67si5LVsSLXqvdBe8_jviv5ypGDVaeJO"; // test
export const PAYPAL_KEY = "AcWuH6I94ir7xRaItzslnXdxJIjkUeN6dBx4vReuyH5kfj5fxyr9bQWPDp72XRO3xoAetTQcZDqLHjdY"; // live