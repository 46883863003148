import React, { Component } from "react";
import { connect } from "react-redux";
import Footer from "./includes/Footer";
import { Field, reduxForm } from "redux-form";
import { FormattedMessage } from "react-intl";

import $ from "jquery";

class Legals extends Component {
    constructor(props) {
        super(props);
        this.state = {
          //contactUsMessage: null,
          //contactUsError: null,
        };
        window.scrollTo(0, 0); // scroll page to top
    }

    componentDidMount() {
        // let language;
        // if (this.props.lang === "en") language = "english";
        // if (this.props.lang === "fr") language = "french";                
    }     
    
  render() {
    //const { intl } = this.props;

    return (
    <React.Fragment>
      <section className="mb-4 pages text-pages">
        <div className="container">
          <h2 className="h1-responsive font-weight-boldmy-4">
            <FormattedMessage id="legal_title" defaultMessage="Mentions Légales"/>
          </h2>
          <main className='page-single__content text-pages' role='main'>
            <h4 className="my-3"><b>La société PILOT RH SOLUTIONS est une entité commerciale de la société 5W</b></h4>
            <p>SARL au capital de 7.623 euros immatriculée au RCS de Paris sous le numéro 424 009 488 00026<br/>
            dont le siège social est situé 4 rue des Fossés Saint-Jacques 75005 PARIS</p>
            <ul>
            <li>Tél. <b>+ 33 (0)9 83 200 015</b></li>
            <li>Mail. <a href='mailto:contact@pilotrhsolutions.com'>contact@pilotrhsolutions.com</a></li>
            </ul>
          </main>
        </div>
      </section>
      <Footer />      
    </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    isSignedIn: state.auth.isSignedIn,  
    lang: state.locale.lang
  };
};

Legals = connect(
  mapStateToProps,
  {  }
)(Legals);


export default reduxForm({
    form: "contactUsValidation",
    enableReinitialize: true    
  })(Legals);

//export default Legals;

