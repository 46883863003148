import axios from "axios";
import { ROOT_URL } from "../helper/Config";
import $ from "jquery";

export const AUTH_ERROR_TALENT = "auth_error_happiness";
export const MOTIVATION_DATA = "motivation_data";
export const MOTIVATION_RESULT = "motivation_result";
export const PERSONALITY_DATA = "personality_data";
export const PERSONALITY_RESULT = "personality_result";
export const PERSONALITY_RESULT_DATA = "personality_result_data";
export const COMMUNICATION_DATA = "communication_data";
export const COMMUNICATION_RESULT = "communication_result";
export const COMMUNICATION_RESULT_DATA = "communication_result_data";
export const COMBINE_RESULT = "combine_result";
export const MOTIVATIONS_DATA ="motivations_data";
export const MOTIVATIONS_DATAS ="motivations_datas";

export function fetchMotivation(
  token,
  hr = null,
  code = null,
  language,
  callback
) {
  let test_url;

  // if (code != "") {
  //   test_url = `${ROOT_URL}/motivation/${language}/${hr}/${code}`;
  // } else if (hr !== null) {
  //   test_url = `${ROOT_URL}/motivation/${language}/${hr}`;
  // }else{
  //   test_url = `${ROOT_URL}/motivation/${language}`;    
  // }

  if (hr === null) {
    test_url = `${ROOT_URL}/motivation/${language}`;
  } else {
    test_url = `${ROOT_URL}/motivation/${language}/${hr}/${code}`;
  }

  // console.log('Talent motivation url '+test_url+' language '+language+' hr '+hr+' code '+code);
  return dispatch => {
    return axios
      .get(test_url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.data.message) {
          callback(response.data.message);
        } else {
          dispatch({
            type: MOTIVATION_DATA,
            payload: response
          });
          callback("success");
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            localStorage.clear();
            dispatch({
              type: AUTH_ERROR_TALENT,
              payload: "Session expired, please login again"
            });
            callback("Unauthenticate");
          }
        }
        callback("error");
      });
  };
}

export function personalityResult(token, callback) {
 
  return dispatch => {
    return axios
      .get(`${ROOT_URL}/personalityResult`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        callback(response.data);
        
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            localStorage.clear();
            dispatch({
              type: AUTH_ERROR_TALENT,
              payload: "Session expired, please login again"
            });
            //callback("Unauthenticate");
          }
          
        }
        callback("error");
        
      });
  };
}

export function combineResult(token, data, callback) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/combineResult`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        //console.log(response);
        dispatch({
          type: COMBINE_RESULT,
          payload: response.data
        });
        
        callback(response);
      })
      .catch(error => {
        console.log(error);
        
      });
  };
}

export function saveMotivation(token, data, callback) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/motivation`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        //console.log(response);
        dispatch({
          type: MOTIVATION_RESULT,
          payload: response.data.result
        });
        callback(response.data.message);
      })
      .catch(error => {
        console.log(error);
        
      });
  };
}

export function getMotivationResult(token, data, callback) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/motivationTalentResult`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
      localStorage.setItem("sender", response.data.sender)
        dispatch({
          type: MOTIVATION_RESULT,
          payload: response.data.result
        });
        dispatch({
          type: MOTIVATIONS_DATA,
          payload: response.data.username
        });
        dispatch({
          type: MOTIVATIONS_DATAS,
          payload: response.data.username2
        });
     
        callback(response.data.message);
      })
    
     
      .catch(error => {
        console.log(error);
        
      });
  };
}

export function motivationResult(token, callback) {
  return dispatch => {
    return axios
      .get(`${ROOT_URL}/motivationResult`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        //console.log(response);
        callback(response.data);
      })
      .catch(error => {
        console.log(error.response);
      });
  };
}

/*********  PERSONALITY TEST ACTIONS   *********/

export function fetchPersonalityTalent(
  token,
  hr = null,
  code = null,
  language,
  callback
) {
  let test_url;
  
  // if (code != "") {
  //   test_url = `${ROOT_URL}/personality/${language}/${hr}/${code}`;
  // } else if (hr !== null) {
  //   test_url = `${ROOT_URL}/personality/${language}/${hr}`;
  // }else{
  //   test_url = `${ROOT_URL}/personality/${language}`;    
  // }

  if (hr === null) {
    test_url = `${ROOT_URL}/personality/${language}`;
  } else {
    test_url = `${ROOT_URL}/personality/${language}/${hr}/${code}`;
  }

  console.log('Talent personality url '+test_url+' language '+language+' hr '+hr+' code '+code);

  return dispatch => {
    return axios
      .get(test_url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.data.message) {
          callback(response.data.message);
        } else {
          dispatch({
            type: PERSONALITY_DATA,
            payload: response
          });
          //console.log(response);
          callback("success");
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            localStorage.clear();
            dispatch({
              type: AUTH_ERROR_TALENT,
              payload: error.response.data.message
            });
          }
        }
        callback("error");
      });
  };
}

export function savePersonality(token, data, callback) {
  $("#success-message").hide();
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/personality`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch({
          type: PERSONALITY_RESULT,
          payload: response.data
        });
        callback(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };
}


export function getPersonalityResult(token, data, callback) {
  $("#success-message").hide();
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/personalityTalentResult`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch({
          type: PERSONALITY_RESULT,
          payload: response.data
        });
        callback(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };
}




/*********** Communication Test  */
export function fetchCommunicationTest(
  token,
  hr = null,
  code = null,
  language,
  callback
) {
  let test_url;
  // if (code != "") {
  //   test_url = `${ROOT_URL}/communication/${language}/${hr}/${code}`;
  // } else if (hr !== null) {
  //   test_url = `${ROOT_URL}/communication/${language}/${hr}`;
  // }else{
  //   test_url = `${ROOT_URL}/communication/${language}`;    
  // }

  
  if (hr === null) {
    test_url = `${ROOT_URL}/communication/${language}`;
  } else {
    test_url = `${ROOT_URL}/communication/${language}/${hr}/${code}`;
  }

  console.log('Talent communication url '+test_url+' language '+language+' hr '+hr+' code '+code);
  
  return dispatch => {
    return axios
      .get(test_url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.data.message === "success") {
          dispatch({
            type: COMMUNICATION_DATA,
            payload: response
          });
          callback(response.data.message);
        } else {
          callback(response.data.message);
        }

        // dispatch({
        //   type: COMMUNICATION_DATA,
        //   payload: response
        // });
        // callback(response);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            // localStorage.clear();
            dispatch({
              type: AUTH_ERROR_TALENT,
              payload: error.response.data.message
            });
          }
        }
        callback("error");
      });
  };
}

export function saveCommunicationTest(token, data, callback) {
  $("#success-message").hide();
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/communication`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch({
          type: COMMUNICATION_RESULT,
          payload: response.data.detailData
        });
        callback(response);
      })
      .catch(error => {
        console.log(error);
      });
  };
}



export function getCommunicationResult(token, data, callback) {
  $("#success-message").hide();
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/getCommunicationResult`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch({
          type: COMMUNICATION_RESULT,
          payload: response.data.detailData
        });
        callback(response);
      })
      .catch(error => {
        console.log(error);
      });
  };
}
