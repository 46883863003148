import React, { Component } from "react";

import MotivationResLink from "../../talent/MotivationResLink";
import PersonalityResultlink from "../../talent/PersonalityResultlink";
import CommunicationResultlink from "../../talent/CommunicationResultlink";

class TalentHrLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test: 1,
      loading: false,
      showResult: false,
      hr: null
    };
  }
  componentDidMount() {
    const { hr } = this.props;
    if (hr) {
      this.setState({ hr: hr });
    }
  }

  componentWillUnmount() {
    this.setState({ loading: false });
  }

  nextTest() {
    let prevTest = this.state.test;
    if (prevTest === 3) {
      this.props.stepUp2();
    } else {
      prevTest = prevTest + 1;
      this.setState({ test: prevTest });
    }
  }

  render() {
    let { test, hr, loading } = this.state;

    var showTest;
    if (test === 1) {
      showTest = <MotivationResLink stepUp={this.nextTest.bind(this)} hr={hr} history={this.props.history}  testcodes={this.props.testId} />;
    } else if (test === 2) {
      showTest = <PersonalityResultlink stepUp={this.nextTest.bind(this)} hr={hr}  testcodes={this.props.testId} />;
    } else if (test === 3) {
      showTest = <CommunicationResultlink stepUp={this.nextTest.bind(this)} hr={hr}  testcodes={this.props.testId} />;
    } else {
      showTest = "";
    }
    if (loading)
      return (
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              <div className="text-center">
                <div
                  className="spinner-border m-5"
                  role="status"
                  style={{ width: "3rem", height: "3rem" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      );

    return (
      <React.Fragment>
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0">{showTest}</div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default TalentHrLink;
