import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
// import { history } from "../../../../helper/History";
// import $ from "jquery";

//import { Link } from "react-router-dom";
//import html2canvas from "html2canvas";
//import * as jsPDF from "jspdf";

import Hash from "../../../../helper/Hashing";
import TalentHrLink from "./TalentHrLink";
import TargetHrlink from "./TargetHrlink";

import { updateTestConsume } from "../../../../actions";

// ------  TALENT RESULT -------
import MotivationResult from "../../talent/result/MotivationResult";
import PersonalityResult from "../../talent/result/PersonalityResult";
import CommunicationResult from "../../talent/result/CommunicationResult";
import CombinedResult from "../../talent/result//CombinedResult";

// ------  TARGET RESULT -------
import ProfessionalResult from "../../target/result/ProfessionalResult";
import WorkCultureResult from "../../target/result/WorkCultureResult";
import WorkGroupResult from "../../target/result/WorkGroupResult";
import PublicSectorResult from "../../target/result/PublicSectorResult";
import WorkAtmosphereResult from "../../target/result/WorkAtmosphereResult";
import ManagementResult from "../../target/result/ManagementResult";
import WorkEnvironmentResult from "../../target/result/WorkEnvironmentResult";
import Testcopyrights from "../../../Testcopyrights"; // used to show test copyright text under test 
import DoAlert from "../../../DoAlert"; // used to render the do another test button
import PrintPdf from "../../../PrintPdf"; // used to print resutl PDF


class CoachingCareerResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test: 1,
      loading: false,
      showResult: false,
      hr: null,
      loader2: false,
      testcodess:null,

    };
    if(this.props.match.params.id !== undefined && this.props.match.params.id !== ''){
      localStorage.setItem("sharedTestId", this.props.match.params.id);
      this.setState({testcodess:this.props.match.params.id})
    }else{
      localStorage.setItem("sharedTestId", null);      
    }
  }

  componentDidMount() {
    const { hr, testId } = this.props.match.params;
    if (hr) {
      this.setState({ hr: hr });
    }
    // set testId to localstorage to make check not deduct a test at the end 
 
    if(this.props.match.params.id !== undefined && this.props.match.params.id !== ''){
      // localStorage.setItem("sharedTestId", this.props.match.params.id);
      this.setState({testcodess:this.props.match.params.id})
    }else{
      localStorage.setItem("sharedTestId", null);      
    }
    //console.log('CoachingCareer '+JSON.stringify(this.props.match.params.testId));
    let testType = localStorage.getItem('testType');
    //console.log('Response => '+JSON.stringify(this.props.location)+' type '+testType);    
    // set the test type to state 
    this.setState({ testType: testType, testId: testId });
    //console.log('CoachingCareer =>'+JSON.stringify(this.props.match.params)+' testId '+testId);       
  }

  nextTest() {
    let prevTest = this.state.test;
    prevTest = prevTest + 1;
    //console.log(prevTest);
    //console.log("aassd");

    this.setState({ test: prevTest });
  }

  getResult = () => {
    this.setState({ loading: true });
    const { hr } = this.state;
    let user_id = 0;
    let client_id = 0;
    let code = 0;
    if (hr) {
      user_id = Hash.decrypt(hr);
      client_id = localStorage.getItem("userId");
      code = hr;
    }
    else if(localStorage.getItem("sender")){
      user_id=localStorage.getItem("sender");
    }
    else {
      user_id = localStorage.getItem("userId");
    }
    // get shared link from the localstorage
    let sharedTest = localStorage.getItem('sharedTestId'); // get testid from    

    const token = localStorage.getItem("token");
    const submitData = {
      user_id: user_id,
      client_id: client_id,
      test: "Career",
      code: code,
      shared_test:sharedTest
    };
    this.props.updateTestConsume(token, submitData, resp => {
      if (resp === "success") {
        this.setState({
          showResult: true,
          loading: false
        });
      }
    });
  };

  
  // printResult(){
  //   this.setState({ loader2: true });
  //   let CurrentDate   =  new Date();
  //   let copyRightDate = CurrentDate.getFullYear();

  //   var pilotRhLogo = new Image();
  //   pilotRhLogo.src = 'assets/images/logo.png';
    
  //   var PDF_Heighti = document.querySelector('#coachingCareerTest').offsetWidth;
  //   var HTML_Width = $("#coachingCareerTest").width();
  //   //console.log(HTML_Width);
  //   //console.log(HTML_Height);
  //   var HTML_Height = $("#coachingCareerTest").height();
  //   //console.log(HTML_Height);
  //   var top_left_margin = 15;
  //   var PDF_Width = HTML_Width+(top_left_margin*2);
  //   var PDF_Height = HTML_Height+(top_left_margin*2);
  //   //var PDF_Height = 2000;
  //   //console.log(PDF_Height);
  //   var canvas_image_width = HTML_Width;
  //   var canvas_image_height = HTML_Height;
    
  //   var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;
  //   //console.log(totalPDFPages);
    
  //   //html2canvas($("#franchiseTest")[0],{allowTaint:true}).then(function(canvas) {
  //   html2canvas($("#coachingCareerTest")[0],{allowTaint:true}).then((canvas) => {
  //     canvas.getContext('2d');
  //     //console.log(canvas.height+" "+canvas.width);
  //     var imgData = canvas.toDataURL("image/jpeg", 1.0);
  //     var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
  //     pdf.addImage(pilotRhLogo, 'PNG', 330, 20);
  //     pdf.addImage(imgData, 'JPG', 15, 50,canvas_image_width,canvas_image_height);
  //     for (var i = 1; i <= totalPDFPages; i++) {
  //       pdf.addPage(PDF_Width, PDF_Height);
  //       pdf.addImage(imgData, 'JPG', top_left_margin, -PDF_Heighti+(top_left_margin),canvas_image_width,canvas_image_height);
  //     }
  //     pdf.setFontSize(16);
  //     pdf.text(350, (PDF_Height-20),'Copyright '+ CurrentDate.getFullYear() +' All rights Reserved 5w Pilot Rh. ('+ CurrentDate.getDate() +'/'+ CurrentDate.getMonth() +'/'+ CurrentDate.getFullYear()+')');
  //     //pdf.text(107,68,currentDate);
  //     pdf.save("CoachingCareerTestResult.pdf");
  //     this.setState({ loader2: false });                      
  //   });
  // }
// working function 
  // printResult(){
  //   this.setState({ loader2: true });
  //   let CurrentDate   =  new Date();
  //   let copyRightDate = CurrentDate.getFullYear();

  //   var pilotRhLogo = new Image();
  //   pilotRhLogo.src = 'assets/images/logo.png';
    
  //   var PDF_Heighti = document.querySelector('#coachingCareerTest').offsetWidth;
  //   //var HTML_Width = $("#coachingCareerTest").width();
  //   var HTML_Width = 800;
  //   //console.log('HTML_Width =>'+HTML_Width);
  //   var HTML_Height = $("#coachingCareerTest").height();
  //   //console.log('HTML_Height =>'+HTML_Height);    
  //   //console.log(HTML_Height);
  //   var top_left_margin = 15;
  //   var PDF_Width = HTML_Width+(top_left_margin*2);
  //   //var PDF_Height = 2000;
  //   var PDF_Height = HTML_Height+(top_left_margin*2);    
  //   //console.log('PDF_HxW'+PDF_Height+' x '+PDF_Width);
  //   var canvas_image_width = HTML_Width;
  //   var canvas_image_height = HTML_Height;
    
  //   var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;
  //   //console.log('totalPDFPages =>'+totalPDFPages);
    
  //   //html2canvas($("#coachingCareerTest")[0],{allowTaint:true}).then(function(canvas) {
  //   html2canvas($("#coachingCareerTest")[0],{allowTaint:true}).then((canvas) => {
  //     canvas.getContext('2d');
  //     //console.log(canvas.height+" "+canvas.width);
  //     var imgData = canvas.toDataURL("image/jpeg", 1.0);
  //     var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
  //     pdf.addImage(pilotRhLogo, 'PNG', 330, 20);
  //     pdf.addImage(imgData, 'JPG', 10, 80);
  //     for (var i = 1; i <= totalPDFPages; i++) {
  //       pdf.addPage(PDF_Width, PDF_Height);
  //       pdf.addImage(imgData, 'JPG', top_left_margin, -PDF_Heighti+(top_left_margin));
  //     }
  //     // pdf.addImage(imgData, 'JPG', 15, 20,canvas_image_width,canvas_image_height);
  //     // for (var i = 1; i <= totalPDFPages; i++) {
  //     //   pdf.addPage(PDF_Width, PDF_Height);
  //     //   pdf.addImage(imgData, 'JPG', top_left_margin, -PDF_Heighti+(top_left_margin),canvas_image_width,canvas_image_height);
  //     // }
  //     pdf.setFontSize(12);
  //     pdf.setTextColor(108, 117, 125);
  //     pdf.text(160, (PDF_Height-30),'© Copyright 5W PILOT RH SOLUTIONS '+ CurrentDate.getFullYear() +' – All right reserved – www.pilotrhsolutions.com.');
  //     pdf.setTextColor(33, 37, 41);
  //     pdf.text(310, (PDF_Height-10), 'DATE OF TEST '+("0" + (CurrentDate.getDate() + 1)).slice(-2) +'/'+ ("0" + (CurrentDate.getMonth() + 1)).slice(-2)  +'/'+ CurrentDate.getFullYear());
  //     pdf.save("coachingTestResult.pdf");
  //     this.setState({ loader2: false });                      
  //   });
  // }

  renderAnotherTestButtons() {
    //console.log('Inisde renderAnotherTestButtons '+this.state.testType);
    const buttonName =  <FormattedMessage id="btn.do.another.test" defaultMessage="DO ANOTHER TEST"/>                        
    return (
      <DoAlert 
        type={this.state.testType} 
        buttonName={buttonName}
        className="btn btn-warning mt-3 mx-3"
        canvasSelector="coachingCareerTest"
        pdfName="coachingTestResult"
       />
    );
  }
  
  renderContactCoachButtons() {
    //console.log('Inisde renderContactCoachButtons '+this.state.testType);
    const buttonName =  <FormattedMessage id="btn.goto.home" defaultMessage="BACK TO HOME"/>                                    
    return (
      <DoAlert 
        type={this.state.testType} 
        buttonName={buttonName}
        className="btn btn-info mt-3 w-50"
        target="_blank"
        href="/"
        canvasSelector="coachingCareerTest"
        pdfName="coachingTestResult"        
      />
    );
  }

  render() {
    let { test, showResult, hr, loading, loader2 } = this.state;
 
    let {
      // talent result
    
  
      motivationResult,
      personalityResult,
      communicationResult,
      communicationTest,
      combinedResults,

      // target result
      professionalResult,
      publicSectorResult,
      workCultureResult,
      workGroupResult,
      workAtmosphereResult,
      managementResult,
      workEnvResult
    } = this.props;
    var showTest;

    if (test === 1) {
      showTest = <TalentHrLink stepUp2={this.nextTest.bind(this)} hr={hr}  history={this.props.history} testId={this.state.testcodess} />;
    } else if (test === 2) {
      showTest = <TargetHrlink stepUp2={this.nextTest.bind(this)} hr={hr} history={this.props.history} testId={ this.state.testcodess}/>;
    } else {
      showTest = (
        <React.Fragment>
          <section className="position-relative top-100">
            <div className="row d-flex p-0 m-0" id="mb-space">
              <div className="col-lg-12 col-md-12 p-0 m-0">
                <h2 className="text-center mb-1">
                  <FormattedMessage id="coaching.tool.results" defaultMessage="COACHING TOOL RESULTS"/>                  
                </h2>
                <div className="col-lg-8 col-md-10 col-sm-12 m-auto text-center">
                  <button className="btn btn-info" onClick={this.getResult}>
                    <FormattedMessage id="get_result" defaultMessage="Get Result"/>
                  </button>
                </div>
                <Testcopyrights />                
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }

    if (loading)
      return (
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              <div className="text-center">
                <div
                  className="spinner-border m-5"
                  role="status"
                  style={{ width: "3rem", height: "3rem" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      );

    if (showResult)
      return (
        <React.Fragment>
          <section className="position-relative top-100">
            <div className="row d-flex p-0 m-0" id="mb-space">
              <div className="col-lg-8 col-md-10 col-sm-12 m-auto m-90">
                <div id="coachingCareerTest">                
                  <h2 className="text-center mb-1">
                    <FormattedMessage id="coaching.tool.results" defaultMessage="COACHING TOOL RESULTS"/>
                  </h2>
                  {/* {(this.props.resu !=null ?<h4 className="text-center mb-2">Candidate:  {this.props.resu} {this.props.resu}</h4>
             :"")} */}
                    <MotivationResult result={motivationResult} />
                    <PersonalityResult result={personalityResult} />
                    <CombinedResult result={combinedResults}  testType={this.state.testType}/>                    
                    <CommunicationResult
                      result={communicationResult}
                      label={communicationTest.quesLabelArr}
                    />
                    <ProfessionalResult
                      result={professionalResult}
                      word={publicSectorResult.word}
                    />
                    <PublicSectorResult
                      result={publicSectorResult.result}
                      chartdata={publicSectorResult.chartdata}
                      word={publicSectorResult.word}
                      average={publicSectorResult.average}                    
                      testType={this.state.testType}
                    />
                    <WorkCultureResult
                      result={workCultureResult.result}
                      top={workCultureResult.top}
                      word={workGroupResult.word}
                      testType={this.state.testType}                    
                    />
                    <WorkGroupResult
                      result={workGroupResult.result}
                      chartdata={workGroupResult.chartdata}
                      word={workGroupResult.word}
                      average={workGroupResult.average}                    
                      testType={this.state.testType}
                    />
                    <WorkAtmosphereResult result={workAtmosphereResult} />
                    <ManagementResult result={managementResult} />
                    <WorkEnvironmentResult result={workEnvResult.detailData} />
                </div>
                <div className="col-lg-8 col-md-10 col-sm-12 mx-auto text-center mb-4 pb-45 d-flex justify-content-center">
                  <PrintPdf  type={this.state.testType}  canvasSelector="coachingCareerTest"  pdfName="coachingToolResult"/>
                    {/* <button onClick={e => this.printResult(e)} className="btn btn-info mt-3">
                      SAVE YOUR RESULTS
                    </button> */}
                    {this.renderAnotherTestButtons()}
                    {this.renderContactCoachButtons()}
                    {loader2 && (
                      <div className="position-absolute result-loader">
                        <div
                          className="spinner-border"
                          role="status"
                          style={{ width: "2.5rem", height: "2.5rem" }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                  </div>
              </div>
              <Testcopyrights />
            </div>
          </section>
        </React.Fragment>
      );

    return (
      <React.Fragment>
        <section className="position-relative">
          <div className="row d-flex p-0 m-0">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              {showTest}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  //console.log(state);

  return {
    // talent result
    motivationResult: state.talent.MotivationResult,
    resu:state.talent.MotivationsData,
    personalityResult: state.talent.PersonalityResult,
    communicationResult: state.talent.CommunicationResult,
    communicationTest: state.talent.CommunicationData.data,
    combinedResults: state.talent.CombineResult.data,
    // target result
    professionalResult: state.target.ProfessionalResult,
    publicSectorResult: state.target.PublicSectorResult,
    workCultureResult: state.target.WorkCultureResult,
    workGroupResult: state.target.WorkGroupResult,
    workAtmosphereResult: state.target.WorkAtmosphereResult,
    managementResult: state.target.ManagementResult,
    workEnvResult: state.target.WorkEnvResult
  };
};

export default connect(
  mapStateToProps,
  { updateTestConsume }
)(CoachingCareerResult);
