import React, { Component } from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  FormattedMessage,
  FormattedHTMLMessage,
  IntlProvider,
} from "react-intl";
import { history } from "../helper/History";
import Carousel from "react-bootstrap/Carousel";
import ScrollIntoView from "react-scroll-into-view";

class Individual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state: null,
      roles: [],
      showfirst_card: "",
      alert2: null,
      guide_card: null,
    };
    window.scrollTo(0, 0);
    localStorage.setItem("alert2", null);
  }

  render() {
    //let CopyrightDate =  new Date();
    return (
      <React.Fragment>
        <section className="coaches-section">
          <div className="container-fluid p-0">
            <div className="coaches-wrapper" data-animation="fadeIn">
              <div className="coaches-block">
                <div className="coaches-inner">
                  <div className="custom_box">
                    <FormattedHTMLMessage
                      id="choose-choche-heading"
                      defaultMessage="TOOL"
                    />
                  </div>

                  <ul className="coaches-main-wrap">
                    <li>
                      <a href="https://www.linkedin.com/in/olivier-colson-pilotrh/">
                        {/* <img src="assets/images/smile-1.png" alt="" /> */}

                        <img
                          className="coach-image"
                          src="assets/images/oliver.png"
                          alt=""
                          style={{ }}
                        />

                        <br />
                        {/* <FormattedMessage
                          id="test_capital"
                          defaultMessage="TEST"
                        /> */}
                        <p>
                          <img
                            className="social-icon-img"
                            src="assets/images/linked.png"
                            alt=""
                          />
                        </p>
                        <FormattedHTMLMessage
                          id="oliver-linked"
                          defaultMessage="TOOL"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/in/emmanuelle-nassoy/">
                        <span style={{ color: "#18B6CE" }}>
                          <img
                            className="coach-image"
                            src="assets/images/emmuele.png"
                            alt=""
                          />
                        </span>
                        <br />
                        {/* <FormattedMessage
                          id="test_capital"
                          defaultMessage="TEST"
                        /> */}
                        <p>
                          <img
                            className="social-icon-img"
                            src="assets/images/linked.png"
                            alt=""
                          />
                        </p>
                        <FormattedHTMLMessage
                          id="emule-linked"
                          defaultMessage="TOOL"
                        />
                      </a>
                    </li>
                    <li style={{ backgroundColor: "white", color: "black" }}>
                      <a href="https://www.linkedin.com/in/christel-m-8b7624161/">
                        <span style={{ color: "#18B6CE" }}>
                          <img
                            className="coach-image"
                            src="assets/images/critel.png"
                            alt=""
                          />
                        </span>
                        <br />
                        {/* <FormattedMessage
                          id="test_capital"
                          defaultMessage="TEST"
                        /> */}
                        <p>
                          <img
                            className="social-icon-img"
                            src="assets/images/linked.png"
                            alt=""
                          />
                        </p>
                        <FormattedHTMLMessage
                          id="christel-linked"
                          defaultMessage="TOOL"
                        />
                      </a>
                    </li>
                    <li style={{ backgroundColor: "white", color: "black" }}>
                      <a href="https://www.linkedin.com/in/michelbacquet/">
                        <span style={{ color: "#18B6CE" }}>
                          <img
                            className="coach-image"
                            src="assets/images/michel.png"
                            alt=""
                          />
                        </span>
                        <br />
                        {/* <FormattedMessage
                          id="test_capital"
                          defaultMessage="TEST"
                        /> */}
                        <p>
                          <img
                            className="social-icon-img"
                            src="assets/images/linked.png"
                            alt=""
                          />
                        </p>
                        <FormattedHTMLMessage
                          id="mechel-linked"
                          defaultMessage="TOOL"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/in/katell-l-helgoualc-h-01a0a980/">
                        <span style={{ color: "#18B6CE" }}>
                          <img
                            className="coach-image"
                            src="assets/images/Katell.png"
                            alt=""
                          />
                        </span>
                        <br />
                        {/* <FormattedMessage
                          id="test_capital"
                          defaultMessage="TEST"
                        /> */}
                        <p>
                          <img
                            className="social-icon-img"
                            src="assets/images/linked.png"
                            alt=""
                          />
                        </p>
                        <FormattedHTMLMessage
                          id="kattel-linked"
                          defaultMessage="TOOL"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/in/nathaliedecarsin/">
                        <span style={{ color: "#18B6CE" }}>
                          <img
                            className="coach-image"
                            src="assets/images/Nathalie.png"
                            alt=""
                          />
                        </span>
                        <br />
                        {/* <FormattedMessage
                          id="test_capital"
                          defaultMessage="TEST"
                        /> */}
                        <p>
                          <img
                            className="social-icon-img"
                            src="assets/images/linked.png"
                            alt=""
                          />
                        </p>
                        <FormattedHTMLMessage
                          id="Nathali-linked"
                          defaultMessage="TOOL"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* </div> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    lang: state.locale.lang,
  };
};

Individual = connect(mapStateToProps, {})(Individual);

export default Individual;
