import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import Footer from "./includes/Footer";

class Aboutus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
    };
    window.scrollTo(0, 0); // scroll page to top
  }

  render() {
    return (
      <React.Fragment>
        <section>
          <div className="container about pages text-justify">
            <div className="row">
              <div className="col-md-12 text-center text-justify">
                <h2 className="pb-2">
                  <FormattedMessage
                    id="aboutus.title"
                    defaultMessage="The PILOT HR platform®"
                  />
                </h2>
              </div>
              <div className="col-md-12 text-center text-justify">
                <h3 className="border-bottom pb-2">
                  <FormattedMessage
                    id="aboutus.subtitle"
                    defaultMessage="The PILOT HR platform®"
                  />
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h5 className="pb-2">
                  <FormattedMessage
                    id="aboutus.sub.title"
                    defaultMessage="ABOUT PILOT RH"
                  />
                </h5>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <FormattedHTMLMessage
                  id="aboutus.desc_1"
                  defaultMessage='<p className="text-muted">PILOT RH Solutions is a French consulting firm founded in 1999 providing digital HR assessment tools for individuals, HR Professionals and enterprises. </p><p className="text-muted">Its solutions are used by large groups as Air France KLM, SAFRAN Snecma, Intermarché and many more of its franchises. </p><p className="text-muted">They provide various online quizzes/tests to assess the candidate’s ability, personality, business and entrepreneurial skills and used for high potentials recruitment. Its digital tools produce intuitive graphs to understand immediately the scope of candidates. </p><p className="text-muted">PILOT RH makes accessible the best and psychology and sociology scientific researches used around the world (C G Jung, E. Schein, R. Sainsaulieu, …). </p><p className="text-muted">It uses intelligent methodology of cross-checking weak signals to highlight the strengths of the candidate and correlation technologies to secure HR decisions. </p>'
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 img-content">
                <FormattedHTMLMessage id="aboutus.title_4" />
                <br />
                <FormattedHTMLMessage
                  id="aboutus.desc_4"
                  defaultMessage=" </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus.desc_5"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus.desc_6"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus.desc_7"
                  defaultMessage=" </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12 img-content">
                <FormattedHTMLMessage
                  id="aboutus.title_11"
                  Style={{ marginTop: "20px" }}
                />
                <br />
                <FormattedHTMLMessage
                  id="aboutus.desc_8"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus.desc_9"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus.desc_10"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12 img-content">
                <FormattedHTMLMessage id="aboutus_desc_12" />
                <br />
                <FormattedHTMLMessage
                  id="aboutus_desc_13"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus_desc_14"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus_desc_15"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus_desc_16"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12 img-content">
                <FormattedHTMLMessage id="aboutus_desc_17" />
                <br />
                <FormattedHTMLMessage
                  id="aboutus_desc_18"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12 img-content">
                <FormattedHTMLMessage id="aboutus_desc_19" />
                <br />
                <FormattedHTMLMessage
                  id="aboutus_desc_20"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus_desc_21"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />

                <FormattedHTMLMessage
                  id="aboutus_desc_22"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12 img-content">
                <FormattedHTMLMessage id="aboutus_desc_23" />
                <br />
                <FormattedHTMLMessage
                  id="aboutus_desc_24"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus_desc_25"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12 img-content">
                <FormattedHTMLMessage id="aboutus_desc_26" />
                <br />
                <FormattedHTMLMessage
                  id="aboutus_desc_27"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus_desc_28"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus_desc_29"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12 img-content">
                <FormattedHTMLMessage id="aboutus_desc_30" />
                <br />
                <FormattedHTMLMessage
                  id="aboutus_desc_31"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus_desc_32"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus_desc_33"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus_desc_34"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12 img-content">
                <FormattedHTMLMessage id="aboutus_desc_35" />
                <br />
                <FormattedHTMLMessage
                  id="aboutus_desc_36"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus_desc_37"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus_desc_38"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus_desc_39"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus_desc_40"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus_desc_41"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12 img-content">
                <FormattedHTMLMessage id="aboutus_desc_42" />
                <br />
                <FormattedHTMLMessage
                  id="aboutus_desc_43"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus_desc_44"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus_desc_45"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus_desc_46"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus_desc_47"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus_desc_48"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus_desc_49"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus_desc_50"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus_desc_51"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
                <FormattedHTMLMessage
                  id="aboutus_desc_52"
                  defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"
                />
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    lang: state.locale.lang,
  };
};

Aboutus = connect(mapStateToProps, {})(Aboutus);

export default Aboutus;
