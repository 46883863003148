import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "./includes/Footer";
import { Field, reduxForm } from "redux-form";
import { FormattedMessage } from "react-intl";
import { PieChart, Pie, Cell, Label, ResponsiveContainer, Tooltip } from "recharts";
import PrintPdf from "../components/PrintPdf"; // used to print resutl PDF

// import { shareTestLink, clearSharelinkMessage } from "../actions/Recruitment";
import { getCandidateList, getJobProfileList, getProfileMatching } from "../actions/Recruitment";
import $ from "jquery";

class Correlation extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loading: false,
          error: false,
          errorMessage: "",
          candidatesData: [],
          jobProfileData: [],
          profileMatchingData :[{
                    status : "",
                }]
        };
        window.scrollTo(0, 0); // scroll page to top
        this.showCorelationForm = this.showCorelationForm.bind(this);
    }

    componentDidMount() {
      if (this.props.isSignedIn)
      {
        let language;
        if (this.props.lang === "en") language = "english";
        if (this.props.lang === "fr") language = "french";
        var userid = localStorage.getItem("userId");
        //console.log('Init submit form'+JSON.stringify(this.props));
        let candidateObject = [];
        //used to check if test is empty then redirec it to buy test page
        this.props.getCandidateList(userid, resp => {

          candidateObject = resp.map((candidate) => {
              return candidate
          });
          // update state with candidate data
          this.setState({
            candidatesData: candidateObject,
          });
        });

        // used to check if test is empty then redirec it to buy test page
        let jobProfileObject = [];
        this.props.getJobProfileList(userid, resp => {
          jobProfileObject = resp.map((jobProfile) => {
              return jobProfile
          });
          // update state with jobProfile data
          this.setState({
            jobProfileData: jobProfileObject,
          });
        });


      }
      //console.log('UserType => '+localStorage.getItem("role"))
    }

    showCorelationForm(values) {
      //let { testUrl } = this.state;
      let hrId = localStorage.getItem("userId");
      //var language    =  (this.props.lang == "en") ? "english" : (this.props.lang == "fr") ? "french" : "english";
      // let test_id=this.state.candidatesData['user_test_id'];
      // console.log(test_id);
      let test_id=this.state.candidatesData;
      console.log(this.state.candidatesData);
      let test_id_1;
      // if (test_id> 0) {
      //  test_id_1= this.state.candidatesData.map(data => {return
      //   data.email;
      //   });
      // }
//       let a = test_id.filter(function (b) {
//        return b.id === values['candidateId'];
//    }).map(function (b) {
//        return b.user_test_id;
//    })
//   a.forEach(function (players) {
//     console.log(players);
// });
      if (test_id.length > 0) {
        test_id_1= test_id.map(data => {
          return (
            data.user_test_id
          );
        });
      }
      console.log(this.state.candidatesData[values['candidateId']]);
      console.log(this.state.candidatesData[values['candidateId']].user_test_id);
   console.log(values['candidateId']);


      // assign value to submit
      values['language']  = (this.props.lang === "en") ? "english" : (this.props.lang === "fr") ? "french" : "english";
      values['userId']    = hrId;
      values['user_test_id']=this.state.candidatesData[values['candidateId']].user_test_id;
     values['candidateId']=this.state.candidatesData[values['candidateId']].id;
      const token = localStorage.getItem("token");

      //console.log('Init submit form'+JSON.stringify(values)+' lang'+this.props.lang);
      $("#corelation-loader").show();
      //this.refs.sbmt.setAttribute("disabled", "disabled");
        //console.log('Inside showCorelationForm '+values.email);
        this.props.getProfileMatching(token, values, this.refs.sbmt, response => {
         // console.log('Response '+response);
          //console.log('showCorelationForm ==> '+JSON.stringify(response));
          if (response.status === "success") {
            this.props.reset("corelationFormValidation");
            this.setState({
              error: true,
              loading: false,
              profileMatchingData: response,
            });
            // on successfull signup show login form
            $("#corelation-loader").hide();
          } else if (response.status === "error") {
            this.setState({
              error: true,
              loading: false,
              profileMatchingData: response,
            });
            this.setState({ loading: false });
          } else {
            this.setState({
              error: true,
              loading: false,
              errorMsg: "Error occur please try again later!"
            });
            this.setState({ loading: false });
          }
          //this.setState({ loading: false });
        });
    }


    renderSelectField = ({
      input,
      label,
      meta: { touched, error },
      children
    }) => (
      <div className="form-group">
        <label>{label}</label>
        <select {...input} className="form-control">
          {children}
        </select>
        {touched && (error && <small className="text-danger">{error}</small>)}
      </div>
    );

    renderCandidateDropdown(candidates) {
      if (candidates.length > 0) {
        return candidates.map((data,index) => {
          return (
            <option key={data.id} value={index}>
              {data.email}
            </option>
          );
        });
      }else{
        return (
          <option key='' value=''>Not found any Candidate</option>
        );
      }
    }

    renderJobProfileDropdown(jobprofile) {
      if (jobprofile.length > 0) {
        return jobprofile.map(data => {
          return (
            <option key={data.job_profile} value={data.job_profile}>
              {data.job_profile}
            </option>
          );
        });
      }else{
        return (
          <option key='' value=''>Not found any jobprofile</option>
        );
      }
    }

    returnProfileMatchingTableTr(data) {
      //console.log('TR  ==> '+JSON.stringify(data));
      return data.map((val, key) => {
        return (
          <tr key={key}><td>{val.label}</td>
            <td>
              <div className="progress" style={{ lineHeight:"1.4" }}>
                <div className="progress-bar" style={{ width: val.score+"%", height: "20px" }}></div><span>{val.score}</span>
              </div>
            </td>
          </tr>
          // <tr key={key}>
          //   <td className="text-center grey-bg">{val.label}</td>
          //   <td className="bg-info text-white  text-center">{val.score}</td>
          // </tr>
        );
      });
    }

    returnProfileMatchingTable(data, type) {
      //console.log('returnProfileMatchingTable '+type+' ==> '+JSON.stringify(data));
      var testType = (type ==='Work Culture' ? <FormattedMessage id="profile.matching.workculture" defaultMessage="Work Culture"/> : <FormattedMessage id="profile.matching.motivation" defaultMessage="Motivation"/>);
      return (
        <div>
          <table className="table profile">
          <thead>
              <tr>
              <td className="border-0"></td>
              <td className="border-0"> {testType}</td>
              </tr>
          </thead>
          <tbody>
            
              {this.returnProfileMatchingTableTr(data)}
          </tbody>
          </table>
        </div>
      );
    }

    getCorrelationPercentage = (candidateData, hrData, type) => {
      const COLORS = ["#17a2b8", "#ededed"];    // 17a2b8

    //getCorrelationPercentage(candidateData, hrData){
      //console.log('candidateData  ==> '+JSON.stringify(candidateData)+''+' hrData  ==> '+JSON.stringify(hrData));
      var candidateValues = [];
      var hrValues = [];
      for (var i = 0; i < candidateData.length; i++) {
        //console.log('counter  ==> '+i+' score '+candidateData[i].score);
        candidateValues.push(candidateData[i].score);
        hrValues.push(hrData[i].score);
      }
      var getcoef = this.correlationCoefficient(candidateValues, hrValues);
      if(getcoef<0){
        getcoef=0
      }
      //const corrData = [{ name: "name", score: 0.90 }, { name: "other", score: 99.10 }];
      var calOther = (100 - getcoef).toFixed(2);
      //console.log('calOther  ==> '+calOther+' getcoef '+getcoef);
      const corrData = [{ name: "Matching", score: Number(getcoef)}, { name: "Matching", score: Number(calOther) }];
      //this.setState({ corrData: corrData, correlation: getcoef});
     //console.log('corrData '+JSON.stringify(corrData)+' coef % '+getcoef);

      var testType = (type ==='Work Culture' ? <FormattedMessage id="profile.matching.workculture" defaultMessage="Work Culture"/> : <FormattedMessage id="profile.matching.motivation" defaultMessage="Motivation"/>);

      return (

        <div>
          <h6 className="invisible">{type}</h6>
          <h6>{testType} Correlation  {getcoef} %</h6>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer>
              <PieChart>
                    <Pie
                      data={corrData}
                      dataKey="score"
                      labelLine={false}
                      innerRadius={60}
                      outerRadius={90}
                      fill="#ededed"
                      textAnchor="end"
                    >
                  {corrData.map((entry, index) => (
                    <Cell key={index} fill={COLORS[index % COLORS.length]} />
                  ))}
                  <Label width={80} position="center">
                    {getcoef} %
                  </Label>
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      );
    }


    correlationCoefficient(x, y) {
      //console.log('x => '+JSON.stringify(x)+' y => '+JSON.stringify(y));
      var sum_x = 0;
      var sum_y = 0;
      var sum_xy = 0;
      var squareSum_x = 0;
      var squareSum_y = 0;

      for (let i = 0; i < x.length; i++) {
        //console.log('Inside loop  x= '+x[i]+' y= '+y[i]);
        sum_x += (x[i] !== null ? parseFloat(x[i]) :0);
        sum_y += (y[i] !== null ? parseFloat(y[i]) :0);
        sum_xy +=  parseFloat(x[i] * y[i]);
        squareSum_x += parseFloat(x[i] * x[i]);
        squareSum_y += parseFloat(y[i] * y[i]);
      }
      var n = x.length;
      // formula
      var coeff = parseFloat(n * sum_xy - sum_x * sum_y) / Math.sqrt((n * squareSum_x - sum_x * sum_x) * (n * squareSum_y - sum_y * sum_y));
      console.log('No'+n+' sum_x '+sum_x+' sum_y '+sum_y+' sum_xy '+sum_xy+' squareSum_x '+squareSum_x+' squareSum_y '+squareSum_y+' coeff'+coeff);
      
      if(!isNaN(coeff)){
        //console.log('! isNaN '+coeff);
        return (coeff * 100).toFixed(2);
      }else{
        return '0';
      }
      //return coefCorrelation.toFixed(2);
    }

    renderErrorMessage(errorMessage) {
      if(errorMessage.status === 'error'){
        return (
          <div>
            <h5 className="text-danger text-center">{errorMessage.message}</h5>   
          </div>
        );
      }else{
        return (
          <div></div>
        );
      }
    }
 // get global correlation
  getGlobalCorrelationPercentage = (data) => {
      ///let { candidateData, hrData, motivGlobalScore, workcultGlobalScore, globalCorrelation } = 0;
      let { globalCorrelation } = 0;
      //console.log('globalCore  ==> '+JSON.stringify(data)+' CandidateMotivation '+JSON.stringify(data.candidate_motivation)+' hrMotivation '+JSON.stringify(data.hr_motivation));
      // calclate candidatea coef
      var candidateMotiveValues = [];
      var hrMotiveValues = [];
      for (var i = 0; i < data.candidate_motivation.length; i++) {
        //console.log('counter  ==> '+i+' score '+candidateData[i].score);
        candidateMotiveValues.push(data.candidate_motivation[i].score);
        hrMotiveValues.push(data.hr_motivation[i].score);
      }
      var candidateMotivCoef = this.correlationCoefficient(candidateMotiveValues, hrMotiveValues);
      //console.log('candidateMotivCoef '+candidateMotivCoef);
      // calclate candidatea coef
      var candidateWcValues = [];
      var hrWcValues = [];
      for (var i = 0; i < data.candidate_workculture.length; i++) {
        //console.log('counter  ==> '+i+' score '+candidateData[i].score);
        candidateWcValues.push(data.candidate_workculture[i].score);
        hrWcValues.push(data.hr_workculture[i].score);
      }
      var candidateWcCoef = this.correlationCoefficient(candidateWcValues, hrWcValues);

      if(!isNaN(candidateMotivCoef) && !isNaN(candidateWcCoef)){
        console.log(candidateMotivCoef,candidateWcCoef)
        // if(candidateMotivCoef<0){
        //   candidateMotivCoef=0
        //  }
        //  if(candidateWcCoef<0){
        //   candidateWcCoef=0
        //  }
         console.log((candidateMotivCoef *  0.57142857142))
         console.log((candidateWcCoef * 0.42857142857 ))
       console.log(  globalCorrelation =((candidateMotivCoef *  0.57142857142) +  (candidateWcCoef * 0.42857142857  )))
         if(globalCorrelation<0){
          globalCorrelation=0
         }
         console.log('candidateMotivCoef '+candidateMotivCoef+' candidateWcCoef '+candidateWcCoef+ ' globalCorrelation '+globalCorrelation);
      }else{
        globalCorrelation =  0;
      }

      return (
        <div>
          <h2 className="h1-responsive font-weight-bold text-center my-4">
            <FormattedMessage id="profile.matching.global.coef" defaultMessage="GLOBAL CORRELATION "/> {globalCorrelation.toFixed(2)} %
          </h2>
        </div>
      );
  }

  render() {
    //const { profileMatchingData } = this.props;
    //console.log('Render  -profileMatchingData- '+JSON.stringify(this.state.profileMatchingData.result));
    const selecJobProfile = <FormattedMessage id="corelation.selectjobprofile" defaultMessage="Select Job Profile *"/>
    const selecCandidate = <FormattedMessage id="corelation.selectcandidate" defaultMessage="Select Candidate *"/>
    return (
    <React.Fragment>
      <section className="mb-4 pages">
        <div className="container">
          <div className="row d-flex p-0 m-0">
            <div className="container">
                {this.state.profileMatchingData.status === 'success' ? (
                  <div className="col-md-12 p-4 m-0 text-center">
                    <div id="profileMatchingResult">
                      <div className="col-md-12">
                        {this.getGlobalCorrelationPercentage(this.state.profileMatchingData.result)}
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <h5><FormattedMessage id="profile.matching.hr.result" defaultMessage="Job Profile Results"/> </h5>
                          {this.returnProfileMatchingTable(this.state.profileMatchingData.result.hr_motivation, 'Motivation')}
                        </div>
                        <div className="col-md-4">
                          <h5><FormattedMessage id="profile.matching.cand.result" defaultMessage="Candidate Results"/></h5>
                          {this.returnProfileMatchingTable(this.state.profileMatchingData.result.candidate_motivation, 'Motivation')}
                        </div>
                        <div className="col-md-4">
                            {this.getCorrelationPercentage(this.state.profileMatchingData.result.candidate_motivation, this.state.profileMatchingData.result.hr_motivation, 'Motivation')}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          {this.returnProfileMatchingTable(this.state.profileMatchingData.result.hr_workculture, 'Work Culture')}
                        </div>
                        <div className="col-md-4">
                          {this.returnProfileMatchingTable(this.state.profileMatchingData.result.candidate_workculture, 'Work Culture')}
                        </div>
                        <div className="col-md-4">
                          {this.getCorrelationPercentage(this.state.profileMatchingData.result.candidate_workculture, this.state.profileMatchingData.result.hr_workculture, 'Work Culture')}
                        </div>
                      </div>
                      <div className="col-md-12">
                      <h5 className="h1-responsive font-weight-bold text-center my-4">
                        <FormattedMessage id="profile.matching.of" defaultMessage="PROFILE MATCHING BETWEEN"/>
                      </h5>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <h6>
                            <FormattedMessage id="profile.matching.hr" defaultMessage="Hr Name"/>
                            {this.state.profileMatchingData.result.job_profiles} ({this.state.profileMatchingData.result.hr_profiles.email})
                          </h6>
                        </div>
                        <div className="col-md-6">
                          <h6>
                          <FormattedMessage id="profile.matching.candidate" defaultMessage="Candidate Name"/>
                            {this.state.profileMatchingData.result.candidate_profile.first_name} ({this.state.profileMatchingData.result.candidate_profile.email})
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-10 col-md-10 col-sm-12 mx-auto text-center mb-4 mx-4">
                      <PrintPdf  type={this.state.testType}  canvasSelector="profileMatchingResult"  pdfName="profileMatchingResult"/>
                      <a className="btn btn-primary mt-3 w-50 btn-primary-spacing"  href="/correlation">
                        <FormattedMessage id="reset.button" defaultMessage="RESET"/>
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="col-lg-6 col-md-8 m-auto">
                  <div className="col-md-12">
                  <h2 className="h1-responsive font-weight-bold text-center my-4">
                    <FormattedMessage id="header.match.profile" defaultMessage="MATCHING"/>
                  </h2>
                  </div>
                    <div className="card-div">
                      <div className="row">
                        {this.renderErrorMessage(this.state.profileMatchingData)}
                        <form id="sharelink-form" onSubmit={this.props.handleSubmit(e => this.showCorelationForm(e))} className="row w-100">
                          <div className="col-md-12">
                            <Field name="jobProfileId" component={this.renderSelectField} label={selecJobProfile} >
                              <option value="">Select Job Profile</option>
                              {this.renderJobProfileDropdown(this.state.jobProfileData)}
                            </Field>
                          </div>
                          <div className="col-md-12">
                          <Field name="candidateId" component={this.renderSelectField} label={selecCandidate} >
                            <option value="">Select Candidate</option>
                            {this.renderCandidateDropdown(this.state.candidatesData)}
                          </Field>
                          </div>
                          <div className="col-md-12 text-center">
                              <div className="spinner-border text-primary m-auto" role="status" id="corelation-loader">
                                <span className="sr-only">Loading...</span>
                              </div>
                          </div>
                          <div className="col-md-12 text-center">
                            <button type="submit" name="send-query" id="send-query" tabIndex="4"  className="btn btn-primary" ref="sbmt">
                              <FormattedMessage id="corelation.submit.button" defaultMessage="SUBMIT"/>
                            </button>
                          </div>
                          <div className="status"></div>
                        </form>
                        </div>
                      </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
    );
  }
}

const validate = values => {
  //console.log('Inside validate'+JSON.stringify(values)+' candidateId '+values.candidateId);
    const errors = {};

    if (!values.jobProfileId) {
      errors.jobProfileId = "Please select job profile fields its required.";
    }
    // if (!values.candidateId) {
    //   errors.candidateId = "Please select candidate fields its required.";
    // }

    return errors;
  };

const mapStateToProps = state => {
  return {
    isSignedIn: state.auth.isSignedIn,
    corelationFormValidation: state.form.corelationFormValidation,
    shareLinkError: state.auth.shareLinkError,
    shareLinkMessage: state.auth.shareLinkMessage,
    lang: state.locale.lang
  };
};

Correlation = connect(
  mapStateToProps,
  { getCandidateList, getJobProfileList, getProfileMatching }
  // { shareTestLink, clearSharelinkMessage }
)(Correlation);


export default reduxForm({
    form: "corelationFormValidation",
    enableReinitialize: true,
    validate
  })(Correlation);

//export default Correlation;
