import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';
import Carousel from "react-bootstrap/Carousel";
import Footer from "./includes/Footer";


class AboutSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
    roles: []
    };
    
   
  }
    componentDidMount(){

      const element = document.getElementById(this.props.match.params.id);
      
      console.log(this.props.match.params.id);
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section

        element.scrollIntoView({behavior: 'smooth'});
      }
    
    }
  

  render() {
    return (
    <React.Fragment>
      <section>
      <div className="container about pages text-justify" style={{maxWidth:'892px'}}>
      
      <div className="col-md-12 bottombox" id="story">
        <section className="icon-boxed2 teaser-box mt-10"> 
          <div className="container">
            <div className="row" >
              <div className="col-md-12 img-content" style={{background: 'rgb(21 149 165)',color: '#fff', marginBottom:'1%', padding:'40px'}}>
                <p><b style={{color:'#fff', fontSize:'20px'}}><FormattedHTMLMessage id="aboutus.menutitle1" /></b></p>
                  <FormattedHTMLMessage id="story.desc1"   defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"/>
                  <FormattedHTMLMessage id="story.desc2"   defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"/>
                  <FormattedHTMLMessage id="story.desc3"   defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"/>
              </div> 
            </div>
          </div>
        </section>
      </div>

      <div className="col-md-12 bottombox" id="values">
        <section className="icon-boxed2 teaser-box mt-10" > 
          <div className="container">
            <div className="row" >
              <div className="col-md-12 img-content" style={{background: 'rgb(40, 50, 106)',color: '#fff', marginBottom:'1%', padding:'40px'}}>
                <p><b style={{color:'#fff', fontSize:'20px'}}><FormattedHTMLMessage id="aboutus.menutitle2" /></b></p>
                  <div className="col-md-4 img-content" style={{ float:'left'}}>
                    <FormattedHTMLMessage id="value.desc1"   defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"/>
                  </div>
                  <div className="col-md-4 img-content" style={{ float:'left'}}>
                    <FormattedHTMLMessage id="value.desc2"   defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"/>
                  </div>
                  <div className="col-md-4 img-content" style={{ float:'left'}}>
                    <FormattedHTMLMessage id="value.desc3"   defaultMessage="<div className='col-3 mb-2 p-0'><img src='' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"/>
                  </div>
              </div>            
            </div>
          </div>
        </section>
      </div>
      <div className="col-md-12 bottombox" id="sect">
        <section className="icon-boxed2 teaser-box mt-10" > 
          <div className="container">
            <div className="row" >
              <div className="col-md-12 img-content" style={{background: 'rgb(21 149 165)',color: '#fff', marginBottom:'1%', padding:'40px'}}>
                <p><b style={{color:'#fff', fontSize:'20px'}}><FormattedHTMLMessage id="aboutus.menutitle3" /></b></p>     
                <ul type="circle">
                  <li> <FormattedHTMLMessage id="benefit.desc1" /></li>
                  <li> <FormattedHTMLMessage id="benefit.desc2" /></li>
                  <li> <FormattedHTMLMessage id="benefit.desc3" /></li>
                  <li> <FormattedHTMLMessage id="benefit.desc4" /></li>
                  <li> <FormattedHTMLMessage id="benefit.desc5" /></li>
                  <li> <FormattedHTMLMessage id="benefit.desc6" /></li>
                </ul>
              </div>            
            </div>
          </div>
        </section>
      </div>
      
      <div className="col-md-12 bottombox" id="good">
        <section className="icon-boxed2 teaser-box mt-10" > 
          <div className="container">      
            <div className="row">
            <div className="col-md-12 img-content" style={{background: '#28326a',color: '#fff', marginBottom:'1%', padding:'40px'}}>
              <p><b style={{color:'#fff', fontSize:'20px'}}><FormattedHTMLMessage id="aboutus.menutitle4" /></b></p>
              <ol>
                <li> <FormattedHTMLMessage id="reason.desc1" /></li>
                <li> <FormattedHTMLMessage id="reason.desc2" /></li>
                <li> <FormattedHTMLMessage id="reason.desc3" /></li>
                <li> <FormattedHTMLMessage id="reason.desc4" /></li>
                <li> <FormattedHTMLMessage id="reason.desc5" /></li>
                <li> <FormattedHTMLMessage id="reason.desc6" /></li>
                {/* <li> <FormattedHTMLMessage id="reason.desc7" /></li> */}
                {/* <li> <FormattedHTMLMessage id="reason.desc8" /></li> */}
              </ol>
              </div>            
            </div>
          </div>
        </section>
      </div>
      <div className="col-md-12 bottombox" id="testi">
        <section className="icon-boxed2 teaser-box mt-10" > 
          <div className="container"> 
          <div className="row">
            <div className="col-md-12 img-content" style={{background: 'rgb(21 149 165)',color: '#fff', marginBottom:'1%', padding:'40px'}}>
              <p><b style={{color:'#fff', fontSize:'20px'}}><FormattedHTMLMessage id="aboutus.menutitle5" /></b></p>
              <p><b style={{color:'#fff', fontSize:'15px'}}><FormattedHTMLMessage id="testimonial.title" /></b></p>
              <p style={{padding:'85px'}}>
              <Carousel>
                    <Carousel.Item>
                        <div>
                             <FormattedHTMLMessage id="testimonial.desc1" />
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div>
                            <FormattedHTMLMessage id="testimonial.desc2" />
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div>
                            <FormattedHTMLMessage id="testimonial.desc3" />
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div>
                            <FormattedHTMLMessage id="testimonial.desc4" />
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div>
                            <FormattedHTMLMessage id="testimonial.desc5" />
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div>
                            <FormattedHTMLMessage id="testimonial.desc6" />
                        </div>
                    </Carousel.Item>
              </Carousel>
              </p>
              </div>            
            </div>
          </div>
        </section>
      </div>
      <div className="col-md-12 bottombox" id="faq">
        <section className="icon-boxed2 teaser-box mt-10" > 
          <div className="container"> 
          <div className="row" >
            <div className="col-md-12 img-content" style={{background: '#28326a',color: '#fff', marginBottom:'10%', padding:'40px'}}>
              <p><b style={{color:'#fff', fontSize:'20px'}}><FormattedHTMLMessage id="FAQ.title" /></b></p>
              <p><FormattedHTMLMessage id="aboutus.faq1" /><FormattedHTMLMessage id="aboutus.faq.a1" /></p>
              <p><FormattedHTMLMessage id="aboutus.faq2" /><FormattedHTMLMessage id="aboutus.faq.a2" /></p>
              <p><FormattedHTMLMessage id="aboutus.faq3" /><FormattedHTMLMessage id="aboutus.faq.a3" /></p>
              <p><FormattedHTMLMessage id="aboutus.faq4" /><FormattedHTMLMessage id="aboutus.faq.a4" /></p>
              </div>            
            </div>
          </div>
        </section>
      </div>
    </div>  
  </section>
      <Footer />            
    </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    isSignedIn: state.auth.isSignedIn,
    lang: state.locale.lang
  };
};

AboutSection = connect(
  mapStateToProps,
  {}
)(AboutSection);

export default AboutSection;

