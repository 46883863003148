import React from "react";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";

const renderSelected = (data, testType)  => {
  
  //console.log(' renderSelected  '+testType);

  if(testType == 'individual'){        /// check type of test individual or professional
    return data.map((value, index) => {
      if(index == 0){
        return (
          <React.Fragment key={index}>
            <div className="row mb-2">
              <div className="col-md-3 bg-info text-white d-flex align-items-center font-weight-bold p-3">
                {value.name}
              </div>
              <div className="col-md-6 p-0 border-top border-bottom px-2 text-justify">
              <b>{value.name} : </b> {value.description}
              </div>
              <div className="col-md-3 bg-info text-white d-flex align-items-center">
                {value.label}
              </div>
            </div>
          </React.Fragment>
        );
      }
    });
  }else{
    return data.map((value, index) => {
      return (
        <React.Fragment key={index}>
          <div className="row mb-2">
            {index+1 == 1 && (
              <div className="col-md-2 col-md-2 display-3 text-center res-display" >N° {index+1}</div>
            )}
            {index+1 == 2 && (  // condition to check 2 and set diffrent class 
              <div className="col-md-2 col-md-2 display-4 text-center res-display" >n° {index+1}</div>
            )}
            <div className="col-md-3 bg-info text-white d-flex align-items-center font-weight-bold p-3">
              {value.name}
            </div>
            <div className="col-md-5 p-0 border-top border-bottom px-2 text-justify">
              {value.description}
            </div>
            <div className="col-md-2 bg-info text-white d-flex align-items-center">
              {value.label}
            </div>
          </div>
        </React.Fragment>
      );
    });
  }
};

const renderUnSelected = (data, testType) => {
  //console.log(' renderUnSelected  '+testType);
  if(testType != 'individual'){
    return data.map((value, index) => {
      return (
        <React.Fragment key={index}>
          <div className="row mb-2">
            <div className="col-md-2" />
            <div className="col-md-3 grey-bg d-flex align-items-center font-weight-bold p-3">
              {value.name}
            </div>          
            <div className="col-md-5 p-0 border px-2 text-justify">{value.description}</div>
            <div className="col-md-2" />
          </div>
        </React.Fragment>
      );
    });
  }
};

const renderFooter = (data, word, type) => {
  //console.log(' Here we go =>  '+JSON.stringify(data)+' 0  '+typeof(data[0])+' 1  '+typeof(data[1]));
  if(typeof(data[0]) === 'object' && typeof(data[1]) === 'object'){
    //console.log('Inside lengith '+data[0].name+' -- '+data[1].name);
    if(type != 'individual'){
      return (
        <React.Fragment>
          <div className="alert alert-secondary text-center">
            <FormattedMessage id="prof.target.result.preffered.work.env" defaultMessage="My two preffered work environment are"/> <b>{data[0].name}</b> <FormattedMessage id="and" defaultMessage="and"/> <b>{data[1].name}</b>
            {/* <FormattedHTMLMessage
              id="prof.target.result.preffered.work.env"
              defaultMessage={`My two preffered work environment are <b>{name1}</b> and <b> {name2}</b>.`}
              values={{name1: data[0].name}, {name2: data[1].name}}
            /> */}
            {/* My two preffered work environment are <b>{data[0].name}</b> and <b> {data[1].name}</b>. */}
          </div>
        </React.Fragment>
      );
    }else{
      return (
        <React.Fragment>
          <div className="alert alert-secondary text-center"> 
            <FormattedHTMLMessage
              id="ind.target.result.preffered.work.env"
              defaultMessage={`My two preffered work environment are <b>{name1}</b> and <b> {name2}</b>.It's<b> {word}</b> for me to picture myself working in a government owned organisation.`}
              // values={{name1: data[0].name}, {name2: data[1].name}, {word: word}}
            />          
            {/* My two preffered work environment are <b>{data[0].name}</b> and <b> {data[1].name}</b>.It's<b> {word}</b> for me to picture myself working in a government owned organisation */}
          </div>
        </React.Fragment>
      );
    }
  }else{
    return false;
  }
};

function ProfessionalResult(props) {
  let { testType  } = props;  
  //console.log(' ProfessionalResult => '+JSON.stringify(props)+' type+> '+testType);
  return (
    // <div className="col-lg-10 col-md-10 col-sm-12 m-auto">
    <div className="m-auto">
        {props.testType == 'individual' && ( /// check type of test individual
          <h4 className="text-center mb-1 mt-5">
            <FormattedMessage id="ind.target.your.work.env.result.title" defaultMessage="Your work environment"/>
          </h4>
        )}
        {props.testType != 'individual' && ( /// check type of test professional
          <div>
            <h3 className="text-center mb-1">TARGET</h3>
            <h4 className="text-center mb-1 mt-2">
              <FormattedMessage id="ind.target.work.env.result.title" defaultMessage="Work environment"/>
            </h4>
          </div>
        )}
      <div className="card">
        {props.testType != 'individual' && ( /// check type of test individual or professional        
          <div className="card-header">
              <FormattedMessage id="ind.target.two.preffered.env" defaultMessage="MY TWO PREFERED WORK ENVIRONMENT"/>
          </div>
        )}
        <div className="card-body">
          {renderSelected(props.result.top, props.testType)}
          {renderUnSelected(props.result.bottom, props.testType)}
        </div>
        {props.testType != 'individual' && ( /// check type of test individual or professional                
          <div className="card-footer text-muted">
            {renderFooter(props.result.top, props.word, props.testType)}
          </div>
        )}
      </div>
    </div>
  );
}

export default ProfessionalResult;
