import React from "react";
import { FormattedMessage } from "react-intl";

function renderAnswers(data, key) {
  let and = null;
  if(localStorage.getItem("lang")=="en"){
    and=" and "
  }else{
     and =" et "
  }
  if (data) {
    return (
      <React.Fragment>
        <span>
          {key === 1 && <p>{data.question1}</p>}
          {key === 2 && <p>{data.question2}</p>}
          {key === 3 && <p>{data.question3}</p>}
          {key === 4 && (
         
            <p>
              {data.question4_1.includes(",")
                ? data.question4_1.replace(",",
               and
             )
                : data.question4_1}
            </p>
          )}
          {key === 5 && (
            <p>
              {data.question4_2.includes(",")
                ? data.question4_2.replace(",", 
               and
              )
                : data.question4_2}
            </p>
          )}
          {key === 6 && (
            <p>
              {data.question4_3.includes(",")
                ? data.question4_3.replace(",", 
               and
             )
                : data.question4_3}
            </p>
          )}
          {key === 7 && (
            <p>
              {data.question4_4.includes(",")
                ? data.question4_4.replace(",", 
               and
              )
                : data.question4_4}
            </p>
          )}
          {key === 8 && (
            <p>
              {data.question4_5.includes(",")
                ? data.question4_5.replace(",",
               and
            )
                : data.question4_5}
            </p>
          )}
          {key === 9 && (
            <p>
              {data.question4_6.includes(",")
                ? data.question4_6.replace(",", 
               and
             )
                : data.question4_6}
            </p>
          )}
          {key === 10 && (
            <p>
              {data.question4_7.includes(",")
                ? data.question4_7.replace(",", 
               and
               )
                : data.question4_7}
            </p>
          )}
          {key === 11 && (
            <p>
              {data.question4_8.includes(",")
                ? data.question4_8.replace(",",
               and
              )
                : data.question4_8}
            </p>
          )}
          {key === 12 && (
            <p>
              {data.question4_9.includes(",")
                ? data.question4_9.replace(",",
               and
             )
                : data.question4_9}
            </p>
          )}
        </span>
      </React.Fragment>
    );
  }
}

function WorkEnvironmentResult(props) {
  return (
    // <div className="col-lg-10 col-md-10 col-sm-12 m-auto">
    <div className="m-auto">
      <h4 className="text-center mb-1 mt-5">
        <FormattedMessage id="ind.target.your.desire.work.place.title" defaultMessage="Workplace you declare desired"/>
      </h4>
      <div className="card">
        <div className="card-body mb-5">
          <div className="row">
            {props.result.category.map((value, key) => {
              return (
                <div key={key} className="col-md-4 border-bottom">
                  <div
                    className={
                      key < 9
                        ? "py-3 we-minhgt"
                        : "py-3 we-minhgt"
                    }
                  >
                    <h6 className="text-primary font-weight-bold">
                      {value.question === null || "" ? value.category  : value.question}
                    </h6>
                    <b />
                    {renderAnswers(props.result, key + 1)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkEnvironmentResult;
