import React, { Component } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import Sortable from "react-sortablejs";
import { FormattedMessage } from "react-intl";
import Hash from "../../../helper/Hashing";
import Testcopyrights from "../../Testcopyrights"; // used to show test copyright text under test

import {
  fetchWorkAtmosphere,
  saveWorkAtmosphere,
  getWorkAtmosphereResult
} from "../../../actions/Target";

class WorkAtmosphereResultlink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      now: 0,
      loading: true,
      error: false,
      errorMsg: "",
      test_id: null,
      user_id: null,
      test_version_id: null,
      invalid: true,
      success_msg: false,
      hideForm: true,
      language: null,
      question: [],
      questionAnswers: [],
      maxElements: null,
      disable: false,
      testcode: this.props.testcodes !=null ? this.props.testcodes : localStorage.getItem('sharedTestId')
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    // let language;
    // if (this.props.lang === "en") language = "english";
    // if (this.props.lang === "fr") language = "french";
    const { hr } = this.props;
    let hrId = null;
    let code = null;
    if (hr) {
      code = hr;
      hrId = Hash.decrypt(hr);
    }
    // const token = localStorage.getItem("token");
   
    // e.preventDefault();

    //const { page } = this.state;
    //let quesNo = page - 1;
    const token = localStorage.getItem("token");
 
      this.setState({ loading: true });
      let language;
      if (this.props.lang === "en") language = "english";
      if (this.props.lang === "fr") language = "french";
      const submitData = {
        // user_id: this.state.user_id,
        user_id:localStorage.getItem("userId"),
        questionAnswers: this.state.questionAnswers,
        language: language,
        testcode:this.state.testcode
      };
      this.props.getWorkAtmosphereResult(token, submitData, response => {
        if (response.message === "success") {
          this.setState({ loading: false });
          this.props.stepUp();
        }
      });
   
    // const token = localStorage.getItem("token");

    // this.props.fetchWorkAtmosphere(token, resp => {
    //   this.setState({
    //     question: resp.data.options,
    //     max: maxElements,
    //     loading: false
    //   });
    // });
  }

  selectAnswer(val) {
    var { page } = this.state;
    let questionNo = "answer" + page;
    this.setState({
      [questionNo]: val
    });

    $("#error-message").hide();
  }

  renderQuestions() {
    const { page } = this.state;

    let inc = 1;

    if (this.props.test) {
      return (
        <React.Fragment>
          {page === inc && (
            <div className="card">
              <div className="card-header">{this.props.test.question}</div>
              {this.renderSortedData()}
            </div>
          )}
          <div className="d-none">{(inc = inc + 1)}</div>
        </React.Fragment>
      );
    }
  }

  renderSortedData() {
    // for left side question answer
    const question = this.state.question.map((val, key) => (
      <div className="col-md-4 card" key={key} data-id={val}>
        {val}
      </div>
    ));

    // for right side question answer after pull from left side
    const questionAnswers = this.state.questionAnswers.map((val, key) => (
      <div className="col-md-12 p-3 card right" key={key} data-id={val}>
        {val}
      </div>
    ));

    return (
      <React.Fragment>
        <div className="row p-3">
          <div className="col-md-8 m-auto">
            <Sortable
              options={{
                animation: 150,
                sort: false,
                group: {
                  name: "clone2",
                  pull: true,
                  put: true
                }
              }}
              className="row shadow minHeight"
              onChange={items => {
                if (items.length === 0) {
                  return false;
                }
                this.setState({ question: items });
              }}
              tag="div"
            >
              {question}
            </Sortable>
          </div>

          <div className="col-md-4 m-auto">
            <div className="row">
              <div className="col-md-3 m-auto pr-0 minHeight dragdropno">
                <ul className=" list-group minHeight text-right bg-trans d-flex">
                  <li className="list-group-item border-0 p-0 flex-1">n°1</li>
                  <li className="list-group-item border-0 p-0 flex-1">n°2</li>
                </ul>
              </div>                        
              <Sortable
                className="col-md-9 ansMinHeight dragdropno1"
                onChange={(items, sortable, evt) => {
                  //let { length } = items.length;
                  if (items.length > 2) {
                    return false;
                  }
                  this.setState({
                    questionAnswers: items,
                    user_id: localStorage.getItem("userId")
                  });
                }}
                options={{
                  animation: 150,
                  group: {
                    name: "clone2",
                    pull: true,
                    put: true
                  }
                }}
                tag="div"
              >
                {questionAnswers}
              </Sortable>
            </div>
          </div>
          <div className="col-md-12 mx-auto mb-0">
            <div className="jumbotron  mb-0 p-1 mt-2">
              <p className="mb-0">
                <FormattedMessage id="ind.target.workatmosphere.expl" defaultMessage="Identify from these 3 blocks, the two you preffer and place them in righthand column (n°1 being the most important)"/>
              </p>
            </div>
          </div>          
        </div>
      </React.Fragment>
    );
    //});
  }

  onSubmit(e) {
    e.preventDefault();

    //const { page } = this.state;
    //let quesNo = page - 1;
    const token = localStorage.getItem("token");
    let maxElements = $(`.right`).length;
    //console.log(this.state);

    if (maxElements === 2) {
      this.setState({ loading: true });
      let language;
      if (this.props.lang === "en") language = "english";
      if (this.props.lang === "fr") language = "french";
      const submitData = {
        user_id: this.state.user_id,
        questionAnswers: this.state.questionAnswers,
        language: language
      };
      this.props.saveWorkAtmosphere(token, submitData, response => {
        if (response.message === "success") {
          this.setState({ loading: false });
          this.props.stepUp();
        }
      });
    } else {
      $("#error-message").show();
    }
  }

  render() {
    const { success_msg, hideForm, loading, error, errorMsg } = this.state;
    var displayForm = { display: hideForm ? "block" : "none" };

    if (loading)
      return (
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              <div className="text-center">
                <div
                  className="spinner-border m-5"
                  role="status"
                  style={{ width: "3rem", height: "3rem" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      );

    return (
      <React.Fragment>
        <section className="position-relative">
          <div className="row d-flex p-0 m-0">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              <h4 className="text-center mb-4">
              <FormattedMessage id="ind.target.workculture.title" defaultMessage="Work atmosphere"/>              
              </h4>
              <div className="col-lg-8 col-md-10 col-sm-12 m-auto">
                {error && <div className="alert alert-danger">{errorMsg}</div>}
                {success_msg && (
                  <div id="success-message" className="text-success">
                    <FormattedMessage id="test_saved" defaultMessage="Test saved successfully !"/>
                  </div>
                )}
                <div id="error-message" className="alert alert-danger">
                  Drag two Items
                </div>

                <div style={displayForm}>
                  <form onSubmit={this.onSubmit}>
                    {this.renderQuestions()}
                    <div className="d-table m-auto p-90">
                      <button
                        type="submit"
                        className="btn btn-info mx-auto"
                      >
                      <FormattedMessage id="submit" defaultMessage="SUBMIT"/>
                      </button>
                    </div>                    
                  </form>
                </div>
              </div>
              <Testcopyrights />              
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    test: state.target.WorkAtmosphereData,
    lang: state.locale.lang
  };
};

export default connect(
  mapStateToProps,
  { fetchWorkAtmosphere, saveWorkAtmosphere,getWorkAtmosphereResult }
)(WorkAtmosphereResultlink);
