import React, { Component } from "react";
import { connect } from "react-redux";
//import { history } from "../helper/History";
import $ from "jquery";

export default function(CheckComponent) {
  class AuthGuard extends Component {
    componentWillMount() {
      if (!this.props.isSignedIn) {
        this.props.history.push("/");
        //history.push("/");
        if(localStorage.getItem("lang")=="en"){
          var title="SIGNUP";
        }else{
          var title='S’INSCRIRE'
        }
        $("#loginModal").modal("show");
        $("#register-form").delay(100).fadeIn(100);
        $("#login-form").fadeOut(100);
        $("#login-form-link").removeClass("active");
        $('.modal-title').text(title);
        $('select.form-control[name="role"]').val("2");
        $('select.form-control[name="role"] option[value="2"]').attr("selected",true);
      }
    }

    componentWillUpdate(nextProps) {
      if (!nextProps.isSignedIn) {
        this.props.history.push("/");
        //history.push("/");
      }
    }

    render() {
      return <CheckComponent {...this.props} />;
    }
  }

  const mapStateToProps = state => {
    return { isSignedIn: state.auth.isSignedIn };
  };
  return connect(mapStateToProps)(AuthGuard);
}
