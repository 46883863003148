import React, { Component } from "react";

import ProfessionalResultlink from "../../target/ProfessionalResultlink";
import PublicSectorResultlink from "../../target/PublicSectorResultlink";
import WorkGroupResultlink from "../../target/WorkGroupResultlink";
import WorkCultureReslutlink from "../../target/WorkCultureReslutlink";
import WorkAtmosphereResultlink from "../../target/WorkAtmosphereResultlink";
import ManagementResultlink from "../../target/ManagementResultlink";
import WorkEnvironmentResultlink from "../../target/WorkEnvironmentResultlink";

class TargetHrlink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test: 1,
      loading: false,
      showResult: false,
      hr: null
    };
  }

  componentDidMount() {
    const { hr } = this.props;
    if (hr) {
      this.setState({ hr: hr });
    }
  }

  nextTest() {
    let prevTest = this.state.test;
    if (prevTest === 7) {
      this.props.stepUp2();
    } else {
      prevTest = prevTest + 1;
      this.setState({ test: prevTest });
    }
  }

  render() {
    let { test, hr, loading } = this.state;

    let showTest;

    console.log(this.props.testId)

    if (test === 1) {
      showTest = <ProfessionalResultlink stepUp={this.nextTest.bind(this)} hr={hr} testcodes={this.props.testId} />;
    } else if (test === 2) {
      showTest = <PublicSectorResultlink stepUp={this.nextTest.bind(this)} hr={hr}  testcodes={this.props.testId} />;
    } else if (test === 3) {
      showTest = <WorkGroupResultlink stepUp={this.nextTest.bind(this)} hr={hr}  testcodes={this.props.testId} />;
    } else if (test === 4) {
      showTest = <WorkCultureReslutlink stepUp={this.nextTest.bind(this)} hr={hr}  testcodes={this.props.testId} />;
    } else if (test === 5) {
      showTest = <WorkAtmosphereResultlink stepUp={this.nextTest.bind(this)} hr={hr}  testcodes={this.props.testId}/>;
    } else if (test === 6) {
      showTest = <ManagementResultlink stepUp={this.nextTest.bind(this)} hr={hr}  testcodes={this.props.testId} />;
    } else if (test === 7) {
      showTest = <WorkEnvironmentResultlink stepUp={this.nextTest.bind(this)} hr={hr}  testcodes={this.props.testId}/>;
    } else {
      showTest = "";
    }

    if (loading)
      return (
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              <div className="text-center">
                <div
                  className="spinner-border m-5"
                  role="status"
                  style={{ width: "3rem", height: "3rem" }}>
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      );

    return (
      <React.Fragment>
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0">{showTest}</div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default TargetHrlink;
