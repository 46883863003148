import React, { Component } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import { confirmAlert } from 'react-confirm-alert'; // Import
import html2canvas from "html2canvas";
import * as jsPDF from "jspdf";
import { history } from "../helper/History";
import { APP_URL } from "../helper/Config";

import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

class DoAlert extends Component {
    constructor(props) {
        //console.log("Inside DoAlert => "+JSON.stringify(props));
        super(props);
        this.state = {
            loader2: false,
            redirectUrl: "",
            showDialog: false,
            testType: props.type,
            buttonName: props.buttonName,
            className: props.className,
            target: props.target,
            href: props.href,
            pdfName: null,                        
            canvasSelector: null
        };
    }

    submit = () => {
      const { href,buttonName } = this.props;
      var userType = localStorage.getItem('userRole');
      //console.log('userType '+userType);            

      //console.log('inside submit =>'+JSON.stringify(this.propsn));
      // const alreadySave   = 'It’s already saved';
      // const alertTitle    =  <FormattedMessage id="do.alert.title" defaultMessage="Did you save your results?"/>
      //const alertMessage  =  <FormattedMessage id="do.alert.subtitle" defaultMessage={`We are not keeping your result please save your result before {action} otherwise you will loose it.`} values={{action: buttonName}}/>
      // const saveNow       =  <FormattedMessage id="do.alert.saveButton" defaultMessage="I save now"/>
      // const alreadySave   =  <FormattedMessage id="do.alert.alreadySavebutton" defaultMessage="It’s already saved"/>

      const alertTitle    =  (this.props.lang == "en") ? "Did you save your results?" : (this.props.lang == "fr") ? "Avez-vous enregistré les résultats?" : "Did you save your results?";
      const alertMessage  =  (this.props.lang == "en") ? "We do not save your results so before you do another test please make sure you save your results first otherwise you will lose* them." : "Nous ne conservons pas les résultats, veuillez les sauvegarder avant de faire un autre test, sinon vous les perdrez.";
      const saveNow       =  (this.props.lang == "en") ? "I save now" : (this.props.lang == "fr") ? "J’enregistre maintenant" : "I save now";
      const alreadySave   =  (this.props.lang == "en") ? "It’s already saved" : (this.props.lang == "fr") ? "Ils sont déjà enregistrés" : "It’s already saved";
      
      
      confirmAlert({
        title: alertTitle,
        message: alertMessage,
        buttons: [
          {
            label: saveNow,
            onClick: () => {
              // nothing to do
              this.printResult();
            }
          },
          {
            label: alreadySave,
            onClick: () => {
              //console.log('href '+href+' == '+this.state.testType+' userType'+userType);              
              if(href === undefined){ // check conditoin if button type is do another test
                if(this.state.testType === null){ // if testtype is not defined
                  if(userType !== 'User'){
                    history.push("/hr"); 
                  }else{
                    history.push("/individual");
                  }
                }else{
                  if(this.state.testType === 'individual'){
                    history.push("/individual");
                  }else{
                    history.push("/hr");
                  }
                }
              }else{ // redirect to contact to coach
                history.push(href);
              }
            }
          }
        ]
      });
    };

    printResult(){
      const { canvasSelector, pdfName } = this.props;
      //console.log(canvasSelector+' -- '+pdfName);
      if(canvasSelector != undefined && pdfName != undefined){
        this.setState({ loader2: true });    
  
        let CurrentDate   =  new Date();
        let copyRightDate = CurrentDate.getFullYear();
    
        var pilotRhLogo = new Image();
        pilotRhLogo.src = APP_URL+'assets/images/logo.png';
    
        var PDF_Heighti   = document.querySelector('#'+canvasSelector).offsetWidth;
        //var HTML_Width    = $("#happinessTest").width();
        var HTML_Width    = 790;    
        //console.log('Html width => '+HTML_Width);
        var HTML_Height   = $('#'+canvasSelector).height();
        //console.log('Html height => '+HTML_Height);
        var top_left_margin = 5;
        var PDF_Width = HTML_Width+(top_left_margin*2);
        //var PDF_Height = 2000;
        var PDF_Height = HTML_Height+(top_left_margin*2);
        //console.log('PDF Height x Width => '+PDF_Height+'x'+PDF_Width);
        var canvas_image_width  = HTML_Width;
        var canvas_image_height = HTML_Height;
        
        var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;
        //console.log('NoOfPage => '+totalPDFPages);
        
        //html2canvas($("#happinessTest")[0],{allowTaint:true}).then(function(canvas) {
        html2canvas($('#'+canvasSelector)[0],{allowTaint:true}).then((canvas) => {
          canvas.getContext('2d');
          //console.log(canvas.height+" "+canvas.width);
          var imgData = canvas.toDataURL("image/jpeg", 1.0);
          var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
          //var pdf = new jsPDF();
          pdf.addImage(pilotRhLogo, 'png', 330, 10);
          pdf.addImage(imgData, 'JPG', 10, 80);
          for (var i = 1; i <= totalPDFPages; i++) {
            pdf.addPage(PDF_Width, PDF_Height);
            pdf.addImage(imgData, 'JPG', top_left_margin, -PDF_Heighti+(top_left_margin));
          }
          pdf.setFontSize(12);
          pdf.setTextColor(108, 117, 125);
          pdf.text(160, (PDF_Height-40),'© Copyright 5W PILOT RH SOLUTIONS '+ CurrentDate.getFullYear() +' – All right reserved – www.pilotrhsolutions.com.');
          pdf.setTextColor(23, 162, 184);
          pdf.setFontType("bold");
          pdf.text(310, (PDF_Height-20), localStorage.getItem("userName").toUpperCase()+' - '+("0" + (CurrentDate.getDate() + 1)).slice(-2) +'/'+ ("0" + (CurrentDate.getMonth() + 1)).slice(-2)  +'/'+ CurrentDate.getFullYear()+' - '+ CurrentDate.getHours()+':'+ CurrentDate.getMinutes());
          pdf.save(pdfName+".pdf");
          this.setState({ loader2: false });
        });
      }
    }    
  
    render() {
      const { testType,buttonName, className, target, href } = this.props;
      return (
        <React.Fragment>
            <button onClick={e => this.submit(e)} className={className} target={target} >
              {buttonName}
            </button>                       
        </React.Fragment>
      );
    }
  }
  const mapStateToProps = state => {
    return {
      isSignedIn: state.auth.isSignedIn,
      lang: state.locale.lang
    };
  };
  
  DoAlert = connect(
    mapStateToProps,
    {}
  )(DoAlert);

  export default DoAlert;

