import {
  GROUP_DATA,
  WORK_ENV_DATA,
  WORK_ENV_RESULT,
  PROFESSIONAL_DATA,
  PUBLIC_DATA,
  PUBLIC_SECTOR_RESULT,
  PROFESSIONAL_RESULT,
  WORK_GROUP_RESULT,
  WORK_CULTURE_DATA,
  WORK_CULTURE_RESULT,
  WORK_ATMOSPHERE_DATA,
  WORK_ATMOSPHERE_RESULT,
  MANAGEMENT_DATA,
  MANAGEMENT_RESULT
} from "../actions/Target";

const initialState = {
  GroupData: [],
  WorkGroupResult: [],
  WorkEnvData: [],
  WorkEnvResult: [],
  ProfessionalData: [],
  ProfessionalResult: [],
  PublicData: [],
  PublicSectorResult: [],
  WorkCultureData: [],
  WorkCultureResult: [],
  WorkAtmosphereData: [],
  WorkAtmosphereResult: [],
  ManagementData: [],
  ManagementResult: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GROUP_DATA:
      return { ...state, GroupData: action.payload };

    case WORK_GROUP_RESULT:
      return { ...state, WorkGroupResult: action.payload };

    case WORK_ENV_DATA:
      return { ...state, WorkEnvData: action.payload };

    case WORK_ENV_RESULT:
      return { ...state, WorkEnvResult: action.payload };

    case PROFESSIONAL_DATA:
      return { ...state, ProfessionalData: action.payload };

    case PROFESSIONAL_RESULT:
      return { ...state, ProfessionalResult: action.payload };

    case PUBLIC_DATA:
      return { ...state, PublicData: action.payload };

    case PUBLIC_SECTOR_RESULT:
      return { ...state, PublicSectorResult: action.payload };

    case WORK_CULTURE_DATA:
      return { ...state, WorkCultureData: action.payload };

    case WORK_CULTURE_RESULT:
      return { ...state, WorkCultureResult: action.payload };

    case WORK_ATMOSPHERE_DATA:
      return { ...state, WorkAtmosphereData: action.payload };

    case WORK_ATMOSPHERE_RESULT:
      return { ...state, WorkAtmosphereResult: action.payload };

    case MANAGEMENT_DATA:
      return { ...state, ManagementData: action.payload };

    case MANAGEMENT_RESULT:
      return { ...state, ManagementResult: action.payload };

    default:
      return state;
  }
}
