import axios from "axios";

import { ROOT_URL } from "../helper/Config";

export const AUTH_ERROR = "auth_error";
export const ENTREPRENEUR_DATA = "entrepreneur_data";
//export const RESULT_DATA = "result_data";

export function fetchEntrepreneur(
  token,
  hr = null,
  code = null,
  language,
  callback
) {
  let test_url;
  if (hr === null) {
    test_url = `${ROOT_URL}/entrepreneur/${language}`;
  } else {
    test_url = `${ROOT_URL}/entrepreneur/${language}/${hr}/${code}`;
  }
  return dispatch => {
    return axios
      .get(test_url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.data.message) {
          callback(response.data.message);
        } else {
          dispatch({
            type: ENTREPRENEUR_DATA,
            payload: response
          });
          //console.log(response);
          callback("success");
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
}

export function saveEntrepreneur(token, data, callback) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/entrepreneur`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        //console.log(response);
        if (response.data.score >= 0 && response.data.message === "success") {
          // let groupResult = [];
          // groupResult.push({
          //   name: "Exchange ideas",
          //   Your: response.data.scoreGroup.col1,
          //   Average: response.data.averageGroup.col1
          // });
          // groupResult.push({
          //   name: "Act or achieve in his or her own way",
          //   Your: response.data.scoreGroup.col2,
          //   Average: response.data.averageGroup.col2
          // });
          // groupResult.push({
          //   name: "Work at his or her own rate",
          //   Your: response.data.scoreGroup.col3,
          //   Average: response.data.averageGroup.col3
          // });
          // groupResult.push({
          //   name: "A good career development",
          //   Your: response.data.scoreGroup.col4,
          //   Average: response.data.averageGroup.col4
          // });
          // groupResult.push({
          //   name: "Express his or her wishes",
          //   Your: response.data.scoreGroup.col5,
          //   Average: response.data.averageGroup.col5
          // });
          // groupResult.push({
          //   name: "A job that brings freedom",
          //   Your: response.data.scoreGroup.col6,
          //   Average: response.data.averageGroup.col6
          // });
          // groupResult.push({
          //   name: "A job that build identity",
          //   Your: response.data.scoreGroup.col7,
          //   Average: response.data.averageGroup.col7
          // });
          // groupResult.push({
          //   name: "A job that is meaningful",
          //   Your: response.data.scoreGroup.col8,
          //   Average: response.data.averageGroup.col8
          // });

          callback(response.data.score, response.data.average, response.data.groupResult);
        } else {
          callback(0, 0, 0);
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.clear();
          dispatch({
            type: AUTH_ERROR,
            payload: error.response.data.message
          });
        }
      });
  };
}
