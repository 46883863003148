import React, { Component } from "react";
import { connect } from "react-redux";
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';
import Footer from "./includes/Footer";

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
    roles: []
    };
    window.scrollTo(0, 0); // scroll page to top
  }

  render() {
    return (
    <React.Fragment>
      <section>
      <div className="container about pages text-justify">
          <div className="row">
            {/* <div className="col-md-12 text-center text-justify">
                <h2 className="border-bottom pb-2">
                  <FormattedMessage id="FAQ.title" defaultMessage="The PILOT HR platform®"/>
                </h2>
            </div> */}
          </div>
          <div className="row">
            <div className="col-md-12">
               <h4 className="pb-2">
                  <FormattedMessage id="FAQ.title" defaultMessage="ABOUT PILOT RH"/>
               </h4>              
            </div>
          </div>         
          <div className="row">
            <div className="col-md-12">
              <FormattedHTMLMessage id="FAQ.desc"   defaultMessage='<p className="text-muted">PILOT RH Solutions is a French consulting firm founded in 1999 providing digital HR assessment tools for individuals, HR Professionals and enterprises. </p><p className="text-muted">Its solutions are used by large groups as Air France KLM, SAFRAN Snecma, Intermarché and many more of its franchises. </p><p className="text-muted">They provide various online quizzes/tests to assess the candidate’s ability, personality, business and entrepreneurial skills and used for high potentials recruitment. Its digital tools produce intuitive graphs to understand immediately the scope of candidates. </p><p className="text-muted">PILOT RH makes accessible the best and psychology and sociology scientific researches used around the world (C G Jung, E. Schein, R. Sainsaulieu, …). </p><p className="text-muted">It uses intelligent methodology of cross-checking weak signals to highlight the strengths of the candidate and correlation technologies to secure HR decisions. </p>'/>
            </div>
          </div>
          {/* <div className="row ">
            <div className="col-md-12 img-content">
              <FormattedHTMLMessage id="aboutus.desc_2"   defaultMessage="<div className='col-3 mb-2 p-0'><img src='assets/images/president-olivier-colson.png' alt='Olivier Colson' className='img-fluid rounded w-90'/> </div><div className='col pr-0'> <p className='text-muted'>Its founding President; Olivier COLSON, is economist, alumni of Sciences Po Paris, specialized very early in the research and assessment of high potentials profiles. He holds a Master`s degree in Econometrics (Paris-X-Nanterre), a Master`s degree in Economic Intelligence (Skémia Business School) and a Master`s degree in Economic History (Sorbonne-Paris IV).He coaches the careers of several CEO’s and managers for more than 20 years especially many Sciences Po alumni of Paris </p></div></div>"/>
              
            </div>            
          </div> */}
        </div>  
      </section>
      <Footer />            
    </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    isSignedIn: state.auth.isSignedIn,
    lang: state.locale.lang
  };
};

FAQ = connect(
  mapStateToProps,
  {}
)(FAQ);

export default FAQ;

