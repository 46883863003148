import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import Sortable from "react-sortablejs";
import Hash from "../../../helper/Hashing";
import ProgressBar from "react-bootstrap/ProgressBar";
//import html2canvas from "html2canvas";
//import * as jsPDF from "jspdf";
import { fetchMotivation, saveMotivation, motivationResult, personalityResult, combineResult } from "../../../actions/Talent";
import CustomerRights from "../../CustomerRights"; // used to show the two steps of customer right before start test 
import Testcopyrights from "../../Testcopyrights"; // used to show test copyright text under test 

// motivationResult
// import {
//   ResponsiveContainer,
//   ComposedChart,
//   Line,
//   LabelList,
//   Bar,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend
// } from "recharts";

class Motivation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: -2,    // talent motication question start from 0 thats why we set it to -2
      error: false,
      errorMsg: "",
      loading: true,
      user_id: null,
      language: null,
      result: [],
      ques0: "",
      ques1: "",
      ques2: "",
      ques3: "",
      ques4: "",
      ques5: "",
      question0: [],
      question0Answers: [],
      question1: [],
      question1Answers: [],
      question2: [],
      question2Answers: [],
      question3: [],
      question3Answers: [],
      question4: [],
      question4Answers: [],
      question5: [],
      question5Answers: [],
      persResult: [],
      persOrder: [],
      combineResultData: [],
      testid:null,
      now: 0
    };
  }

  componentDidMount() {
    if (this.props.isSignedIn) {
      // window.localStorage.clear();
      let language;
      if (this.props.lang === "en") language = "english";
      if (this.props.lang === "fr") language = "french";
      const { hr } = this.props;
      let hrId = null;
      let code = null;
      if (hr) {
        code = hr;
        hrId = Hash.decrypt(hr);
      }else{
        code = localStorage.getItem('sharedTestId');
      }

      if(code != "null"){
        code = localStorage.getItem('sharedTestId');
        //  window.location.reload(false);
      //  if(code == null) {
      //   //  window.location.reload(false);
      //  }
       if(code!=null){
       hrId =Hash.decrypt(code);
       }
     }
    
      //console.log('Props => '+JSON.stringify(this.props));
      let testType = localStorage.getItem('testType');
      // set the test type to state 
      this.setState({ testType: testType });           

      const token = localStorage.getItem("token");

      this.setState({testid:code});
 
      // window.location.reload(false);
   
      if(localStorage.getItem("sharedTestId")!=null){
        // window.location.reload(false);
        this.setState({testid:this.props.codex}); 
      }else{
        this.setState({testid:this.props.codex}); 
      }
    
      
      // console.log('Response fetchMotivation => '+JSON.stringify(this.props)+' hrId '+hrId+' code '+code);
      this.props.fetchMotivation(token, hrId, code, language, resp => {
      //console.log('Response ==> '+resp);            
        if (resp === "success") {
          this.setState({ loading: false });
        }else if (resp === "used") {
          this.setState({
            loading: false,
            error: true,
            errorMsg: "Test already taken"
          });
        
        }else if (resp === "no test") {
          this.setState({
            loading: false,
            error: true,
            errorMsg: "Test already taken"
          });
          this.props.history.push("/buy-test");
        }
        else if (resp === "error") {
          this.setState({
            loading: false,
            error: true,
            errorMsg: "Error occur, please try again!!"
          });
        }
      });
      // show result
      // const token = localStorage.getItem("token");
      this.props.personalityResult(token, response => {
       // console.log(response);
        if(response.result){
          var selected = response.result.selectedValues;
          var notselected = response.result.notSelectedValues;
          var finalOrder = selected.concat(notselected);
          this.setState({ persResult: finalOrder });
        }
      });

      this.props.motivationResult(token, response => {

        this.setState({ loading: false, result: response });
      });

    }
  }

  getCombineResult(){
    //console.log('in combine');
    
    const token = localStorage.getItem("token");
    var sendData = {'motivation': this.state.result, 'personality': this.state.persResult}
    this.props.combineResult(token, sendData, response => {
      //console.log(response);
      
      this.setState({  combineResultData: response.data.data });
    });
  }

  returnTableThead(data){
    //let inc = 1;
    //console.log('Inside return Thead'+JSON.stringify(data));
    return data.map((thead, key) => {
        return (
            <th key={key}>{thead.label}</th>
        );       
    });  
  };
    
  returnCombinedResultTableTr(motivResult, persResult){
    //let inc = 1;
    //console.log('motivResult => '+JSON.stringify(motivResult));
    //console.log('persResult => '+JSON.stringify(persResult.result));
  //   var data = [
  //     {
  //         id : "001",
  //         label : "Motivating Factors \ Personalities",
  //         thead : [
  //           {
  //             label : "Manager Organizer",
  //           },
  //           {
  //             label : "Innovator Inventor",
  //           },
  //           {
  //             label : "Doer",
  //           },
  //           {
  //             label : "Communicator",
  //           }
  //       ]
  //     },
  //     {
  //         id : "002",
  //         label : "Expertise",
  //         column : [
  //           {
  //             value : "1_1",
  //           },
  //           {
  //             value : "1_2",
  //           },
  //           {
  //             value : "1_3",
  //           },
  //           {
  //             value : "1_4",
  //           }
  //       ]
  //     },
  //     {
  //         id : "003",
  //         label : "Harmonious Lifestyle",
  //         column : [
  //           {
  //             value : "2_1",
  //           },
  //           {
  //             value : "2_2",
  //           },
  //           {
  //             value : "2_3",
  //           },
  //           {
  //             value : "2_4",
  //           }
  //         ]
  //     },
  //     {
  //       id : "004",
  //       label : "Secutiry",
  //       column : [
  //         {
  //           value : "3_1",
  //         },
  //         {
  //           value : "3_2",
  //         },
  //         {
  //           value : "3_3",
  //         },
  //         {
  //           value : "3_4",
  //         }
  //       ]
  //     },
  //     {
  //       id : "005",
  //       label : "Enterpnureship",
  //       column : [
  //         {
  //           value : "4_1",
  //         },
  //         {
  //           value : "4_2",
  //         },
  //         {
  //           value : "4_3",
  //         },
  //         {
  //           value : "4_4",
  //         }
  //       ]
  //     },
  //     {
  //       id : "006",
  //       label : "Autonomy",
  //       column : [
  //         {
  //           value : "5_1",
  //         },
  //         {
  //           value : "5_2",
  //         },
  //         {
  //           value : "5_3",
  //         },
  //         {
  //           value : "5_4",
  //         }
  //       ]
  //     },
  //     {
  //       id : "007",
  //       label : "Challenges",
  //       column : [
  //         {
  //           value : "6_1",
  //         },
  //         {
  //           value : "6_2",
  //         },
  //         {
  //           value : "6_3",
  //         },
  //         {
  //           value : "6_4",
  //         }
  //       ]      
  //     },
  //     {
  //       id : "008",
  //       label : "Leadership",
  //       column : [
  //         {
  //           value : "7_1",
  //         },
  //         {
  //           value : "7_2",
  //         },
  //         {
  //           value : "7_3",
  //         },
  //         {
  //           value : "7_4",
  //         }
  //       ]
  //     },
  //     {
  //       id : "009",
  //       label : "Services to other",
  //       column : [
  //         {
  //           value : "8_1",
  //         },
  //         {
  //           value : "8_2",
  //         },
  //         {
  //           value : "8_3",
  //         },
  //         {
  //           value : "8_4",
  //         }
  //       ]
  //     }        
  // ];
  
    return this.state.combineResultData.map((val, key) => {
      //create thead dynamicaly and set condition 
      if (key === 0) {
        
        return (
          <tr key={key}>
            {key < 0 ? <td>0</td> : <td>Motavating Factors \ Personalities</td>}
            {this.returnTableThead(val.thead)}
          </tr>
        );       
      } else {
        // else create rows dynamically
        //console.log('Combined Result '+val.column);
        return (
          <tr key={key}>
          <td>{val.label}</td>
            <td>
            {val.column[0].value}
            </td>
            <td>
            {val.column[1].value}
            </td>
            <td>
            {val.column[2].value}
            </td>
            <td>
            {val.column[3].value}
            </td>                                                
          </tr>
        );        
      }
    });  
    // return motivResult.map((val, key) => {
    //   return (
    //           <tr>
    //           {key < 0 ? <td>0</td> : <td>{val.label}</td>}
    //             <td>
    //                {(inc = key)}
    //             </td>
    //             <td>
    //                {(inc = key)}
    //             </td>
    //             <td>
    //                {(inc = key)}
    //             </td>
    //             <td>
    //                {(inc = key)}
    //             </td>                                                
    //           </tr>
    //   );
    // });
  }

  renderCombineResult(motivResult, persResult){
    //console.log('persResult => '+persResult);
    let inc = 1;
    //let combinedResultData = '';
      return (
        <React.Fragment key={(inc = inc + 1)}>
          <div className="card border-0">
            <table border="1px solid red">
            <tbody>
            {this.returnCombinedResultTableTr(motivResult, persResult)}
            </tbody>
            </table>
            {this.renderNotSelectedData(motivResult)}
          </div>
        </React.Fragment>
      );
  }

   renderResult(data) {
        
    //var background = { color: "white" };
    if (data.result) {
      let firstAns = data.firstQuesAns;
    let thirdAns = data.thirdQuesAns;
    let inc = 1;
      return data.result.selected.map((labels, index) => {
        return (
          <React.Fragment key={(inc = inc + 1)}>
            <div className="card border-0">
              {firstAns === thirdAns && (
                <div className="row border">
                  <div className="col-sm col-md-3 bg-primary text-white d-flex align-items-center font-weight-bold">
                    {labels["name" + firstAns]}
                  </div>
                  <div className="col-md-9 p-0 border-0">
                    <div className="px-2 pt-2 font-weight-bold">
                      {labels["label" + firstAns]}
                    </div>
                    <div className="px-2 pb-2">{labels["desc" + firstAns]}</div>
                  </div>
                </div>
              )}
              {firstAns !== thirdAns && (
                <div className="row border">
                  <div className="col-sm col-md-3 bg-primary text-white d-flex align-items-center font-weight-bold">
                    {labels["name" + firstAns]}
                  </div>
                  <div className="col-md-9 p-0 border-0">
                    <div className="px-2 pt-2 font-weight-bold">
                      {labels["label" + firstAns]}
                    </div>
                    <div className="px-2 pb-2">{labels["desc" + firstAns]}</div>
                  </div>
                </div>
              )}
              {firstAns !== thirdAns && (
                <div className="row border">
                  <div className="col-sm col-md-3 bg-primary text-white d-flex align-items-center font-weight-bold">
                    {labels["name" + thirdAns]}
                  </div>
                  <div className="col-md-9 p-0 border-0">
                    <div className="px-2 pt-2 font-weight-bold">
                      {labels["label" + thirdAns]}
                    </div>
                    <div className="px-2 pb-2">{labels["desc" + thirdAns]}</div>
                  </div>
                </div>
              )}
  
              {this.renderNotSelectedData(data)}
            </div>
            <div className="d-none">{(inc = inc + 1)}</div>
          </React.Fragment>
        );
      });
    }
  }
  
   renderNotSelectedData(data) {
    let inc = 1;
  
    if (data.result) {
      return data.result.notSelected.map((labels, index) => {
        return (
          <React.Fragment key={(inc = inc + 1)}>
            <div className="card border-0">
              {data.result.notSelectedValues.map((value, key) => {
                return (
                  <div className="row border" key={(inc = inc + 1)}>
                    <div className="col-sm col-md-3 bg-secondary text-white d-flex align-items-center font-weight-bold">
                      {labels["name" + value]}
                    </div>
                    <div className="col-md-9 p-0 border-0">
                      <div className="px-2 pt-2 font-weight-bold">
                        {labels["label" + value]}
                      </div>
                      <div className="px-2 pb-2">{labels["desc" + value]}</div>
                    </div>
                  </div>
                );
              })}
            </div>
  
            <div className="d-none">{(inc = inc + 1)}</div>
          </React.Fragment>
        );
      });
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.Motivation !== prevProps.Motivation) {
      //console.log(this.props.Motivation);
      this.props.Motivation.data.map((elem, index) =>
        this.setState({
          ["question" + index]: elem.options,
          ["ques" + index]: elem.question
        })
      );
    }
  }

  componentWillUnmount() {
    this.setState({
      loading: false
    });
  }

  renderTestTitle() {
    let { page, testType } = this.state;
    let testId = localStorage.getItem('sharedTestId'); // get testid from
    // let p=this.props.macth.params.op;
    // console.log(p);
    if (testType !=='professional' ) {
      // render the main title for first two steps only 
      //console.log('Inc page =>'+page);
      if(testId !== null && page < 0 ){
        
        return (
          <h2 className="text-center mb-1">Coaching Tool</h2>
        );
      }
      if(page >= 0){
        return (
          <h2 className="text-center mb-1">Motivation</h2>
        );
      }
     
      // }else{
      //   return (
      //     <h2 className="text-center mb-1">Talent Test</h2>
      //   );
      // }
      // render sub test tiles       
      if(page >= 0){
        return (
          <h2 className="text-center mb-1">Motivation</h2>
        );
      }
    } else {
      // render the main title for first two steps only 
      if(page < 0){
        return (
          <h2 className="text-center mb-1">Coaching Tool</h2>
        );
      }
      // render sub test tiles             
      if(page >= 0){
        return (
          <h2 className="text-center mb-1">Motivation</h2>
        );
      }      
    }
  }

  renderQuestions() {
    let { page } = this.state;
    //console.log(this.state);
    // show two stepe candidate rights option before start test     
    if(page >= 0){
      var questions = [0, 1, 2, 3, 4, 5];
      return questions.map((value, i) => {
        let ques = "question" + i;
        let quesAns = "question" + i + "Answers";
  
        const question = this.state[ques].map((val, key) => (
          //const question = Object.entries(this.state[ques]).map(([key, val]) => (
          <li className="list-group-item" key={key} data-id={val}>
            {val}
          </li>
        ));
        const questionAnswers = this.state[quesAns].map((val, key) => (
          <li className="list-group-item item-clr" key={key} data-id={val}>
            {val}
          </li>
        ));
        return (
          <React.Fragment key={value}>
            {page === i && (
              <div className="row p-2 mb-2">
                <div className="col-md-12 mx-auto mb-0">
                  <div className="jumbotron mb-2 px-2 py-1">
                    <p className="lead mb-0 font16">{this.state["ques" + i]}</p>
                  </div>
                </div>
                <div className="col-md-12 mx-auto mb-0">
                  <div className="jumbotron  mb-0 p-1 mt-2">
                      <p className="mb-0 font14">
                        <FormattedMessage id="ind.talent.motivation.expl" defaultMessage="Please select each icon in the left hand column by a mouse click, and drag it towards the appropriate box  in the right hand column (N°1 is the most important). You can move icons around in the right hand column until they are in the right order."/>
                      </p>
                  </div>
                </div>
                <div className="col-md-5 m-auto">
                  <Sortable
                    options={{
                      animation: 150,
                      sort: false,
                      group: {
                        name: "clone2",
                        pull: true,
                        put: false
                      }
                    }}
                    className="list-group shadow minHeight"
                    onChange={items => {
                      this.setState({ [ques]: items });
                    }}
                    tag="ul"
                  >
                    {question}
                  </Sortable>
                </div>
                <div className="col-md-2 m-auto pr-0 dragdropno">
                  <ul className=" list-group minHeight text-right bg-trans">
                    <li className="list-group-item border-0">n°1</li>
                    <li className="list-group-item border-0">n°2</li>
                    <li className="list-group-item border-0">n°3</li>
                    <li className="list-group-item border-0">n°4</li>
                    <li className="list-group-item border-0">n°5</li>
                    <li className="list-group-item border-0">n°6</li>
                    <li className="list-group-item border-0">n°7</li>
                    <li className="list-group-item border-0">n°8</li>
                  </ul>
                </div>                
                <div className="col-md-5 m-auto dragdropno1">
                  <Sortable
                    options={{
                      animation: 150,
                      group: {
                        name: "clone2",
                        pull: true,
                        put: true
                      }
                    }}
                    className="list-group minHeight shadow"
                    onChange={items => {
                      this.setState({ [quesAns]: items });
                    }}
                    tag="ul"
                  >
                    {questionAnswers}
                  </Sortable>
                  {/* <button onClick={e => this.getNewSorted(e)}>Get result</button> */}
                </div>
                {/* <div className="col-md-12 mx-auto mb-0">
                  <div className="jumbotron  mb-0 p-1 mt-2">
                      <p className="mb-0 font14">
                        <FormattedMessage id="ind.talent.motivation.expl" defaultMessage="Please select each icon in the left hand column by a mouse click, and drag it towards the appropriate box  in the right hand column (N°1 is the most important). You can move icons around in the right hand column until they are in the right order."/>
                      </p>
                  </div>
                </div> */}
              </div>
            )}
          </React.Fragment>
        );
      });
    } else {
      return (
        <CustomerRights page={page+1} />
      );            
      //console.log('Inside 2 steps '+page);
    }

  }

  renderProgress() {
    const { now, page } = this.state;



    if (page > 0 ) {
      return <ProgressBar now={now} label={`${now}%`} className="mb-3" />;
    } else {
      return null;
    }
  }

  nextQuestion = () => {

    let { page } = this.state;
    // show two stepe candidate rights option before start test 
    window.scrollTo(0,0);
    if(page >= 0){
      if(localStorage.getItem('testType')=='individual'){
      let progres = (100 /13) * page;
      let progress= progres.toFixed(2);
      // let quesAns = "question" + page + "Answers";
  
      this.setState({ page: page +1, now: progress });
      localStorage.setItem('page',this.state.page+1);
      localStorage.setItem('now',this.state.now)
    }else{
    let progres = (100 /42) * page;
    let progress= progres.toFixed(2);

    this.setState({ page: page +1, now: progress });
    localStorage.setItem('page',this.state.page+1);
    localStorage.setItem('now',this.state.now)}
    let quesAns = "question" + page + "Answers";
      if (this.state[quesAns].length < 8) {
        this.setState({ error: true, errorMsg: "Drag all items" });
        setTimeout(
          function() {
            this.setState({ error: false });
          }.bind(this),
          5000
        );
      } else {
        this.setState({ page: page + 1, error: false });
      }
    } else{
      this.setState({ page: page + 1, error: false }); // increatement steps in case till then page set 1       
      //console.log('No validate');
    }
  };

  submitMotivation = () => {
    const token = localStorage.getItem("token");
  

    let { page } = this.state;
    let quesAns = "question" + page + "Answers";

    if (this.state[quesAns].length < 8) {
      this.setState({ error: true, errorMsg: "Drag all items" });
      setTimeout(
        function() {
          this.setState({ error: false });
        }.bind(this),
        5000
      );
    } else {
      this.setState({ error: false, loading: true });
      let language;
      if (this.props.lang === "en") language = "english";
      if (this.props.lang === "fr") language = "french";
      var data = {
        question1: this.state.question0Answers,
        question2: this.state.question1Answers,
        question3: this.state.question2Answers,
        question4: this.state.question3Answers,
        question5: this.state.question4Answers,
        question6: this.state.question5Answers,
        user_id: localStorage.getItem("userId"),
        language: language,
        testcode:this.state.testid
      };
      this.props.saveMotivation(token, data, response => {
        if (response === "success") {
          this.setState({ loading: false });
          this.props.stepUp();
        }
      });
      
    }
  };

  renderButton() {
    let { page } = this.state;
    if (page < 0) {
      return (
        <div className="d-table m-auto">
          <button className="btn btn-info mt-3" onClick={this.nextQuestion}>
            <FormattedMessage id="start" defaultMessage="START"/>
          </button>
        </div>
      );
    }else if (page < 5) {      
      return (
        <div className="d-table m-auto">
          <button className="btn btn-info mt-3" onClick={this.nextQuestion}>
          <FormattedMessage id="next" defaultMessage="NEXT"/>
          </button>
        </div>
      );
    } else {
      return (
        <div className="d-table m-auto">
          <button className="btn btn-info" onClick={this.submitMotivation}>
            <FormattedMessage id="submit" defaultMessage="Submit"/>
          </button>
        </div>        
      );
    }
  }

  render() {
    let { error, loading, errorMsg, page } = this.state;

    // const getDescription = label => {
    //   let des = result.filter(elem => {
    //     return elem.label === label;
    //   });
    //   if (des.length > 0) {
    //     return des[0].desc;
    //   } else {
    //     return null;
    //   }
    // };
    // const CustomTooltip = ({ active, payload, label }) => {
    //   if (active) {
    //     return (
    //       <div className="custom-tooltip">
    //         <p className="label">
    //           <b>{`${label} : ${payload[0].value}`}</b>
    //         </p>
    //         <p className="desc">{getDescription(label)}.</p>
    //       </div>
    //     );
    //   }

    //   return null;
    // };

    if (loading)
      return (
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              <div className="text-center">
                <div
                  className="spinner-border m-5"
                  role="status"
                  style={{ width: "3rem", height: "3rem" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    return (
      <React.Fragment>
         {this.renderTestTitle()}
        {/* {this.state.testType !='professional' && <h2 className="text-center mb-4">Motivation Test</h2>}
        {this.state.testType =='professional' && <h2 className="text-center mb-4">Coaching Career</h2>} */}
        <div className="col-lg-8 col-md-10 col-sm-12 m-auto p-90">
          {error && <div className="alert alert-danger py-1 px-2 mb-1">{errorMsg}</div>}

          
                 {/* <div style={{ width: "90%", height: 500 }} className="m-auto" id="result-1">
                  <ResponsiveContainer>
                    <ComposedChart
                      layout="vertical"
                      width={500}
                      height={400}
                      data={result}
                      margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20
                      }}
                    >
                      <CartesianGrid stroke="#f5f5f5" />
                      <XAxis type="number" domain={[0, 100]} />
                      <YAxis
                        dataKey="label"
                        type="category"
                        textAnchor="end"
                        interval={0}
                        width={200}
                      />
                      <Tooltip content={<CustomTooltip />} />
                      <Bar dataKey="score" barSize={20} fill="#413ea0">
                        <LabelList dataKey="score" position="right" />
                      </Bar>
                      <Line dataKey="mid" stroke="#ff7300" />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div> */}
                {/* <button onClick={e => this.printResult(e)}  className="btn btn-info">Print result</button>  */}
          {/* {this.renderResult(this.state.persResult)} */}
          {/* {this.renderCombineResult(this.state.result, this.state.persResult)} */}
          {this.renderProgress()}
          {this.renderQuestions()}
          {this.renderButton()}
          {/* <button onClick={e => this.getCombineResult()}>get combine result</button> */}
        </div>
        {page > 0 && ( // check page and hide the title from first two steps i.e user explanations
          <Testcopyrights />
        )}        
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    Motivation: state.talent.MotivationData.data,
    Result: state.talent.MotivationResult,
    isSignedIn: state.auth.isSignedIn,
    lang: state.locale.lang
  };
};

export default connect(
  mapStateToProps,
  { fetchMotivation, saveMotivation, motivationResult, personalityResult, combineResult }
)(Motivation);

// BEGIN
// 	UPDATE user_test SET no_of_test = no_of_test - 1 WHERE user_id = NEW.hr_id;
//  END