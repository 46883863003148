import {
  MOTIVATION_DATA,
  MOTIVATION_RESULT,
  PERSONALITY_DATA,
  PERSONALITY_RESULT,
  COMMUNICATION_DATA,
  COMMUNICATION_RESULT,
  COMBINE_RESULT,
  MOTIVATIONS_DATA,
  MOTIVATIONS_DATAS,
} from "../actions/Talent";

const initialState = {
  MotivationData: [],
  MotivationResult: [],
  PersonalityData: [],
  PersonalityResult: [],
  CommunicationData: [],
  CommunicationResult: [],
  CombineResult: [],
  MotivationsData:null,
  MotivationsDatas:null,
};

export default function(state = initialState, action) {
  //console.log('Reducer => '+JSON.stringify(state)+' action'+JSON.stringify(action));
  // console.log(action.payload);
  switch (action.type) {
    case MOTIVATION_DATA:
      return { ...state, MotivationData: action.payload };
    
    case MOTIVATION_RESULT:
      return { ...state, MotivationResult: action.payload };

      case MOTIVATIONS_DATA:
        return { ...state, MotivationsData: action.payload };

        case MOTIVATIONS_DATAS:
          return { ...state, MotivationsDatas: action.payload };


    case PERSONALITY_DATA:
      return { ...state, PersonalityData: action.payload };

    case PERSONALITY_RESULT:
      return { ...state, PersonalityResult: action.payload };

    case COMMUNICATION_DATA:
      return { ...state, CommunicationData: action.payload };

    case COMMUNICATION_RESULT:
      return { ...state, CommunicationResult: action.payload };
      
      case COMBINE_RESULT:
        return { ...state, CombineResult: action.payload };
  
    default:
      return state;
  }
}
