import React from "react";
import { FormattedMessage } from "react-intl";

import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  LabelList,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";

// function used to render the explanation of motivational hover effect 
function RenderResultExpliation(resultData, testType) {
  //console.log('Inside RenderResultExpliation'+resultData);
  if (resultData) {
    return resultData.map((value, index) => {
      //console.log('testType' +testType+' i '+index);
        return (
          <React.Fragment key={index}>
            <li className="greenclass flex-1" key={index} >
              <span className="font-weight-bold">{value.label} : {value.score}</span>
              <p className="mb-0">{value.desc}</p>
            </li>
          </React.Fragment>
        );        
    });
  }
}






function MotivationResult(props) {
  //console.log('Here we go +++++ ');
  let { result, testType } = props;
  //console.log('testType '+testType+' -- '+result);
  // check condition based on test type and show only three graph for individual test 
  if(testType === 'individual'){
    //console.log(' Before  '+JSON.stringify(result));
    var i;
    var resultData =[]; //define empty array 
    for (i = 0; i < 3; i++) { 
      //console.log(' Loop  '+i+' result '+JSON.stringify(Object.values(result)[i]));
      resultData.push(Object.values(result)[i]); // assign only first three to result data 
    }
    result = resultData; // set result data to result 

  }
  // const getDescription = label => {
  //   let des = result.filter(elem => {
  //     return elem.label === label;
  //   });
  //   if (des.length > 0) {
  //     return des[0].desc;
  //   } else {
  //     return null;
  //   }
  // };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          <p className="label">
            <b>{`${label} : ${payload[0].value}`}</b>
          </p>
          {/* <p className="desc">{getDescription(label)}.</p> */}
        </div>
      );
    }

    return null;
  };

  return (
    <React.Fragment>
      {/* <div className="col-lg-10 col-md-10 col-sm-12 m-auto p-0"> */}
      <div className="m-auto p-0">
        {testType === 'individual' && ( /// check type of test individual
          <h4 className="text-center mb-1 mt-5">
            <FormattedMessage id="ind.talent.your.motivation.title" defaultMessage="Your motivations"/>
          </h4>
        )}
        {props.testType !== 'individual' && ( /// check type of test professional
          <div>
            <h3 className="text-center mb-4">TALENT</h3>
          
            <h4 className="text-center mb-1 mt-5">
              <FormattedMessage id="ind.talent.motivation.title" defaultMessage="Motivations"/>
            </h4>
            {(localStorage.getItem("u")?<h3 className="mb-2 float-right" style={{fontSize:"15px",marginTop:"-75px"}}>{localStorage.getItem("u")} {localStorage.getItem("u2")}</h3>
             :"")} 
          </div>
        )}        
        {/* Candidate:  {localStorage.getItem("u")} {localStorage.getItem("u2")} */}
        <div className="row">
          <div className="col-md-6">        
            <div style={{ width: "100%", height: 500 }} className="m-auto">
              <ResponsiveContainer>
                <ComposedChart
                  layout="vertical"
                  width={500}
                  height={400}
                  data={result}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20
                  }}
                >
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis type="number" domain={[0, 100]} />
                  <YAxis
                    dataKey="label"
                    type="category"
                    textAnchor="end"
                    interval={0}
                    width={200}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar dataKey="score" barSize={20} fill="#17a2b8">
                    <LabelList dataKey="score" position="right" />
                  </Bar>
                  <Line dataKey="mid" stroke="#ff7300" />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="col-md-6">
            <ul className="custom-list h-100 d-flex flex-column small">
                {RenderResultExpliation(result, testType)}
                {/* one additional li to makr labels equlant to graphs  */}
                {testType !== 'individual' && ( /// check type of test individual
                  <li className="greenclass flex-1" ><span className="font-weight-bold"></span><p className="mb-0"></p></li>
                )}
            </ul>
          </div>
        </div>        
      </div>
    </React.Fragment>
  );
}

export default MotivationResult;
