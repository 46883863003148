import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { ROOT_URL, APP_URL } from "../../helper/Config";
import { FormattedMessage } from "react-intl";
import ProgressBar from "react-bootstrap/ProgressBar";
import $ from "jquery";
//import html2canvas from "html2canvas";
//import { history } from "../../helper/History";
//import * as jsPDF from "jspdf";
import * as jsPDF from "jspdf";

import Hash from "../../helper/Hashing";
import CustomerRights from "../CustomerRights"; // used to show the two steps of customer right before start test 
import Testcopyrights from "../Testcopyrights"; // used to show test copyright text under test 
import DoAlert from "../DoAlert"; // used to render the do another test button
import PrintPdf from "../PrintPdf"; // used to print resutl PDF
import NeedToImprove from "../NeedToImprove"; // used to render the Need to improve in test result


import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  PieChart, Pie, Label,  
  ResponsiveContainer
} from "recharts";

import { fetchSale, saveSale,resultSale } from "../../actions/Sale";
import { updateTestConsume } from "../../actions";

// // function is used to render the result title what need to improve to user
// function ResultActionNeedToPerform(props) {
//   if (props.sum > 480) {
//     return (
//       <div className="row">
//         <div className="col-md-12">      
//           <p>Generally Satisfied</p>
//         </div>
//       </div>
//     );
//   }else{
//     return false;
//   }
// }

class Resultsale extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: -1,
      now: 0,
      loading: true,
      loader2: false,      
      error: false,
      errorMsg: "",
      sectionResult: null,      
      test_id: null,
      user_id: null,
      userScore: null,
      average: null,
      scoreGroup: null,
      averageGroup: null,
      chart2: null,
      add:"",
      global:"",
      sum:[],
      resultData: [],
      needToImprove: null,      
      resultBtn: false,
      showChart: false,
      test_version_id: null,
      language:this.props.lang,
      answer1: null,
      answer2: null,
      answer3: null,
      answer4: null,
      answer5: null,
      answer6: null,
      answer7: null,
      answer8: null,
      answer9: null,
      answer10: null,
      answer11: null,
      answer12: null,
      answer13: null,
      answer14: null,
      answer15: null,
      answer16: null,
      answer17: null,
      answer18: null,
      answer19: null,
      answer20: null,
      answer21: null,
      answer22: null,
      answer23: null,
      answer24: null,
      answer25: null,
      answer26: null,
      answer27: null,
      answer28: null,
      answer29: null,
      answer30: null,
      answer31: null,
      answer32: null,
      answer33: null,
      answer34: null,
      answer35: null,
      answer36: null,
      answer37: null,
      answer38: null,
      answer39: null,
      answer40: null,
      invalid: true,
      linkcode:this.props.match.params.id,
      totaltest:"",
      newaverage:"",
      totalTestc:"",
      globalAverage:"",
      globalav:"",
      usernamep:null,
      usernamep2:null
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    // if (this.props.isSignedIn) 
    // {
      const { hr, testId } = this.props.match.params;          
      //console.log('Response => '+JSON.stringify(this.props));
      let testType = localStorage.getItem('testType');
      //console.log('Response => '+JSON.stringify(this.props.location)+' testType '+testType);    
      // set the test type to state 
      this.setState({ testType: testType }); 

      // set testId to localstorage to make check not deduct a test at the end 
      if(testId !== undefined && testId !== ''){
        localStorage.setItem("sharedTestId", testId);
      }else{
        localStorage.setItem("sharedTestId", null);      
      }    
 

      let language;
      if (this.props.lang === "en") language = "english";
      if (this.props.lang === "fr") language = "french";
      
      let hrId = null;
      let code = null;
      //console.log('Check hr '+hr+' -- '+testId+' param '+JSON.stringify(this.props.match.params, null, 4));
      if (hr) {
        code = hr;
        hrId = (hr !== undefined) ? Hash.decrypt(hr) :'';
        this.setState({ cod: hr }); 
        this.setState({ HrId: hrId }); 
        const link=`${APP_URL}Result/${code}`;
        this.setState({resultlink: link});
      }


      this.setState({ loading: true });
      const token = localStorage.getItem("token");
  
      this.props.resultSale(
        token,
        this.state,
        (testScore, averageScore, groupResult, sectionResult,needToImprove,totaltest,newaverage,globalav,globalAverage,totalTestc,username,username2) => {
        //console.log('render => '+JSON.stringify(needToImprove, null, 4)+' -- '+needToImprove);        
          this.setState({ loading: false });
          if (testScore >= 0) {
            this.setState({
              userScore: testScore,
              average: averageScore,
              chart2: groupResult,
              sectionResult: sectionResult,
              needToImprove: needToImprove,            
              resultBtn: true,
              totaltest:totaltest,
              newaverage:newaverage,
              globalAverage:globalAverage,
            totalTestc:totalTestc,
              globalav:globalav,
              usernamep:username,
              usernamep2:username2

            });
          }
        }
      );
  // else{
      //   hrId = (testId !== undefined) ? Hash.decrypt(testId) : localStorage.getItem("userId");
      //   code = testId;        
      // }

    //   const token = localStorage.getItem("token");
    //   this.props.fetchSale(token, hrId, code, language, resp => {
    //     if (resp === "success") {
    //       this.setState({ loading: false });
    //     }
    //     if (resp === "no test") {
    //       console.log("no test");
    //       this.setState({ loading: false });
    //       this.props.history.push("/buy-test");
    //     }
    //     if (resp === "used") {
    //       this.setState({
    //         loading: false,
    //         error: true,
    //         errorMsg: "Test already taken"
    //       });
    //     }

    //     if (resp === "error") {
    //       this.setState({
    //         loading: false,
    //         error: true,
    //         errorMsg: "Error occur, please try again!!"
    //       });
    //     }
    //   });
    // }
  }

  //renderField({ input, label, type, meta: { touched, error } }) {
  // renderField(field) {
  //   return (
  //     <div className="form-check">
  //       <label className="form-check-label">
  //         <input
  //           type="radio"
  //           className={field.class}
  //           value={field.rbvalue}
  //           onClick={() => {
  //             field.handleChange(field.rbvalue);
  //           }}
  //           {...field.input}
  //         />
  //         {field.label}
  //       </label>
  //     </div>
  //   );
  // }

  // selectAnswer(val) {
  //   //console.log(val);
  //   var { page } = this.state;
  //   const questionNo = "answer" + page;
  //   this.setState({
  //     [questionNo]: val
  //   });

  //   $("#error-message").hide();
  // }

  // renderAnswers(answers, questionId, quesNo) {
  //   if (answers.length > 0) {
  //     return answers.map(answer => {
  //       return (
  //         <li className="list-group-item" key={answer.answer_id}>
  //           <Field
  //             name={"answer" + questionId}
  //             class={"form-check-input answer" + quesNo}
  //             component={this.renderField}
  //             label={answer.answer}
  //             rbvalue={answer.score}
  //             handleChange={e => this.selectAnswer(e)}
  //           />
  //         </li>
  //       );
  //     });
  //   }
  // }

  // renderQuestions() {
  //   const { page } = this.state;
  //   let inc = 1;
  //   // show two stepe candidate rights option before start test     
  //   if(page >0){
  //     if (this.props.test) {
  //       return this.props.test.questions.map((question, index) => {
  //         return (
  //           <React.Fragment key={question.question_id}>
  //             {page === inc && (
  //               <div className="card">
  //                 <div className="card-header">{inc}. {question.question}</div>
  //                 <ul className="list-group list-group-flush">
  //                   {this.renderAnswers(
  //                     question.answers,
  //                     question.question_id,
  //                     index
  //                   )}
  //                 </ul>
  //               </div>
  //             )}
  //             <div className="d-none">{(inc = inc + 1)}</div>
  //           </React.Fragment>
  //         );
  //       });
  //     }
  //   } else{
  //     return (
  //       <CustomerRights page={page} />        
  //       );            
  //   //console.log('Inside 2 steps '+page);
  //   }
  // }

  // nextQuestion() {
  //   //console.log(this.state);
  //   const { page } = this.state;
  //   let quesNo = page - 1;
  //   // show two stepe candidate rights option before start test 
  //   if(page >0){
  //     if ($(`.answer${quesNo}`).is(":checked")) {
  //       let progress = (100 / 40) * page;
  //       this.setState({ page: page + 1, now: progress });
  //       $("#error-message").hide();
  
  //       if (page === 40) {
  //         let language;
  //         if (this.props.lang === "en") language = "english";
  //         if (this.props.lang === "fr") language = "french";
  //         this.setState({
  //           invalid: false,
  //           test_id: this.props.test.id,
  //           user_id: localStorage.getItem("userId"),
  //           language: language
  //         });
  //       }
  //     } else {
  //       $("#error-message").show();
  //     }  
  //   } else{
  //     this.setState({ page: page + 1, now: 0 }); // increatement steps in case till then page set 1       
  //     //console.log('No validate');
  //   }
  //   //console.log(this.state);
  // }

  showResult() {
    this.setState({ loading: true });
    const { hr } = this.props.match.params;
    let user_id = 0;
    let client_id = 0;
    let code = 0;
    if (hr) {
      user_id = Hash.decrypt(hr);
      client_id = localStorage.getItem("userId");
      code = hr;
    } else {
      user_id = localStorage.getItem("userId");
    }
    // get shared link from the localstorage
    let sharedTest = localStorage.getItem('sharedTestId'); // get testid from    
    const token = localStorage.getItem("token");
    let language;
           if (this.props.lang === "en") language = "english";
        if (this.props.lang === "fr") language = "french";

    const submitData = {
      user_id: user_id,
      client_id: client_id,
      test: "Sale",
      code: code,
      shared_test:sharedTest,
      
    };
    this.props.updateTestConsume(token, submitData, resp => {
      if (resp === "success") {
        var y = (this.props.lang === "en") ? "Your Score" : "Votre score";

        this.setState({
          resultData: [
            { name: y, score: this.state.userScore },
            { name: "Average Score", score: this.state.average }
          ],
          showChart: true,
          loading: false
        });
      }
    });
  }

  //Himanshu
  showResult2() {
    this.setState({ loading: true });
    const { hr } = this.props.match.params;
    let user_id = 0;
    let client_id = 0;
    let code = 0;
    if (hr) {
      user_id = Hash.decrypt(hr);
      client_id = localStorage.getItem("userId");
      code = hr;
    } else {
      user_id = localStorage.getItem("userId");
    }
    // get shared link from the localstorage
    let sharedTest = localStorage.getItem('sharedTestId'); // get testid from    
    const token = localStorage.getItem("token");

    const submitData = {
      user_id: user_id,
      client_id: client_id,
      test: "Sale",
      code: code,
      shared_test:sharedTest      
    };
    this.props.updateTestConsume(token, submitData, resp => {
      // if (resp === "success") {
      //   this.setState({
      //     resultData: [
      //       { name: "User score", score: this.state.userScore },
      //       { name: "Average score", score: this.state.average }
      //     ],
      //     showChart: true,
      //     loading: false
      //   });
      // }
    });
  }

  onSubmit() {
    this.setState({ loading: true });
    const token = localStorage.getItem("token");

    this.props.saveSale(
      token,
      this.state,
      (testScore, averageScore, groupResult, sectionResult,needToImprove,totaltest,newaverage,globalAverage,totalTestc) => {
      //console.log('render => '+JSON.stringify(needToImprove, null, 4)+' -- '+needToImprove);        
        this.setState({ loading: false });
        if (testScore >= 0) {
          this.setState({
            userScore: testScore,
            average: averageScore,
            chart2: groupResult,
            sectionResult: sectionResult,
            needToImprove: needToImprove,            
            resultBtn: true,
            totaltest:totaltest,
            newaverage:newaverage,
            globalAverage:globalAverage,
            totalTestc:totalTestc  
          });
        }
      }
    );
  }

  renderAnotherTestButtons() {
    //console.log('Inisde renderAnotherTestButtons '+this.state.testType);
    const buttonName =  <FormattedMessage id="btn.do.another.test" defaultMessage="DO ANOTHER TEST"/>            
    return (
      <DoAlert 
        type={this.state.testType} 
        buttonName={buttonName}
        className="btn btn-warning mt-3 mx-3 w-50"
        canvasSelector="saleTest"
        pdfName="saleTestResult"
       />
    );
  }

  renderContactCoachButtons() {
    //console.log('Inisde renderContactCoachButtons '+this.state.testType);
    if (this.state.testType == 'individual') {
      const buttonName =  <FormattedMessage id="btn.contact.a.coach" defaultMessage="CONTACT A COACH"/>                        
      return (
        <DoAlert 
          type={this.state.testType} 
          buttonName={buttonName}
          className="btn btn-info mt-3 w-50"
          target="_blank"
          href="/contact-to-coach"
          canvasSelector="saleTest"
          pdfName="saleTestResult"          
        />
      );
    }else{
      // const buttonName =  <FormattedMessage id="btn.send.results" defaultMessage="SEND RESULTS"/>                        
      // return (
      //   <DoAlert 
      //     type={this.state.testType} 
      //     buttonName={buttonName}
      //     className="btn btn-info mt-3 w-50"
      //     target=""
      //     href="mailto:name@mail.com?subject=PilotRH: %20Happiness%2Tools%2Result&amp;body=This%20is%20a%20message%20body"
      //     canvasSelector="saleTest"
      //     pdfName="saleTestResult"          
      //   />
      // );
    }
  }

  renderCharts() {
    const { resultData, chart2, sectionResult, needToImprove, testType } = this.state;
    //var data = [{ name: "a", score: 50 }, { name: "b", score: 86 }];
    const COLORS = ["#17a2b8", "#e0fbff"];
    //console.log('resultData => '+resultData+' -- '+JSON.stringify(chart2, null, 4));     
    const AVERAGEDATA = Object.values(resultData)[0];
    var your = (this.props.lang == "en") ? "You" : (this.props.lang == "fr") ? "Vous" : "You";
    var average  = (this.props.lang == "en") ? "Average of tests" : (this.props.lang == "fr") ? "Moyenne des tests" : "Average of tests";
        
    if (this.state.showChart) {
      return (
        <React.Fragment>
          <div id="saleTest">
            <h2 className="text-center mb-1">
              <FormattedMessage id="sale.test.results" defaultMessage="Sale Test Result"/>
            </h2>  
            {(this.state.usernamep !=null ?<h4 className="text-center mb-2" style={{fontSize:"15px!important", textAlign:"right"}}>{this.props.lang=='en'?"Candidate":"Candidat"}:  {this.state.usernamep} {this.state.usernamep2}</h4>
             :"")}          
            <h4 className="text-center mb-4">
              <FormattedMessage id="sale.result.subtitle" defaultMessage="Your level as a sales Manager"/>
            </h4>
            <div className="row" id="result-1">
                {/* <div className="col-md-5">
                  <div style={{ width: "100%", height: 300 }} className="m-auto">
                      <ResponsiveContainer>
                        <BarChart
                          data={resultData}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis domain={[0, 100]} />
                          <Tooltip />
                          <Legend />
                          <Bar dataKey="score">
                            {resultData.map((result, index) => (
                              <Cell
                                key={index}
                                fill={result.score > 50 ? "#17a2b8" : "#C0C0C0"}
                              />
                            ))}
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                </div> */}
                <div className="col-md-12">
                  <div className="m-auto">
                    <div style={{ width: '100%', height: 300 }}>
                      <ResponsiveContainer>
                        <PieChart>
                              <Pie
                                data={resultData}
                                dataKey="score"
                                labelLine={false}
                                innerRadius={60}
                                outerRadius={90}
                                fill="#17a2b8"
                                textAnchor="end"
                              >
                            {resultData.map((entry, index) => (
                              <Cell key={index} fill={COLORS[index % COLORS.length]} />
                            ))}

{/*                                   
                    {
                    
                    this.state.globalav.map((chart,index)=>(
                     
                      this.state.sum.push(chart.Moyennedestests)
                     
                 
                    ))}
                    {
                      this.state.add=this.state.sum.reduce((result,number)=> result+number)
                      
                    }
                    { this.state.global=this.state.add/8} */}

                            <Label width={80} position="center">                  
                            {`${AVERAGEDATA.score}%`}
                            </Label>
                          </Pie>
                          {/* <Tooltip />                 */}
                        </PieChart>               
                      </ResponsiveContainer>    
                    </div>                 
                  </div>
                </div>
            </div>
            {/* <h5 className="text-center mb-4" style={{color:"grey",fontSize:"14px"}}><FormattedMessage id="test-average" defaultMessage="Tests average:62%"/> {this.state.totalTestc} tests: {Math.round(this.state.global*100)/100} %</h5>                        */}

            <div className="row" id="result-2">
              <div className="col-md-12">
                <div style={{ width: "100%", height: 500 }} className="m-auto  Mob-hide">
                  <ResponsiveContainer>
                    <BarChart
                      width={500}
                      height={300}
                      data={chart2}
                      margin={{
                        top: 70,
                        right: 30,
                        left: 20,
                        bottom: 5
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="name"
                        interval={0}
                        angle={-12}
                        textAnchor="end"
                      />
                      <YAxis domain={[0, 100]} />
                      {/* <Tooltip /> */}
                      <Legend wrapperStyle={{ paddingTop: "60px" }} />
                      <Bar dataKey={your} fill="#17a2b8" />
                      <Bar dataKey={average} fill="#C0C0C0" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
            <hr/>
            <NeedToImprove average={this.state.userScore } sectionResult={sectionResult}  needToImprove={needToImprove} testType={testType}/>
            {/* <div id="result-3"><hr/>
              {testType === 'individual' ? ( // check page and hide the title from first two steps i.e user explanations
                <h4>YOUR NEEDS TO IMPROVE</h4>
              ) : (
                  <h4>NEEDS TO IMPROVE</h4>
              )}
                <div className="row">
                <ResultActionNeedToPerform sum={this.state.sectionResult.col1 + sectionResult.col2 + sectionResult.col3 + sectionResult.col4 + sectionResult.col5 + sectionResult.col6 + sectionResult.col7 + sectionResult.col8} />
                {sectionResult.col1 + sectionResult.col2 + sectionResult.col3 <
                  180 &&
                  this.graphDetail(
                    "DRIVE",
                    {
                      line1: "Resolve, Perseverance",
                      line2: "Keenness, Motivation, Challenge",
                      line3: "Sharing, Pleasure"
                    },
                    "Salespeople rely on their drive to sell. They need to develop their ability to keep up their positive thoughts, perseverance and resolve and thus maintain their drive. If need be, coaching will help them develop this capacity."
                  )}
                {sectionResult.col4 < 60 &&
                  this.graphDetail(
                    "PEOPLE SKILLS",
                    {
                      line1: "Openness, Authenticity, Availability",
                      line2: "Listening skills, Curiosity, Consideration",
                      line3: "Adaptability, Flexibility, Empathy"
                    },
                    "Salespeople have highly developed people skills. They are at ease with all sorts of people. They make use of a number of communication techniques and display high psychological maturity in their relationships. If this is not the case, they can get the help of a coach."
                  )}
                {sectionResult.col5 < 60 &&
                  this.graphDetail(
                    "KNOW HOW",
                    {
                      line1: "Market and technological knowledge",
                      line2: "Professional and customer knowledge",
                      line3: "Expertise, Professionalism"
                    },
                    "Salespeople are experts that display a high level of professionalism. They advise their clients in a competent way and assist him in the choice of the right product. The sales process is conducted in a precise  and professional way. Salespeople constantly train to improve their knowledge and know how."
                  )}
                {sectionResult.col6 < 60 &&
                  this.graphDetail(
                    "NEGOTIATION SKILLS",
                    {
                      line1: "Equal Footing",
                      line2: "Genuineness",
                      line3: "Self Confidence"
                    },
                    "Salespeople offer their clients win/win solutions. They are assertive, sensitive and master the main negotiation techniques. They relate to their clients on an equal footing and with confidence. These skills can be acquired by training in negotiation skills."
                  )}
                {sectionResult.col7 < 60 &&
                  this.graphDetail(
                    "DECISION MAKING",
                    {
                      line1: "Courage, Will Power",
                      line2: "Assertiveness, Authority",
                      line3: "Follow up"
                    },
                    "Deciding is not easy for all. Fear of making mistakes is often present. Salespeople must know how to assist their clients through the decision making process. Listening skills but also authority are required. Knowing how to bring the sale to a close is the hallmark of great salespeople."
                  )}
                {sectionResult.col8 < 60 &&
                  this.graphDetail(
                    "SELF CONTROL",
                    {
                      line1: "Calm, Independence",
                      line2: "Concentration, Seeing the big picture",
                      line3: "Endurance, Professionalism"
                    },
                    "Salespeople are often exposed to criticism and objections. They must know how to keep their self control in all circumstances and not loose track of their goal. Self control is mostly acquired through experience but specific training exercises do help."
                  )}
              </div>
            </div> */}
          </div>
          <div className="col-lg-8 col-md-10 col-sm-12 mx-auto text-center mb-4 pb-45 d-flex justify-content-center">
            <PrintPdf  type={this.state.testType}  canvasSelector="saleTest"  pdfName="saleTestResult"/>            
            {/* <button onClick={e => this.printResult(e)} className="btn btn-info mt-3">
            SAVE YOUR RESULTS
            </button> */}
            {this.renderAnotherTestButtons()}
            {this.renderContactCoachButtons()}            
          </div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }

  // graphDetail(title, header, description) {
  //   return (
  //     <div className="col-md-4 p-1">
  //       <div className="card text-center mb-3 shadow gd-card">
  //         <div
  //           className="card-header bg-info text-white shadow text-uppercase"
  //           style={{ borderRadius: ".25rem", fontSize: "20px" }}
  //         >
  //           {title}
  //         </div>
  //         <div className="card-body">
  //           <div className="border-bottom">
  //             <h6 className="card-title">{header.line1}</h6>
  //             <h6 className="card-title">{header.line2}</h6>
  //             <h6 className="card-title">{header.line3}</h6>
  //           </div>
  //           <div className="pt-2">
  //             <p className="mb-0 text-muted" style={{ fontSize: "14px" }}>
  //               {description}
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  renderProgress() {
    const { now, page, showChart } = this.state;

    if (page > 1 && !showChart) {
      return <ProgressBar now={now} label={`${now}%`} className="mb-3" />;
    } else {
      return null;
    }
  }

  render() {
    const {
      invalid,
      resultBtn,
      showChart,
      loading,
      error,
      page,
      errorMsg,
      testType      
    } = this.state;

    const testTitle =  (testType === 'individual'? 'Sales Test':'Sales Force'); // check if result or not

    if (loading)
      return (
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              <div className="text-center">
                <div
                  className="spinner-border m-5"
                  role="status"
                  style={{ width: "3rem", height: "3rem" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      );

    return (
      <React.Fragment>
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              {this.state.showChart === false && ( // show if there is not short chart or result 
                <h2 className="text-center mb-2">{testTitle}</h2>
              )}
              {error ? (
                <div className="alert alert-danger col-md-6 text-center mx-auto">
                  <h4 className="alert-heading">
                    Hello {localStorage.getItem("userName")}!
                  </h4>
                  {errorMsg}
                </div>
              ) : (
                <div className="col-lg-8 col-md-10 col-sm-12 m-auto m-90">
                  <div id="result-chart">{this.renderCharts()}</div>

                  <div id="error-message" className="text-danger">
                    Select option
                  </div>
                  {this.renderProgress()}
                  <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    {/* {this.renderQuestions()} */}
                    <button
                      type="submit"
                      className="btn btn-info mt-2 mx-auto"
                      disabled={invalid}
                      style={{
                        display: invalid || resultBtn ? "none" : "block"
                      }}
                    >
                      <FormattedMessage id="submit" defaultMessage="SUBMIT"/>
                    </button>
                  </form>
                  {page > 0 && ( // check page and hide the title from first two steps i.e user explanations
                    <button
                      className="btn btn-info mt-2 text-left"
                      // onClick={() => this.nextQuestion()}
                      disabled={!invalid}
                      style={{
                        display: invalid && !resultBtn ? "block" : "none"
                      }}
                    >
                    <span>
                      <FormattedMessage id="next" defaultMessage="NEXT"/>
                    </span>
                    </button>                    
                  )}
                  {page <= 0 && (
                    <button
                      className="btn btn-info mt-2 mx-auto"
                      // onClick={() => this.nextQuestion()}
                      disabled={!invalid}
                      style={{
                        display: invalid && !resultBtn ? "block" : "none"
                      }}
                    >                    
                    <span>
                     <FormattedMessage id="start" defaultMessage="START"/>
                    </span>
                    </button>                    
                  )} 
                  <div
                    className="text-center"
                    style={{
                      display: resultBtn && !showChart ? "block" : "none"
                    }}
                  >
                    <h3 className="text-info mb-3">
                      <FormattedMessage id="test_saved" defaultMessage="Test save successfully"/>
                    </h3>
                    {this.props.match.params.hr && <h5 className="text-info mb-3">
                      <FormattedMessage id="test_result" defaultMessage="Your test result is has been mailed to your corresponding HR"/>
                    </h5> }
                    {this.props.match.params.hr?<Link className="" to="/"><div><button
                      className="btn btn-info"
                      onClick={() => this.showResult2()}
                    >
                      <FormattedMessage id="btn.goto.home" defaultMessage="Get Result"/>
                    </button></div></Link>:
                    <button
                      className="btn btn-info"
                      onClick={() => this.showResult()}
                    >
                      <FormattedMessage id="get_result" defaultMessage="Get Result"/>
                    </button>
  }
                    {/* <button
                      className="btn btn-info"
                      onClick={() => this.showResult()}
                    >
                      <FormattedMessage id="get_result" defaultMessage="Get Result"/>
                    </button> */}
                  </div>
                </div>
              )}
            </div>
            {page > 0 && ( // check page and hide the title from first two steps i.e user explanations
              <Testcopyrights />
            )}                                      
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  //console.log(state);

  return {
    isSignedIn: state.auth.isSignedIn,    
    test: state.happiness.SaleData.data,
    lang: state.locale.lang
  };
};

Resultsale = connect(
  mapStateToProps,
  { fetchSale, saveSale,resultSale,updateTestConsume }
)(Resultsale);

export default reduxForm({
  form: "SaleForm"
})(Resultsale);
