import React from "react";
import { FormattedMessage } from "react-intl";

import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  LabelList,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Cell,
  PieChart, Pie, Label,
  Tooltip
} from "recharts";

function workGroupResult(props) {
  let { result, chartdata, word, average, testType  } = props;
  const COLORS = ["#17a2b8", "#C0C0C0"];    
  //console.log('resultData  word => '+word+' -- '+JSON.stringify(result, null, 4));

  const getDescription = label => {
    let des = result.filter(elem => {
      return elem.label === label;
    });
    if (des.length > 0) {
      return des[0].desc;
    } else {
      return null;
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          <p className="label">
            <b>{`${label} : ${payload[0].value}`}</b>
          </p>
          <p className="desc">{getDescription(label)}.</p>
        </div>
      );
    }

    return null;
  };

  return (
    <React.Fragment>
      {/* <div className="col-lg-10 col-md-10 col-sm-12 m-auto"> */}
      <div className="m-auto">
        <h4 className="text-center mb-1 mt-5">
        <FormattedMessage id="ind.target.work.with.group.title" defaultMessage="Work with a group"/>
        </h4>
        <div className="card">
          <div className="card-body">
            <div className="row">
              {/* <div className="col-lg-8 col-md-10 col-sm-12 m-auto"> */}
              <div className="col-lg-12 m-auto">
                <div className="text-center font-weight-bold">
                <FormattedMessage id="your.score" defaultMessage="You score:"/> {Object.values(result)[0].score}/{Object.values(result)[1].score}
                </div>
                <div className="text-center">
                <FormattedMessage
                  id="ind.target.result.preffered.working.group"
                  defaultMessage={`Working in a group is {word} important for me `}
                  values={{word: word}}
                />
                {/* Working in a group is {word} important for me  */}
                </div>
                <div id="result-chart" style={{ width: '100%', height: 300 }}>
                  <ResponsiveContainer>
                    <PieChart>
                        <Pie
                            data={chartdata}
                            dataKey="score"
                            labelLine={false}
                            innerRadius={60}
                            outerRadius={90}
                            fill="#17a2b8"
                            textAnchor="end"
                          >
                        {result.map((entry, index) => (
                          <Cell key={index} fill={COLORS[index % COLORS.length]} />
                        ))}
                        <Label width={80} position="center">                  
                          {average} %
                        </Label>
                      </Pie>
                      <Tooltip />                
                    </PieChart>
                    </ResponsiveContainer>                    
                </div>
              </div>
            </div>               
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default workGroupResult;
