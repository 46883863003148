import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "./includes/Footer";
import { Field, reduxForm } from "redux-form";
import { FormattedMessage } from "react-intl";
import DeleteService from '../actions/DeleteService';
// import { shareTestLink, clearSharelinkMessage } from "../actions/Recruitment";
import { getCandidateList, getJobProfileList } from "../actions/Recruitment";
import $ from "jquery";

class DisplayCandidates extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loading: false,
          error: false,
          errorMessage: "",
          candidatesData: [],
          jobProfileData: [],
          profileMatchingData :[{
                    status : "",
                }]
        };
        window.scrollTo(0, 0); // scroll page to top
        this.delete = this.delete.bind(this);
    }

    componentDidMount() {
      if (this.props.isSignedIn)
      {
        let language;
        if (this.props.lang === "en") language = "english";
        if (this.props.lang === "fr") language = "french";
        var userid = localStorage.getItem("userId");
        // console.log(userid);
        //console.log('Init submit form'+JSON.stringify(this.props));

        let candidateObject = [];
        //used to check if test is empty then redirec it to buy test page
        this.props.getCandidateList(userid, resp => {
          candidateObject = resp.map((candidate) => {
              return candidate
          });
          // update state with candidate data
          this.setState({
            candidatesData: candidateObject,
          });
        });
        // used to check if test is empty then redirec it to buy test page
        let jobProfileObject = [];
        this.props.getJobProfileList(userid, resp => {
          jobProfileObject = resp.map((jobProfile) => {
              return jobProfile
          });
          // update state with jobProfile data
          this.setState({
            jobProfileData: jobProfileObject,
          });
        });


      }
      //console.log('UserType => '+localStorage.getItem("role"))
    }
    delete(id){
      const token = localStorage.getItem("token");
      DeleteService.delete(token,id).then( res => {
          this.setState({candidatesData: this.state.candidatesData.filter(data =>data.id !== id)});
      });
       }

    renderCandidateDropdown(candidates) {
      if (candidates.length > 0) {
        return candidates.map(data => {
          return (
            // <option key={data.id} value={data.id}>
            //   {data.email}
            // </option>
             <tr key={data.id} value={data.id} style={{"border":"1px solid"}}>
             <th style={{"border":"1px solid"}}>{data.id}</th>
            <th style={{"border":"1px solid"}}>{data.email}</th>
            <th style={{"border":"1px solid"}}>
            <button style={{marginLeft: "10px"}} onClick={() => {const confirmBox = window.confirm(  "Do you really want to delete this ?")
            if (confirmBox === true){this.delete(data.id)}}} className="btn btn-danger">Delete </button>
          </th>
          </tr>
          );
        });
      }else{
        return (
          <option key='' value=''>Not found any Candidate</option>
        );
      }
    }


  render() {
    //const { profileMatchingData } = this.props;
    //console.log('Render  -profileMatchingData- '+JSON.stringify(this.state.profileMatchingData.result));
    const selecJobProfile = <FormattedMessage id="corelation.selectjobprofile" defaultMessage="Select Job Profile *"/>
    const selecCandidate = <FormattedMessage id="corelation.selectcandidate" defaultMessage="Select Candidate *"/>
    return (
    <React.Fragment>
      <section className="mb-4 pages">
        <div className="container">
          <div className="row d-flex p-0 m-0">
            <div className="container">
                  <div className="col-lg-6 col-md-8 m-auto">
                  <div className="col-md-12">
                  <h2 className="h1-responsive font-weight-bold text-center my-4">
                    <FormattedMessage id="header.match.profile" defaultMessage="MATCHING"/>
                  </h2>
                  </div>
                    <div className="card-div">
                      { this.state.candidatesData.length > 0 && (
                        <div className="row">
                          <div className="col-md-12 table m-auto">
                            <table className="w-100" style={{"border":"1px solid"}}>
                              <tbody>
                                <tr style={{"border":"1px solid"}}>
                                  <th style={{"border":"1px solid"}}>S.No</th>
                                  <th style={{"border":"1px solid"}}>Candidate List</th>
                                  <th style={{"border":"1px solid"}}>Action</th>
                                </tr>
                                {this.renderCandidateDropdown(this.state.candidatesData)}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    isSignedIn: state.auth.isSignedIn,
    corelationFormValidation: state.form.corelationFormValidation,
    shareLinkError: state.auth.shareLinkError,
    shareLinkMessage: state.auth.shareLinkMessage,
    lang: state.locale.lang
  };
};

DisplayCandidates = connect(
  mapStateToProps,
  { getCandidateList, getJobProfileList }
  // { shareTestLink, clearSharelinkMessage }
)(DisplayCandidates);


export default reduxForm({
    form: "corelationFormValidation",
    enableReinitialize: true
  })(DisplayCandidates);

//export default Correlation;
