import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";

const EntrepreneurentrepreneurskillAssessment = () => {
  return (
    <React.Fragment>
      <div className="container">
        <div className="container about pages text-justify">
          <div className="row">
            <div className="col-md-12">
            <h4 className="pb-2 text-center"><span><FormattedMessage id="entrepreneurskill.test.title" defaultMessage="FULL entrepreneurskillS ASSESSMENT"/> : </span></h4>
            <h5 className="pb-2 text-center"><span><FormattedMessage id="entrepreneurskill.test.desc" defaultMessage="5 tests + 1 hour of online debriefing"/> </span></h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
            <span>
              <FormattedHTMLMessage id="entrepreneurskill.test.content" defaultMessage=""/>
            </span>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-12 img-content">
              <h2 className="col-md-12 py-3 text-center"><FormattedMessage id="entrepreneurskill.test.credit" defaultMessage="2 TESTS (+ 1 free)"/></h2>
              <div className="col-md-6 m-auto p-0">
                {localStorage.getItem('lang') != 'en' ?  <img src="assets/images/full-entrepreneurskill-test-fr.png" alt="Olivier Colson" className="img-fluid rounded"/> :  <img src="assets/images/full-entrepreneurskill-test-eng.png" alt="Olivier Colson" className="img-fluid rounded"/> }
              </div>
              <div className="col-md-4 mx-auto mt-4 p-0 plus-icon text-center">
                  <i className="fa fa-plus"></i>  <i className="fas fa-equals"></i>
              </div>
              <h3 className="col-md-12 py-4 text-center"><FormattedMessage id="entrepreneurskill.test.debriefing" defaultMessage="1 hour of online debriefing"/></h3>
              <div className="col-md-4 m-auto p-0">
                  <img src="assets/images/online-debriefing.jpg" alt="Olivier Colson" className="img-fluid rounded"/>
              </div>
              <div className="col-md-4 mx-auto mt-4 p-0 plus-icon text-center">
                <span class="font-weight">&#x3d;</span>
              </div>              
              <h3 className="col-md-12 py-4 text-center">
                <FormattedHTMLMessage id="entrepreneurskill.test.price" defaultMessage="€350 vat included instead of <span className='text-danger'><del>€450</del></span>"/>
              </h3>
              <div className="col-md-12 text-center mb-5">
                <Link className="" to="/buy-test">
                  <FormattedHTMLMessage id="entrepreneurskill.test.buy.button" defaultMessage="<button class='buy-full-entrepreneurskill px-5 py-3'><h3 className='mb-4'>BUY NOW</h3><h4>Full entrepreneurskills assessment</h4><h6>(5 tests + 1 hour of online debriefing) </h6><h4> €350 </h4><p>(safe & secure payment) </p></button>"/>
                </Link>
              </div>
            </div>
        </div>
        </div>
      </div>      
    </React.Fragment>
  );
};

export default EntrepreneurentrepreneurskillAssessment;
