import React, { Component } from "react";
import { Link } from "react-router-dom";
//import $ from "jquery";
import {
  FormattedMessage,
  FormattedHTMLMessage,
  IntlProvider,
} from "react-intl";
import Carousel from "react-bootstrap/Carousel";
import Footer from "./includes/Footer";
import Register from "./includes/Register";
import { history } from "../helper/History";
import $ from "jquery";
import ScrollIntoView from "react-scroll-into-view";
//import { FormattedHTMLMessage } from "react-intl";

class PreServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dis: "",
    };
    window.scrollTo(0, 0); // scroll page to top
  }


  showRegister(e) {
    var userType = localStorage.getItem("role");
    if (!userType) {
      history.push("/register");
     
    }
  }

  sendparam() {
    localStorage.setItem("classic", "classic");
  }
  showRegister2(e) {
    console.log("in " + e);
    $("#loginModal").modal("show");
    $("#register-form").delay(100).fadeIn(100);
    $("#login-form").fadeOut(100);
    $("#login-form-link").removeClass("active");
    console.log("Inside Header " + e);
    if (e === "#register-form-link") {
      $(".modal-title").text("SIGNUP");
      $(e).addClass("active");
    } else {
      $(e.target).addClass("active");
    }
  }

  handleClick() {
    const price = "unique";
    const element = document.getElementById(price);
    console.log(element);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section

      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  render() {
    return (
      <React.Fragment>

        <section className=" teaser-box lower-soucing-box mt-40" id="unique">
          <div className="container">
            <div className="row" style ={{marginTop:'6%'}}>
              <p className="text-center w-100 ">
                <FormattedHTMLMessage
                  id="pre-selection-heading"
                  defaultMessage="All subscriptions provide access to all tools and can be recharged with credits at any time."
                />
              </p>

              <div className="col-lg-3 col-md-3" style={{ marginLeft:"15%",height:"300px" }}>
                <div className="icon-box text-center hero-bg box-shadow">
                  <div>
                    <p>
                      <h5>
                      {/* <Link to="/" className=""> */}
                      <FormattedHTMLMessage
                        id="pre-service-stater"
                        defaultMessage="starter"
                      />
                      {/* </Link> */}
                      </h5>
                      {/* <FormattedHTMLMessage
                        id="pre-service-starter-body"
                        defaultMessage="starter"
                      /> */}
                    </p>
                    
                    <div className="HalfBordr mt-5" style={{ width:"200px" }}>
                      {/* <FormattedHTMLMessage
                        id="coach.offer.subs.desc2"
                        defaultMessage="12 months"
                      /> */}

                      <p className="mt-2">
                        <FormattedHTMLMessage
                          id="pre-service-starter-body"
                          defaultMessage="12 months"
                        />
                      </p>
                    </div>
                  </div>
                  <div style={{}}>
                    {/* <Link
                      to="/contact-us"
                      className="nav-link"
                      data-target=".navbar-collapse.show"
                    >
                      <button
                        class="btn btn-primary"
                        style={{
                          background: "#18B6CE",
                          border: "none",
                          marginTop: "50px",
                        }}
                      >
                        <FormattedHTMLMessage
                          id="coach.offer.subs.btn1"
                          defaultMessage="Subscribe"
                        />
                      </button>
                    </Link> */}
                  </div>
                </div>
              </div>
              <i class="fa fa-plus plus-icon" aria-hidden="true" style={{ fontSize:"50px" }}></i>
              <div className="col-lg-3 col-md-3">
                <div className="icon-box text-center hero-bg box-shadow">
                  <div>
                    <p>
                      <h5>
                      <FormattedHTMLMessage
                        id="pre-service-values"
                        defaultMessage="Classic"
                      />
                      </h5>
                    </p>

                    {/* <p>
                      <FormattedHTMLMessage
                        id="pre-selection-subhead"
                        defaultMessage="€49.90"
                      />
                    </p> */}

                    <div className="HalfBordr mt-4">
                      {/* <FormattedHTMLMessage
                        id="pre-selection-title2"
                        defaultMessage="per month - annual payment"
                      /> */}

                      <p class="mt-2">
                        <FormattedHTMLMessage
                          id="pre-service-values-body"
                          defaultMessage="per month - annual payment"
                        />
                      </p>
                    </div>

                    {/* <p><FormattedHTMLMessage id="Starter.user" defaultMessage="1 user" /></p>    */}
                  </div>
                  <div style={{}}>
                    {/* <Link
                      to="/contact-us"
                      className="nav-link"
                      data-target=".navbar-collapse.show"
                    >
                      <button
                        class="btn btn-primary"
                        style={{ background: "#18B6CE", border: "none" }}
                      >
                        <FormattedHTMLMessage
                          id="coach.offer.subs.btn2"
                          defaultMessage="Subscribe"
                        />
                      </button>
                    </Link> */}
                  </div>
                </div>
              </div>
              <i class="fa fa-plus plus-icon" aria-hidden="true" style={{ fontSize:"50px" }}></i>
              <div className="col-lg-3 col-md-3">
                <div className="icon-box text-center hero-bg box-shadow ">
                  <div>
                    <h5>
                      <FormattedHTMLMessage
                        id="pre-service-skills"
                        defaultMessage="Classic"
                      />
                    </h5>

                    {/* <p>
                      <FormattedHTMLMessage
                        id="pre-selection-subhead2"
                        defaultMessage="€49.90"
                      />
                    </p> */}
                    <div className="HalfBordr mt-5" >
                      {/* <FormattedHTMLMessage
                        id="pre-selection-title3"
                        defaultMessage="per month - annual payment"
                      /> */}

                      <p className="mt-2">
                        <FormattedHTMLMessage
                          id="pre-service-skills-body"
                          defaultMessage="per month - annual payment"
                        />
                      </p>
                    </div>
                    {/* <p><FormattedHTMLMessage id="Starter.user" defaultMessage="1 user" /></p>    */}
                  </div>
                  <div style={{}}>
                    {/* <Link
                      to="/contact-us"
                      className="nav-link"
                      data-target=".navbar-collapse.show"
                    >
                      <button
                        class="btn btn-primary"
                        style={{ background: "#18B6CE", border: "none" }}
                      >
                        <FormattedHTMLMessage
                          id="coach.offer.subs.btn2"
                          defaultMessage="Subscribe"
                        />
                      </button>
                    </Link> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 text-center mt-4">
              <Link
                      to="/contact-us"
                      className="nav-link"
                      data-target=".navbar-collapse.show"
                    >
                      <button
                        class="btn btn-primary"
                        style={{ background: "#18B6CE", border: "none" }}
                      >
                        <FormattedHTMLMessage
                          id="pre-service-btn2"
                          defaultMessage="Subscribe"
                        />
                      </button>
                    </Link>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default PreServices;
