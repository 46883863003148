import React, { Component } from "react";
import { Link } from "react-router-dom";
//import $ from "jquery";
import { FormattedMessage, FormattedHTMLMessage,IntlProvider  } from "react-intl";
import Carousel from "react-bootstrap/Carousel";
import Footer from "./includes/Footer";
import Register from "./includes/Register";
import { history } from "../helper/History";
import $ from "jquery";
//import { FormattedHTMLMessage } from "react-intl";

class Subscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dis:"",
    };
    window.scrollTo(0, 0); // scroll page to top
   
  }  
  // componentDidMount(){
  //   if (this.props.isSignedIn) {

  //     this.setState({dis:"none"});
  //   }
  //   console.log(this.state.dis);
  // }


 
  showRegister(e) {
    var userType = localStorage.getItem("role");
    if(!(userType)){
      history.push("/register");
    // console.log('in '+e);
    // $("#loginModal").modal("show");
    // $("#register-form").delay(100).fadeIn(100);
    // $("#login-form").fadeOut(100);
    // $("#login-form-link").removeClass("active");
    // console.log('Inside Header '+e);
    // if(e === '#register-form-link'){
    //   $('.modal-title').text('SIGNUP');
    //   $(e).addClass("active");
    // }else{
    //   $(e.target).addClass("active");
    // }
  }
 

  }

  sendparam(){

    localStorage.setItem('classic','classic');
  }
  showRegister2(e) {
    console.log('in '+e);
    $("#loginModal").modal("show");
    $("#register-form").delay(100).fadeIn(100);
    $("#login-form").fadeOut(100);
    $("#login-form-link").removeClass("active");
    console.log('Inside Header '+e);
    if(e === '#register-form-link'){
      $('.modal-title').text('SIGNUP');
      $(e).addClass("active");
    }else{
      $(e.target).addClass("active");
    }
 
  }

  render() {
   

    return (
      <React.Fragment>

        <section className="icon-boxed1 teaser-box">
          <div className="container">
            <div className="row">
            {/* <h2 className="text-center w-100 mb-5 mt-5">
                <FormattedHTMLMessage
                  id="Subscribe.Myskill"
                  defaultMessage="All subscriptions provide access to all tools and can be recharged with credits at any time."
                />
              </h2>
              <p><FormattedHTMLMessage
                  id="subscribe.myskill.ul"
                  defaultMessage="All subscriptions provide access to all tools and can be recharged with credits at any time."
                /></p> */}
              {/* <h1 className="text-center w-100 mb-5">
                <FormattedHTMLMessage
                  id="Subscribe.title"
                  defaultMessage="PILOT RH® SUBCRIPTIONS®"
                />
              </h1>
             */}
           

               <h2 className="text-center w-100 ">
                <FormattedHTMLMessage
                  id="subscribe.coaching"
                  defaultMessage="All subscriptions provide access to all tools and can be recharged with credits at any time."
                />
              </h2> 
                {/* <h4 className="text-center w-100 mb-5">
                <FormattedHTMLMessage
                  id="Subscribe.coaching.Subtitle"
                  defaultMessage="All subscriptions provide access to all tools and can be recharged with credits at any time."
                />
              </h4> */}
              
              {/* <h4 className="text-center w-100 mb-5" style={{color:'grey'}}>
                <FormattedHTMLMessage
                  id="Subscribe.Subtitle"
                  defaultMessage="All subscriptions provide access to all tools and can be recharged with credits at any time."
                />
              </h4> */}
              <div className="col-lg-4">
                <div className="icon-box text-center hero-bg box-shadow minHite" >
                  {/* <div className="teaser-icon icon-styled bg-maincolor3 m-auto d-flex align-items-center justify-content-center">
                    <img src="assets/images/scientific.png" alt="Scientific" />
                  </div> */}
                  <div>
                  <h3 >
                    {/* <Link to="/" className=""> */}
                    <FormattedHTMLMessage
                      id="Starter"
                      defaultMessage="starter"
                    />
                    {/* </Link> */}
                  </h3>
                  {/* <p>
                    <FormattedHTMLMessage
                      id="Starter.subtitle.1"
                      defaultMessage="12 months"
                    />
                  </p> */}
                  <p>
                    <FormattedHTMLMessage
                      id="Starter.buy"
                      defaultMessage="12 months"
                    />
                  </p>
                  <p className="font24"><FormattedHTMLMessage id="Starter.price" defaultMessage="€9,90" /></p>
                  {/* <p><FormattedHTMLMessage id="Starter.subpricetitle" defaultMessage="per month - annual payment" /></p>    */}

                <h6><FormattedHTMLMessage id="Starter.valid" defaultMessage="Buy per unit"/></h6>        
                {/* <p><i><FormattedHTMLMessage id="Starter.buytitle" defaultMessage="buy" /></i></p>     */}
                {/* <p><FormattedHTMLMessage id="Starter.user" defaultMessage="1 user" /></p>       */}
                <Link to="/contact-professional" className="nav-link"  data-target=".navbar-collapse.show"><button class="btn btn-primary px-4 mb-4" style={{background:"#18B6CE",border:"none"}}><FormattedHTMLMessage id="subscribe2" defaultMessage="Subscribe"/></button></Link>   
                </div> 
                <h6 className="mb-3"><FormattedHTMLMessage id="Description" defaultMessage="Description"/></h6>  
                <p><FormattedHTMLMessage id="Description.paragraph1" defaultMessage="Description"/></p>   
                <p><FormattedHTMLMessage id="Description.para1" defaultMessage="Description"/></p>   
                {/* <h6><FormattedHTMLMessage id="Interes.formula" defaultMessage="Interes.formula"/></h6>
                <p><FormattedHTMLMessage id="Interes.para1" defaultMessage="Interes.para"/></p> */}
                <div style={{marginTop:'50px'}}><Link to="/buy-test" className="nav-link"  data-target=".navbar-collapse.show"><button class="btn btn-primary" style={{background:"#18B6CE",border:"none"}}><FormattedHTMLMessage id="Free.trial1" defaultMessage="Subscribe"/></button></Link></div>
                  <div><p style={{marginRight:'50px'}}><FormattedHTMLMessage id="stater.prize"/></p></div>
                </div>
        
              </div>
              <div className="col-lg-4">
                <div className="icon-box text-center hero-bg box-shadow minHite">
                  {/* <div className="teaser-icon icon-styled bg-maincolor3 m-auto d-flex align-items-center justify-content-center">
                    <img src="assets/images/Immidiate-report.png" alt="Immidiate Reports" />
                 </div> */}
                  <div>
                  <h3 >
                    {/* <Link to="/" className=""> */}
                    <FormattedHTMLMessage
                      id="Classic"
                      defaultMessage="Classic"
                    />
                    {/* </Link> */}
                  </h3>
                  <p>
                    <FormattedHTMLMessage
                      id="Starter.subtitle.2"
                      defaultMessage="12 months"
                    />
                  </p>
                  {/* <p>
                    <FormattedHTMLMessage
                      id="Starter.subtitle"
                      defaultMessage="12 months"
                    />
                  </p> */}
                  <p className="font24">
                     <FormattedHTMLMessage
                      id="Classic.price"
                      defaultMessage="€49.90"
                    />
                    </p>
                    <p>
                     <FormattedHTMLMessage
                      id="Starter.subpricetitle1"
                      defaultMessage="per month - annual payment"
                    />
                    </p>
{/*                     
                <h6><FormattedHTMLMessage id="Classic.buy" defaultMessage="Buy per unit"/></h6>        
                <p><i><FormattedHTMLMessage id="Classic.buytitle" defaultMessage="buy" /></i></p>     */}
                <p><FormattedHTMLMessage id="Starter.user" defaultMessage="1 user" /></p>   
                <Link to="/buy-subscription"    className="nav-link"  data-target=".navbar-collapse.show"><button class="btn btn-primary px-4 mb-4" onClick={this.sendparam()}><FormattedHTMLMessage id="subscribe" defaultMessage="Subscribe"/></button></Link>
                </div>     
                <h6><FormattedHTMLMessage id="Description" defaultMessage="Description"/></h6>  
                <p><FormattedHTMLMessage id="Description.paragraph2" defaultMessage="Description"/></p>  
                {/* <p><FormattedHTMLMessage id="Description.para2" defaultMessage="Description"/></p> */}
                {/* <h6><FormattedHTMLMessage id="Interes.formula" defaultMessage="Interes.formula"/></h6>
                <p><FormattedHTMLMessage id="Interes.para2" defaultMessage="Interes.para"/>
                </p> */}
                    {/* <div style={{marginTop:'40px'}}> <Link to="/contact-professional" className="nav-link"  data-target=".navbar-collapse.show"><button class="btn btn-primary mt28"><FormattedHTMLMessage id="Free.trial2" defaultMessage="Subscribe"/></button></Link></div> */}
                </div>
              
              </div>
              <div className="col-lg-4">
                <div className="icon-box text-center hero-bg box-shadow minHite">
                  {/* <div className="teaser-icon icon-styled bg-maincolor3 m-auto d-flex align-items-center justify-content-center">
                    <img src="assets/images/confidential.png" alt="Totally Confidential" />
                  </div> */}
                  <div>
                  <h3 >
                    {/* <Link to="/" className=""> */}
                    <FormattedHTMLMessage
                      id="Premium"
                      defaultMessage="Premium"
                    />
                    {/* </Link> */}
                  </h3>
                  <p>
                    <FormattedHTMLMessage
                      id="Starter.subtitle.3"
                      defaultMessage="12 months"
                    />
                  </p>
                  {/* <p>
                    <FormattedHTMLMessage
                      id="Starter.subtitle"
                      defaultMessage="12 months"
                    />
                  </p>                   */}
                  <p className="font24"><FormattedHTMLMessage
                      id="Premium.price"
                      defaultMessage="199€"
                    /></p>
                     {/* <p><FormattedHTMLMessage
                      id="Premium.pricesub"
                      defaultMessage="12 months"
                    /></p>
                      <p className="font24"><FormattedHTMLMessage
                      id="Premium.price5"
                      defaultMessage="199€"
                    /></p> */}
                     {/* <p><i><FormattedHTMLMessage
                      id="Premium.pricetitle"
                      defaultMessage=""
                    /></i></p> */}
                     <h6><FormattedHTMLMessage id="Premium.buy" defaultMessage="Buy per unit"/></h6>        
                <p><FormattedHTMLMessage id="Premium.buytitle" defaultMessage="buy" /></p> 
                <p><FormattedHTMLMessage id="Premium.multi" defaultMessage="Multi-Utilisateurs" /></p>   
                <p><FormattedHTMLMessage id="Premium.user" defaultMessage="1 user" /></p>    
                <Link to="/contact-us" className="nav-link"  data-target=".navbar-collapse.show"><button class="btn btn-primary  px-4 mb-4" style={{background:"#F806CC",border:"none"}}><FormattedHTMLMessage id="subscribe_contactus" defaultMessage="Subscribe"/></button></Link>  
                </div> 
                <h6><FormattedHTMLMessage id="Description" defaultMessage="Description"/></h6>   
                  <p><FormattedHTMLMessage id="Description.paragraph3" defaultMessage="Description"/></p>
                  {/* <h6><FormattedHTMLMessage id="Interes.formula" defaultMessage="Interes.formula"/></h6>
                  <p><FormattedHTMLMessage id="Interes.para3" defaultMessage="Interes.para"/>
                  </p>  */}
                  {/* <div style={{marginTop:'20px'}}> <Link to="/contact-enterprise" className="nav-link"  data-target=".navbar-collapse.show"><button class="btn btn-primary btn-dark mt15"><FormattedHTMLMessage id="Free.trial.prem" defaultMessage="Subscribe"/></button></Link></div> */}
                
                </div>
              </div>
             
            </div>
            <h2 className="text-center w-100 mb-1 mt-5">
                <FormattedHTMLMessage
                  id="subscribe.bussiness"
                  defaultMessage="All subscriptions provide access to all tools and can be recharged with credits at any time."
                />
              </h2>
                <h4 className="text-center w-100 mb-3">
                <FormattedHTMLMessage
                  id="Subscribe.Bussiness.Subtitle"
                  defaultMessage="All subscriptions provide access to all tools and can be recharged with credits at any time."
                />
              </h4>
              <Link to="/sourceresources" className="nav-link text-center w-100 mb-5"  data-target=".navbar-collapse.show"><button class="btn btn-primary"><FormattedHTMLMessage id="subscribe.bussiness.btn" defaultMessage="Subscribe"/></button></Link>

            <div>
            {/* <h3><FormattedHTMLMessage id="subscribe.ul.heading" defaultMessage="subscribe.ul"/></h3> */}
              <FormattedHTMLMessage id="subscribe.ul" defaultMessage="subscribe.ul"/>
              </div>
            
           <p class="font20  strongs"><FormattedHTMLMessage id="subscribe.testimonial" defaultMessage="subscribe.ul"/></p>
           <p>
           <h3 style={{paddingTop: "20px"}}><FormattedHTMLMessage id="testimonial2.heading" defaultMessage="Questions ?"/></h3>
           </p>
            <p class="font20 fontItalicH5"><FormattedHTMLMessage id="subscribe.testimonial2" defaultMessage="subscribe.testimonial2"></FormattedHTMLMessage></p>
          
          

          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}
export default Subscribe;
