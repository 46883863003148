import axios from "axios";

import { ROOT_URL } from "../helper/Config";

export const AUTH_ERROR = "auth_error";
export const BUSINESSCREATION_DATA = "businesscreation_data";
//export const RESULT_DATA = "result_data";

export function fetchBusinessCreation(
  token,
  hr = null,
  code = null,
  language,
  callback
) {
  let test_url;
  if (hr === null) {
    test_url = `${ROOT_URL}/business-creation/${language}`;
  } else {
    test_url = `${ROOT_URL}/business-creation/${language}/${hr}/${code}`;
  }

  return dispatch => {
    return axios
      .get(test_url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.data.message) {
          callback(response.data.message);
        } else {
          dispatch({
            type: BUSINESSCREATION_DATA,
            payload: response
          });
          //console.log(response);
          callback("success");
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
}

export function saveBusinessCreation(token, data, callback) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/business-creation`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        //console.log(response);
        if (response.data.score >= 0 && response.data.message === "success") {
          // let groupResult = [];
          // groupResult.push({
          //   name: "column1",
          //   user: response.data.scoreGroup.col1,
          //   average: response.data.averageGroup.col1
          // });
          // groupResult.push({
          //   name: "column2",
          //   user: response.data.scoreGroup.col2,
          //   average: response.data.averageGroup.col2
          // });
          // groupResult.push({
          //   name: "column3",
          //   user: response.data.scoreGroup.col3,
          //   average: response.data.averageGroup.col3
          // });
          // groupResult.push({
          //   name: "column4",
          //   user: response.data.scoreGroup.col4,
          //   average: response.data.averageGroup.col4
          // });
          // groupResult.push({
          //   name: "column5",
          //   user: response.data.scoreGroup.col5,
          //   average: response.data.averageGroup.col5
          // });
          // groupResult.push({
          //   name: "column6",
          //   user: response.data.scoreGroup.col6,
          //   average: response.data.averageGroup.col6
          // });
          // groupResult.push({
          //   name: "column7",
          //   user: response.data.scoreGroup.col7,
          //   average: response.data.averageGroup.col7
          // });
          // groupResult.push({
          //   name: "column8",
          //   user: response.data.scoreGroup.col8,
          //   average: response.data.averageGroup.col8
          // });

          callback(response.data.score, 
            response.data.average,
             response.data.groupResult,
             response.data.scoreGroup,
             response.data.needToImprove,
             response.data.totaltest,
            response.data.newaverage,
            response.data.globalAverage,
            response.data.totalTestc,
            response.data.globalav,
        
            );
        } else {
          callback(0, 0, 0);
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.clear();
          dispatch({
            type: AUTH_ERROR,
            payload: error.response.data.message
          });
        }
      });
  };
}

export function ResultCreation(token, data, callback) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/creationResult`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        //console.log(response);
        if (response.data.score >= 0 && response.data.message === "success") {
          // let groupResult = [];
          // groupResult.push({
          //   name: "column1",
          //   user: response.data.scoreGroup.col1,
          //   average: response.data.averageGroup.col1
          // });
          // groupResult.push({
          //   name: "column2",
          //   user: response.data.scoreGroup.col2,
          //   average: response.data.averageGroup.col2
          // });
          // groupResult.push({
          //   name: "column3",
          //   user: response.data.scoreGroup.col3,
          //   average: response.data.averageGroup.col3
          // });
          // groupResult.push({
          //   name: "column4",
          //   user: response.data.scoreGroup.col4,
          //   average: response.data.averageGroup.col4
          // });
          // groupResult.push({
          //   name: "column5",
          //   user: response.data.scoreGroup.col5,
          //   average: response.data.averageGroup.col5
          // });
          // groupResult.push({
          //   name: "column6",
          //   user: response.data.scoreGroup.col6,
          //   average: response.data.averageGroup.col6
          // });
          // groupResult.push({
          //   name: "column7",
          //   user: response.data.scoreGroup.col7,
          //   average: response.data.averageGroup.col7
          // });
          // groupResult.push({
          //   name: "column8",
          //   user: response.data.scoreGroup.col8,
          //   average: response.data.averageGroup.col8
          // });

          callback(response.data.score,
             response.data.average,
              response.data.groupResult,
              response.data.scoreGroup,
              response.data.needToImprove,
              response.data.totaltest,
              response.data.newaverage,
              response.data.globalAverage,
              response.data.totalTestc,
              response.data.globalav,
              response.data.username,
              response.data.username2
              );
        } else {
          callback(0, 0, 0);
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.clear();
          dispatch({
            type: AUTH_ERROR,
            payload: error.response.data.message
          });
        }
      });
  };
}

