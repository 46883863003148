import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "./includes/Footer";
import { Field, reduxForm } from "redux-form";
import { contactToHr, clearContHrMessage } from "../actions";
import $ from "jquery";
import { FormattedMessage } from "react-intl";

class ContactToHr extends Component {
    constructor(props) {
        super(props);
        this.state = {
        roles: [],
        language: null        
        };
        window.scrollTo(0, 0); // scroll page to top
        this.contactHrForm = this.contactHrForm.bind(this);
    }

    componentDidMount() {
      if (this.props.isSignedIn) 
      {
        //console.log('Response => '+JSON.stringify(this.props));
        let testType = localStorage.getItem('testType');
        //console.log('Response => '+JSON.stringify(this.props.location)+' type '+testType);    
        // set the test type to state 
        this.setState({ testType: testType });
        let language;
        if (this.props.lang === "en") language = "english";
        if (this.props.lang === "fr") language = "french";
      }
    } 

    contactHrForm(values) {
      values['language'] = this.props.lang;      
      //console.log('Init submit form');
      $("#contactus-loader").show();          
      this.refs.sbmt.setAttribute("disabled", "disabled");          

        //console.log('Inside contactHrForm '+values.email);
        this.props.contactToHr(values, this.refs.sbmt, response => {
          //console.log('Response '+response);
          //var redirectUrl = (this.props.match.params.type == 'ent' ? '/enterprise':'/hr');

          if (response === "success") {
            this.props.reset("contactUsValidation"); 
            // on successfull signup show login form 
            $("#contactus-loader").hide();
            // after successfully submit redirect to respective page
            setTimeout(function(){ window.location = '/hr' }, 10000);
          }
        });
    }

    renderInput(field) {
      return (
        <div className="form-group">
          <label>{field.label}</label>
          <input
            type={field.type}
            className="form-control"
            placeholder={field.placeholder}
            autoComplete="off"
            {...field.input}
          />
          {field.meta.touched && field.meta.error && (
            <small className="text-danger">{field.meta.error}</small>
          )}
        </div>
      );
    }

    renderTextarea(field) {
      return (
        <div className="form-group">
          <label>{field.label}</label>
          <textarea
            type={field.type}
            className="form-control md-textarea"
            placeholder={field.placeholder}
            {...field.input}
          ></textarea>
          <p><FormattedMessage id="contact.us.textareaexp" defaultMessage="* required"/></p>
          {field.meta.touched && field.meta.error && (<small className="text-danger">{field.meta.error}</small>)}
        </div>
      );
    }

    errorMessage() {
      //console.log(this.props.contactHrError+' --  '+this.props.contactHrMessage+' props '+JSON.stringify(this.props));
      if (this.props.contactHrError !== null && this.props.contactHrError !== "") {
        return (
          <div className="col-md-12 alert alert-danger" role="alert">
            {this.props.contactHrError}
          </div>
        );
      }
      if (
        this.props.contactHrMessage !== null &&
        this.props.contactHrMessage !== ""
      ) {
        return (
          <div className="col-md-12 alert alert-success" role="alert">
            {this.props.contactHrMessage}
          </div>
        );
      }
      // used to reset the error or sucess message
      //setTimeout(function(){ this.props.clearContHrMessage(); }, 10000);      
      // setTimeout(() => {
      //   this.props.clearContHrMessage();
      // }, 18000);
    }    
    
  render() {
    //const { hr } = this.props.match.params;    
    //console.log('====> '+JSON.stringify(this.props.match.params)+' == '+this.props.match.params.type);
    const { testType } = this.state;
    //var redirectUrl = (testType === 'individual' ? 'individual':'hr');
    //console.log('Message => '+JSON.stringify(this.props));
    // check param and set Title and sub title based on param
    //var moduleTitle = (this.props.match.params.type == 'ent' ? 'You are an Enterprise':'You are an HR Professional');
    //var moduleSubTitle = (this.props.match.params.type == 'ent' ? 'Ask for a private demonstration of our assessment platforms':'ASK FOR FREE CREDIT (14 days free trial only for HR Professionals)');
    var contact_hr_title = 'contact_hr_title_hr';
    var contact_hr_sub_title = 'contact_hr_sub_title_hr';
    const firstname  = <FormattedMessage id="contact.us.name" defaultMessage="First name, Name *"/>
    const emailaddress  = <FormattedMessage id="contact.us.emailaddress" defaultMessage="Email address *"/>
    const phoneno  = <FormattedMessage id="contact.us.phoneno" defaultMessage="Phone Number *"/>
    const company  = <FormattedMessage id="contact.us.company" defaultMessage="Name of company *"/>
    const designation  = <FormattedMessage id="contact.us.designation" defaultMessage="Designation"/>

    //var redirectUrl = (this.props.match.params.type == 'ent' ? '/enterprise':'/hr');    

    return (
    <React.Fragment>
      <section className="mb-4 pages">
        <div className="container">
          {this.props.contactHrMessage == null ? (
            <div className="row">
              <h2 className="h1-responsive font-weight-bold text-center my-4 w-100">
                <FormattedMessage id={contact_hr_title} defaultMessage={contact_hr_title}/>
              </h2>                            
              <p className="text-center w-responsive mx-auto mb-5">
                <FormattedMessage id={contact_hr_sub_title} defaultMessage={contact_hr_sub_title}/>
              </p>
              <div className="row w-100">
                {this.errorMessage()}                
              </div>
              <form id="contact-hr" onSubmit={this.props.handleSubmit(e => this.contactHrForm(e))} className="row w-100">
                <div className="col-lg-10 col-md-12 p-0 m-0">
                        <div className="row" style={{padding:"3px 24px 2px"}}>
                            <div className="col-md-6">
                                <div className="md-form mb-0">
                                    <Field
                                      name="name"
                                      type="text"
                                      label={firstname}
                                      placeholder=""
                                      className="form-control"
                                      component={this.renderInput}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="md-form mb-0">
                                  <Field
                                      name="email"
                                      type="email"
                                      label={emailaddress}
                                      placeholder=""
                                      className="form-control"
                                      component={this.renderInput}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{padding:"3px 24px 2px"}}>
                            <div className="col-md-6">
                                <div className="md-form mb-0">
                                    <Field
                                      name="phone"
                                      type="tel"
                                      label={phoneno}
                                      placeholder="(0)9 83 20 00 15"
                                      className="form-control"
                                      component={this.renderInput}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="md-form mb-0">
                                  <Field
                                      name="company"
                                      type="text"
                                      label={company}
                                      className="form-control"
                                      placeholder=""
                                      component={this.renderInput}
                                    />
                                </div>
                            </div>
                        </div>                      
                        <div className="row" style={{padding:"3px 24px 2px"}}>
                            <div className="col-md-12">
                                <div className="md-form mb-0">
                                    <Field
                                      name="designation"
                                      type="text"
                                      label={designation}
                                      className="form-control"
                                      placeholder=""                                      
                                      component
                                      ={this.renderTextarea}
                                    />
                                </div>
                            </div>
                        </div>
                </div>
                <div className="col-lg-2 col-md-12 d-flex flex-column p-0 m-0 text-center">
                    <ul className="list-unstyled mb-0">
                        <li><i className="fa fa-map-marker"></i>
                            <p>4 rue des Fosses Saint-Jacques 75005 PARIS</p>
                        </li>
                        <li><i className="fa fa-phone"></i>
                            <p>+ 33 (0) 9 83 20 00 15</p>
                        </li>
                        <li><i className="fa fa-envelope"></i>
                            <p>contact@pilotrh.com</p>
                        </li>
                    </ul>
                </div>
                <div className="col-md-12 text-center">
                    <div className="spinner-border text-primary m-auto" role="status" id="contactus-loader">
                      <span className="sr-only"><FormattedMessage id="loading" defaultMessage="Loading..."/></span>
                    </div>
                </div>
                <div className="col-md-12 text-center">
                    <input type="hidden" name="language" value={this.props.lang} />                                    
                    <button type="submit" name="send-query" id="send-query" tabIndex="4"  className="btn btn-primary" ref="sbmt">
                      <FormattedMessage id="submit" defaultMessage="SUBMIT"/>                       
                    </button>
                    {/* <input type="submit" name="send-query" id="send-query" tabIndex="4"  className="btn btn-primary" value="SUBMIT"  ref="sbmt"/> */}
                </div>
                <div className="status"></div>                
              </form>              
            </div>
          ) : (
            <div className="row">
              <div className="col-md-12 p-4 m-0 text-center">
                {this.errorMessage()}
                {/* <div className="col-sm-12 mx-auto text-center mb-4 pb-45">
                  <Link to='/hr' className="btn btn-warning mt-3 mx-3">
                    <FormattedMessage id="do_another_test" defaultMessage="DO ANOTHER TEST"/>
                  </Link>
                  <Link to="/" className="btn btn-primary mt-3 mx-3">
                    <FormattedMessage id="back_to_home" defaultMessage="BACK TO HOME PAGE"/>
                  </Link>
                </div> */}
              </div>
            </div>    
          )}            
        </div> 
      </section>
      <Footer />      
    </React.Fragment>
    );
  }
}

const validate = values => {
  //console.log('Inside validate => '+values.designation);
    const errors = {};
    if (!values.name) {
      errors.name = "Name is required";
    }
    if (!values.email) {
      errors.email = "Email address is required";
    }
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)){
      errors.email = "Invalid email";
    }
    if (!values.phone) {
      errors.phone = "Phone number is required";
    }
    if (values.phone && !/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/i.test(values.phone)){
      errors.phone = "Invalid phone number";
    }
    if (!values.company) {
      errors.company = "Name of company is required";
    }
    if (!values.designation) {
      errors.designation = "Designation is required";
    }    
    return errors;
  };

const mapStateToProps = state => {
  return {
    isSignedIn: state.auth.isSignedIn,
    contactUsValidation: state.form.contactUsValidation,
    contactHrError: state.auth.contactHrError,
    contactHrMessage: state.auth.contactHrMessage,    
    lang: state.locale.lang
  };
};

ContactToHr = connect(
  mapStateToProps,
  { contactToHr, clearContHrMessage }
)(ContactToHr);


export default reduxForm({
    form: "contactUsValidation",
    enableReinitialize: true,    
    validate
  })(ContactToHr);

//export default ContactToHr;

