import { HmacSHA256 } from "crypto-js";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

  // function is used to render the result title what need to improve to user
  function ResultActionNeedToPerform(props) {
    //console.log("ResultActionNeedToPerform => "+JSON.stringify(props));    
    if (props.firstbox >= 150 && props.secondbox >= 50 && props.thirdbox >= 50 && props.fourthbox >= 50 && props.fifthbox >= 50 && props.thirdbox >= 50){
      return (
          <div className="col-md-12 text-left pl-0 mt-4">
            <p className="generally_satisfied">
              <ul/>
              {/* <FormattedMessage id="generally_satisfied" defaultMessage="Generally Satisfied"/> */}
              <ul/>
            </p>
          </div>
      );
    }else{
      return false;
    }
  }

class NeedToImprove extends Component {
    constructor(props) {
        //console.log("Inside NeedToImprove => "+JSON.stringify(props));
        super(props);
        this.state = {
            loader2: false,
            redirectUrl: "",
            showDialog: false,
            testType: null,
            sectionResult: null,
            needToImprove: null
        }
    }

    graphDetail(title, header, description) {
      return (
        <div className="col-md-4 p-1">
          <div className="card text-center mb-3 shadow gd-card">
            <div
              className="card-header bg-info text-white shadow text-uppercase"
              style={{ borderRadius: ".25rem", fontSize: "20px" }}
            >
              {title}
            </div>
            <div className="card-body">
              <div className="border-bottom">
                <h6 className="card-title">{header}</h6>
              </div>
              <div className="pt-2">
                <p className="mb-0 " style={{ fontSize: "16px",color:"black" }}>
                  {description}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    render() {
      const { testType, sectionResult, needToImprove , average} = this.props;
      console.log('Inside NeedToImprove  ', average);     
      return (
        <React.Fragment>
          <div>
            <h4>
                 <FormattedMessage id="need_to_improve" defaultMessage="NEEDS TO IMPROVE"/>              
            </h4>
          { sectionResult.col1 + sectionResult.col2 + sectionResult.col3 >90 || sectionResult.col4 > 59 || sectionResult.col5 > 59 || sectionResult.col6 > 59 || sectionResult.col7 > 59 || sectionResult.col8 > 59 &&  needToImprove != null ?
            <h6>
                 {/* <FormattedMessage id="need_to_improve" defaultMessage="NEEDS TO IMPROVE"/>               */}
            </h6>
          : <h4>
                <FormattedMessage id="nothing" defaultMessage="Nothing"/>              
            </h4>} 
            <div className="row px-3">
            <ResultActionNeedToPerform firstbox={sectionResult.col1 + sectionResult.col2 + sectionResult.col3} secondbox={sectionResult.col4} thirdbox={sectionResult.col5} fourthbox={sectionResult.col6} fifthbox={sectionResult.col7} sixthbox={sectionResult.col8}  />
            {sectionResult.col1 + sectionResult.col2 + sectionResult.col3 > 
              90 && needToImprove != null &&
              this.graphDetail(
                needToImprove.title_1,
                needToImprove.label_1,
                needToImprove.desc_1
            )}
            {sectionResult.col4 > 59 &&  needToImprove != null &&
              this.graphDetail(
                needToImprove.title_2,
                needToImprove.label_2,
                needToImprove.desc_2
              )}
            {sectionResult.col5 > 59 &&  needToImprove != null &&
              this.graphDetail(
                needToImprove.title_3,
                needToImprove.label_3,
                needToImprove.desc_3           
            )}
            {sectionResult.col6 > 59 &&  needToImprove != null &&
              this.graphDetail(
                needToImprove.title_4,
                needToImprove.label_4,
                needToImprove.desc_4            
            )}
            {sectionResult.col7 > 59 &&  needToImprove != null &&
              this.graphDetail(
                needToImprove.title_5,
                needToImprove.label_5,
                needToImprove.desc_5
            )}
            {sectionResult.col8 > 59 &&  needToImprove != null &&
              this.graphDetail(
                needToImprove.title_6,
                needToImprove.label_6,
                needToImprove.desc_6
            )}

        {sectionResult.col4 > 60 && sectionResult.col5 > 60 && sectionResult.col6 > 60 && sectionResult.col7 > 60 && sectionResult.col8 > 60 &&  needToImprove != null ?
            <h5 style={{color:"#23B4C4"}}>
                {/* <FormattedMessage id="generally_satisfied" defaultMessage="Generally Satisfied"/>                   */}
            </h5>
            : <h6>
                {/* <FormattedMessage id="need_to_improve" defaultMessage="NEEDS TO IMPROVE"/>               */}
              </h6>} 
            </div>
          </div>
          
          <div>
            <h4>
                 <FormattedMessage id="your_need_to_improve" defaultMessage="NEEDS TO IMPROVE"/>              
            </h4>
          {sectionResult.col4 < 59 || sectionResult.col5 < 59 || sectionResult.col6 < 59 || sectionResult.col7 < 59 || sectionResult.col8 < 59 &&  needToImprove != null ?
             <h6>
                 {/* <FormattedMessage id="your_need_to_improve" defaultMessage="NEEDS TO IMPROVE"/>               */}
            </h6>
      
          : <h4>
                <FormattedMessage id="nothing" defaultMessage="Nothing"/>                    
            </h4>} 
              
            <div className="row px-3">
            
            <ResultActionNeedToPerform firstbox={sectionResult.col1 + sectionResult.col2 + sectionResult.col3} secondbox={sectionResult.col4} thirdbox={sectionResult.col5} fourthbox={sectionResult.col6} fifthbox={sectionResult.col7} sixthbox={sectionResult.col8}  />
            
            {sectionResult.col1 + sectionResult.col2 + sectionResult.col3 <
              90 && needToImprove != null &&
              this.graphDetail(
                needToImprove.title_1,
                needToImprove.label_1,
                needToImprove.desc_1
            )}
            {sectionResult.col4 < 59 &&  needToImprove != null &&
              this.graphDetail(
                needToImprove.title_2,
                needToImprove.label_2,
                needToImprove.desc_2
              )}
            {sectionResult.col5 < 59 &&  needToImprove != null &&
              this.graphDetail(
                needToImprove.title_3,
                needToImprove.label_3,
                needToImprove.desc_3           
            )}
            {sectionResult.col6 < 59 &&  needToImprove != null &&
              this.graphDetail(
                needToImprove.title_4,
                needToImprove.label_4,
                needToImprove.desc_4            
            )}
            {sectionResult.col7 < 59 &&  needToImprove != null &&
              this.graphDetail(
                needToImprove.title_5,
                needToImprove.label_5,
                needToImprove.desc_5
            )}
            {sectionResult.col8 < 59 &&  needToImprove != null &&
              this.graphDetail(
                needToImprove.title_6,
                needToImprove.label_6,
                needToImprove.desc_6
            )}
            {sectionResult.col4 < 59 && sectionResult.col5 < 59 && sectionResult.col6 < 59 && sectionResult.col7 < 59 && sectionResult.col8 < 59 &&  needToImprove != null ?
            <h6>
                 {/* <FormattedMessage id="your_need_to_improve" defaultMessage="NEEDS TO IMPROVE"/>               */}
            </h6>
          : <h6 style={{color:"#23B4C4"}}>
               {/* <FormattedMessage id="generally_satisfied" defaultMessage="Generally Satisfied"/>               */}
            </h6>} 
            
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  export default NeedToImprove;
