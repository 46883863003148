import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { ROOT_URL, APP_URL } from "../../helper/Config";
import { Link } from "react-router-dom";
import { history } from "../../helper/History";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FormattedMessage } from "react-intl";
import $ from "jquery";

import Hash from "../../helper/Hashing";
import CustomerRights from "../CustomerRights"; // used to show the two steps of customer right before start test 
import Testcopyrights from "../Testcopyrights"; // used to show test copyright text under test
import DoAlert from "../DoAlert"; // used to render the do another test button
import PrintPdf from "../PrintPdf"; // used to print resutl PDF
import NeedToImprove from "../NeedToImprove"; // used to render the Need to improve in test result



import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  PieChart, Pie, Label,
  ResponsiveContainer
} from "recharts";

import {
  fetchHappiness,
  saveHappiness,
  fetchResult
} from "../../actions/Happiness";
import { updateTestConsume } from "../../actions";

class Happiness extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: -1,
      now: 0,
      loading: true,
      loader2: false,
      error: false,
      errorMsg: "",
      sectionResult: null,
      test_id: null,
      user_id: null,
      userScore: null,
      average: null,
      scoreGroup: null,
      averageGroup: null,
      chart2: null,
      sum:[],
      resultData: [],
      needToImprove: null,
      resultBtn: false,
      showChart: false,
      test_version_id: null,
      language: null,
      answer1: null,
      answer2: null,
      answer3: null,
      answer4: null,
      answer5: null,
      answer6: null,
      answer7: null,
      answer8: null,
      answer9: null,
      answer10: null,
      answer11: null,
      answer12: null,
      answer13: null,
      answer14: null,
      answer15: null,
      answer16: null,
      answer17: null,
      answer18: null,
      answer19: null,
      answer20: null,
      answer21: null,
      answer22: null,
      answer23: null,
      answer24: null,
      answer25: null,
      answer26: null,
      answer27: null,
      answer28: null,
      answer29: null,
      answer30: null,
      answer31: null,
      answer32: null,
      answer33: null,
      answer34: null,
      answer35: null,
      answer36: null,
      answer37: null,
      answer38: null,
      answer39: null,
      answer40: null,
      invalid: true,
      cod: null,
      HrId:null,
      candidateid:null,
      add:"",
      global:"",
      resultlink:null,
      totaltest:"",
      newaverage:"",
      globalAverage:"",
      totalTestc:"",
      averaged:"",
      globalav:""
    };
    this.onSubmit = this.onSubmit.bind(this);
    // localStorage.setItem("linku",window.location.href); 
    // console.log(window.location.href);
    // this.props.history.push("/login");
  }

  componentDidMount() {
   
    localStorage.setItem("linku",window.location.href); 
    console.log(window.location.href);
    // this.props.history.push("/login");
    if (this.props.isSignedIn) 
    {
      //console.log('Response => -- '+localStorage.getItem("userName"));
      let testType = localStorage.getItem('testType');
      //console.log('Response => '+JSON.stringify(this.props.location)+' type '+testType);    
      // set the test type to state 
      this.setState({ testType: testType }); 

      let language;
      if (this.props.lang === "en") language = "english";
      if (this.props.lang === "fr") language = "french";
      const { hr } = this.props.match.params;
      let hrId = null;
      let code = null;
      if (hr) {
        code = hr;
        hrId = Hash.decrypt(hr);
        this.setState({ cod: hr }); 
        this.setState({ HrId: hrId }); 
        const link=`${APP_URL}Result/${code}`;
        this.setState({resultlink: link});
        if(this.props.match.params.op == 3){
          this.setState({ candidateid: 3 }); 
        }
      }

      var averageds  = (this.props.lang === "en") ? "Averageoftests" : (this.props.lang === "fr") ? "Moyennedestests" : "Averageoftests";
      this.setState({averaged:averageds});
      console.log(this.props.match.params,hrId,code,language);
      //console.log(' History '+JSON.stringify(this.props));       
      const token = localStorage.getItem("token");
      this.props.fetchHappiness(token, hrId, code, language, resp => {
        //console.log('Response => '+resp);
        if (resp === "success") {
          this.setState({ loading: false });
        }
        if (resp === "no test") {
          this.setState({ loading: false });
          this.props.history.push("/buy-test");
        }
        if (resp === "used") {
          this.setState({
            loading: false,
            error: true,
            errorMsg: "Test already taken"
          });
        }

        if (resp === "error") {
          this.setState({
            loading: false,
            error: true,
            errorMsg: "Error occur, please try again!!"
          });
        }
      });

      // this.props.fetchResult(
      //   token,
      //   (testScore, averageScore, groupResult, sectionResult) => {
      //     this.setState({ loading: false });
      //     if (testScore > 0) {
      //       this.setState({
      //         userScore: testScore,
      //         average: averageScore,
      //         chart2: groupResult,
      //         sectionResult: sectionResult,
      //         resultBtn: true,
      //         : [
      //           { name: "User score", score: testScore },
      //           { name: "Average score", score: averageScore }
      //         ],
      //         showChart: true,
      //         loading: false
      //       });
      //     }
      //   }
      // );
    }
    // else{
    //   // localStorage.setItem("linku",window.location.href); 
    //   // console.log(window.location.href);
    //   // this.props.history.push("/login");
    // }
  } 

  renderField(field) {
    return (
      <div className="form-check">
        <label className="form-check-label">
          <input
            type="radio"
            className={field.class}
            value={field.rbvalue}
            onClick={() => {
              field.handleChange(field.rbvalue);
            }}
            {...field.input}
          />
          {field.label}
        </label>
      </div>
    );
  }

  selectAnswer(val) {
    //console.log(val);
    var { page } = this.state;
    const questionNo = "answer" + page;
    this.setState({
      [questionNo]: val
    });

    $("#error-message").hide();
  }

  renderAnswers(answers, questionId, quesNo) {
    if (answers.length > 0) {
      return answers.map(answer => {
        return (
          <li className="list-group-item" key={answer.answer_id}>
            <Field
              name={"answer" + questionId}
              class={"form-check-input answer" + quesNo}
              component={this.renderField}
              label={answer.answer}
              rbvalue={answer.score}
              handleChange={e => this.selectAnswer(e)}
            />
          </li>
        );
      });
    }
  }

  renderQuestions() {
    const { page } = this.state;
    let inc = 1;
   //console.log('Init Happiness => '+this.props.test.questions+' page '+page+' inc '+inc);            
    //console.log(this.props.questions);
    //const { questions } = this.props;
    // show two stepe candidate rights option before start test     
    if(page >0){
      if (this.props.test) {
        return this.props.test.questions.map((question, index) => {
          return (
            <React.Fragment key={question.question_id}>
              {page === inc && (
                <div className="card">
                  <div className="card-header">{inc}. {question.question} </div>
                  <ul className="list-group list-group-flush">
                    {this.renderAnswers(
                      question.answers,
                      question.question_id,
                      index
                    )}
                  </ul>
                </div>
              )}
              <div className="d-none">{(inc = inc + 1)}</div>
            </React.Fragment>
          );
        });
      }
    }else{
      return (
        <CustomerRights page={page} />
      );            
      //console.log('Inside 2 steps '+page);
    }
  }

  nextQuestion() {
    //console.log(this.state);
    const { page } = this.state;
    let quesNo = page - 1;
    //console.log('Inside nextQuestion  quesNo => '+quesNo+' page -1 '+page - 1);    
    // show two stepe candidate rights option before start test 
    if(page >0){
      if ($(`.answer${quesNo}`).is(":checked")) {
        let progress = (100 / 40) * page;
        this.setState({ page: page + 1, now: progress });
        $("#error-message").hide();
  
        if (page === 40) {
          let language;
          if (this.props.lang === "en") language = "english";
          if (this.props.lang === "fr") language = "french";
          this.setState({
            invalid: false,
            test_id: this.props.test.id,
            user_id: localStorage.getItem("userId"),
            language: language
          });
        }
      } else {
        $("#error-message").show();
      }
    }else{
      this.setState({ page: page + 1, now: 0 }); // increatement steps in case till then page set 1       
      //console.log('No validate');
    }
  }

  showResult() {
    this.setState({ loading: true });
    const { hr } = this.props.match.params;
    let user_id = 0;
    let client_id = 0;
    let code = 0;
    //console.log('showResult hr --> '+hr);

    if (hr) {
      user_id = Hash.decrypt(hr);
      client_id = localStorage.getItem("userId");
      code = hr;
    } else {
      user_id = localStorage.getItem("userId");
    }
    const token = localStorage.getItem("token");
    // get shared link from the localstorage

    const submitData = {
      user_id: user_id,
      client_id: client_id,
      test: "Happiness",
      code: code
    };
    this.props.updateTestConsume(token, submitData, resp => {
      //console.log('showResult => '+JSON.stringify(submitData, null, 4));
      if (resp === "success") {

        var y = (this.props.lang === "en") ? "Your Score" : "Votre score";

        this.setState({
          resultData: [
            { name: y, score: this.state.userScore },
            { name: "Average Score", score: this.state.average }
          ],
          showChart: true,
          loading: false
        });
      }
    });
  }

  //13-1-2022 by himasnhu thakur 
  showResult2(){
  
      this.setState({ loading: true });
      const { hr } = this.props.match.params;
      let user_id = 0;
      let client_id = 0;
      let code = 0;
      //console.log('showResult hr --> '+hr);
  
      if (hr) {
        user_id = Hash.decrypt(hr);
        client_id = localStorage.getItem("userId");
        code = hr;
      } else {
        user_id = localStorage.getItem("userId");
      }
      const token = localStorage.getItem("token");
      // get shared link from the localstorage
  
      const submitData = {
        user_id: user_id,
        client_id: client_id,
        test: "Happiness",
        code: code
      };
      this.props.updateTestConsume(token, submitData, resp => {
        //console.log('showResult => '+JSON.stringify(submitData, null, 4));
        // if (resp === "success") {
        //   this.setState({
        //     resultData: [
        //       { name: "Your Score", score: this.state.userScore },
        //       { name: "Average Score", score: this.state.average }
        //     ],
        //     showChart: true,
        //     loading: false
        //   });
        // }
      });
    }
  
  

  onSubmit() {
    this.setState({ loading: true });
    //console.log(this.state);
   
  

    const token = localStorage.getItem("token");
    this.props.saveHappiness(
      token,
      this.state,
      (testScore, averageScore, groupResult, sectionResult,needToImprove,totaltest,newaverage,globalAverage,totalTestc,globalav) => {
        this.setState({ loading: false });
        if (testScore >= 0) {
          this.setState({
            userScore: testScore,
            average: averageScore,
            chart2: groupResult,
            sectionResult: sectionResult,
            needToImprove: needToImprove,
            resultBtn: true,
            totaltest:totaltest,
            newaverage:newaverage,
            globalAverage:globalAverage,
            totalTestc:totalTestc,
            globalav:globalav
          });
          console.log(this.state.newaverage);
        }
      }
    );
 

  }

  renderAnotherTestButtons() {
    //console.log('Inisde renderAnotherTestButtons '+this.state.testType);
    const buttonName =  <FormattedMessage id="btn.do.another.test" defaultMessage="DO ANOTHER TEST"/>
    return (
      <DoAlert 
        type={this.state.testType}
        buttonName={buttonName}
        className="btn btn-warning mt-3 mx-3 w-50"
        canvasSelector="happinessTest"
        pdfName="happinessTestresult"
       />
    );
  }

  renderContactCoachButtons() {
    //console.log('Inisde renderContactCoachButtons '+this.state.testType);
    if (this.state.testType === 'individual') {
      const buttonName =  <FormattedMessage id="btn.contact.a.coach" defaultMessage="CONTACT A COACH"/>      
      return (
        <DoAlert 
          type={this.state.testType} 
          buttonName={buttonName}
          className="btn btn-info mt-3 w-50"
          target="_blank"
          href="/contact-to-coach"
          canvasSelector="happinessTest"
          pdfName="happinessTestresult"
        />
      );
    }else{
      // const buttonName =  <FormattedMessage id="btn.send.results" defaultMessage="SEND RESULTS"/>            
      // return (
      //   <DoAlert 
      //     type={this.state.testType} 
      //     buttonName={buttonName}
      //     className="btn btn-info mt-3 w-50"
      //     target=""
      //     href="mailto:name@mail.com?subject=PilotRH: %20Happiness%2Tools%2Result&amp;body=This%20is%20a%20message%20body"
      //     canvasSelector="happinessTest"
      //     pdfName="happinessTestresult"          
      //   />
      // );
    }
  }

  renderCharts() {
    //console.log('Inisde render chart '+this.state.testType);
    const { resultData, chart2, sectionResult, loader2, needToImprove, testType } = this.state;
    // const greenclass = "border-success px-3 mb-2";
    // const redclass = "border-danger px-3 mb-2";
    //var data = [{ name: "a", score: 50 }, { name: "b", score: 86 }];
    //const data = [{ name: "name", value: 51 }, { name: "other", value: 49 }];
    const COLORS = ["#17a2b8", "#e0fbff"];    
    //console.log('render => '+JSON.stringify(needToImprove, null, 4)+' -- '+needToImprove);
    const AVERAGEDATA = Object.values(resultData)[0];
    console.log("values------"+Object.values(resultData));
   
    const sums=0;
    


    var your = (this.props.lang === "en") ? "You" : (this.props.lang === "fr") ? "Vous" : "You";
    var average  = (this.props.lang === "en") ? "Average of tests" : (this.props.lang === "fr") ? "Moyenne des tests" : "Average of tests";
    //console.log(this.state);
  
    if (this.state.showChart) {
      return (
        <React.Fragment>
          <div id="happinessTest">
            {testType === 'individual' ? ( // check page and hide the title from first two steps i.e user explanations
              <h2 className="text-center mb-2"><FormattedMessage id="happiness.test.results" defaultMessage="Happiness Test Result"/></h2>
            ) : (
              <h4 className="text-center mb-2"><FormattedMessage id="happiness.tool.results" defaultMessage="Happiness Tool Result"/></h4>
            )}
            <h4 className="text-center mb-4">
              <FormattedMessage id="happiness.result.subtitle" defaultMessage="Your level of happiness in your job"/>
            </h4>
            <div className="row" >
              <div className="col-md-12">
                <div className="m-auto">
                  <div style={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer>
                      <PieChart>
                            <Pie
                              data={resultData}
                              dataKey="score"

                              innerRadius={60}
                              outerRadius={90}
                              fill="#17a2b8"
                              textAnchor="end"
                            >
                          {resultData.map((entry, index) => (
                            <Cell key={index} fill={COLORS[index % COLORS.length]} />
                          ))}
                           {
                    
                    this.state.globalav.map((chart,index)=>(
                     
                      this.state.sum.push(chart.Moyennedestests)
                     
                 
                    ))}
                    {
                      this.state.add=this.state.sum.reduce((result,number)=> result+number)
                      
                    }
                    { this.state.global=this.state.add/8}
                    {console.log(this.state.global)}

                          <Label width={80} position="center">   
                                  
                            {`${AVERAGEDATA.score}%`}
                          </Label>
                        </Pie>
                        {/* <Tooltip />                 */}
                      </PieChart>  
                              
                    </ResponsiveContainer>  
            
                    {/* <h5 className="text-center mb-4" style={{color:"grey",fontSize:"14px"}}><FormattedMessage id="test-average" defaultMessage="Tests average:62%"/> {this.state.totalTestc} tests: {Math.round(this.state.global * 100)/100} %</h5>                        */}
               
                  </div> 
                </div> 
              </div>
            </div>
            {/* <h5 className="text-center mb-4" style={{color:"grey",fontSize:"14px"}}><FormattedMessage id="test-average" defaultMessage="Tests average:62%"/> {this.state.totalTestc} tests: {Math.round(this.state.global * 100)/100} %</h5>                        */}
            <div className="row">
              <div
                style={{ width: "100%", height: 500 }}
                className="m-auto col-md-12 Mob-hide"
              >
                <ResponsiveContainer>
                  <BarChart
                    width={500}
                    height={300}
                    data={chart2}
                    margin={{
                      top: 70,
                      right: 30,
                      left: 20,
                      bottom: 5
                    }}
                  >
                    
                   
                    
                    <CartesianGrid strokeDasharray="3 3" />
                  
                    <XAxis
                      dataKey="name"
                      interval={0}
                      angle={-12}
                      textAnchor="end"
                    />
                  
                    <YAxis domain={[0, 100]} />
                    <Tooltip />
                    <Legend wrapperStyle={{ paddingTop: "60px" }} />
                    <Bar dataKey={your} fill="#17a2b8" />
                    <Bar dataKey={average} fill="#C0C0C0" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
            <hr/>
            <NeedToImprove average={this.state.userScore } sectionResult={sectionResult}  needToImprove={needToImprove} testType={testType}/>
          </div>

          <div className="col-lg-8 col-md-10 col-sm-12 mx-auto text-center mb-4 pb-45 d-flex justify-content-center">
            <PrintPdf  type={this.state.testType}  canvasSelector="happinessTest"  pdfName="happinessTestresult"/>
            {/* <button onClick={e => this.printResult(e)} className="btn btn-info mt-3">
              SAVE YOUR RESULTS
            </button> */}
            {this.renderAnotherTestButtons()}
            {this.renderContactCoachButtons()}
            {loader2 && (
              <div className="position-absolute result-loader">
                <div
                  className="spinner-border"
                  role="status"
                  style={{ width: "2.5rem", height: "2.5rem" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }


  renderProgress() {
    const { now, page, showChart } = this.state;
    console.log(now);
    console.log(page);
    if (page > 1 && !showChart) {
      return <ProgressBar now={now} label={`${now}%`} className="mb-3" />;
    } else {
      return null;
    }
  }

  render() {
    //const { handleSubmit } = this.props;

    //console.log(this.props);
    const {
      invalid,
      resultBtn,
      showChart,
      loading,
      error,
      page,      
      errorMsg,
      testType
    } = this.state;

    const testTitle =  (testType === 'individual'? 'Happiness Test':'Happiness Tool'); // check if result or not    
    //console.log('Show Chart '+this.state.showChart);
    if (loading)
      return (
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              <div className="text-center">
                <div
                  className="spinner-border m-5"
                  role="status"
                  style={{ width: "3rem", height: "3rem" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      );

    return (
      <React.Fragment>
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              {this.state.showChart === false && ( // show if there is not short chart or result 
                <h2 className="text-center mb-2">{testTitle}</h2>
              )}
              {error ? (
                <div className="alert alert-danger col-md-6 text-center mx-auto">
                  <h4 className="alert-heading">
                    Hello {localStorage.getItem("userName")}!
                  </h4>
                  {errorMsg}
                </div>
              ) : (
                <div className="col-lg-8 col-md-10 col-sm-12 m-auto m-90">
                  <div id="result-chart">{this.renderCharts()}</div>
                  <div id="error-message" className="text-danger">
                    Select option
                  </div>
                  {this.renderProgress()}
                  <form
                    onSubmit={this.props.handleSubmit(this.onSubmit)}
                    id="happiness-form"
                  >
                    {this.renderQuestions()}
                    <button
                      type="submit"
                      className="btn btn-info mt-2 mx-auto"
                      disabled={invalid}
                      style={{
                        display: invalid || resultBtn ? "none" : "block"
                      }}
                    >
                      <FormattedMessage id="submit" defaultMessage="SUBMIT"/>
                    </button>
                  </form>
                  {page > 0 && ( // check page and hide the title from first two steps i.e user explanations
                    <button
                      className="btn btn-info mt-2 text-left"
                      onClick={() => this.nextQuestion()}
                      disabled={!invalid}
                      style={{
                        display: invalid && !resultBtn ? "block" : "none"
                      }}
                    >
                    <span>
                      <FormattedMessage id="next" defaultMessage="NEXT"/>
                    </span>
                    </button>                    
                  )}
                  {page <= 0 && (
                    <button
                      className="btn btn-info mt-2 mx-auto"
                      onClick={() => this.nextQuestion()}
                      disabled={!invalid}
                      style={{
                        display: invalid && !resultBtn ? "block" : "none"
                      }}
                    >                    
                    <span>
                      <FormattedMessage id="start" defaultMessage="START"/>
                    </span>
                    </button>                                        
                  )} 
                  <div
                    className="text-center"
                    style={{
                      display: resultBtn && !showChart ? "block" : "none"
                    }}
                  >
                    <h3 className="text-info mb-3">
                      <FormattedMessage id="test_saved" defaultMessage="Test save successfully"/>
                    </h3>
                    {this.props.match.params.op !=2 &&this.props.match.params.op == 0  && window.location.href !="https://pilotrh.drishinfo.com/happiness" && <h5 className="text-info mb-3">
                      <FormattedMessage id="test_result" defaultMessage="Your test result has been mailed to your corresponding HR"/>
                    </h5> }
            
                    {/* {console.log(window.location.href)} */}
                    {this.props.match.params.hr && this.props.match.params.op !=2 && this.props.match.params.op !=3?<Link className="" to="/"><div><button
                      className="btn btn-info"
                      onClick={() => this.showResult2()}
                    >
                      <FormattedMessage id="btn.goto.home" defaultMessage="Get Result"/>
                    </button></div></Link>:
                    <button
                      className="btn btn-info"
                      onClick={() => this.showResult()}
                    >
                      <FormattedMessage id="get_result" defaultMessage="Get Result"/>
                    </button>
  }
                 
                  </div>
                </div>
              )}

            {page > 0 && ( // check page and hide the title from first two steps i.e user explanations
              <Testcopyrights />
            )}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  //console.log(state);

  return {
    isSignedIn: state.auth.isSignedIn,    
    test: state.happiness.HappinessData.data,
    lang: state.locale.lang
  };
};

Happiness = connect(
  mapStateToProps,
  { fetchHappiness, saveHappiness, updateTestConsume, fetchResult }
)(Happiness);

export default reduxForm({
  form: "HappyinessForm"
})(Happiness);
