import React, { Component } from "react";

import Professional from "../../target/Professional";
import PublicSector from "../../target/PublicSector";
import WorkGroup from "../../target/WorkGroup";
import WorkCulture from "../../target/WorkCulture";
import WorkAtmosphere from "../../target/WorkAtmosphere";
import Management from "../../target/Management";
import WorkEnvironment from "../../target/WorkEnvironment";

class TargetHr extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test: 1,
      loading: false,
      showResult: false,
      hr: null
    };
  }

  componentDidMount() {
    const { hr } = this.props;
    if (hr) {
      this.setState({ hr: hr });
    }
  }

  nextTest() {
    let prevTest = this.state.test;
    if (prevTest === 7) {
      this.props.stepUp2();
    } else {
      prevTest = prevTest + 1;
      this.setState({ test: prevTest });
    }
  }

  render() {
    let { test, hr, loading } = this.state;

    let showTest;

    if (test === 1) {
      showTest = <Professional stepUp={this.nextTest.bind(this)} hr={hr} />;
    } 
    else if ( test === 2) {
      showTest = <PublicSector stepUp={this.nextTest.bind(this)} hr={hr} />;
    } else if (test === 3) {
      showTest = <WorkGroup stepUp={this.nextTest.bind(this)} hr={hr} />;
    } else if (test === 4) {
      showTest = <WorkCulture stepUp={this.nextTest.bind(this)} hr={hr} />;
    } else if (test === 5) {
      showTest = <WorkAtmosphere stepUp={this.nextTest.bind(this)} hr={hr} />;
    } else if (test === 6) {
      showTest = <Management stepUp={this.nextTest.bind(this)} hr={hr} />;
    } else if (test === 7) {
      showTest = <WorkEnvironment stepUp={this.nextTest.bind(this)} hr={hr} pars={this.props.par} />;
    } else {
      showTest = "";
    }

    if (loading)
      return (
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              <div className="text-center">
                <div
                  className="spinner-border m-5"
                  role="status"
                  style={{ width: "3rem", height: "3rem" }}>
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      );

    return (
      <React.Fragment>
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0">{showTest}</div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default TargetHr;
