import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { FormattedMessage } from "react-intl";
import ProgressBar from "react-bootstrap/ProgressBar";
import $ from "jquery";
import Hash from "../../../helper/Hashing";
import Testcopyrights from "../../Testcopyrights"; // used to show test copyright text under test 

import {
  fetchPersonalityTalent,
  savePersonality, combineResult
} from "../../../actions/Talent";
import { relative } from "path";

class Personality extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      now: 0,
      pages:localStorage.getItem('page'),
      nows:localStorage.getItem('now'),
      loading: true,
      error: false,
      errorMsg: "",
      option1: [],
      option2: [],
      option3: [],
      option4: [],
      showOption1Result: false,
      showOption2Result: false,
      invalid: true,
      success_msg: false,
      test_id: null,
      showViewBtn: false,
      hideForm: true,
      resultant: [],
      ansData: [],
      showResultDiv: false,
      firstAns: [],
      thirdAns: [],
      notSelected: [],
      selected: [],
      testcode:null
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    let language;
    if (this.props.lang === "en") language = "english";
    if (this.props.lang === "fr") language = "french";
    const { hr } = this.props;
    let hrId = null;
    let code = null;
    if (hr) {
      code = hr;
      hrId = Hash.decrypt(hr);
    }else{
      code = localStorage.getItem('sharedTestId');
    }

    if(code != "null"){
      code = localStorage.getItem('sharedTestId');
    
     if(code!=null){
     hrId =Hash.decrypt(code);
     }
   }
    // console.log(code);
    this.setState({testcode:code});
    const token = localStorage.getItem("token");
    // console.log('Response fetchPersonalityTalent => '+JSON.stringify(this.props)+' hrId '+hrId+' code '+code);    
    
    this.props.fetchPersonalityTalent(token, hrId, code, language, resp => {
      if (resp === "success") {
        this.setState({ loading: false });
      }
      if (resp === "no test") {
        this.setState({ loading: false });
        this.props.history.push("/buy-test");
      }
      if (resp === "used") {
        this.setState({
          loading: false,
          error: true,
          errorMsg: "Test already taken"
        });
      }
      if (resp === "error") {
        this.setState({
          loading: false,
          error: true,
          errorMsg: "Error occur, please try again!!"
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {}

  renderField(field) {
    return (
      <React.Fragment>
        <div className="custom-control custom-radio custom-control-inline d-block w-100 text-center pl-0">
          <input
            type="radio"
            className={field.class}
            id={field.id}
            value={field.rbvalue}
            onClick={() => {
              field.handleChange(field);
            }}
            {...field.input}
          />
            <label className="custom-control-label text-uppercase" htmlFor={field.id}>
            <span className="font-weight-bold">{field.label}</span>
          </label>
        </div>
      </React.Fragment>
    );
  }

  selectAnswer(obj) {
    const questionNo = obj.input.name;
    this.setState({
      [questionNo]: obj.rbvalue
    });
    $("#error-message").hide();
  }
  /*** show second question option according to first question options */
  setSelecteOption(option) {
    if (option === "option1") {
      this.setState({ showOption1Result: true, showOption2Result: false });
    } else if (option === "option2") {
      this.setState({ showOption2Result: true, showOption1Result: false });
    }
  }
  renderAnswers(question, index) {
    const { showOption1Result, showOption2Result } = this.state;
    var displayPosition = { position: relative };
    if (question) {
      return (
        <span>
          {question.option1 && (
            <li className="list-group-item w-50 float-left mt-4">
              <Field
                name={"option" + (index + 1)}
                id={"option1" + question.id}
                class={"custom-control-input answer" + (index + 1)}
                style={displayPosition}
                component={this.renderField}
                label={question.option1}
                rbvalue={1}
                handleChange={e => this.selectAnswer(e)}
                onChange={e => this.setSelecteOption("option1")}
              />
              <p className={"text-center"}>{question.desc1} </p>
            </li>
          )}
          {question.option2 && (
            <li className="list-group-item w-50 float-left mt-4">
              <Field
                name={"option" + (index + 1)}
                id={"option_1" + question.id}
                class={"custom-control-input answer" + (index + 1)}
                component={this.renderField}
                label={question.option2}
                style={displayPosition}
                rbvalue={2}
                handleChange={e => this.selectAnswer(e)}
                onChange={e => this.setSelecteOption("option2")}
              />
              <p className={"text-center"}>{question.desc2}</p>
            </li>
          )}

          {showOption1Result && question.option1_1 && (
            <li className="list-group-item  w-50 float-left option1_1 mt-4">
              <Field
                name={"option" + (index + 1)}
                id={"option1_" + question.id}
                class={"custom-control-input answer" + (index + 1)}
                style={{ position: "relative !important" }}
                component={this.renderField}
                label={question.option1_1}
                rbvalue={"1_1"}
                handleChange={e => this.selectAnswer(e)}
              />
              <p className={"text-center"}>{question.desc1_1} </p>
            </li>
          )}
          {showOption1Result && question.option1_2 && (
            <li className="list-group-item  w-50 float-left option1_2 mt-4">
              <Field
                name={"option" + (index + 1)}
                id={"option_1_" + question.id}
                class={"custom-control-input answer" + (index + 1)}
                style={{ position: "relative !important" }}
                component={this.renderField}
                label={question.option1_2}
                rbvalue={"1_2"}
                handleChange={e => this.selectAnswer(e)}
              />
              <p className={"text-center"}>{question.desc1_2} </p>
            </li>
          )}

          {showOption2Result && question.option2_1 && (
            <li className="list-group-item  w-50 float-left option2_1 mt-4">
              <Field
                name={"option" + (index + 1)}
                id={"option1_" + question.id}
                class={"custom-control-input answer" + (index + 1)}
                style={{ position: "relative !important" }}
                component={this.renderField}
                label={question.option2_1}
                rbvalue={"2_1"}
                handleChange={e => this.selectAnswer(e)}
              />
              <p className={"text-center"}>{question.desc2_1} </p>
            </li>
          )}
          {showOption2Result && question.option2_2 && (
            <li className="list-group-item w-50 float-left option2_2 mt-4">
              <Field
                name={"option" + (index + 1)}
                id={"option_1_" + question.id}
                class={"custom-control-input answer" + (index + 1)}
                style={{ position: "relative !important" }}
                component={this.renderField}
                label={question.option2_2}
                rbvalue={"2_2"}
                handleChange={e => this.selectAnswer(e)}
              />
              <p className={"text-center"}>{question.desc2_2} </p>
            </li>
          )}
        </span>
      );
    }
  }

  renderQuestions() {
    const { page } = this.state;
    let inc = 1;

    if (this.props.test) {
      return this.props.test.map((question, index) => {
        return (
          <React.Fragment key={question.id}>
            {page === inc && (
              <div className="card personality-list">
                <div className="card-header">
                  {"Q" + (index + 1)}. {question.question}
                </div>
                <ul className="list-group list-group-flush">
                  {this.renderAnswers(question, index)}
                </ul>
              </div>
            )}
            <div className="d-none">{(inc = inc + 1)}</div>
          </React.Fragment>
        );
      });
    }
  }
  
  renderProgress() {
    const { nows, pages } = this.state;

    console.log(nows);
    console.log(pages)

    if (pages > 0 ) {
      return <ProgressBar now={nows} label={`${nows}%`} className="mb-3" />;
    } else {
      return null;
    }
  }

  nextQuestion() {
    const { page,pages } = this.state;
    let quesNo = page;
    
    if ($(`.answer${quesNo}`).is(":checked")) {
      let completed = (100 / 4) * page;
      if(localStorage.getItem('testType')=='individual'){
        let progres = (100 /13) * pages;
      let progress= progres.toFixed(2);
      // let quesAns = "question" + page + "Answers";
      console.log(progress);
      let addedpages=parseInt(pages) + 1;
      this.setState({ pages: addedpages, nows: progress });
      let addpage=parseInt(this.state.pages) + 1;
      localStorage.setItem('page',addpage);
      localStorage.setItem('now',this.state.nows)
      }else{
      let progres = (100 /40) * pages;
      let progress= progres.toFixed(2);
      // let quesAns = "question" + page + "Answers";
      console.log(progress);
      let addedpages=parseInt(pages) + 1;
      this.setState({ pages: addedpages, nows: progress });
      let addpage=parseInt(this.state.pages) + 1;
      localStorage.setItem('page',addpage);
      localStorage.setItem('now',this.state.nows)}
      this.setState({ page: page + 1, now: completed });
      $("#error-message").hide();
      if (page === 3) {
        let language;
        if (this.props.lang === "en") language = "english";
        if (this.props.lang === "fr") language = "french";
        this.setState({
          invalid: false,
          user_id: localStorage.getItem("userId"),
          language: language
        });
      }
    } else {
      $("#error-message").show();
    }
  }

  onSubmit(e) {
    const token = localStorage.getItem("token");
    // to check last question option selected or not
    if ($(`.answer${this.state.page}`).is(":checked")) {
      $("#error-message").hide();
      this.setState({ loading: true });
      
      this.props.savePersonality(token, this.state, resp => {
        if (resp.message === "success") {

          // get combine result
          var selected = resp.result.selectedValues;
          var notselected = resp.result.notSelectedValues;
          var finalOrder = selected.concat(notselected);

          let language;
          if (this.props.lang === "en") language = "english";
          if (this.props.lang === "fr") language = "french";
          
          var sendData = {'motivation': this.props.motivationResult, 'personality': finalOrder, 'language': language}
          this.props.combineResult(token, sendData, response => {
            //this.setState({  combineResultData: response.data.data });
            //if (response.message === "success") {
            this.setState({ loading: false });
            this.props.stepUp();
            //}
          });

          
        }
        // this.setState({
        //   test_id: resp.data.last_id,
        //   success_msg: true,
        //   invalid: false,
        //   hideForm: false,
        //   showViewBtn: true
        // });
      });
    } else {
      $("#error-message").show();
    }
    //console.log(this.state);
  }

  /***** view saved test result*** 
  showTestResult(val) {
    const token = localStorage.getItem("token");

    this.props.showPersonalityTestResult(token, val, response => {
      this.setState({
        showResultDiv: true,
        ansData: this.props.result.ansData,
        firstAns: this.props.result.firstQuesAns,
        thirdAns: this.props.result.thirdQuesAns,
        showViewBtn: false,
        success_msg: false
      });
    });
  }
  */

  renderNotSelectedData() {
    let inc = 1;

    if (this.props.result.result) {
      return this.props.result.result.notSelected.map((labels, index) => {
        return (
          <React.Fragment key={(inc = inc + 1)}>
            <div className="card">
              {this.props.result.result.notSelectedValues.map((value, key) => {
                return (
                  <div className="row" key={(inc = inc + 1)}>
                    <div className="col-sm  list-group-item positionRel w-25 float-left">
                      {labels["name" + value]}
                    </div>
                    <div className="col-sm  list-group-item positionRel w-25 float-right">
                      {labels["label" + value]}
                    </div>
                    <div className="col-sm  list-group-item positionRel w-50 float-right">
                      {labels["desc" + value]}
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="d-none">{(inc = inc + 1)}</div>
          </React.Fragment>
        );
      });
    }
  }

  renderResult(data) {
    const { firstAns, thirdAns } = this.state;
    let inc = 1;
    var background = { background: "grey" };

    if (this.props.result.result) {
      return this.props.result.result.selected.map((labels, index) => {
        return (
          <React.Fragment key={(inc = inc + 1)}>
            <div className="card">
              {firstAns === thirdAns && (
                <div className="row">
                  <div
                    className="col-sm  list-group-item positionRel w-25 float-left"
                    style={background}
                  >
                    {labels["name" + firstAns]}
                  </div>
                  <div className="col-sm  list-group-item positionRel w-25 float-right">
                    {labels["label" + firstAns]}
                  </div>
                  <div className="col-sm  list-group-item positionRel w-50 float-right">
                    {labels["desc" + firstAns]}
                  </div>
                </div>
              )}
              {firstAns !== thirdAns && (
                <div className="row">
                  <div
                    className="col-sm  list-group-item positionRel w-25 float-left"
                    style={background}
                  >
                    {labels["name" + firstAns]}
                  </div>
                  <div className="col-sm  list-group-item positionRel w-25 float-right">
                    {labels["label" + firstAns]}
                  </div>
                  <div className="col-sm  list-group-item positionRel w-50 float-right">
                    {labels["desc" + firstAns]}
                  </div>
                </div>
              )}
              {firstAns !== thirdAns && (
                <div className="row">
                  <div
                    className="col-sm  list-group-item positionRel w-25 float-left"
                    style={background}
                  >
                    {labels["name" + thirdAns]}
                  </div>
                  <div className="col-sm  list-group-item positionRel w-25 float-right">
                    {labels["label" + thirdAns]}
                  </div>
                  <div className="col-sm  list-group-item positionRel w-50 float-right">
                    {labels["desc" + thirdAns]}
                  </div>
                </div>
              )}

              {this.renderNotSelectedData(this.props.result.result)}
            </div>
            <div className="d-none">{(inc = inc + 1)}</div>
          </React.Fragment>
        );
      });
    }
  }

  render() {
    const {
      page,
      success_msg,
      hideForm,
      showViewBtn,
      showResultDiv,
      loading,
      error,
      errorMsg
    } = this.state;
    var displayBtn = { display: page === 4 ? "none" : "block" };
    var displayForm = { display: hideForm ? "block" : "none" };
    // var displayViewBtn  = {display:  showViewBtn ? "block" : "none"};
    // let resultDiv       ={ display: showResultDiv ? "block" : 'none '};

    if (loading)
      return (
        <section className="position-relative top-100">
          <div className="row d-flex p-0 m-0" id="mb-space">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              <div className="text-center">
                <div
                  className="spinner-border m-5"
                  role="status"
                  style={{ width: "3rem", height: "3rem" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    return (
      <React.Fragment>
        <h2 className="text-center mb-1">
          <FormattedMessage id="ind.talent.pesonality.title" defaultMessage="Personality"/>
        </h2>

        <div className="col-lg-8 col-md-10 col-sm-12 m-auto">
          {error && <div className="alert alert-danger">{errorMsg}</div>}
          {success_msg && (
            <div id="success-message" className="text-success">
              <FormattedMessage id="test_saved" defaultMessage="Test saved successfully !"/>
            </div>
          )}
          <div id="error-message" className="text-danger">
            Select option
          </div>

          <div style={displayForm}>
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
            {this.renderProgress()}
              {this.renderQuestions()}
              <button
                type="submit"
                className="btn btn-info btnSubmit mt-2  mx-auto"
                style={{ display: !this.state.invalid ? "block" : "none" }}
              >
                <FormattedMessage id="submit" defaultMessage="Submit"/>
              </button>
            </form>
          </div>

          <button
            style={displayBtn}
            className="btn btn-info mt-2 mx-auto"
            onClick={() => this.nextQuestion()}
          >
          <FormattedMessage id="next" defaultMessage="NEXT"/>
          </button>
          {showViewBtn && (
            <button
              className="btn btn-info mt-2"
              onClick={() => this.showTestResult(this.state)}
            >
              <FormattedMessage id="view_result" defaultMessage="View Result"/>
            </button>
          )}
          {showResultDiv && (
            <div className="resultGraph">{this.renderResult(this.state)}</div>
          )}
        </div>
        {page > 0 && ( // check page and hide the title from first two steps i.e user explanations
          <Testcopyrights />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    motivationResult: state.talent.MotivationResult,
    test: state.talent.PersonalityData.data,
    result: state.talent.PersonalityResult,
    lang: state.locale.lang
  };
};

Personality = connect(
  mapStateToProps,
  { fetchPersonalityTalent, savePersonality, combineResult }
)(Personality);

export default reduxForm({
  form: "PersonalityForm"
})(Personality);
