import React from "react";

import InjectedCheckoutForm from "./CheckoutForm";

class MyStoreCheckout extends React.Component {
  render() {
    return (
      <div>
        <InjectedCheckoutForm />
      </div>
    );
  }
}

export default MyStoreCheckout;
