import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import AuthReducer from "./authReducer";
import HappinessReducer from "./happinessReducer";
import TalentReducer from "./talentReducer";
import TargetReducer from "./targetReducer";
import LanguageReducer from "./languageReducer";

export default combineReducers({
  auth: AuthReducer,
  locale: LanguageReducer,
  happiness: HappinessReducer,
  talent: TalentReducer,
  target: TargetReducer,
  form: formReducer
});
