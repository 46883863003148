import React, {  } from "react";
//import ReactDOM from 'react-dom';
import { FormattedMessage } from "react-intl";

// component used to add the copyrights under each test
const Testcopyrights = props => {
    let CopyrightDate =  new Date();
    return (
        <React.Fragment>
        <div className="col-lg-12 p-0 fixed-bottom mt-2">
            <div className="card">
                <div className="text-center p-0 small">
                    <p>
                        <FormattedMessage
                        id="test.copyrights"
                        defaultMessage={`© Copyright 5W PILOT RH SOLUTIONS {year} – All right reserved – www.pilotrhsolutions.com..`}
                        values={{year: CopyrightDate.getFullYear()}}
                        />
                    </p>
                </div>
            </div>
        </div>
        </React.Fragment>
    );
}

export default Testcopyrights;
