import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import $ from "jquery";
import { FormattedMessage,FormattedHTMLMessage  } from "react-intl";
import ReactCountryFlag from "react-country-flag";

import { ROOT_URL, APP_URL } from "../helper/Config";
//import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { login, logout, clearMessage, setLocale } from "../actions";
import Register from "./includes/Register";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      remainingTest: 0,
      language: null,
      forgetemail : null
    };
    localStorage.removeItem('guide')
    this.loginUser = this.loginUser.bind(this);
    this.forgetUser = this.forgetUser.bind(this);
    this.handleforgetemailChange = this.handleforgetemailChange.bind(this);
    axios.get(`${ROOT_URL}/getRoles`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }).then(response => {
        //console.log(response);
        this.setState({
          roles: response.data.roles
        });
      }).catch(error => {
        console.log(error);
      });
  }
  handleforgetemailChange(e) {
   this.setState({forgetemail: e.target.value});
  }
  updateContent(language) {
    //console.log(' -- '+language);
    const popupContent  = (language === "en") ? "<ul className='list-group'><li className='list-group-item'>Choose your language and click <b>LOGIN</b> at the top right of the screen. An identification window opens.</li><li className='list-group-item'>Click <b>SIGN UP</b> at the bottom right of the window.</li><li className='list-group-item'>Complete the registration form by choosing the 'Individual' or ‘Professional’ section and then click <b>SIGN UP</b>.</li></ul>" : "<ul className='list-group'><li className='list-group-item'>Choisir votre langue et cliquez sur <b>LOGIN</b> en haut à droite de l’écran. Une fenêtre d’identification s’ouvre.</li><li className='list-group-item'>Cliquez sur <b>S’INSCRIRE</b> en bas à droite,de la fenêtre.</li><li className='list-group-item'>Complétez le formulaire d’inscription en choisissant la rubrique ‘Particulier’ ou ‘Professionnel’ suivant le cas et cliquez sur <b>S’INSCRIRE</b>.</li></ul>";
    //console.log('updateContent => '+popupContent+' lang '+language);
    $('button.dropdown-item').attr('data-content', popupContent);
  }

  logout(e) {
    e.preventDefault();
    this.setState({
      remainingTest: '0'
    });
    this.props.logout();
  }

  openLogin(e) {
    $("#loginModal").modal("show");
    // $("#register-form").delay(100).fadeIn(100);
    // $("#login-form").fadeOut(100);
    // $("#login-form-link").removeClass("active");
    // console.log('Inside Header '+e);
    // if(e === '#register-form-link'){
    //   $('.modal-title').text('SIGNUP');
    //   $(e).addClass("active");
    // }else{
    // //   $(e.target).addClass("active");
    // // }
  }
  
  openLogin2(e) {
    $("#loginModal").modal("show");
    $("#register-form").delay(100).fadeIn(100);
    $("#login-form").fadeOut(100);
    $("#login-form-link").removeClass("active");
    console.log('Inside Header '+e);
    if(e === '#register-form-link'){
      $('.modal-title').text('SIGNUP');
      $(e).addClass("active");
    }else{
    //   $(e.target).addClass("active");
    }
  }
  openClickNav() {
    $('.navbar-collapse').collapse('hide');
  }

  openMenuDropdown() {
    if (this.props.isSignedIn) {
      var userid = localStorage.getItem("userId");
      //console.log("user loggedIN "+userid);
      var api_url = `${ROOT_URL}/getTests/${userid}`;
      // get no of users test
      axios.get(api_url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }).then(response => {
        //console.log('Inside componentDidMount '+response.data.tests);
        this.setState({
          remainingTest: response.data.tests
        });
      }).catch(error => {
        console.log(error);
      });
    }
  }

  componentDidMount() {
    $(document).ready(function(){
      $('[data-toggle="popover"]').popover({
          placement : 'left',
          trigger: 'hover',
          html:true
      });
    });
    if (this.props.isSignedIn) {
      var userid = localStorage.getItem("userId");
      //console.log("user loggedIN "+userid);
      var api_url = `${ROOT_URL}/getTests/${userid}`;
      // get no of users test
      axios.get(api_url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }).then(response => {
        //console.log('Inside componentDidMount '+response.data.tests);
        this.setState({
          remainingTest: response.data.tests
        });
      }).catch(error => {
        console.log(error);
      });
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    // if (this.props.isSignedIn !== prevProps.isSignedIn) {
    //   //console.log("state is differ");
    // }
  }

  renderAuthButton() {
    var userType = localStorage.getItem("role");
    if(userType !== 'User'){
      var fileLink    =  (this.props.lang === "en") ? "/assets/pdf/hr-pro-user-guide.pdf" : "/assets/pdf/guide-utilisateur-professionnel.pdf";
    }else{
      var fileLink    =  (this.props.lang === "en") ? "/assets/pdf/individual-user-guide.pdf" : "/assets/pdf/guide-utilisateur-particulier.pdf";
    }
   //console.log('userType => '+userType+' lang '+this.props.lang+' fileLink '+fileLink);
   // convert first connection popup
   const popupContent  = (this.props.lang === "en") ? "<ul className='list-group'><li className='list-group-item'>Choose your language and click <b>LOGIN</b> at the top right of the screen. An identification window opens.</li><li className='list-group-item'>Click <b>SIGN UP</b> at the bottom right of the window.</li><li className='list-group-item'>Complete the registration form by choosing the 'Individual' or ‘Professional’ section and then click <b>SIGN UP</b>.</li></ul>" : "<ul className='list-group'><li className='list-group-item'>Choisir votre langue et cliquez sur <b>LOGIN</b> en haut à droite de l’écran. Une fenêtre d’identification s’ouvre.</li><li className='list-group-item'>Cliquez sur <b>S’INSCRIRE</b> en bas à droite,de la fenêtre.</li><li className='list-group-item'>Complétez le formulaire d’inscription en choisissant la rubrique ‘Particulier’ ou ‘Professionnel’ suivant le cas et cliquez sur <b>S’INSCRIRE</b>.</li></ul>";

    if (this.props.isSignedIn === null || !this.props.isSignedIn) {
      return (
        <React.Fragment>
          <Link
            to="/"
            className="nav-link dropdown-toggle"
            id="navbarDropdownMenuLink"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            // onClick={e => this.openMenuDropdown()}
          >
            Login
          </Link>
          <div  className="dropdown-menu"  aria-labelledby="navbarDropdownMenuLink" >
            {this.props.lang === 'en' ? (
              <button type="button" className="dropdown-item nav-link"  data-toggle="popover" onClick={e => this.openLogin2()}>
                <b>FIRST CONNECTION</b>
              </button>
              //  onMouseOver={this.updateContent.bind(this.props.lang)} data-content={popupContent}
            ) : (
              <button type="button" className="dropdown-item nav-link"  data-toggle="popover" onClick={e => this.openLogin2()} >
                <b>PREMIERE CONNEXION</b>
              </button>
            )}
             <span className="nav-link p-link" onClick={e => this.openLogin()}>
              Login
            </span>
          </div>
        </React.Fragment>
      );
    } else if (this.props.isSignedIn) {
      let { remainingTest } = this.state;
      return (
        <React.Fragment>
          <Link
            to="/"
            className="nav-link dropdown-toggle"
            id="navbarDropdownMenuLink"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
          <span onClick={e => this.openMenuDropdown()} onMouseEnter={() => this.openMenuDropdown()} > {localStorage.getItem("userName")} </span>
          </Link>

          <div
            className="dropdown-menu"
            aria-labelledby="navbarDropdownMenuLink"
          >
            <Link className="dropdown-item" target="_blank" to={fileLink}>
              <FormattedMessage id="header.user.guide" defaultMessage="USER GUIDE"/>
            </Link>
            <Link className="dropdown-item" to="/buy-test">
              <FormattedMessage id="header.buy.test" defaultMessage="BUY TEST"/>
            </Link>
            {localStorage.getItem("userRole") === "Hr" ? (
              <Link className="dropdown-item" to="/create-link">
                <FormattedMessage id="header.test.link" defaultMessage="SHARE TEST"/>
              </Link>
            ) : null}
            {localStorage.getItem("userRole") === "Hr" ? (
              <Link className="dropdown-item" to="/correlation">
                <FormattedMessage id="header.match.profile" defaultMessage="MATCHING"/>
              </Link>
            ) : null}
            {localStorage.getItem("userRole") === "Hr" ? (
              <Link className="dropdown-item" to="/hr-candidates">
                <FormattedMessage id="header.candidates" defaultMessage="Candidates"/>
              </Link>
            ) : null}
            {localStorage.getItem("userRole") === "Hr" ? (
              <Link className="dropdown-item" to="/hr-jobprofile">
                <FormattedMessage id="header.job.profile" defaultMessage="Job Profiles"/>
              </Link>
            ) : null}
            <Link to="/buy-test" className="dropdown-item">
                <FormattedMessage id="header.nooftest" defaultMessage="TOTAL NO OF TEST"/> <span id="remainingTest">({this.state.remainingTest})</span>
            </Link>
            <Link to="/" className="dropdown-item" onClick={this.logout.bind(this)} >
                <FormattedMessage id="header.logout" defaultMessage="LOGOUT"/>
            </Link>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <span className="nav-link p-link" onClick={e => this.openLogin()}>
          Login
        </span>
      );
    }
  }

  errorMessage() {
    //console.log(this.props.signupError+' --  '+this.props.successMessage+' props '+JSON.stringify(this.props));
    // // check for signup error message
    if (this.props.signupError !== null && this.props.signupError !== "") {
      return (
        <div className="alert alert-danger" role="alert">
          {this.props.signupError}
        </div>
      );
    }
    // check for loginn error message
    if (this.props.errorMessage !== null && this.props.errorMessage !== "") {
      return (
        <div className="alert alert-danger" role="alert">
          {this.props.errorMessage}
        </div>
      );
    }
    if (
      this.props.successMessage !== null &&
      this.props.successMessage !== ""
    ) {
      return (
        <div className="alert alert-success" role="alert">
          {this.props.successMessage}
        </div>
      );
    }

    setTimeout(() => {
      this.props.clearMessage();
    }, 90000);
  }


  msgMessage() {
    //console.log(this.props.signupError+' --  '+this.props.successMessage+' props '+JSON.stringify(this.props));
    // // check for signup error message
    // if (this.props.signupError !== null && this.props.signupError !== "") {
      return (
        <div className="alert alert-success" role="alert">
          <FormattedHTMLMessage
                      id="Link.Message"
                      defaultMessage="Login"
                    />
        </div>
      );
    // }
    // check for loginn error message
 
    

    // setTimeout(() => {
    //   this.props.clearMessage();
    // }, 90000);
  }

  loginUser(values) {
    /*var userData = {
      grant_type: "password",
      client_id: "2",
      client_secret: "KNHif0NpXU3tVu9pIuVsWCLDIjOpv1uS3fvHUzCg",
      username: values.email,
      password: values.password
    };
    */
   // pass the languages in values
   //var language = localStorage.getItem("lang");
   values.language = this.props.lang;
   if(this.props.match.params.id){
    values.redirectpage=this.props.match.params.id
   }
   
    $("#login-loader").show();
    this.refs.sbmt.setAttribute("disabled", "disabled");

    this.props.login(values, this.refs.sbmt);
  }
  forgetUser(event) {
   /* event.preventDefault();
    let values = {};
    values.language = this.props.lang;
    values.forgetemail = this.state.forgetemail;
    $("#forgetpasswordModel").show();
    //this.refs.sbmt.setAttribute("disabled", "disabled");
    Forgetpassword(values, this.refs.sbmt ,response => {
      //console.log(' errors++++ '+JSON.stringify(response));
      if (response === "success") {
        $("#forgetpasswordModel").fadeOut(100);
      }else{
        console.log(' errors*** '+response);
        $("#loginModal").scrollTop(0);
      }
   });*/
  }


  renderInput(field) {
    return (
      <div className="form-group">
        <label>{field.label}</label>
        <input
          type={field.type}
          className="form-control"
          placeholder=""
          autoComplete="off"
          {...field.input}
        />
        {field.meta.touched && field.meta.error && (
          <small className="text-danger">{field.meta.error}</small>
        )}
      </div>
    );
  }

  showLogin(e) {
    $("#login-form").delay(100).fadeIn(100);
    $("#register-form").fadeOut(100);
    $("#register-form-link").removeClass("active");
    if(e === '#login-form-link'){
      $(e).addClass("active");
    }else{
      $(e.target).addClass("active");
    }
  }

  showForget(e) {
    $("#loginModal").modal("hide");
    $("#forgetpasswordModel").modal("show");
  }

  showRegister(e) {
    // console.log('in '+e);
    $("#register-form").delay(100).fadeIn(100);
    $("#login-form").fadeOut(100);
    $("#login-form-link").removeClass("active");
    // console.log('Inside Header '+e);
    if(e === '#register-form-link'){
      $('.modal-title').text('SIGNUP');
      $(e).addClass("active");
    }else{
      $(e.target).addClass("active");
    }
  }



  render() {

    const emailaddress  = <FormattedMessage id="signup.email" defaultMessage="Email"/>
    const password  = <FormattedMessage id="signup.password" defaultMessage="Password"/>
    const logoUrl  = APP_URL+"assets/images/logo.png";
    return (
      <React.Fragment>
         {/* <div> <h1>hello</h1></div>  */}
        {/* <section id="header">
          <div className="col-xl-11 col-lg-11 col-md-12 m-auto p-0">
            <nav className="navbar navbar-expand-lg  navbar-light">
              <div className="header_logo_center">
                <Link to="/" className="navbar-brand">
                  <img src={logoUrl} alt="PilorRh Solution" />
                </Link>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#collapsibleNavbar"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse" id="collapsibleNavbar">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item" onClick={e => this.openClickNav()}>
                    <Link to="/" className="nav-link"  data-target=".navbar-collapse.show">
                      <FormattedMessage
                        id="head.homepage"
                        defaultMessage="Homepage"
                      />
                    </Link>
                  </li>
                  <li className="nav-item" onClick={e => this.openClickNav()}>
                    <Link to="/individual" className="nav-link"  data-target=".navbar-collapse.show">
                      <FormattedMessage
                        id="head.individual"
                        defaultMessage="Individual"
                      />
                    </Link>
                  </li>
                  <li className="nav-item" onClick={e => this.openClickNav()}>
                    <Link to="/hr" className="nav-link"  data-target=".navbar-collapse.show">
                      <FormattedMessage
                        id="head.professional"
                        defaultMessage="HR PROFESSIONAL TOOLS"
                      />
                    </Link>
                  </li>
                  <li className="nav-item" onClick={e => this.openClickNav()}>
                    <Link to="/enterprise" className="nav-link"  data-target=".navbar-collapse.show">
                      <FormattedMessage
                        id="head.enterprise"
                        defaultMessage="Enterprise Solutions"
                      />
                    </Link>
                  </li>
                  <li className="nav-item aboutus" onClick={e => this.openClickNav()}>
                    <Link to="/about-us" className="nav-link"  data-target=".navbar-collapse.show">
                      <FormattedMessage
                        id="head.aboutus"
                        defaultMessage="About Us"
                      />
                    </Link>
                  </li>
                  <li className="nav-item" onClick={e => this.openClickNav()}>
                    <Link to="/FAQ" className="nav-link"  data-target=".navbar-collapse.show">
                      <FormattedMessage
                        id="head.FAQ"
                        defaultMessage="FAQ"
                      />
                    </Link>
                  </li>
                  <li className="nav-item" onClick={e => this.openClickNav()}>
                    <Link to="" className="nav-link"  data-target=".navbar-collapse.show">
                      <FormattedMessage
                        id="head.Blog"
                        defaultMessage="Blog"
                      />
                    </Link>
                  </li>
                  <li className="nav-item" onClick={e => this.openClickNav()}>
                    <Link to="/contact-us" className="nav-link"  data-target=".navbar-collapse.show">
                      <FormattedMessage
                        id="head.contactus"
                        defaultMessage="Contact"
                      />
                    </Link>
                  </li>

                  <li className="nav-item dropdown" onClick={e => this.openClickNav()}>
                    <div
                      className="nav-link dropdown-toggle"
                      id="dropdown09"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <ReactCountryFlag
                        code={this.props.lang === "en" ? "gb" : "fr"}
                        svg
                      />
                    </div>
                    <div className="dropdown-menu" aria-labelledby="dropdown09">
                      <div className="dropdown-item">
                        <div onClick={() => this.props.setLocale("fr")}>
                          <ReactCountryFlag code="fr" svg />
                        </div>
                      </div>
                      <div className="dropdown-item">
                        <div onClick={() => this.props.setLocale("en")}>
                          <ReactCountryFlag code="gb" svg />
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    {this.renderAuthButton()}
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </section> */}

        <div 
        // data-backdrop="static"
         
        >
          <div >
            <div >
              <div >
                {/* <h4 className="modal-title text-center text-uppercase w-100 medium text-white font-20">
                   <FormattedMessage
                      id="head.login"
                      defaultMessage="Login"
                    />
                </h4> */}
              
              </div>
              <div >
                {/* <div className="col-md-12 mt-5">
                  {this.errorMessage()}
                  <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <Field
                      name="username"
                      type="text"
                      label="Email"
                      component={this.renderInput}
                    />
                    <Field
                      name="password"
                      type="password"
                      label="Password"
                      component={this.renderInput}
                    />

                    <button
                      type="submit"
                      className="btn btn-primary float-left"
                      ref="sbmt"
                    >
                      Submit
                    </button>
                    <div className="float-left">
                      <div
                        className="spinner-border text-primary ml-2"
                        role="status"
                        id="login-loader"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </form>
                </div> */}

                <div className="col-md-12">
                  <div className="panel panel-login">
                    <div className="panel-heading">
                      <div className="row mb-3 border-bottom">
                        {/* <div className="col-md-6">
                          <p
                            className="active"
                            id="login-form-link"
                            onClick={e => this.showLogin(e)}
                          >
                          <FormattedMessage
                            id="head.login"
                            defaultMessage="Login"
                          />
                          </p>
                        </div>
                        <div className="col-md-6">
                          <p
                            to="/"
                            id="register-form-link"
                            onClick={e => this.showRegister(e)}
                          >
                          <FormattedMessage
                            id="head.register"
                            defaultMessage="Register"
                          />
                          </p>
                        </div> */}
                      </div>
                    </div>
                    <div className="panel-body" style={{marginTop:"9%",padding:"24px 0"  }}>
                      <div className="row mb-3 border-bottom">
               
                        <div className="col-md-6 col-sm-12 m-auto">
                        <h4 className="modal-title text-center text-uppercase w-100 medium text-grey font-20">
                   <FormattedMessage
                      id="head.login"
                      defaultMessage="Login"
                    />
                </h4>
                    
                          {localStorage.getItem("Linktest")?this.msgMessage():this.errorMessage()}
                         
                          
                          <form
                            id="login-form"
                            onSubmit={this.props.handleSubmit(this.loginUser)}
                            style={{display:"block",padding: "15px 26px",border: "1px solid #000"}}
                          >
                            
                            <Field
                              name="email"
                              type="text"
                              label={emailaddress}
                              component={this.renderInput}
                            />
                            <Field
                              name="password"
                              type="password"
                              label={password}
                              component={this.renderInput}
                            />

                            <div className="form-group">
                              <div className="row">
                                <div className="m-auto d-flex">
                                <button type="submit" name="login-submit" id="login-submit" tabIndex="4"  className="form-control btn btn-primary mr-3 whitespacenowrap" ref="sbmt">
                                  <FormattedMessage id="signup.login" defaultMessage="lOG IN"/>
                                </button>
                                </div>
                              </div>
                                <p class="text-center">
                                 <a href="https://www.pilotrhsolutions.com/pilotRH/password/reset">
                                  <FormattedMessage id="signup.forget" defaultMessage="Forgot password ?"/>
                                 </a>
                                </p>
                              <hr/>
                              <div className="row">
                                <div className="m-auto d-flex signuppage">
                                  <FormattedMessage id="login.click.desc" defaultMessage="Do not have account yet Click to?"/>&nbsp;  &nbsp;
                                  <Link className="" to="register">
                                    <FormattedMessage id="signup.signup" defaultMessage=" SIGN UP"/>
                                  </Link>
                                {/* <button type="button" name="register-submit" id="register-submit" tabIndex="4"  className="" ref="sbmt" onClick={e => this.showRegister('#register-form-link')}>
                                  <FormattedMessage id="signup.signup" defaultMessage="SIGN UP"/>
                                </button> */}
                                </div>
                              </div>
                            </div>
                            <div className="float-left">
                              <div
                                className="spinner-border text-primary ml-2"
                                role="status"
                                id="login-loader"
                              >
                                <span className="sr-only">
                                <FormattedMessage
                                  id="loading"
                                  defaultMessage="Loading..."
                                />
                                </span>
                              </div>
                            </div>
                          </form>
                          <Register Roles={this.state.roles} lang={this.props.lang} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="forgetpasswordModel" data-backdrop="static">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header bg-secondary">
                <h4 className="modal-title text-center text-uppercase w-100 medium text-white font-20">
                   <FormattedMessage
                      id="head.forgot"
                      defaultMessage="Forgot Password"
                    />
                </h4>
                <button type="button" className="close" data-dismiss="modal">
                  ×
                </button>
              </div>
              <div className="modal-body">
                <div className="col-md-12">
                  <div className="panel panel-login">
                    <div className="panel-body">
                      <div className="row mb-3 border-bottom">
                        <div className="col-md-12">
                          {/* {this.errorMessage()} */}
                          <form
                            id="forget-form"
                            onSubmit={this.forgetUser}
                          >
                            <div className="form-group">
                              <label>{emailaddress}</label>
                              <input
                                type="email"
                                name="forgetemail"
                                id="forgetemail"
                                className="form-control"
                                required
                                value={this.state.forgetemail}
                                onChange={this.handleforgetemailChange}
                              />
                            </div>
                            <div className="form-group">
                              <div className="row">
                                <div className="m-auto d-flex">
                                <button type="submit" name="forget-submit" id="forget-submit" tabIndex="4"  className="form-control btn btn-primary mr-3 whitespacenowrap" ref="sbmt">
                                  <FormattedMessage id="signup.forget" defaultMessage="Apply"/>
                                </button>
                                </div>
                              </div>
                          </div>
                          </form>
                          <Register Roles={this.state.roles} lang={this.props.lang} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="errorModal" className="modal hide fade" tabIndex="-1" data-focus-on="input:first">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header bg-secondary">
                <h4 className="modal-title text-center text-uppercase w-100 medium text-white font-20">
                   Error !
                </h4>
                <button type="button" className="close" data-dismiss="modal">
                  ×
                </button>
              </div>
              <div className="modal-body">
                <div className="col-md-12" id="errorTxt"></div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const validate = values => {
  const errors = {};

  if (!values.username) {
    errors.username = "Email is required";
  }
  if (
    values.username &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)
  ) {
    errors.username = "Invalid email";
  }
  if (!values.password) {
    errors.password = "Password is required";
  }
  return errors;
};
const mapStateToProps = state => {
  return {
    isSignedIn: state.auth.isSignedIn,
    errorMessage: state.auth.loginError,
    signupError: state.auth.signupError,
    successMessage: state.auth.successMessage,
    lang: state.locale.lang
  };
};

Header = connect(
  mapStateToProps,
  { login, logout, clearMessage, setLocale }
)(Header);

export default reduxForm({
  form: "loginForm",
  validate
})(Header);

/*export default connect(
  mapStateToProps,
  { login, logout }
)(Header);
*/
