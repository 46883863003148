import React from "react";
import { FormattedMessage } from "react-intl";

function renderResult(data, testType) {
  let firstAns = data.result.firstQuesAns;
  let thirdAns = data.result.thirdQuesAns;
  let inc = 1;
  //var background = { color: "white" };
  console.log('renderResult'+testType);
  if (data.result.result) {
    if(testType === 'individual'){
      return data.result.result.selected.map((labels, index) => {
        return (
          <React.Fragment key={(inc = inc + 1)}>
            <div className="card border-0">
                <div className="row border">
                  <div className="col-sm col-md-4 bg-info text-white d-flex align-items-center font-weight-bold flex-column">
                      <div className="px-2 pt-2 font-weight-bold text-uppercase text-center">
                       {labels["name" + firstAns]}
                      </div>                  
                      <div className="">( {labels["label" + firstAns]} )</div>
                  </div>
                  <div className="col-md-8 pr-2 border-0 text-justify">
                    <div className="px-2 pb-2">{labels["desc" + firstAns]}</div>
                  </div>
                </div>
            </div>
            <div className="d-none">{(inc = inc + 1)}</div>
          </React.Fragment>
        );
      });
    }else{
      return data.result.result.selected.map((labels, index) => {
        console.log('renderResult++ => '+index+' firstAns '+firstAns+' thirdAns '+thirdAns);
        return (
          <React.Fragment key={(inc = inc + 1)}>
            <div className="card border-0">
              {firstAns === thirdAns && (
                <div className="row border">
                <div className="col-md-2 display-3 text-center res-display">N° {inc-1} </div>
                  <div className="col-sm col-md-3 bg-info text-white d-flex align-items-center font-weight-bold flex-column">
                      <div className="px-2 pt-2 font-weight-bold text-uppercase text-center">
                       {labels["name" + firstAns]}
                      </div>                  
                      <div className="">( {labels["label" + firstAns]} )</div>
                  </div>
                  <div className="col-md-6 p-0 border-0">
                    <div className="px-2 pb-2 text-justify">{labels["desc" + firstAns]}</div>
                  </div>          
                </div>
              )}
              {firstAns !== thirdAns && (
                <div className="row border">
                  <div className="col-md-2 display-3 text-center res-display"> N° {inc-1} </div>
                  <div className="col-sm col-md-3 bg-info text-white d-flex align-items-center font-weight-bold flex-column">
                    <div className="px-2 pt-2 font-weight-bold text-uppercase text-center">
                    {labels["name" + firstAns]}
                    </div>                  
                    <div className="">( {labels["label" + firstAns]} )</div>
                  </div>
                  <div className="col-md-6 p-0 border-0">
                    <div className="px-2 pb-2 text-justify">{labels["desc" + firstAns]}</div>
                  </div>
                </div>
              )}
              {firstAns !== thirdAns && (
                <div className="row border">
                  <div className="col-md-2 display-3 text-center res-display"> n° {inc} </div>
                  <div className="col-sm col-md-3 bg-info text-white d-flex align-items-center font-weight-bold flex-column">
                    <div className="px-2 pt-2 font-weight-bold text-uppercase text-center">
                      {labels["name" + thirdAns]}
                    </div>                  
                    <div className="">( {labels["label" + thirdAns]}  )</div>
                  </div>
                  <div className="col-md-6 p-0 border-0">
                    <div className="px-2 pb-2 text-justify">{labels["desc" + thirdAns]}</div>
                  </div>
                </div>
              )}
              {renderNotSelectedData(data)}
            </div>
            <div className="d-none">{(inc = inc + 1)}</div>
          </React.Fragment>
        );
      });
    }
  }
}

function renderNotSelectedData(data) {
  let inc = 1;

  if(data.testType !== 'individual'){  // check condition if test type is not individual then show unselected data also 
    if (data.result.result) {
      return data.result.result.notSelected.map((labels, index) => {
        return (
          <React.Fragment key={(inc = inc + 1)}>
            <div className="card border-0">
              {data.result.result.notSelectedValues.map((value, key) => {
                return (
                  <div className="row border" key={(inc = inc + 1)}>
                    <div className="col-md-2 display-3 text-center" />
                    <div className="col-sm col-md-3 grey-bg d-flex align-items-center font-weight-bold flex-column">
                      <div className="px-2 pt-2 font-weight-bold text-uppercase text-center">
                        {labels["name" + value]}
                      </div>
                      <div className="">( {labels["label" + value]} )</div>
                    </div>
                    <div className="col-md-6 p-0 border-0">
                      <div className="px-2 pb-2 text-justify">{labels["desc" + value]}</div>
                    </div>
                  </div>
                );
              })}
            </div>
  
            <div className="d-none">{(inc = inc + 1)}</div>
          </React.Fragment>
        );
      });
    }    
  }
}

function personalityResult(props) {
  //console.log(' ProfessionalResult  '+JSON.stringify(props));
  return (
    // <div className="col-lg-10 col-md-10 col-sm-12 mt-4 mx-auto">
    <div className="mt-4 mx-auto">
        {props.testType === 'individual' && ( /// check type of test individual
          <h4 className="text-center mb-1 mt-5">
            <FormattedMessage id="ind.talent.your.personality.title" defaultMessage="Your personality"/>
          </h4>
        )}
        {props.testType !== 'individual' && ( /// check type of test professional                
          <h4 className="text-center mb-1 mt-5">
            <FormattedMessage id="ind.talent.personality.title" defaultMessage="Personality"/>
          </h4>
        )}
      {renderResult(props, props.testType)}
    </div>
  );
}

export default personalityResult;
