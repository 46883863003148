import axios from "axios";
import { ROOT_URL } from "../helper/Config";
import { logout } from "./index";
import $ from "jquery";

export const WORK_CULTURE_DATA              = "work_culture_data";
export const WORK_CULTURE_RESULT            = "work_culture_result";
export const AUTH_ERROR_TALENT              = "auth_error_happiness";
export const MOTIVATION_DATA                = "motivation_data";
export const MOTIVATION_RESULT              = "motivation_result";
export const MOTIVATIONS_DATA             = "motivations_data";
export const MOTIVATIONS_DATAS             = "motivations_datas";
export const PERSONALITY_DATA               = "personality_data";
export const PERSONALITY_RESULT             = "personality_result";
export const PERSONALITY_RESULT_DATA        = "personality_result_data";
export const COMMUNICATION_DATA             = "communication_data";
export const COMMUNICATION_RESULT           = "communication_result";
export const COMMUNICATION_RESULT_DATA      = "communication_result_data";
export const COMBINE_RESULT                 = "combine_result";
export const CLEAR_SHARELINK_MESSAGE        = "clear_sharelink_message";
export const PROFILE_MATCHING               = "profile_matching";
export const CLEAR_PROFILE_MATCHING_MESSAGE = "clear_profile_matching_message";

export function fetchMotivation(
  token,
  language,
  hr,
  code,
  callback
) {
  let test_url;
  if (hr === null) {
    test_url = `${ROOT_URL}/recruitment-motivation/${language}`;
  } else {
    test_url = `${ROOT_URL}/recruitment-motivation/${language}/${hr}/${code}`;
  }
  //console.log('Rec Action  prop.lang => '+ language +' URL '+test_url+' '+hr);
  //console.log('Action fetchMotivation => '+test_url);

  return dispatch => {
    return axios
      .get(test_url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.data.message) {
          callback(response.data.message);
        } else {
          dispatch({
            type: MOTIVATION_DATA,
            payload: response
          });
          callback("success");
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            localStorage.clear();
            dispatch({
              type: AUTH_ERROR_TALENT,
              payload: "Session expired, please login again"
            });
            callback("Unauthenticate");
          }
        }
        callback("error");
      });
  };
}


export function combineResult(token, data, callback) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/combineResult`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        //console.log(response);
        dispatch({
          type: COMBINE_RESULT,
          payload: response.data
        });

        callback(response);
      })
      .catch(error => {
        console.log(error);

      });
  };
}

export function saveMotivation(token, data,callback) {
   //console.log('saveMotivation => '+JSON.stringify(data));
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/recruitment-motivation`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        //console.log(response);
        dispatch({
          type: MOTIVATION_RESULT,
          payload: response.data.result
        });
        callback(response.data.message);
      })
      .catch(error => {
        console.log(error);

      });
  };
}

export function getMotivationresult(token, data,callback) {
  //console.log('saveMotivation => '+JSON.stringify(data));
 return dispatch => {
   return axios
     .post(`${ROOT_URL}/recruitment-motivation-result`, data, {
       headers: {
         "content-type": "application/json",
         Authorization: `Bearer ${token}`
       }
     })
     .then(response => {
       //console.log(response);
       dispatch({
         type: MOTIVATION_RESULT,
         payload: response.data.result
       });
       dispatch({
        type: MOTIVATIONS_DATA,
        payload: response.data.username
      });
      dispatch({
        type: MOTIVATIONS_DATAS,
        payload: response.data.username2
      });
       callback(response.data.message);
     })
     .catch(error => {
       console.log(error);

     });
 };
}

export function motivationResult(token, callback) {
  //console.log('Here we go .....-->'+`${ROOT_URL}/recruitment-motivationResult`);
  //exit('Here we go...');
  return dispatch => {
    return axios
      .get(`${ROOT_URL}/recruitment-motivationResult`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        console.log('Insise saveMotivation =>'+response);
        callback(response.data);
      })
      .catch(error => {
        console.log(error.response);
      });
  };
}

export function WorkCultureResult(token, callback) {
  return dispatch => {
    return axios
      .get(`${ROOT_URL}/recruitment-work-culture-result`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        callback(response.data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logout());
          }
        }
        callback("error");
      });
  };
}

export function fetchWorkCulture(
  token,
  hr = null,
  code = null,
  language,
  callback
) {
  let test_url;
  if (hr === null) {
    test_url = `${ROOT_URL}/recruitment-work-culture/${language}`;
  } else {
    test_url = `${ROOT_URL}/recruitment-work-culture/${language}/${hr}/${code}`;
  }

  return dispatch => {
    return axios
      .get(test_url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.data.message) {
          callback(response.data.message);
        } else {
          dispatch({
            type: WORK_CULTURE_DATA,
            payload: response.data
          });
          callback("success");
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logout());
          }
        }
        callback("error");
      });
  };
}

export function saveWorkCulture(token, data, callback) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/recruitment-work-culture`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch({
          type: WORK_CULTURE_RESULT,
          payload: response.data
        });
        callback(response.data.message);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logout());
          }
        }
        callback("error");
      });
  };
}

export function getWorkCulture(token, data, callback) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/recruitment-work-result`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch({
          type: WORK_CULTURE_RESULT,
          payload: response.data
        });
        callback(response.data.message);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logout());
          }
        }
        callback("error");
      });
  };
}


// is used to get candidate profile list
export function getCandidateList(userid, callback) {
  //var userid = localStorage.getItem("userId");
  return dispatch => {
    return axios.get(`${ROOT_URL}/hr-candidate-list/${userid}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    }).then(response => {
        //console.log('Inside action getCandidateList '+JSON.stringify(response.data.candidates.length));
        callback(response.data.candidates);
        // btnCrtrl.removeAttribute("disabled");
        // $("#sharelink-loader").hide();
      }).catch(error => {
        console.log(error.response);
        // dispatch({
        //   type: SHARE_LINK,
        //   payload: ""
        // });
       // btnCrtrl.removeAttribute("disabled");
        $("#sharelink-loader").hide();
      });
  };
}
// is used to get job profile list
export function getJobProfileList(userid, callback) {
  //var userid = localStorage.getItem("userId");
  return dispatch => {
    return axios.get(`${ROOT_URL}/get-job-profile/${userid}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    }).then(response => {
        //console.log('Inside action getJobProfileList '+JSON.stringify(response.data.jobprofile));
        callback(response.data.jobprofile);
        // btnCrtrl.removeAttribute("disabled");
        // $("#sharelink-loader").hide();
      }).catch(error => {
        console.log(error.response);
        // dispatch({
        //   type: SHARE_LINK,
        //   payload: ""
        // });
       // btnCrtrl.removeAttribute("disabled");
        $("#sharelink-loader").hide();
      });
  };
}

// is used to get profile matching data of Candidate and Professinals
export function getProfileMatching(token, userData, btnCrtrl, callback) {
  return dispatch => {
    return axios.post(`${ROOT_URL}/profile-matching`, userData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        //console.log('getProfileMatching==> '+JSON.stringify(response));
        //console.log(response.status);
        if (response.data.status && response.data.status === "success") {
          dispatch({
            type: PROFILE_MATCHING,
            payload: response.data
          });
          callback(response.data);
          //$("#loginModal").modal("hide");
          //history.push("/hr");
        } else {
          //console.log("error in response");
          dispatch({
            type: PROFILE_MATCHING,
            payload: response.data
          });
          callback(response.data);
        }
        // btnCrtrl.removeAttribute("disabled");
        // $("#sharelink-loader").hide();
      }).catch(error => {
        console.log(error.response);
        // dispatch({
        //   type: PROFILE_MATCHING,
        //   payload: ""
        // });
        btnCrtrl.removeAttribute("disabled");
        $("#sharelink-loader").hide();
      });
  };
}
