import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';
import Carousel from "react-bootstrap/Carousel";
import Footer from "./includes/Footer";


class Userdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
    roles: []
    };
    
   
  }
    componentDidMount(){

      const element = document.getElementById(this.props.match.params.id);
      
      console.log(this.props.match.params.id);
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section

        element.scrollIntoView({behavior: 'smooth'});
      }
    
    }
  

  render() {
    return (
    <React.Fragment>
      <section>
      <div className="container about pages text-justify" style={{maxWidth:'892px'}}>
      
      <div className="col-md-12 bottombox" id="story">
        <section className="icon-boxed2 teaser-box mt-10"> 
          <div className="container">
            <div className="row" >
              <div className="col-md-12 img-content" style={{background: 'rgb(21 149 165)',color: '#fff', marginBottom:'1%', padding:'40px'}}>
                <p><b style={{color:'#fff', fontSize:'20px'}}>User Name -:</b> {localStorage.getItem('userName')}</p>
                <p><b style={{color:'#fff', fontSize:'20px'}}>Email -:</b> {localStorage.getItem('userEmail')}</p>
                <p><b style={{color:'#fff', fontSize:'20px'}}>Role -:</b> {localStorage.getItem('userRole')=='Hr'?"Consultant":localStorage.getItem('userRole')}</p>
                <p><b style={{color:'#fff', fontSize:'20px'}}>  
                <FormattedHTMLMessage
                    id="user_sub"
                    defaultMessage=""
                  />
                  </b>
                      {localStorage.getItem('subscription')&&localStorage.getItem('subscription')!='null'?localStorage.getItem('subscription'):"NA"}
                  </p>
                <p><b style={{color:'#fff', fontSize:'20px'}}>
                  <FormattedHTMLMessage
                    id="user_purchasedate"
                    defaultMessage=""
                  />
                  </b>
                  {localStorage.getItem('purchasedate')&& localStorage.getItem('purchasedate')!='null'?localStorage.getItem('purchasedate'):"NA"}
                   </p>
                <p><b style={{color:'#fff', fontSize:'20px'}}>
                  <FormattedHTMLMessage
                    id="user_enddate"
                    defaultMessage=""
                  />
                  </b>
                      {localStorage.getItem('enddate')&& localStorage.getItem('enddate')!='null'?localStorage.getItem('enddate'):"NA"}
                   </p>

              </div> 
            </div>
          </div>
        </section>
      </div>


  
    </div>  
  </section>
    <Footer />         
    </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    isSignedIn: state.auth.isSignedIn,
    lang: state.locale.lang
  };
};

Userdetails = connect(
  mapStateToProps,
  {}
)(Userdetails);

export default Userdetails;

