import React, { Component } from "react";
import { Link } from "react-router-dom";
//import $ from "jquery";
import { FormattedMessage, FormattedHTMLMessage,IntlProvider  } from "react-intl";
import Carousel from "react-bootstrap/Carousel";
import Footer from "./includes/Footer";
import Register from "./includes/Register";
import { history } from "../helper/History";
import $ from "jquery";
//import { FormattedHTMLMessage } from "react-intl";

class Subscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dis:"",
    };
    window.scrollTo(0, 0); // scroll page to top
   
  }  
  // componentDidMount(){
  //   if (this.props.isSignedIn) {

  //     this.setState({dis:"none"});
  //   }
  //   console.log(this.state.dis);
  // }


 
  showRegister(e) {
    var userType = localStorage.getItem("userRole");
    if(!(userType)){
      history.push("/register");
    // console.log('in '+e);
    // if(e === '#register-form-link'){
    //   $('.modal-title').text('SIGNUP');
    //   $(e).addClass("active");
    // }else{
    //   $(e.target).addClass("active");
    // }
  }
 

  }

  sendparam(){

    localStorage.setItem('classic','classic');
  }
  showRegister2(e) {
    console.log('in '+e);
    $("#loginModal").modal("show");
    $("#register-form").delay(100).fadeIn(100);
    $("#login-form").fadeOut(100);
    $("#login-form-link").removeClass("active");
    console.log('Inside Header '+e);
    if(e === '#register-form-link'){
      $('.modal-title').text('SIGNUP');
      $(e).addClass("active");
    }else{
      $(e.target).addClass("active");
    }
 
  }

  render() {
   

    return (
      <React.Fragment>

        <section className="icon-boxed1 teaser-box">
          <div className="container">
            <div className="row">
          
           
{/* 
               <h2 className="text-center w-100 ">
                <FormattedHTMLMessage
                  id="subscribe.coaching"
                  defaultMessage="All subscriptions provide access to all tools and can be recharged with credits at any time."
                />
              </h2>  */}
             
              <div className="col-lg-4">
                <div className="icon-box text-left hero-bg box-shadow minHite" style={{backgroundColor:"#18B6CE",color:"white"}} >
                  {/* <div className="teaser-icon icon-styled bg-maincolor3 m-auto d-flex align-items-center justify-content-center">
                    <img src="assets/images/scientific.png" alt="Scientific" />
                  </div> */}
                  <div>
                  <h3 className=" text-center " >
                    {/* <Link to="/" className=""> */}
                    <FormattedHTMLMessage
                      id="Recruitment.Sourcing"
                      defaultMessage="starter"
                    />
                    {/* </Link> */}
                  </h3>
               
                   <p className="text-left" style={{color:"white"}}>
                    <FormattedHTMLMessage
                      id="Recruitment.Sourcing.list"
                      defaultMessage="12 months"
                    />
                  </p> 

                  {/* <p className="font24"><FormattedHTMLMessage id="Starter.price" defaultMessage="€9,90" /></p>
             
                <h6><FormattedHTMLMessage id="Starter.valid" defaultMessage="Buy per unit"/></h6>        
         
                <Link to="/contact-professional" className="nav-link"  data-target=".navbar-collapse.show"><button class="btn btn-primary px-4 mb-4" style={{background:"#18B6CE",border:"none"}}><FormattedHTMLMessage id="subscribe2" defaultMessage="Subscribe"/></button></Link>   
               
                <h6 className="mb-3"><FormattedHTMLMessage id="Description" defaultMessage="Description"/></h6>  
                <p><FormattedHTMLMessage id="Description.paragraph1" defaultMessage="Description"/></p>   
                <p><FormattedHTMLMessage id="Description.para1" defaultMessage="Description"/></p>    */}
             </div> 
                <div style={{marginTop:'50px'}}><Link to="/buy-test" className="nav-link text-center"  data-target=".navbar-collapse.show"><button class="btn btn-primary" style={{background:"white",border:"none",color:"black"}}><FormattedHTMLMessage id="Discover" defaultMessage="Subscribe"/></button></Link></div>
                  
                </div>
        
              </div>
              <div className="col-lg-8">
                <div className="icon-box text-center hero-bg box-shadow minHite" style={{backgroundColor:"#18B6CE",color:"white"}}>
                 
                  <div>
             <img src="assets/images/recruitment.jpg" alt="" />
                  {/* <p>
                    <FormattedHTMLMessage
                      id="Starter.subtitle.2"
                      defaultMessage="12 months"
                    />
                  </p> */}
             
                  {/* <p style={{color:"white"}}>
                     <FormattedHTMLMessage
                      id="you.consultant.card2"
                      defaultMessage="€49.90"
                    />
                    </p> */}
                    {/* <p>
                     <FormattedHTMLMessage
                      id="Starter.subpricetitle1"
                      defaultMessage="per month - annual payment"
                    />
                    </p> */}

                {/* <p><FormattedHTMLMessage id="Starter.user" defaultMessage="1 user" /></p>    */}
                {/* <Link to="/buy-subscription"    className="nav-link"  data-target=".navbar-collapse.show"><button class="btn btn-primary px-4 mb-4" style={{background:"white",border:"none",color:"black"}} onClick={this.sendparam()}><FormattedHTMLMessage id="Discover" defaultMessage="Subscribe"/></button></Link> */}
                </div>     
                {/* <h6><FormattedHTMLMessage id="Description" defaultMessage="Description"/></h6>  
                <p><FormattedHTMLMessage id="Description.paragraph2" defaultMessage="Description"/></p>   */}
           
                </div>
              
              </div>

            
            
             
            </div>
          
       
            
         
          
          

          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}
export default Subscribe;
